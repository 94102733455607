"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Atmoscoop = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var constants = require("./../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var Atmoscoop = (function (_super) {
    __extends(Atmoscoop, _super);
    function Atmoscoop() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.ATMOSCOOP,
            cost: 22,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.SPACE],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 3); }),
            metadata: {
                cardNumber: '217',
                description: 'Requires 3 Science tags. Either raise the temperature 2 steps, or raise Venus 2 steps. Add 2 Floaters to ANY card.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.temperature(2).or(Size_1.Size.SMALL).venus(2).br;
                    b.floaters(2).asterix();
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    Atmoscoop.prototype.canPlay = function (player) {
        var remainingTemperatureSteps = (constants.MAX_TEMPERATURE - player.game.getTemperature()) / 2;
        var remainingVenusSteps = (constants.MAX_VENUS_SCALE - player.game.getVenusScaleLevel()) / 2;
        var stepsRaised = Math.min(remainingTemperatureSteps, remainingVenusSteps, 2);
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(this.cost + constants.REDS_RULING_POLICY_COST * stepsRaised, { titanium: true });
        }
        return true;
    };
    Atmoscoop.prototype.play = function (player) {
        var game = player.game;
        var floaterCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
        if (this.temperatureIsMaxed(game) && this.venusIsMaxed(game) && floaterCards.length === 0) {
            return undefined;
        }
        var increaseTemp = new SelectOption_1.SelectOption('Raise temperature 2 steps', 'Raise temperature', function () {
            game.increaseTemperature(player, 2);
            return undefined;
        });
        var increaseVenus = new SelectOption_1.SelectOption('Raise Venus 2 steps', 'Raise venus', function () {
            game.increaseVenusScaleLevel(player, 2);
            return undefined;
        });
        var increaseTempOrVenus = new OrOptions_1.OrOptions(increaseTemp, increaseVenus);
        increaseTempOrVenus.title = 'Choose global parameter to raise';
        var addFloaters = new SelectCard_1.SelectCard('Select card to add 2 floaters', 'Add floaters', floaterCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { qty: 2, log: true });
            return undefined;
        });
        if (!this.temperatureIsMaxed(game) && this.venusIsMaxed(game)) {
            player.game.increaseTemperature(player, 2);
        }
        else if (this.temperatureIsMaxed(game) && !this.venusIsMaxed(game)) {
            player.game.increaseVenusScaleLevel(player, 2);
        }
        switch (floaterCards.length) {
            case 1:
                player.addResourceTo(floaterCards[0], { qty: 2, log: true });
            case 0:
                if (!this.temperatureIsMaxed(game) && !this.venusIsMaxed(game)) {
                    return increaseTempOrVenus;
                }
                return undefined;
            default:
                if (!this.temperatureIsMaxed(game) && !this.venusIsMaxed(game)) {
                    increaseTempOrVenus.cb = function () { return addFloaters; };
                    return increaseTempOrVenus;
                }
                return addFloaters;
        }
    };
    Atmoscoop.prototype.getVictoryPoints = function () {
        return 1;
    };
    Atmoscoop.prototype.temperatureIsMaxed = function (game) {
        return game.getTemperature() === constants.MAX_TEMPERATURE;
    };
    Atmoscoop.prototype.venusIsMaxed = function (game) {
        return game.getVenusScaleLevel() === constants.MAX_VENUS_SCALE;
    };
    return Atmoscoop;
}(Card_1.Card));
exports.Atmoscoop = Atmoscoop;
