"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtectedHabitats = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var ProtectedHabitats = (function (_super) {
    __extends(ProtectedHabitats, _super);
    function ProtectedHabitats() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.PROTECTED_HABITATS,
            cost: 5,
            metadata: {
                cardNumber: '173',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Opponents may not remove your', Size_1.Size.SMALL, true).br;
                    b.plants(1).animals(1).microbes(1);
                }),
            },
        }) || this;
    }
    ProtectedHabitats.prototype.play = function (_player) {
        return undefined;
    };
    return ProtectedHabitats;
}(Card_1.Card));
exports.ProtectedHabitats = ProtectedHabitats;
