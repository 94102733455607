"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inventrix = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Inventrix = (function (_super) {
    __extends(Inventrix, _super);
    function Inventrix() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.INVENTRIX,
            tags: [Tags_1.Tags.SCIENCE],
            initialActionText: 'Draw 3 cards',
            startingMegaCredits: 45,
            metadata: {
                cardNumber: 'R43',
                description: 'As your first action in the game, draw 3 cards. Start with 45 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(45).nbsp.cards(3);
                    b.corpBox('effect', function (ce) {
                        ce.effect('Your temperature, oxygen, ocean, and Venus requirements are +2 or -2 steps, your choice in each case.', function (eb) {
                            eb.plate('Global requirements').startEffect.text('+/- 2');
                        });
                    });
                }),
            },
        }) || this;
    }
    Inventrix.prototype.initialAction = function (player) {
        player.drawCard(3);
        return undefined;
    };
    Inventrix.prototype.getRequirementBonus = function () {
        return 2;
    };
    Inventrix.prototype.play = function () {
        return undefined;
    };
    return Inventrix;
}(Card_1.Card));
exports.Inventrix = Inventrix;
