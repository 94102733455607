"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Card_vue_1 = require("@/client/components/card/Card.vue");
var AllCards_1 = require("@/cards/AllCards");
var GameModule_1 = require("@/GameModule");
var CardType_1 = require("@/cards/CardType");
var ICardRenderDescription_1 = require("@/cards/render/ICardRenderDescription");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var PartyName_1 = require("@/turmoil/parties/PartyName");
var GlobalEventDealer_1 = require("@/turmoil/globalEvents/GlobalEventDealer");
var GlobalEvent_vue_1 = require("@/client/components/GlobalEvent.vue");
var cards = new Map();
AllCards_1.ALL_CARD_MANIFESTS.forEach(function (manifest) {
    var module = manifest.module;
    [
        manifest.projectCards,
        manifest.corporationCards,
        manifest.preludeCards,
        manifest.standardProjects
    ].forEach(function (deck) {
        deck.factories.forEach(function (cf) {
            var card = new cf.Factory();
            var cardNumber = card.metadata.cardNumber;
            cards.set(card.name, { card: card, module: module, cardNumber: cardNumber });
        });
    });
});
var MODULE_BASE = 'b';
var MODULE_CORP = 'c';
var MODULE_PRELUDE = 'p';
var MODULE_VENUS = 'v';
var MODULE_COLONIES = 'o';
var MODULE_TURMOIL = 't';
var MODULE_COMMUNITY = '*';
var MODULE_PROMO = 'r';
var MODULE_ARES = 'a';
var MODULE_MOON = 'm';
var ALL_MODULES = "" + MODULE_BASE + MODULE_CORP + MODULE_PRELUDE + MODULE_VENUS + MODULE_COLONIES + MODULE_TURMOIL + MODULE_COMMUNITY + MODULE_PROMO + MODULE_ARES + MODULE_MOON;
exports.default = vue_1.default.extend({
    name: 'debug-ui',
    components: {
        Card: Card_vue_1.default,
        GlobalEvent: GlobalEvent_vue_1.default,
    },
    data: function () {
        return {
            filterText: '',
            filterDescription: false,
            sortById: false,
            base: true,
            corporateEra: true,
            prelude: true,
            venusNext: true,
            colonies: true,
            turmoil: true,
            community: true,
            ares: true,
            moon: true,
            promo: true,
            pathfinders: true,
            types: {
                event: true,
                active: true,
                automated: true,
                prelude: true,
                corporation: true,
                standard_project: true,
            },
        };
    },
    mounted: function () {
        var urlParams = new URLSearchParams(window.location.search);
        var searchString = urlParams.get('search');
        if (searchString) {
            this.filterText = searchString;
        }
        var modules = urlParams.get('m') || ALL_MODULES;
        this.base = modules.includes(MODULE_BASE);
        this.corporateEra = modules.includes(MODULE_CORP);
        this.prelude = modules.includes(MODULE_PRELUDE);
        this.venusNext = modules.includes(MODULE_VENUS);
        this.colonies = modules.includes(MODULE_COLONIES);
        this.turmoil = modules.includes(MODULE_TURMOIL);
        this.community = modules.includes(MODULE_COMMUNITY);
        this.promo = modules.includes(MODULE_PROMO);
        this.ares = modules.includes(MODULE_ARES);
        this.moon = modules.includes(MODULE_MOON);
    },
    watch: {
        filterText: function (newSearchString) {
            this.updateUrl(newSearchString);
        },
        base: function () {
            this.updateUrl();
        },
        corporateEra: function () {
            this.updateUrl();
        },
        prelude: function () {
            this.updateUrl();
        },
        venusNext: function () {
            this.updateUrl();
        },
        colonies: function () {
            this.updateUrl();
        },
        turmoil: function () {
            this.updateUrl();
        },
        community: function () {
            this.updateUrl();
        },
        ares: function () {
            this.updateUrl();
        },
        moon: function () {
            this.updateUrl();
        },
        promo: function () {
            this.updateUrl();
        },
        types: function () {
            this.updateUrl();
        },
    },
    computed: {
        allTypes: function () {
            return [
                CardType_1.CardType.EVENT,
                CardType_1.CardType.ACTIVE,
                CardType_1.CardType.AUTOMATED,
                CardType_1.CardType.PRELUDE,
                CardType_1.CardType.CORPORATION,
                CardType_1.CardType.STANDARD_PROJECT,
            ];
        },
    },
    methods: {
        updateUrl: function (search) {
            if (window.history.pushState) {
                var url = window.location.protocol + '//' + window.location.host + window.location.pathname;
                if (search) {
                    url = url + '?search=' + search;
                }
                var m = '';
                if (this.base)
                    m += MODULE_BASE;
                if (this.corporateEra)
                    m += MODULE_CORP;
                if (this.prelude)
                    m += MODULE_PRELUDE;
                if (this.venusNext)
                    m += MODULE_VENUS;
                if (this.colonies)
                    m += MODULE_COLONIES;
                if (this.turmoil)
                    m += MODULE_TURMOIL;
                if (this.community)
                    m += MODULE_COMMUNITY;
                if (this.promo)
                    m += MODULE_PROMO;
                if (this.ares)
                    m += MODULE_ARES;
                if (this.moon)
                    m += MODULE_MOON;
                if (m === '')
                    m = '-';
                if (m !== ALL_MODULES) {
                    url = url + '?m=' + m;
                }
                window.history.pushState({ path: url }, '', url);
            }
        },
        toggleAll: function () {
            var data = this.$data;
            data.base = !data.base;
            data.corporateEra = !data.corporateEra;
            data.prelude = !data.prelude;
            data.venusNext = !data.venusNext;
            data.colonies = !data.colonies;
            data.turmoil = !data.turmoil;
            data.community = !data.community;
            data.promo = !data.promo;
            data.ares = !data.ares;
            data.moon = !data.moon;
            data.pathfinders = !data.pathfinders;
        },
        sort: function (names) {
            var copy = __spreadArray([], names);
            if (this.$data.sortById) {
                return copy.sort(function (a, b) {
                    var _a, _b;
                    var an = ((_a = cards.get(a)) === null || _a === void 0 ? void 0 : _a.cardNumber) || '';
                    var bn = ((_b = cards.get(b)) === null || _b === void 0 ? void 0 : _b.cardNumber) || '';
                    return an.localeCompare(bn);
                });
            }
            else {
                return copy.sort();
            }
        },
        getAllStandardProjectCards: function () {
            return this.sort(AllCards_1.ALL_STANDARD_PROJECT_CARD_NAMES);
        },
        getAllProjectCards: function () {
            return this.sort(AllCards_1.ALL_PROJECT_CARD_NAMES);
        },
        getAllCorporationCards: function () {
            return this.sort(AllCards_1.ALL_CORPORATION_CARD_NAMES);
        },
        getAllPreludeCards: function () {
            return this.sort(AllCards_1.ALL_PRELUDE_CARD_NAMES);
        },
        getAllGlobalEvents: function () {
            return GlobalEventDealer_1.ALL_EVENTS.keys();
        },
        getGlobalEvent: function (globalEventName) {
            var globalEvent = GlobalEventDealer_1.getGlobalEventByName(globalEventName);
            if (globalEvent) {
                return {
                    name: globalEvent.name,
                    description: globalEvent.description,
                    revealed: globalEvent.revealedDelegate,
                    current: globalEvent.currentDelegate,
                };
            }
            return {
                name: globalEventName,
                description: 'global event not found',
                revealed: PartyName_1.PartyName.GREENS,
                current: PartyName_1.PartyName.GREENS,
            };
        },
        filtered: function (cardName) {
            var _a;
            var card = cards.get(cardName);
            if (card === undefined) {
                return false;
            }
            var filterText = this.$data.filterText.toUpperCase();
            if (this.$data.filterText.length > 0) {
                if (cardName.toUpperCase().includes(filterText) === false) {
                    if (this.$data.filterDescription) {
                        var desc = (_a = card.card.metadata) === null || _a === void 0 ? void 0 : _a.description;
                        if (ICardRenderDescription_1.isIDescription(desc)) {
                            desc = desc.text;
                        }
                        if (desc === undefined || desc.toUpperCase().includes(filterText) === false) {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }
                }
            }
            if (!this.types[card.card.cardType])
                return false;
            switch (card.module) {
                case GameModule_1.GameModule.Base:
                    return this.base === true;
                case GameModule_1.GameModule.CorpEra:
                    return this.corporateEra === true;
                case GameModule_1.GameModule.Promo:
                    return this.promo === true;
                case GameModule_1.GameModule.Venus:
                    return this.venusNext === true;
                case GameModule_1.GameModule.Colonies:
                    return this.colonies === true;
                case GameModule_1.GameModule.Prelude:
                    return this.prelude === true;
                case GameModule_1.GameModule.Turmoil:
                    return this.turmoil === true;
                case GameModule_1.GameModule.Community:
                    return this.community === true;
                case GameModule_1.GameModule.Ares:
                    return this.ares === true;
                case GameModule_1.GameModule.Moon:
                    return this.moon === true;
                case GameModule_1.GameModule.Pathfinders:
                    return this.pathfinders === true;
                default:
                    return true;
            }
        },
        getLanguageCssClass: function () {
            var language = PreferencesManager_1.PreferencesManager.load('lang') || 'en';
            return 'language-' + language;
        },
    },
});
