"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefugeeCamps = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var RefugeeCamps = (function (_super) {
    __extends(RefugeeCamps, _super);
    function RefugeeCamps() {
        var _this = _super.call(this, {
            cost: 10,
            tags: [Tags_1.Tags.EARTH],
            name: CardName_1.CardName.REFUGEE_CAMP,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.CAMP,
            metadata: {
                cardNumber: 'C33',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Decrease your M€ production 1 step to add a camp resource to this card.', function (eb) {
                        eb.production(function (pb) { return pb.megacredits(1); });
                        eb.startAction.camps();
                    }).br;
                    b.vpText('1 VP for each camp resource on this card.');
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.camps(1, 1),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    RefugeeCamps.prototype.canAct = function (player) {
        return player.getProduction(Resources_1.Resources.MEGACREDITS) >= -4;
    };
    RefugeeCamps.prototype.action = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, -1);
        player.addResourceTo(this, 1);
        return undefined;
    };
    RefugeeCamps.prototype.play = function () {
        return undefined;
    };
    RefugeeCamps.prototype.getVictoryPoints = function () {
        return this.resourceCount;
    };
    return RefugeeCamps;
}(Card_1.Card));
exports.RefugeeCamps = RefugeeCamps;
