"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicatePirateRaids = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var SyndicatePirateRaids = (function (_super) {
    __extends(SyndicatePirateRaids, _super);
    function SyndicatePirateRaids() {
        return _super.call(this, {
            name: CardName_1.CardName.SYNDICATE_PIRATE_RAIDS,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.SPACE],
            cost: 8,
            metadata: {
                description: 'ALL OPPONENTS CANNOT RETRIEVE THEIR TRADE FLEETS THIS GENERATION',
                cardNumber: 'M65',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.tradeFleet().asterix;
                }),
            },
        }) || this;
    }
    ;
    SyndicatePirateRaids.prototype.play = function (player) {
        var game = player.game;
        game.syndicatePirateRaider = player.id;
        game.log('All players except ${0} may not retrieve their trade fleets this generation.', function (b) { return b.player(player); });
        return undefined;
    };
    return SyndicatePirateRaids;
}(Card_1.Card));
exports.SyndicatePirateRaids = SyndicatePirateRaids;
