"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcologyResearch = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var ResourceType_1 = require("../../ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var EcologyResearch = (function (_super) {
    __extends(EcologyResearch, _super);
    function EcologyResearch() {
        return _super.call(this, {
            cost: 21,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.PLANT, Tags_1.Tags.ANIMAL, Tags_1.Tags.MICROBE],
            name: CardName_1.CardName.ECOLOGY_RESEARCH,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                description: 'Increase your plant production 1 step for each colony you own. Add 1 animal to ANOTHER card and 2 microbes to ANOTHER card.',
                cardNumber: 'C09',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.plants(1).slash().colonies(1, { size: Size_1.Size.SMALL }); }).br;
                    b.animals(1).asterix().nbsp.nbsp.microbes(2).asterix();
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    EcologyResearch.prototype.play = function (player) {
        var coloniesCount = player.getColoniesCount();
        player.addProduction(Resources_1.Resources.PLANTS, coloniesCount, { log: true });
        var animalCards = player.getResourceCards(ResourceType_1.ResourceType.ANIMAL);
        if (animalCards.length) {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.ANIMAL, { count: 1 }));
        }
        var microbeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        if (microbeCards.length) {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.MICROBE, { count: 2 }));
        }
        return undefined;
    };
    EcologyResearch.prototype.getVictoryPoints = function () {
        return 1;
    };
    return EcologyResearch;
}(Card_1.Card));
exports.EcologyResearch = EcologyResearch;
