"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'Party',
    props: {
        party: {
            type: Object,
        },
        isDominant: {
            type: Boolean,
        },
        isAvailable: {
            type: Boolean,
        },
    },
    methods: {
        partyNameToCss: function (party) {
            if (party === undefined) {
                console.warn('no party provided');
                return '';
            }
            return party.toLowerCase().split(' ').join('_');
        },
        getDominantClass: function () {
            return 'select-party-leader-spot' + (this.isDominant ? ' dominance-marker' : '');
        },
        getUnavailablePartyClass: function () {
            return this.isAvailable ? '' : ' unavailable-party';
        },
    },
});
