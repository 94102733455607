"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawCards = void 0;
var DeferredAction_1 = require("./DeferredAction");
var SelectCard_1 = require("../inputs/SelectCard");
var SelectHowToPayDeferred_1 = require("./SelectHowToPayDeferred");
var LogHelper_1 = require("../LogHelper");
var LogType;
(function (LogType) {
    LogType["DREW"] = "drew";
    LogType["BOUGHT"] = "bought";
    LogType["DREW_VERBOSE"] = "drew_verbose";
})(LogType || (LogType = {}));
var DrawCards = (function () {
    function DrawCards(player, count, options, cb) {
        if (count === void 0) { count = 1; }
        if (options === void 0) { options = {}; }
        this.player = player;
        this.count = count;
        this.options = options;
        this.cb = cb;
        this.priority = DeferredAction_1.Priority.DRAW_CARDS;
    }
    DrawCards.prototype.execute = function () {
        var _this = this;
        var game = this.player.game;
        var cards = game.dealer.drawProjectCardsByCondition(game, this.count, function (card) {
            if (_this.options.resource !== undefined && _this.options.resource !== card.resourceType) {
                return false;
            }
            if (_this.options.cardType !== undefined && _this.options.cardType !== card.cardType) {
                return false;
            }
            if (_this.options.tag !== undefined && !card.tags.includes(_this.options.tag)) {
                return false;
            }
            if (_this.options.include !== undefined && !_this.options.include(card)) {
                return false;
            }
            return true;
        });
        return this.cb(cards);
    };
    ;
    DrawCards.keepAll = function (player, count, options) {
        if (count === void 0) { count = 1; }
        return new DrawCards(player, count, options, function (cards) {
            return DrawCards.keep(player, cards, options === undefined ? LogType.DREW : LogType.DREW_VERBOSE);
        });
    };
    DrawCards.keepSome = function (player, count, options) {
        if (count === void 0) { count = 1; }
        return new DrawCards(player, count, options, function (cards) { return DrawCards.choose(player, cards, options); });
    };
    DrawCards.keep = function (player, cards, logType) {
        var _a;
        if (logType === void 0) { logType = LogType.DREW; }
        (_a = player.cardsInHand).push.apply(_a, cards);
        if (logType === LogType.DREW_VERBOSE) {
            LogHelper_1.LogHelper.logDrawnCards(player, cards);
        }
        else {
            player.game.log('${0} ${1} ${2} card(s)', function (b) { return b.player(player).string(logType).number(cards.length); });
            LogHelper_1.LogHelper.logDrawnCards(player, cards, true);
        }
        return undefined;
    };
    DrawCards.discard = function (player, preserve, discard) {
        discard.forEach(function (card) {
            if (preserve.find(function (f) { return f.name === card.name; }) === undefined) {
                player.game.dealer.discard(card);
            }
        });
    };
    DrawCards.choose = function (player, cards, options) {
        var _this = this;
        var max = options.keepMax || cards.length;
        if (options.paying) {
            max = Math.min(max, Math.floor(player.spendableMegacredits() / player.cardCost));
        }
        var min = options.paying ? 0 : options.keepMax;
        var msg = options.paying ? (max === 0 ? 'You cannot afford any cards' : 'Select card(s) to buy') :
            "Select " + max + " card(s) to keep";
        var button = max === 0 ? 'Ok' : (options.paying ? 'Buy' : 'Select');
        var cb = function (selected) {
            if (options.paying && selected.length > 0) {
                player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, selected.length * player.cardCost, {
                    title: 'Select how to pay for cards',
                    afterPay: function () {
                        _this.keep(player, selected, LogType.BOUGHT);
                        _this.discard(player, selected, cards);
                    },
                }));
            }
            else if (options.logDrawnCard === true) {
                _this.keep(player, selected, LogType.DREW_VERBOSE);
                _this.discard(player, selected, cards);
            }
            else {
                _this.keep(player, selected, options.paying ? LogType.BOUGHT : LogType.DREW);
                _this.discard(player, selected, cards);
            }
            return undefined;
        };
        return new SelectCard_1.SelectCard(msg, button, cards, cb, max, min, false, undefined, false);
    };
    return DrawCards;
}());
exports.DrawCards = DrawCards;
