"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var SpaceBonus_1 = require("@/SpaceBonus");
exports.default = vue_1.default.extend({
    name: 'bonus',
    props: {
        bonus: {
            type: Array,
        },
    },
    methods: {
        getClass: function (idx, bonus) {
            var ret = 'board-space-bonus board-space-bonus--';
            if (bonus === SpaceBonus_1.SpaceBonus.TITANIUM) {
                ret += 'titanium';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.STEEL) {
                ret += 'steel';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.PLANT) {
                ret += 'plant';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.DRAW_CARD) {
                ret += 'card';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.HEAT) {
                ret += 'heat';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.OCEAN) {
                ret += 'bonusocean';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.MICROBE) {
                ret += 'microbe';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.DATA) {
                ret += 'data';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.ENERGY_PRODUCTION) {
                ret += 'energy-production';
            }
            else if (bonus === SpaceBonus_1.SpaceBonus.SCIENCE) {
                ret += 'science';
            }
            ret += ' board-space-bonus-pos--' + idx.toString();
            return ret;
        },
    },
});
