"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'Award',
    props: {
        award: {
            type: Object,
            required: true,
        },
        showScores: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            showDescription: false,
        };
    },
    methods: {
        toggleDescription: function () {
            this.showDescription = !this.showDescription;
        },
    },
    computed: {
        maAwardClass: function () {
            return 'ma-name--' + this.award.award.name.replace(/ /g, '-').toLowerCase();
        },
        sortedScores: function () {
            return __spreadArray([], this.award.scores).sort(function (s1, s2) { return s2.playerScore - s1.playerScore; });
        },
    },
});
