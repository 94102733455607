"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpaceRelay = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../Tags");
var Options_1 = require("../Options");
var SpaceRelay = (function (_super) {
    __extends(SpaceRelay, _super);
    function SpaceRelay() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.SPACE_RELAY,
            cost: 13,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.JOVIAN],
            metadata: {
                cardNumber: 'Pf33',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('Whenever you play a card with a Jovian tag, including this, draw a card.', function (eb) {
                        eb.jovian({ amount: 1, played: Options_1.played }).startEffect.cards(1);
                    }).br;
                    b.production(function (pb) { return pb.megacredits(1); });
                }),
                description: 'Increase your MC production 1 step.',
            },
        }) || this;
    }
    SpaceRelay.prototype.onCardPlayed = function (player, card) {
        if (card.tags.includes(Tags_1.Tags.JOVIAN)) {
            player.drawCard();
        }
    };
    SpaceRelay.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        return undefined;
    };
    return SpaceRelay;
}(Card_1.Card));
exports.SpaceRelay = SpaceRelay;
