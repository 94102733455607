"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var SelectPlayerRow_vue_1 = require("@/client/components/SelectPlayerRow.vue");
exports.default = vue_1.default.extend({
    name: 'SelectPartyPlayer',
    props: {
        players: {
            type: Array,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data: function () {
        return {
            selectedPlayer: undefined,
        };
    },
    components: {
        Button: Button_vue_1.default,
        'select-player-row': SelectPlayerRow_vue_1.default,
    },
    methods: {
        saveData: function () {
            var result = [];
            result.push([]);
            if (this.selectedPlayer !== undefined) {
                result[0].push(this.selectedPlayer);
            }
            this.onsave(result);
        },
    },
});
