"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrediCor = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var CrediCor = (function (_super) {
    __extends(CrediCor, _super);
    function CrediCor() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.CREDICOR,
            startingMegaCredits: 57,
            metadata: {
                cardNumber: 'R08',
                description: 'You start with 57 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.megacredits(57);
                    b.corpBox('effect', function (ce) {
                        ce.effect('After you pay for a card or standard project with a basic cost of 20M€ or more, you gain 4 M€.', function (eb) {
                            eb.minus().megacredits(20).startEffect.megacredits(4);
                        });
                    });
                }),
            },
        }) || this;
    }
    CrediCor.prototype.effect = function (player, card) {
        if (player.corporationCard !== undefined && player.corporationCard.name === this.name && card.cost >= 20) {
            player.megaCredits += 4;
        }
    };
    CrediCor.prototype.onCardPlayed = function (player, card) {
        this.effect(player, card);
    };
    CrediCor.prototype.onStandardProject = function (player, project) {
        this.effect(player, project);
    };
    CrediCor.prototype.play = function () {
        return undefined;
    };
    return CrediCor;
}(Card_1.Card));
exports.CrediCor = CrediCor;
