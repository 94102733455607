"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlliedBanks = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var AlliedBanks = (function (_super) {
    __extends(AlliedBanks, _super);
    function AlliedBanks() {
        return _super.call(this, {
            name: CardName_1.CardName.ALLIED_BANKS,
            tags: [Tags_1.Tags.EARTH],
            metadata: {
                cardNumber: 'P01',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(4); }).br;
                    b.megacredits(3);
                }),
                description: 'Increase your M€ production 4 steps. Gain 3 M€.',
            },
        }) || this;
    }
    AlliedBanks.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 4);
        player.megaCredits += 3;
        return undefined;
    };
    return AlliedBanks;
}(PreludeCard_1.PreludeCard));
exports.AlliedBanks = AlliedBanks;
