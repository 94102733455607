"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonCard = void 0;
var ProjectCard_1 = require("../ProjectCard");
var MoonCard = (function (_super) {
    __extends(MoonCard, _super);
    function MoonCard(properties, moonCardProperties) {
        if (moonCardProperties === void 0) { moonCardProperties = {}; }
        var _this = _super.call(this, properties) || this;
        _this.moonCardProperties = moonCardProperties;
        return _this;
    }
    Object.defineProperty(MoonCard.prototype, "tilesBuilt", {
        get: function () {
            return this.moonCardProperties.tilesBuilt || [];
        },
        enumerable: false,
        configurable: true
    });
    MoonCard.prototype.canPlay = function (player) {
        return player.canAdjustProduction(this.productionBox);
    };
    MoonCard.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        player.adjustProduction(this.productionBox, { log: true });
        return undefined;
    };
    return MoonCard;
}(ProjectCard_1.ProjectCard));
exports.MoonCard = MoonCard;
