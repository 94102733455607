"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'SelectProductionToLose',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data: function () {
        return {
            megacredits: 0,
            steel: 0,
            titanium: 0,
            plants: 0,
            energy: 0,
            heat: 0,
            warning: undefined,
        };
    },
    methods: {
        canDeductMegaCredits: function () {
            return this.playerinput.payProduction.units.megacredits > -5;
        },
        canDeductSteel: function () {
            return this.playerinput.payProduction.units.steel > 0;
        },
        canDeductTitanium: function () {
            return this.playerinput.payProduction.units.titanium > 0;
        },
        canDeductPlants: function () {
            return this.playerinput.payProduction.units.plants > 0;
        },
        canDeductEnergy: function () {
            return this.playerinput.payProduction.units.energy > 0;
        },
        canDeductHeat: function () {
            return this.playerinput.payProduction.units.heat > 0;
        },
        hasWarning: function () {
            return this.$data.warning !== undefined;
        },
        delta: function (type, direction) {
            var expendableProductionQuantity = function (type, model) {
                switch (type) {
                    case 'megacredits':
                        return model.units.megacredits + 5;
                    case 'steel':
                        return model.units.steel;
                    case 'titanium':
                        return model.units.titanium;
                    case 'plants':
                        return model.units.plants;
                    case 'energy':
                        return model.units.energy;
                    case 'heat':
                        return model.units.heat;
                }
                return -1;
            };
            var current = this.$data[type];
            var newValue = current + direction;
            var lowestValue = (type === 'megacredit') ? -5 : 0;
            var expendableQuantity = expendableProductionQuantity(type, this.playerinput.payProduction);
            newValue = Math.min(Math.max(newValue, lowestValue), expendableQuantity);
            this.$data[type] = newValue;
        },
        saveData: function () {
            var htp = {
                megacredits: this.$data.megacredits,
                steel: this.$data.steel,
                titanium: this.$data.titanium,
                plants: this.$data.plants,
                energy: this.$data.energy,
                heat: this.$data.heat,
            };
            var sum = this.$data.megacredits +
                this.$data.steel +
                this.$data.titanium +
                this.$data.plants +
                this.$data.energy +
                this.$data.heat;
            if (sum !== this.playerinput.payProduction.cost) {
                this.$data.warning = "Pay a total of " + this.playerinput.payProduction.cost + " production units";
                return;
            }
            this.onsave([[
                    JSON.stringify(htp),
                ]]);
        },
    },
});
