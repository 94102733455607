"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Deck = void 0;
var CARD_RENAMES = new Map([]);
var Deck = (function () {
    function Deck(cards) {
        this.factories = new Map(cards.map(function (cf) { return [cf.cardName, cf]; }));
    }
    Deck.prototype.findByCardName = function (name) {
        var updatedName = CARD_RENAMES.get(name);
        if (updatedName !== undefined) {
            name = updatedName;
        }
        return this.factories.get(name);
    };
    return Deck;
}());
exports.Deck = Deck;
