"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UndergroundCity = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var UndergroundCity = (function (_super) {
    __extends(UndergroundCity, _super);
    function UndergroundCity() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.UNDERGROUND_CITY,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 18,
            productionBox: Units_1.Units.of({ energy: -2, steel: 2 }),
            metadata: {
                cardNumber: '032',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(2).br;
                        pb.plus().steel(2);
                    }).nbsp.city();
                }),
                description: 'Place a City tile. Decrease your Energy production 2 steps and increase your steel production 2 steps.',
            },
        }) || this;
    }
    UndergroundCity.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 2 && player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    UndergroundCity.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (foundSpace) {
            player.game.addCityTile(player, foundSpace.id);
            player.addProduction(Resources_1.Resources.ENERGY, -2);
            player.addProduction(Resources_1.Resources.STEEL, 2);
            return undefined;
        });
    };
    return UndergroundCity;
}(Card_1.Card));
exports.UndergroundCity = UndergroundCity;
