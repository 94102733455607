"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvestmentLoan = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var InvestmentLoan = (function (_super) {
    __extends(InvestmentLoan, _super);
    function InvestmentLoan() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.INVESTMENT_LOAN,
            tags: [Tags_1.Tags.EARTH],
            cost: 3,
            metadata: {
                cardNumber: '151',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(-1); }).nbsp.megacredits(10);
                }),
                description: 'Decrease your M€ production 1 step. Gain 10 M€.',
            },
        }) || this;
    }
    InvestmentLoan.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.MEGACREDITS) >= -4;
    };
    InvestmentLoan.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, -1);
        player.megaCredits += 10;
        return undefined;
    };
    return InvestmentLoan;
}(Card_1.Card));
exports.InvestmentLoan = InvestmentLoan;
