"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrOptions = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var OrOptions = (function () {
    function OrOptions() {
        var options = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            options[_i] = arguments[_i];
        }
        this.title = 'Select one option';
        this.buttonLabel = 'Save';
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.OR_OPTIONS;
        this.options = options;
    }
    OrOptions.prototype.cb = function () {
        return undefined;
    };
    return OrOptions;
}());
exports.OrOptions = OrOptions;
