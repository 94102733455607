"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.JupiterFloatingStation = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var JupiterFloatingStation = (function (_super) {
    __extends(JupiterFloatingStation, _super);
    function JupiterFloatingStation() {
        var _this = _super.call(this, {
            cost: 9,
            tags: [Tags_1.Tags.JOVIAN],
            name: CardName_1.CardName.JUPITER_FLOATING_STATION,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 3); }),
            metadata: {
                cardNumber: 'C19',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 floater to a JOVIAN CARD.', function (eb) {
                        eb.empty().startAction.floaters(1, { secondaryTag: Tags_1.Tags.JOVIAN });
                    }).br;
                    b.or().br;
                    b.action('Gain 1 M€ for every floater here [MAX 4].', function (eb) {
                        eb.empty().startAction;
                        eb.megacredits(1).slash().floaters(1).text('[max 4]', Size_1.Size.SMALL);
                    });
                }),
                description: {
                    text: 'Requires 3 Science tags.',
                    align: 'left',
                },
                victoryPoints: 1,
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    JupiterFloatingStation.prototype.canAct = function () {
        return true;
    };
    JupiterFloatingStation.prototype.action = function (player) {
        var _this = this;
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Add 1 floater to a Jovian card', 'Add floater', function () {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, {
                restrictedTag: Tags_1.Tags.JOVIAN, title: 'Add 1 floater to a Jovian card',
            }));
            return undefined;
        }), new SelectOption_1.SelectOption('Gain 1 M€ per floater here (max 4) ', 'Gain M€', function () {
            player.addResource(Resources_1.Resources.MEGACREDITS, Math.min(_this.resourceCount, 4), { log: true });
            return undefined;
        }));
    };
    JupiterFloatingStation.prototype.play = function () {
        return undefined;
    };
    JupiterFloatingStation.prototype.getVictoryPoints = function () {
        return 1;
    };
    return JupiterFloatingStation;
}(Card_1.Card));
exports.JupiterFloatingStation = JupiterFloatingStation;
