"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'ShiftAresGlobalParameters',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data: function () {
        var hazardData = this.playerinput.aresData.hazardData;
        return {
            hazardData: hazardData,
            lowOceanDelta: 0,
            highOceanDelta: 0,
            temperatureDelta: 0,
            oxygenDelta: 0,
            ADJUSTMENT_RANGE: [-1, 0, 1],
        };
    },
    methods: {
        saveData: function () {
            var response = {
                lowOceanDelta: this.$data.lowOceanDelta,
                highOceanDelta: this.$data.highOceanDelta,
                temperatureDelta: this.$data.temperatureDelta,
                oxygenDelta: this.$data.oxygenDelta,
            };
            this.onsave([[
                    JSON.stringify(response),
                ]]);
        },
    },
});
