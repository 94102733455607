"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Incite = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Turmoil_1 = require("../../turmoil/Turmoil");
var Incite = (function (_super) {
    __extends(Incite, _super);
    function Incite() {
        return _super.call(this, {
            name: CardName_1.CardName.INCITE,
            tags: [Tags_1.Tags.SCIENCE],
            startingMegaCredits: 32,
            cardType: CardType_1.CardType.CORPORATION,
            initialActionText: 'Place 2 delegates in one party',
            metadata: {
                cardNumber: 'R37',
                description: 'You start with 32 M€. As your first action, place two delegates in one party.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(32).nbsp.delegates(2);
                    b.corpBox('effect', function (ce) {
                        ce.vSpace(Size_1.Size.LARGE);
                        ce.effect(undefined, function (eb) {
                            eb.startEffect.influence();
                        });
                        ce.vSpace(Size_1.Size.SMALL);
                        ce.effect('You have +1 influence. When you send a delegate using the lobbying action, you pay 2 M€ less for it.', function (eb) {
                            eb.delegates(1).startEffect.megacredits(-2);
                        });
                    });
                }),
            },
        }) || this;
    }
    Incite.prototype.play = function (player) {
        Turmoil_1.Turmoil.getTurmoil(player.game).addInfluenceBonus(player);
        return undefined;
    };
    Incite.prototype.initialAction = function (player) {
        var title = 'Incite first action - Select where to send two delegates';
        player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, title, { count: 2, source: 'reserve' }));
        return undefined;
    };
    return Incite;
}(Card_1.Card));
exports.Incite = Incite;
