"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveResourcesFromCard = void 0;
var ResourceType_1 = require("../ResourceType");
var OrOptions_1 = require("../inputs/OrOptions");
var SelectCard_1 = require("../inputs/SelectCard");
var SelectOption_1 = require("../inputs/SelectOption");
var CardName_1 = require("../CardName");
var DeferredAction_1 = require("./DeferredAction");
var animalsProtectedCards = [CardName_1.CardName.PETS, CardName_1.CardName.BIOENGINEERING_ENCLOSURE];
var RemoveResourcesFromCard = (function () {
    function RemoveResourcesFromCard(player, resourceType, count, ownCardsOnly, mandatory, title) {
        if (count === void 0) { count = 1; }
        if (ownCardsOnly === void 0) { ownCardsOnly = false; }
        if (mandatory === void 0) { mandatory = true; }
        if (title === void 0) { title = 'Select card to remove ' + count + ' ' + resourceType + '(s)'; }
        this.player = player;
        this.resourceType = resourceType;
        this.count = count;
        this.ownCardsOnly = ownCardsOnly;
        this.mandatory = mandatory;
        this.title = title;
        this.priority = DeferredAction_1.Priority.ATTACK_OPPONENT;
        if (ownCardsOnly) {
            this.priority = DeferredAction_1.Priority.LOSE_RESOURCE_OR_PRODUCTION;
        }
    }
    RemoveResourcesFromCard.prototype.execute = function () {
        var _this = this;
        if (this.ownCardsOnly === false && this.player.game.isSoloMode()) {
            return undefined;
        }
        var resourceCards = RemoveResourcesFromCard.getAvailableTargetCards(this.player, this.resourceType, this.ownCardsOnly);
        if (resourceCards.length === 0) {
            return undefined;
        }
        var selectCard = new SelectCard_1.SelectCard(this.title, 'Remove resource(s)', resourceCards, function (foundCards) {
            var card = foundCards[0];
            var owner = _this.player.game.getCardPlayer(card.name);
            owner.removeResourceFrom(card, _this.count, _this.player.game, _this.player);
            return undefined;
        });
        selectCard.showOwner = true;
        if (this.mandatory) {
            if (resourceCards.length === 1) {
                var card = resourceCards[0];
                var owner = this.player.game.getCardPlayer(card.name);
                owner.removeResourceFrom(card, this.count, this.player.game, this.player);
                return undefined;
            }
            return selectCard;
        }
        return new OrOptions_1.OrOptions(selectCard, new SelectOption_1.SelectOption('Do not remove', 'Confirm', function () {
            return undefined;
        }));
    };
    RemoveResourcesFromCard.getAvailableTargetCards = function (player, resourceType, ownCardsOnly) {
        if (ownCardsOnly === void 0) { ownCardsOnly = false; }
        var resourceCards;
        if (ownCardsOnly) {
            if (resourceType === ResourceType_1.ResourceType.ANIMAL) {
                resourceCards = player.getCardsWithResources(resourceType).filter(function (card) { return animalsProtectedCards.includes(card.name) === false; });
            }
            else {
                resourceCards = player.getCardsWithResources(resourceType);
            }
        }
        else {
            resourceCards = [];
            player.game.getPlayers().forEach(function (p) {
                switch (resourceType) {
                    case ResourceType_1.ResourceType.ANIMAL:
                        if (p.hasProtectedHabitats() && player.id !== p.id)
                            return;
                        resourceCards.push.apply(resourceCards, p.getCardsWithResources(resourceType).filter(function (card) { return animalsProtectedCards.includes(card.name) === false; }));
                        break;
                    case ResourceType_1.ResourceType.MICROBE:
                        if (p.hasProtectedHabitats() && player.id !== p.id)
                            return;
                    default:
                        resourceCards.push.apply(resourceCards, p.getCardsWithResources(resourceType));
                }
            });
        }
        return resourceCards;
    };
    return RemoveResourcesFromCard;
}());
exports.RemoveResourcesFromCard = RemoveResourcesFromCard;
