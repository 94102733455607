"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerMixin = void 0;
var CardType_1 = require("@/cards/CardType");
var ActiveCardsSortingOrder_1 = require("@/client/utils/ActiveCardsSortingOrder");
exports.PlayerMixin = {
    'name': 'PlayerMixin',
    'methods': {
        sortActiveCards: ActiveCardsSortingOrder_1.sortActiveCards,
        getCardsByType: function (inCards, cardType) {
            var cards = [];
            for (var index = 0; index < inCards.length; index++) {
                if (cardType.includes(inCards[index].cardType)) {
                    cards.push(inCards[index]);
                }
            }
            return cards.reverse();
        },
        getPlayerCardsPlayed: function (player, withCorp) {
            var playedCardsNr = player.playedCards.length || 0;
            return withCorp ? playedCardsNr + 1 : playedCardsNr;
        },
        getActiveCardType: function () {
            return CardType_1.CardType.ACTIVE;
        },
        getEventCardType: function () {
            return CardType_1.CardType.EVENT;
        },
        getAutomatedCardType: function () {
            return CardType_1.CardType.AUTOMATED;
        },
        getPreludeCardType: function () {
            return CardType_1.CardType.PRELUDE;
        },
        isCardActivated: function (card, player) {
            return ((player !== undefined &&
                player.actionsThisGeneration !== undefined &&
                player.actionsThisGeneration.includes(card.name)) || card.isDisabled);
        },
    },
};
