"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sabotage = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.production(function (pb) { return pb.minus().energy(1).steel(1); }).br;
    b.steel(1).slash().nbsp.influence({ size: Size_1.Size.SMALL });
});
var Sabotage = (function () {
    function Sabotage() {
        this.name = GlobalEventName_1.GlobalEventName.SABOTAGE;
        this.description = 'Decrease steel and energy production 1 step each. Gain 1 steel per influence.';
        this.revealedDelegate = PartyName_1.PartyName.UNITY;
        this.currentDelegate = PartyName_1.PartyName.REDS;
        this.renderData = RENDER_DATA;
    }
    Sabotage.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            if (player.getProduction(Resources_1.Resources.ENERGY) >= 1) {
                player.addProduction(Resources_1.Resources.ENERGY, -1, { log: true, from: _this.name });
            }
            if (player.getProduction(Resources_1.Resources.STEEL) >= 1) {
                player.addProduction(Resources_1.Resources.STEEL, -1, { log: true, from: _this.name });
            }
            player.addResource(Resources_1.Resources.STEEL, turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    return Sabotage;
}());
exports.Sabotage = Sabotage;
