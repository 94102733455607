"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaProjectOffice = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Card_1 = require("../Card");
var CardRequirements_1 = require("../CardRequirements");
var Size_1 = require("../render/Size");
var LunaProjectOffice = (function (_super) {
    __extends(LunaProjectOffice, _super);
    function LunaProjectOffice() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_PROJECT_OFFICE,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 4,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 2); }),
            metadata: {
                description: 'Requires 2 science tags.',
                cardNumber: 'M20',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('DRAW 5 CARDS DURING THE RESEARCH PHASE FOR THE NEXT 2 GENERATIONS.', Size_1.Size.MEDIUM, true);
                }),
            },
        }) || this;
    }
    ;
    LunaProjectOffice.prototype.play = function (player) {
        MoonExpansion_1.MoonExpansion.moonData(player.game).lunaProjectOfficeLastGeneration = player.game.generation + 2;
        return undefined;
    };
    LunaProjectOffice.isActive = function (player) {
        return MoonExpansion_1.MoonExpansion.ifElseMoon(player.game, function (moonData) {
            var _a;
            if (!player.playedCards.some(function (card) { return card.name === CardName_1.CardName.LUNA_PROJECT_OFFICE; })) {
                return false;
            }
            return player.game.generation <= ((_a = moonData.lunaProjectOfficeLastGeneration) !== null && _a !== void 0 ? _a : -1);
        }, function () { return false; });
    };
    return LunaProjectOffice;
}(Card_1.Card));
exports.LunaProjectOffice = LunaProjectOffice;
