"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcoSabotage = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.text('max 3').plants(1).influence({ size: Size_1.Size.SMALL });
});
var EcoSabotage = (function () {
    function EcoSabotage() {
        this.name = GlobalEventName_1.GlobalEventName.ECO_SABOTAGE;
        this.description = 'Lose all plants except 3 + influence.';
        this.revealedDelegate = PartyName_1.PartyName.GREENS;
        this.currentDelegate = PartyName_1.PartyName.REDS;
        this.renderData = RENDER_DATA;
    }
    EcoSabotage.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var plants = player.plants;
            var maxPlants = 3 + turmoil.getPlayerInfluence(player);
            var plantDecrease = Math.max(0, plants - maxPlants);
            player.deductResource(Resources_1.Resources.PLANTS, plantDecrease, { log: true, from: _this.name });
        });
    };
    return EcoSabotage;
}());
exports.EcoSabotage = EcoSabotage;
