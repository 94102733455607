"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectHowToPayForProjectCard = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var Units_1 = require("../Units");
var MoonExpansion_1 = require("../moon/MoonExpansion");
var SelectHowToPayForProjectCard = (function () {
    function SelectHowToPayForProjectCard(player, cards, cb) {
        this.cards = cards;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_HOW_TO_PAY_FOR_PROJECT_CARD;
        this.title = 'Play project card';
        this.buttonLabel = 'Play card';
        this.microbes = player.getMicrobesCanSpend();
        this.floaters = player.getFloatersCanSpend();
        this.canUseHeat = player.canUseHeatAsMegaCredits;
        this.scienceResources = player.getSpendableScienceResources();
        this.reserveUnits = this.cards.map(function (card) {
            return card.reserveUnits ? MoonExpansion_1.MoonExpansion.adjustedReserveCosts(player, card) : Units_1.Units.EMPTY;
        });
    }
    return SelectHowToPayForProjectCard;
}());
exports.SelectHowToPayForProjectCard = SelectHowToPayForProjectCard;
