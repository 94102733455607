"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CeresSpaceport = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SpaceName_1 = require("../../SpaceName");
var SpaceType_1 = require("../../SpaceType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../Resources");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var Options_1 = require("../Options");
var CeresSpaceport = (function (_super) {
    __extends(CeresSpaceport, _super);
    function CeresSpaceport() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.CERES_SPACEPORT,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.JOVIAN, Tags_1.Tags.CITY, Tags_1.Tags.SPACE],
            cost: 36,
            tr: { oceans: 1 },
            metadata: {
                cardNumber: 'Pf14',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(2); })
                        .production(function (pb) { return pb.titanium(1).slash().jovian({ amount: 2, played: Options_1.played }); })
                        .br
                        .cards(1).oceans(1).city().asterix().br;
                }),
                victoryPoints: 1,
                description: 'Increase your MC production 2 steps, and titanium production 1 step for every 2 jovian tags (including these.) ' +
                    'Draw a card. Place an ocean tile. Place a city tile ON THE RESERVED AREA.',
            },
        }) || this;
    }
    CeresSpaceport.prototype.getVictoryPoints = function () {
        return 1;
    };
    CeresSpaceport.prototype.play = function (player) {
        var game = player.game;
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        player.addProduction(Resources_1.Resources.TITANIUM, (1 + Math.floor(player.getTagCount(Tags_1.Tags.JOVIAN) / 2)), { log: true });
        player.drawCard();
        player.game.addCityTile(player, SpaceName_1.SpaceName.CERES_SPACEPORT, SpaceType_1.SpaceType.COLONY);
        game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
        return undefined;
    };
    return CeresSpaceport;
}(Card_1.Card));
exports.CeresSpaceport = CeresSpaceport;
