"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Diversity = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.text('9').diverseTag(1).influence({ size: Size_1.Size.SMALL }).colon().megacredits(10);
});
var Diversity = (function () {
    function Diversity() {
        this.name = GlobalEventName_1.GlobalEventName.DIVERSITY;
        this.description = 'Gain 10 M€ if you have 9 or more different tags. Influence counts as unique tags.';
        this.revealedDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.currentDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.renderData = RENDER_DATA;
    }
    Diversity.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            if (player.getDistinctTagCount(false) + turmoil.getPlayerInfluence(player) >= 9) {
                player.addResource(Resources_1.Resources.MEGACREDITS, 10, { log: true, from: _this.name });
            }
        });
    };
    return Diversity;
}());
exports.Diversity = Diversity;
