"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildColonyStandardProject = void 0;
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var StandardProjectCard_1 = require("../StandardProjectCard");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants = require("../../constants");
var ColonyName_1 = require("../../colonies/ColonyName");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var BuildColonyStandardProject = (function (_super) {
    __extends(BuildColonyStandardProject, _super);
    function BuildColonyStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.BUILD_COLONY_STANDARD_PROJECT,
            cost: 17,
            metadata: {
                cardNumber: 'SP5',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 17 M€ to place a colony.', function (eb) {
                        eb.megacredits(17).startAction.colonies();
                    });
                }),
            },
        }) || this;
    }
    BuildColonyStandardProject.prototype.getOpenColonies = function (player) {
        var openColonies = player.game.colonies.filter(function (colony) { return colony.colonies.length < 3 &&
            colony.colonies.includes(player.id) === false &&
            colony.isActive; });
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !player.canAfford(this.cost + constants.REDS_RULING_POLICY_COST)) {
            openColonies = openColonies.filter(function (colony) { return colony.name !== ColonyName_1.ColonyName.VENUS; });
        }
        return openColonies;
    };
    BuildColonyStandardProject.prototype.canAct = function (player) {
        return _super.prototype.canAct.call(this, player) && this.getOpenColonies(player).length > 0;
    };
    BuildColonyStandardProject.prototype.actionEssence = function (player) {
        player.game.defer(new BuildColony_1.BuildColony(player, false, 'Select colony'));
    };
    return BuildColonyStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.BuildColonyStandardProject = BuildColonyStandardProject;
