"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveOceanTile = void 0;
var SelectSpace_1 = require("../inputs/SelectSpace");
var DeferredAction_1 = require("./DeferredAction");
var LogHelper_1 = require("../LogHelper");
var RemoveOceanTile = (function () {
    function RemoveOceanTile(player, title) {
        if (title === void 0) { title = 'Select an Ocean tile to remove from board'; }
        this.player = player;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    RemoveOceanTile.prototype.execute = function () {
        var _this = this;
        if (this.player.game.board.getOceansOnBoard() === 0) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, this.player.game.board.getOceansTiles(false), function (space) {
            _this.player.game.removeTile(space.id);
            LogHelper_1.LogHelper.logBoardTileAction(_this.player, space, 'ocean tile', 'removed');
            return undefined;
        });
    };
    return RemoveOceanTile;
}());
exports.RemoveOceanTile = RemoveOceanTile;
