"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CometAiming = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var Tags_1 = require("../Tags");
var SelectCard_1 = require("../../inputs/SelectCard");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var constants_1 = require("../../constants");
var LogHelper_1 = require("../../LogHelper");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var CardRenderer_1 = require("../render/CardRenderer");
var CometAiming = (function (_super) {
    __extends(CometAiming, _super);
    function CometAiming() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.COMET_AIMING,
            tags: [Tags_1.Tags.SPACE],
            cost: 17,
            resourceType: ResourceType_1.ResourceType.ASTEROID,
            metadata: {
                cardNumber: 'X16',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 titanium to add 1 asteroid resource to ANY CARD.', function (eb) {
                        eb.titanium(1).startAction.asteroids(1).asterix();
                    }).br;
                    b.or().br;
                    b.action('Remove 1 asteroid here to place an ocean.', function (eb) {
                        eb.asteroids(1).startAction.oceans(1);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    CometAiming.prototype.play = function () {
        return undefined;
    };
    CometAiming.prototype.canAct = function (player) {
        var hasTitanium = player.titanium > 0;
        var canPlaceOcean = this.resourceCount > 0 && player.game.board.getOceansOnBoard() < constants_1.MAX_OCEAN_TILES;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return hasTitanium || (player.canAfford(constants_1.REDS_RULING_POLICY_COST) && canPlaceOcean);
        }
        return hasTitanium || canPlaceOcean;
    };
    CometAiming.prototype.action = function (player) {
        var _this = this;
        var asteroidCards = player.getResourceCards(ResourceType_1.ResourceType.ASTEROID);
        var addAsteroidToSelf = function () {
            player.titanium--;
            player.addResourceTo(asteroidCards[0], { log: true });
            return undefined;
        };
        var addAsteroidToCard = new SelectCard_1.SelectCard('Select card to add 1 asteroid', 'Add asteroid', asteroidCards, function (foundCards) {
            player.titanium--;
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
        var spendAsteroidResource = function () {
            _this.resourceCount--;
            LogHelper_1.LogHelper.logRemoveResource(player, _this, 1, 'place an ocean');
            player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
            return undefined;
        };
        if (this.resourceCount === 0) {
            if (asteroidCards.length === 1)
                return addAsteroidToSelf();
            return addAsteroidToCard;
        }
        if (player.titanium === 0)
            return spendAsteroidResource();
        var availableActions = [];
        var redsAreRuling = PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS);
        var canPlaceOcean = player.game.board.getOceansOnBoard() < constants_1.MAX_OCEAN_TILES;
        if (canPlaceOcean && !redsAreRuling || (redsAreRuling && player.canAfford(constants_1.REDS_RULING_POLICY_COST))) {
            availableActions.push(new SelectOption_1.SelectOption('Remove an asteroid resource to place an ocean', 'Remove asteroid', spendAsteroidResource));
        }
        if (asteroidCards.length === 1) {
            availableActions.push(new SelectOption_1.SelectOption('Spend 1 titanium to gain 1 asteroid resource', 'Spend titanium', addAsteroidToSelf));
        }
        else {
            availableActions.push(addAsteroidToCard);
        }
        if (availableActions.length === 1) {
            var action = availableActions[0];
            if (action instanceof SelectOption_1.SelectOption)
                return availableActions[0].cb();
            return availableActions[0];
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], availableActions)))();
    };
    return CometAiming;
}(Card_1.Card));
exports.CometAiming = CometAiming;
