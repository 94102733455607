"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.KELVINISTS_POLICY_4 = exports.KELVINISTS_POLICY_3 = exports.KELVINISTS_POLICY_2 = exports.KELVINISTS_POLICY_1 = exports.KELVINISTS_BONUS_2 = exports.KELVINISTS_BONUS_1 = exports.Kelvinists = void 0;
var Party_1 = require("./Party");
var PartyName_1 = require("./PartyName");
var Resources_1 = require("../../Resources");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var TurmoilPolicy_1 = require("../TurmoilPolicy");
var constants_1 = require("../../constants");
var Kelvinists = (function (_super) {
    __extends(Kelvinists, _super);
    function Kelvinists() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = PartyName_1.PartyName.KELVINISTS;
        _this.description = 'Pushes for rapid terraforming, usually employing a heat-first strategy.';
        _this.bonuses = [exports.KELVINISTS_BONUS_1, exports.KELVINISTS_BONUS_2];
        _this.policies = [exports.KELVINISTS_POLICY_1, exports.KELVINISTS_POLICY_2, exports.KELVINISTS_POLICY_3, exports.KELVINISTS_POLICY_4];
        return _this;
    }
    return Kelvinists;
}(Party_1.Party));
exports.Kelvinists = Kelvinists;
var KelvinistsBonus01 = (function () {
    function KelvinistsBonus01() {
        this.id = 'kb01';
        this.isDefault = true;
        this.description = 'Gain 1 M€ for each Heat production you have';
    }
    KelvinistsBonus01.prototype.getScore = function (player) {
        return player.getProduction(Resources_1.Resources.HEAT);
    };
    KelvinistsBonus01.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return KelvinistsBonus01;
}());
var KelvinistsBonus02 = (function () {
    function KelvinistsBonus02() {
        this.id = 'kb02';
        this.description = 'Gain 1 heat for each Heat production you have';
        this.isDefault = false;
    }
    KelvinistsBonus02.prototype.getScore = function (player) {
        return player.getProduction(Resources_1.Resources.HEAT);
    };
    KelvinistsBonus02.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.HEAT, _this.getScore(player));
        });
    };
    return KelvinistsBonus02;
}());
var KelvinistsPolicy01 = (function () {
    function KelvinistsPolicy01() {
        this.isDefault = true;
        this.id = TurmoilPolicy_1.TurmoilPolicy.KELVINISTS_DEFAULT_POLICY;
        this.description = 'Pay 10 M€ to increase your Energy and Heat production 1 step (Turmoil Kelvinists)';
    }
    KelvinistsPolicy01.prototype.canAct = function (player) {
        return player.canAfford(10);
    };
    KelvinistsPolicy01.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Kelvinists action', function (b) { return b.player(player); });
        game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 10, {
            title: 'Select how to pay for Turmoil Kelvinists action',
            afterPay: function () {
                player.addProduction(Resources_1.Resources.ENERGY, 1);
                player.addProduction(Resources_1.Resources.HEAT, 1);
                game.log('${0} increased heat and energy production 1 step', function (b) { return b.player(player); });
            },
        }));
        return undefined;
    };
    return KelvinistsPolicy01;
}());
var KelvinistsPolicy02 = (function () {
    function KelvinistsPolicy02() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.KELVINISTS_POLICY_2;
        this.description = 'When you raise temperature, gain 3 M€ per step raised';
        this.isDefault = false;
    }
    return KelvinistsPolicy02;
}());
var KelvinistsPolicy03 = (function () {
    function KelvinistsPolicy03() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.KELVINISTS_POLICY_3;
        this.description = 'Convert 6 heat into temperature (Turmoil Kelvinists)';
        this.isDefault = false;
    }
    KelvinistsPolicy03.prototype.canAct = function (player) {
        return player.availableHeat >= 6 && player.game.getTemperature() < constants_1.MAX_TEMPERATURE;
    };
    KelvinistsPolicy03.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Kelvinists action', function (b) { return b.player(player); });
        game.log('${0} spent 6 heat to raise temperature 1 step', function (b) { return b.player(player); });
        return player.spendHeat(6, function () {
            game.increaseTemperature(player, 1);
            return undefined;
        });
    };
    return KelvinistsPolicy03;
}());
var KelvinistsPolicy04 = (function () {
    function KelvinistsPolicy04() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.KELVINISTS_POLICY_4;
        this.description = 'When you place a tile, gain 2 heat';
        this.isDefault = false;
    }
    KelvinistsPolicy04.prototype.onTilePlaced = function (player) {
        player.addResource(Resources_1.Resources.HEAT, 2);
    };
    return KelvinistsPolicy04;
}());
exports.KELVINISTS_BONUS_1 = new KelvinistsBonus01();
exports.KELVINISTS_BONUS_2 = new KelvinistsBonus02();
exports.KELVINISTS_POLICY_1 = new KelvinistsPolicy01();
exports.KELVINISTS_POLICY_2 = new KelvinistsPolicy02();
exports.KELVINISTS_POLICY_3 = new KelvinistsPolicy03();
exports.KELVINISTS_POLICY_4 = new KelvinistsPolicy04();
