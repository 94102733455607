"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var unique = 0;
exports.default = vue_1.default.extend({
    name: 'or-options',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        Button: Button_vue_1.default,
    },
    data: function () {
        if (this.playerinput.options === undefined) {
            throw new Error('no options provided for OrOptions');
        }
        var displayedOptions = this.playerinput.options.filter(function (o) { return Boolean(o.showOnlyInLearnerMode) === false || PreferencesManager_1.PreferencesManager.loadBoolean('learner_mode'); });
        return {
            displayedOptions: displayedOptions,
            radioElementName: 'selectOption' + unique++,
            selectedOption: displayedOptions[0],
        };
    },
    methods: {
        playerFactorySaved: function () {
            var _this = this;
            var _a;
            var idx = (_a = this.playerinput.options) === null || _a === void 0 ? void 0 : _a.indexOf(this.selectedOption);
            if (idx === undefined || idx === -1) {
                throw new Error('option not found!');
            }
            return function (out) {
                var copy = [[String(idx)]];
                for (var i = 0; i < out.length; i++) {
                    copy.push(out[i].slice());
                }
                _this.onsave(copy);
            };
        },
        saveData: function () {
            var ref = this.$refs['inputfactory'];
            if (Array.isArray(ref)) {
                ref = ref[0];
            }
            ref.saveData();
        },
    },
});
