"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmallComet = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../Tags");
var constants_1 = require("../../constants");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var SpaceType_1 = require("../../SpaceType");
var Options_1 = require("../Options");
var SmallComet = (function (_super) {
    __extends(SmallComet, _super);
    function SmallComet() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.SMALL_COMET,
            cost: 32,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.SPACE],
            tr: { temperature: 1, oxygen: 1, oceans: 1 },
            metadata: {
                cardNumber: 'Pf37',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().plants(2, { all: Options_1.all }).asterix();
                    b.br;
                    b.temperature(1).oxygen(1).oceans(1).asterix();
                    b.br;
                    b.titanium(1);
                }),
                description: 'Every player loses 2 plants. Raise the temperature 1 step. Raise the oxygen 1 step. ' +
                    'Place an ocean ON AN AREA NOT RESERVED FOR OCEAN. Gain 1 titanium.',
            },
        }) || this;
    }
    SmallComet.prototype.play = function (player) {
        player.game.getPlayers().forEach(function (p) {
            if (!p.plantsAreProtected()) {
                p.deductResource(Resources_1.Resources.PLANTS, 2, { log: true, from: player });
            }
        });
        player.game.increaseTemperature(player, 1);
        player.game.increaseOxygenLevel(player, 1);
        player.addResource(Resources_1.Resources.TITANIUM, 1);
        if (player.game.board.getOceansOnBoard() < constants_1.MAX_OCEAN_TILES) {
            return new SelectSpace_1.SelectSpace('Select a land space to place an ocean', player.game.board.getAvailableSpacesOnLand(player), function (space) {
                player.game.addOceanTile(player, space.id, SpaceType_1.SpaceType.LAND);
                return undefined;
            });
        }
        return undefined;
    };
    return SmallComet;
}(Card_1.Card));
exports.SmallComet = SmallComet;
