"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
var CardRenderSymbolComponent_vue_1 = require("@/client/components/card/CardRenderSymbolComponent.vue");
var CardRenderSymbol_1 = require("@/cards/render/CardRenderSymbol");
var CardRenderItem_1 = require("@/cards/render/CardRenderItem");
exports.default = vue_1.default.extend({
    name: 'CardProductionBoxComponent',
    props: {
        rows: {
            type: Array,
            required: true,
        },
    },
    components: {
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
        CardRenderSymbolComponent: CardRenderSymbolComponent_vue_1.default,
    },
    methods: {
        getClasses: function () {
            var classes = ['card-production-box'];
            return classes.join(' ');
        },
        getComponentType: function (rowItem) {
            if (rowItem instanceof CardRenderSymbol_1.CardRenderSymbol) {
                return 'symbol';
            }
            else if (rowItem instanceof CardRenderItem_1.CardRenderItem) {
                return 'item';
            }
            return '';
        },
    },
});
