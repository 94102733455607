"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiningAreaAres = void 0;
var CardName_1 = require("../../CardName");
var MiningArea_1 = require("../base/MiningArea");
var TileType_1 = require("../../TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var MiningAreaAres = (function (_super) {
    __extends(MiningAreaAres, _super);
    function MiningAreaAres() {
        return _super.call(this, CardName_1.CardName.MINING_AREA_ARES, {
            cardNumber: 'A14',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.tile(TileType_1.TileType.MINING_STEEL_BONUS, false, true);
                b.tile(TileType_1.TileType.MINING_TITANIUM_BONUS, false, true).asterix().br;
                b.production(function (pb) {
                    pb.steel(1).or().titanium(1);
                }).asterix();
            }),
            description: 'Place one of these tiles on an area with a steel or titanium placement bonus, ADJACENT TO ANOTHER OF YOUR TILES. This tile provides an ADJACENCY BONUS of the same resource as the area. Increase your production of that resource 1 step.',
        }) || this;
    }
    return MiningAreaAres;
}(MiningArea_1.MiningArea));
exports.MiningAreaAres = MiningAreaAres;
