"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnOfferYouCantRefuse = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var ProjectCard_1 = require("../ProjectCard");
var Turmoil_1 = require("../../turmoil/Turmoil");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var Options_1 = require("../Options");
var AnOfferYouCantRefuse = (function (_super) {
    __extends(AnOfferYouCantRefuse, _super);
    function AnOfferYouCantRefuse() {
        return _super.call(this, {
            name: CardName_1.CardName.AN_OFFER_YOU_CANT_REFUSE,
            cardType: CardType_1.CardType.EVENT,
            cost: 5,
            metadata: {
                description: 'Exchange a NON-NEUTRAL NON-LEADER delegate with one of your own from the reserve. You may then move your delegate to another party.',
                cardNumber: 'M62',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().delegates(1, { all: Options_1.all }).asterix().nbsp.plus().delegates(1);
                }),
            },
        }) || this;
    }
    ;
    AnOfferYouCantRefuse.prototype.isReplaceableDelegate = function (delegate, player, party) {
        return delegate !== player.id && delegate !== 'NEUTRAL' && delegate !== party.partyLeader;
    };
    AnOfferYouCantRefuse.prototype.canPlay = function (player) {
        var _this = this;
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        var hasDelegate = turmoil.hasAvailableDelegates(player.id) || turmoil.lobby.has(player.id);
        if (!hasDelegate)
            return false;
        return turmoil.parties.some(function (party) {
            return party.delegates.some(function (delegate) { return _this.isReplaceableDelegate(delegate, player, party); });
        });
    };
    AnOfferYouCantRefuse.prototype.moveToAnotherParty = function (game, from, delegate) {
        var orOptions = new OrOptions_1.OrOptions();
        var turmoil = Turmoil_1.Turmoil.getTurmoil(game);
        turmoil.parties.forEach(function (party) {
            if (party.name === from) {
                orOptions.options.push(new SelectOption_1.SelectOption('Do not move', '', function () {
                    return undefined;
                }));
            }
            else {
                orOptions.options.push(new SelectOption_1.SelectOption(party.name, 'Select', function () {
                    turmoil.removeDelegateFromParty(delegate, from, game);
                    turmoil.sendDelegateToParty(delegate, party.name, game, 'reserve');
                    return undefined;
                }));
            }
        });
        return orOptions;
    };
    AnOfferYouCantRefuse.prototype.play = function (player) {
        var _this = this;
        var game = player.game;
        var turmoil = Turmoil_1.Turmoil.getTurmoil(game);
        var orOptions = new OrOptions_1.OrOptions();
        turmoil.parties.forEach(function (party) {
            party.getPresentPlayers()
                .forEach(function (delegate) {
                if (!_this.isReplaceableDelegate(delegate, player, party))
                    return;
                var playerName = game.getPlayerById(delegate).name;
                var option = new SelectOption_1.SelectOption(party.name + " / " + playerName, 'Select', function () {
                    var source = turmoil.hasAvailableDelegates(player.id) ? 'reserve' : 'lobby';
                    turmoil.replaceDelegateFromParty(delegate, player.id, source, party.name, game);
                    turmoil.checkDominantParty(party);
                    return _this.moveToAnotherParty(game, party.name, player.id);
                });
                orOptions.options.push(option);
            });
        });
        return orOptions;
    };
    return AnOfferYouCantRefuse;
}(ProjectCard_1.ProjectCard));
exports.AnOfferYouCantRefuse = AnOfferYouCantRefuse;
