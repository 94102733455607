"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Card_vue_1 = require("@/client/components/card/Card.vue");
var CardName_1 = require("@/CardName");
exports.default = vue_1.default.extend({
    name: 'HelpStandardProjects',
    components: {
        Card: Card_vue_1.default,
    },
    methods: {
        getBasicStandardProjects: function () { return [
            CardName_1.CardName.SELL_PATENTS_STANDARD_PROJECT,
            CardName_1.CardName.POWER_PLANT_STANDARD_PROJECT,
            CardName_1.CardName.ASTEROID_STANDARD_PROJECT,
            CardName_1.CardName.AQUIFER_STANDARD_PROJECT,
            CardName_1.CardName.GREENERY_STANDARD_PROJECT,
            CardName_1.CardName.CITY_STANDARD_PROJECT,
        ]; },
        getExpansionStandardProjects: function () { return [
            CardName_1.CardName.AIR_SCRAPPING_STANDARD_PROJECT,
            CardName_1.CardName.BUILD_COLONY_STANDARD_PROJECT,
            CardName_1.CardName.BUFFER_GAS_STANDARD_PROJECT,
        ]; },
        getFanMadeStandardProjects: function () { return [
            CardName_1.CardName.MOON_COLONY_STANDARD_PROJECT,
            CardName_1.CardName.MOON_MINE_STANDARD_PROJECT,
            CardName_1.CardName.MOON_ROAD_STANDARD_PROJECT,
        ]; },
    },
});
