"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Multiset = void 0;
var Multiset = (function () {
    function Multiset(items) {
        var _this = this;
        if (items === void 0) { items = []; }
        this.map = new Map();
        items.forEach(function (item) { return _this.add(item); });
    }
    Multiset.prototype.add = function (key, count) {
        if (count === void 0) { count = 1; }
        var val = (this.get(key) || 0) + count;
        this.map.set(key, val);
        return val;
    };
    Multiset.prototype.subtract = function (key, count) {
        if (count === void 0) { count = 1; }
        var val = this.get(key);
        if (val === undefined)
            return;
        this.map.set(key, val - count);
    };
    Multiset.prototype.remove = function (key) {
        this.map.delete(key);
    };
    Multiset.prototype.get = function (key) {
        return this.map.get(key);
    };
    Multiset.prototype.entries = function () {
        return Array.from(this.map);
    };
    Multiset.prototype.toString = function () {
        return this.map.toString();
    };
    return Multiset;
}());
exports.Multiset = Multiset;
