"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeptumTribus = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../Resources");
var Turmoil_1 = require("../../turmoil/Turmoil");
var SeptumTribus = (function (_super) {
    __extends(SeptumTribus, _super);
    function SeptumTribus() {
        return _super.call(this, {
            name: CardName_1.CardName.SEPTUM_TRIBUS,
            tags: [Tags_1.Tags.WILDCARD],
            startingMegaCredits: 36,
            cardType: CardType_1.CardType.CORPORATION,
            metadata: {
                cardNumber: 'R15',
                description: 'You start with 36 M€. When you perform an action, the wild tag counts as any tag of your choice.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(36);
                    b.corpBox('action', function (ce) {
                        ce.action('Gain 2 M€ for each party where you have at least 1 delegate.', function (eb) {
                            eb.empty().startAction.megacredits(2).slash().delegates(1).asterix();
                        });
                    });
                }),
            },
        }) || this;
    }
    SeptumTribus.prototype.play = function () {
        return undefined;
    };
    SeptumTribus.prototype.canAct = function () {
        return true;
    };
    SeptumTribus.prototype.action = function (player) {
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        var partiesWithPresence = turmoil.parties.filter(function (party) { return party.delegates.includes(player.id); });
        player.addResource(Resources_1.Resources.MEGACREDITS, partiesWithPresence.length * 2, { log: true });
        return undefined;
    };
    return SeptumTribus;
}(Card_1.Card));
exports.SeptumTribus = SeptumTribus;
