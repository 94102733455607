"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CulturalMetropolis = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceCityTile_1 = require("../../deferredActions/PlaceCityTile");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Turmoil_1 = require("../../turmoil/Turmoil");
var CulturalMetropolis = (function (_super) {
    __extends(CulturalMetropolis, _super);
    function CulturalMetropolis() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.CULTURAL_METROPOLIS,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 20,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 3 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.UNITY); }),
            metadata: {
                cardNumber: 'T03',
                description: 'Requires that Unity is ruling or that you have 2 delegates there. Decrease your energy production 1 step and increase your M€ production 3 steps. Place a city tile. Place 2 delegates in 1 party.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(3);
                    }).city().delegates(2);
                }),
            },
        }) || this;
    }
    CulturalMetropolis.prototype.canPlay = function (player) {
        if (player.getProduction(Resources_1.Resources.ENERGY) < 1) {
            return false;
        }
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        var hasEnoughDelegates = turmoil.getDelegatesInReserve(player.id) > 1 ||
            (turmoil.getDelegatesInReserve(player.id) === 1 && turmoil.lobby.has(player.id));
        return hasEnoughDelegates;
    };
    CulturalMetropolis.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 3);
        player.game.defer(new PlaceCityTile_1.PlaceCityTile(player));
        var title = 'Select where to send two delegates';
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        if (turmoil.getDelegatesInReserve(player.id) > 1) {
            player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, title, { count: 2, source: 'reserve' }));
        }
        else if (turmoil.getDelegatesInReserve(player.id) === 1 && turmoil.lobby.has(player.id)) {
            player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, title, { count: 2, source: 'lobby' }));
        }
        return undefined;
    };
    return CulturalMetropolis;
}(Card_1.Card));
exports.CulturalMetropolis = CulturalMetropolis;
