"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunarIndustryComplex = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var PlaceMoonMineTile_1 = require("../../moon/PlaceMoonMineTile");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var TileType_1 = require("../../TileType");
var LunarIndustryComplex = (function (_super) {
    __extends(LunarIndustryComplex, _super);
    function LunarIndustryComplex() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNAR_INDUSTRY_COMPLEX,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.BUILDING],
            cost: 28,
            productionBox: Units_1.Units.of({ steel: 1, titanium: 1, energy: 2, heat: 1 }),
            reserveUnits: Units_1.Units.of({ titanium: 2 }),
            tr: { moonMining: 1 },
            metadata: {
                description: 'Spend 2 Titanium. Place a mine tile on the Moon and raise the Mining Rate 1 step. ' +
                    'Increase your Steel, Titanium, and Heat production 1 step each. Increase your Energy production 2 steps.',
                cardNumber: 'M74',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(2).moonMine().br;
                    b.production(function (pb) { return pb.steel(1).titanium(1).heat(1).energy(2); });
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_MINE],
        }) || this;
    }
    ;
    LunarIndustryComplex.prototype.play = function (player) {
        player.deductUnits(this.reserveUnits);
        player.game.defer(new PlaceMoonMineTile_1.PlaceMoonMineTile(player));
        player.addProduction(Resources_1.Resources.STEEL, 1);
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        player.addProduction(Resources_1.Resources.ENERGY, 2);
        player.addProduction(Resources_1.Resources.HEAT, 1);
        return undefined;
    };
    return LunarIndustryComplex;
}(MoonCard_1.MoonCard));
exports.LunarIndustryComplex = LunarIndustryComplex;
