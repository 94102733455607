"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MercurianAlloys = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var MercurianAlloys = (function (_super) {
    __extends(MercurianAlloys, _super);
    function MercurianAlloys() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MERCURIAN_ALLOYS,
            tags: [Tags_1.Tags.SPACE],
            cost: 3,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 2); }),
            metadata: {
                cardNumber: 'X07',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('Your titanium resources are worth 1 M€ extra.', function (eb) {
                        eb.titanium(1).startEffect.plus(Size_1.Size.SMALL).megacredits(1);
                    });
                }),
                description: 'Requires 2 Science tags.',
            },
        }) || this;
    }
    MercurianAlloys.prototype.play = function (player) {
        player.increaseTitaniumValue();
        return undefined;
    };
    MercurianAlloys.prototype.onDiscard = function (player) {
        player.decreaseTitaniumValue();
    };
    return MercurianAlloys;
}(Card_1.Card));
exports.MercurianAlloys = MercurianAlloys;
