"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DysonScreens = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SpaceName_1 = require("../../SpaceName");
var SpaceType_1 = require("../../SpaceType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var DysonScreens = (function (_super) {
    __extends(DysonScreens, _super);
    function DysonScreens() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.DYSON_SCREENS,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.VENUS, Tags_1.Tags.ENERGY, Tags_1.Tags.SPACE],
            cost: 28,
            tr: { temperature: 1 },
            metadata: {
                cardNumber: 'Pf15',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Pay 2 titanium to raise your heat and energy production 1 step each.', function (ab) { return ab.titanium(2, { digit: Options_1.digit }).startAction.production(function (pb) { return pb.heat(1).energy(1); }); }).br;
                    b.temperature(1).cards(1).city().asterix().br;
                }),
                victoryPoints: 1,
                description: 'Raise the temperature 1 step. Draw a card. Place a city tile ON THE RESERVED AREA.',
            },
        }) || this;
    }
    DysonScreens.prototype.getVictoryPoints = function () {
        return 1;
    };
    DysonScreens.prototype.canAct = function (player) {
        return player.titanium >= 2;
    };
    DysonScreens.prototype.action = function (player) {
        player.titanium -= 2;
        player.addProduction(Resources_1.Resources.HEAT, 1);
        player.addProduction(Resources_1.Resources.ENERGY, 1);
        return undefined;
    };
    DysonScreens.prototype.play = function (player) {
        var game = player.game;
        game.increaseTemperature(player, 1);
        player.drawCard();
        player.game.addCityTile(player, SpaceName_1.SpaceName.DYSON_SCREENS, SpaceType_1.SpaceType.COLONY);
        return undefined;
    };
    return DysonScreens;
}(Card_1.Card));
exports.DysonScreens = DysonScreens;
