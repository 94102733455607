"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerInputTypes = void 0;
var PlayerInputTypes;
(function (PlayerInputTypes) {
    PlayerInputTypes[PlayerInputTypes["AND_OPTIONS"] = 0] = "AND_OPTIONS";
    PlayerInputTypes[PlayerInputTypes["OR_OPTIONS"] = 1] = "OR_OPTIONS";
    PlayerInputTypes[PlayerInputTypes["SELECT_AMOUNT"] = 2] = "SELECT_AMOUNT";
    PlayerInputTypes[PlayerInputTypes["SELECT_CARD"] = 3] = "SELECT_CARD";
    PlayerInputTypes[PlayerInputTypes["SELECT_DELEGATE"] = 4] = "SELECT_DELEGATE";
    PlayerInputTypes[PlayerInputTypes["SELECT_HOW_TO_PAY"] = 5] = "SELECT_HOW_TO_PAY";
    PlayerInputTypes[PlayerInputTypes["SELECT_HOW_TO_PAY_FOR_PROJECT_CARD"] = 6] = "SELECT_HOW_TO_PAY_FOR_PROJECT_CARD";
    PlayerInputTypes[PlayerInputTypes["SELECT_INITIAL_CARDS"] = 7] = "SELECT_INITIAL_CARDS";
    PlayerInputTypes[PlayerInputTypes["SELECT_OPTION"] = 8] = "SELECT_OPTION";
    PlayerInputTypes[PlayerInputTypes["SELECT_PARTY_TO_SEND_DELEGATE"] = 9] = "SELECT_PARTY_TO_SEND_DELEGATE";
    PlayerInputTypes[PlayerInputTypes["SELECT_PLAYER"] = 10] = "SELECT_PLAYER";
    PlayerInputTypes[PlayerInputTypes["SELECT_SPACE"] = 11] = "SELECT_SPACE";
    PlayerInputTypes[PlayerInputTypes["SELECT_COLONY"] = 12] = "SELECT_COLONY";
    PlayerInputTypes[PlayerInputTypes["SELECT_PRODUCTION_TO_LOSE"] = 13] = "SELECT_PRODUCTION_TO_LOSE";
    PlayerInputTypes[PlayerInputTypes["SHIFT_ARES_GLOBAL_PARAMETERS"] = 14] = "SHIFT_ARES_GLOBAL_PARAMETERS";
})(PlayerInputTypes = exports.PlayerInputTypes || (exports.PlayerInputTypes = {}));
