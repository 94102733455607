"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloaterPrototypes = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var FloaterPrototypes = (function (_super) {
    __extends(FloaterPrototypes, _super);
    function FloaterPrototypes() {
        return _super.call(this, {
            cost: 2,
            tags: [Tags_1.Tags.SCIENCE],
            name: CardName_1.CardName.FLOATER_PROTOTYPES,
            cardType: CardType_1.CardType.EVENT,
            metadata: {
                cardNumber: 'C11',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.floaters(2).asterix(); }),
                description: 'Add two floaters to ANOTHER card.',
            },
        }) || this;
    }
    FloaterPrototypes.prototype.play = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2 }));
        return undefined;
    };
    return FloaterPrototypes;
}(Card_1.Card));
exports.FloaterPrototypes = FloaterPrototypes;
