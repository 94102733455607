"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunarBeam = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var LunarBeam = (function (_super) {
    __extends(LunarBeam, _super);
    function LunarBeam() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.LUNAR_BEAM,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.ENERGY],
            cost: 13,
            metadata: {
                cardNumber: '030',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().megacredits(2).br;
                        pb.plus().heat(2).br;
                        pb.plus().energy(2);
                    });
                }),
                description: 'Decrease your M€ production 2 steps and increase your heat production and Energy production 2 steps each.',
            },
        }) || this;
    }
    LunarBeam.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.MEGACREDITS) >= -3;
    };
    LunarBeam.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, -2);
        player.addProduction(Resources_1.Resources.HEAT, 2);
        player.addProduction(Resources_1.Resources.ENERGY, 2);
        return undefined;
    };
    return LunarBeam;
}(Card_1.Card));
exports.LunarBeam = LunarBeam;
