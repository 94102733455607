"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
exports.default = vue_1.default.extend({
    name: 'and-options',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        Button: Button_vue_1.default,
    },
    data: function () {
        if (this.playerinput.options === undefined) {
            throw new Error('options must be defined');
        }
        return {
            responded: this.playerinput.options.map(function () { return undefined; }),
        };
    },
    methods: {
        playerFactorySaved: function (idx) {
            var _this = this;
            return function (out) {
                _this.$data.responded[idx] = out[0];
            };
        },
        canSave: function () {
            for (var _i = 0, _a = this.$children; _i < _a.length; _i++) {
                var child = _a[_i];
                var canSave = child.canSave;
                if (canSave instanceof Function) {
                    if (canSave() === false) {
                        return false;
                    }
                }
            }
            return true;
        },
        saveData: function () {
            if (this.canSave() === false) {
                alert('Not all options selected');
                return;
            }
            for (var _i = 0, _a = this.$children; _i < _a.length; _i++) {
                var child = _a[_i];
                if (child.saveData instanceof Function) {
                    child.saveData();
                }
            }
            this.onsave(this.$data.responded);
        },
    },
});
