"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentWidgetMixin = void 0;
var CardName_1 = require("@/CardName");
var ResourceType_1 = require("@/ResourceType");
exports.PaymentWidgetMixin = {
    'name': 'PaymentWidgetMixin',
    'methods': {
        asModel: function () {
            return this;
        },
        getMegaCreditsMax: function () {
            var model = this.asModel();
            return Math.min(model.playerView.thisPlayer.megaCredits, model.$data.cost);
        },
        getResourceRate: function (resourceName) {
            var rate = 1;
            if (resourceName === 'titanium') {
                rate = this.asModel().playerView.thisPlayer.titaniumValue;
            }
            else if (resourceName === 'steel') {
                rate = this.asModel().playerView.thisPlayer.steelValue;
            }
            else if (resourceName === 'microbes') {
                rate = 2;
            }
            else if (resourceName === 'floaters') {
                rate = 3;
            }
            return rate;
        },
        reduceValue: function (target, to) {
            var currentValue = this.asModel()[target];
            if (currentValue === undefined) {
                throw new Error("can not reduceValue for " + target + " on this");
            }
            if (currentValue === 0)
                return;
            var realTo = Math.min(to, currentValue);
            this.asModel()[target] -= realTo;
            if (target === 'megaCredits' || realTo === 0)
                return;
            this.setRemainingMCValue();
        },
        addValue: function (target, to, max) {
            var currentValue = this.asModel()[target];
            if (currentValue === undefined) {
                throw new Error("can not addValue for " + target + " on this");
            }
            var maxValue = max;
            if (maxValue === undefined && target !== 'microbes' && target !== 'floaters' && target !== 'science') {
                maxValue = this.asModel().playerView.thisPlayer[target];
            }
            switch (target) {
                case 'megaCredits':
                    maxValue = this.getMegaCreditsMax();
                    break;
                case 'microbes':
                    maxValue = this.asModel().playerinput.microbes;
                    break;
                case 'floaters':
                    maxValue = this.asModel().playerinput.floaters;
                    if (maxValue !== undefined && this.isStratosphericBirdsEdgeCase())
                        maxValue--;
                    break;
                case 'science':
                    maxValue = this.asModel().playerinput.science;
                    break;
            }
            if (currentValue === maxValue)
                return;
            if (maxValue === undefined) {
                throw new Error("unable to determine maxValue for " + target);
            }
            var realTo = (currentValue + to <= maxValue) ? to : maxValue - currentValue;
            this.asModel()[target] += realTo;
            if (target === 'megaCredits' || realTo === 0)
                return;
            this.setRemainingMCValue();
        },
        setRemainingMCValue: function () {
            var _a, _b, _c, _d, _e, _f;
            var ta = this.asModel();
            var heatMc = (_a = ta['heat']) !== null && _a !== void 0 ? _a : 0;
            var titaniumMc = ((_b = ta['titanium']) !== null && _b !== void 0 ? _b : 0) * this.getResourceRate('titanium');
            var steelMc = ((_c = ta['steel']) !== null && _c !== void 0 ? _c : 0) * this.getResourceRate('steel');
            var microbesMc = ((_d = ta['microbes']) !== null && _d !== void 0 ? _d : 0) * this.getResourceRate('microbes');
            var floatersMc = ((_e = ta['floaters']) !== null && _e !== void 0 ? _e : 0) * this.getResourceRate('floaters');
            var scienceMc = (_f = ta['science']) !== null && _f !== void 0 ? _f : 0;
            var remainingMC = ta.$data.cost - heatMc - titaniumMc - steelMc - microbesMc - floatersMc - scienceMc;
            ta['megaCredits'] = Math.max(0, Math.min(this.getMegaCreditsMax(), remainingMC));
        },
        setMaxValue: function (target, max) {
            var currentValue = this.asModel()[target];
            if (currentValue === undefined) {
                throw new Error("can not setMaxValue for " + target + " on this");
            }
            var cardCost = this.asModel().$data.cost;
            var amountHave = max;
            if (max === undefined && target !== 'microbes' && target !== 'floaters' && target !== 'science') {
                amountHave = this.asModel().playerView.thisPlayer[target];
            }
            var amountNeed = cardCost;
            if (target !== 'science' && target !== 'heat') {
                amountNeed = Math.floor(cardCost / this.getResourceRate(target));
            }
            if (target === 'microbes')
                amountHave = this.asModel().playerinput.microbes;
            if (target === 'floaters') {
                amountHave = this.asModel().playerinput.floaters;
                if (amountHave !== undefined && this.isStratosphericBirdsEdgeCase())
                    amountHave--;
            }
            if (target === 'science')
                amountHave = this.asModel().playerinput.science;
            if (amountHave === undefined) {
                throw new Error("unable to find amountHave for setMaxValue for " + target);
            }
            while (currentValue < amountHave && currentValue < amountNeed) {
                this.addValue(target, 1, max);
                currentValue++;
            }
        },
        isStratosphericBirdsEdgeCase: function () {
            var _a;
            if (((_a = this.asModel().$data.card) === null || _a === void 0 ? void 0 : _a.name) === CardName_1.CardName.STRATOSPHERIC_BIRDS) {
                var playedCards = this.asModel().playerView.thisPlayer.playedCards;
                var cardsWithFloaters = playedCards.filter(function (card) { return card.resourceType === ResourceType_1.ResourceType.FLOATER && card.resources; });
                return cardsWithFloaters.length === 1;
            }
            return false;
        },
    },
};
