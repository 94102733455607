"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessContacts = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var BusinessContacts = (function (_super) {
    __extends(BusinessContacts, _super);
    function BusinessContacts() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.BUSINESS_CONTACTS,
            tags: [Tags_1.Tags.EARTH],
            cost: 7,
            metadata: {
                cardNumber: '111',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.text('Look at the top 4 cards from the deck. Take 2 of them into hand and discard the other 2.', Size_1.Size.SMALL, true); }),
            },
        }) || this;
    }
    BusinessContacts.prototype.play = function (player) {
        return player.drawCardKeepSome(4, { keepMax: 2 });
    };
    return BusinessContacts;
}(Card_1.Card));
exports.BusinessContacts = BusinessContacts;
