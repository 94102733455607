"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenusSoils = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var VenusSoils = (function (_super) {
    __extends(VenusSoils, _super);
    function VenusSoils() {
        return _super.call(this, {
            name: CardName_1.CardName.VENUS_SOILS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.PLANT],
            cost: 20,
            tr: { venus: 1 },
            metadata: {
                cardNumber: '257',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(1).br;
                    b.production(function (pb) { return pb.plants(1); }).microbes(2).asterix();
                }),
                description: 'Raise Venus 1 step. Increase your Plant production 1 step. Add 2 Microbes to ANOTHER card',
            },
        }) || this;
    }
    ;
    VenusSoils.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        player.game.increaseVenusScaleLevel(player, 1);
        var microbeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        if (microbeCards.length === 0)
            return undefined;
        if (microbeCards.length === 1) {
            player.addResourceTo(microbeCards[0], { qty: 2, log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 2 microbes', 'Add microbe(s)', microbeCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { qty: 2, log: true });
            return undefined;
        });
    };
    return VenusSoils;
}(Card_1.Card));
exports.VenusSoils = VenusSoils;
