"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalDustStorm = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.text('lose all').heat(1).br.megacredits(-2).slash().building(1, { played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var GlobalDustStorm = (function () {
    function GlobalDustStorm() {
        this.name = GlobalEventName_1.GlobalEventName.GLOBAL_DUST_STORM;
        this.description = 'Lose all heat. Lose 2 M€ for each Building tag (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.KELVINISTS;
        this.currentDelegate = PartyName_1.PartyName.GREENS;
        this.renderData = RENDER_DATA;
    }
    GlobalDustStorm.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            if (player.heat > 0) {
                player.deductResource(Resources_1.Resources.HEAT, player.heat, { log: true, from: _this.name });
            }
            var maxedSteelTags = Math.min(5, player.getTagCount(Tags_1.Tags.BUILDING, false, false));
            player.deductResource(Resources_1.Resources.MEGACREDITS, 2 * Math.max(0, maxedSteelTags - turmoil.getPlayerInfluence(player)), { log: true, from: _this.name });
        });
    };
    return GlobalDustStorm;
}());
exports.GlobalDustStorm = GlobalDustStorm;
