"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoholeExcavation = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoholeExcavation = (function (_super) {
    __extends(MoholeExcavation, _super);
    function MoholeExcavation() {
        return _super.call(this, {
            name: CardName_1.CardName.MOHOLE_EXCAVATION,
            tags: [Tags_1.Tags.BUILDING],
            productionBox: Units_1.Units.of({ steel: 1, heat: 2 }),
            metadata: {
                cardNumber: 'P23',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.steel(1).br;
                        pb.heat(2);
                    }).heat(2);
                }),
                description: 'Increase your steel production 1 step and heat production 2 steps. Gain 2 heat.',
            },
        }) || this;
    }
    MoholeExcavation.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.STEEL, 1);
        player.addProduction(Resources_1.Resources.HEAT, 2);
        player.heat += 2;
        return undefined;
    };
    return MoholeExcavation;
}(PreludeCard_1.PreludeCard));
exports.MoholeExcavation = MoholeExcavation;
