"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var ICardRenderDescription_1 = require("@/cards/render/ICardRenderDescription");
var utils_1 = require("@/utils/utils");
exports.default = vue_1.default.extend({
    name: 'CardDescription',
    props: {
        item: {
            required: true,
        },
    },
    methods: {
        getClasses: function () {
            var classes = ['card-description'];
            if (ICardRenderDescription_1.isIDescription(this.item)) {
                if (this.item.align !== 'center') {
                    classes.push('card-description-aligned');
                }
                classes.push('card-description-align--' + this.item.align);
            }
            return utils_1.generateClassString(classes);
        },
        getDescription: function () {
            return ICardRenderDescription_1.isIDescription(this.item) ? this.item.text : String(this.item);
        },
    },
});
