"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathfindersExpansion = void 0;
var PlanetaryTracks_1 = require("./PlanetaryTracks");
var TRACKS = PlanetaryTracks_1.PlanetaryTracks.initialize();
var PathfindersExpansion = (function () {
    function PathfindersExpansion() {
    }
    PathfindersExpansion.initialize = function (gameOptions) {
        return {
            venus: gameOptions.venusNextExtension ? 0 : -1,
            earth: 0,
            mars: 0,
            jovian: 0,
            moon: gameOptions.moonExpansion ? 0 : -1,
            vps: [],
        };
    };
    PathfindersExpansion.raiseTrack = function (_tag, _player, steps) {
        if (steps === void 0) { steps = 1; }
        throw new Error('Not implemented: ' + steps);
    };
    return PathfindersExpansion;
}());
exports.PathfindersExpansion = PathfindersExpansion;
