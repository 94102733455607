"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectSpace = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectSpace = (function () {
    function SelectSpace(title, availableSpaces, cb) {
        this.title = title;
        this.availableSpaces = availableSpaces;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_SPACE;
        this.buttonLabel = 'Save';
        if (availableSpaces.length === 0) {
            throw new Error('No available spaces');
        }
    }
    return SelectSpace;
}());
exports.SelectSpace = SelectSpace;
