"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiantSpaceMirror = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var GiantSpaceMirror = (function (_super) {
    __extends(GiantSpaceMirror, _super);
    function GiantSpaceMirror() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.GIANT_SPACE_MIRROR,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.SPACE],
            cost: 17,
            metadata: {
                cardNumber: '083',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.production(function (pb) { return pb.energy(3); }); }),
                description: 'Increase your energy production 3 steps.',
            },
        }) || this;
    }
    GiantSpaceMirror.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 3);
        return undefined;
    };
    return GiantSpaceMirror;
}(Card_1.Card));
exports.GiantSpaceMirror = GiantSpaceMirror;
