"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMMUNITY_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var AgricolaInc_1 = require("./AgricolaInc");
var Incite_1 = require("./Incite");
var Playwrights_1 = require("./Playwrights");
var ProjectWorkshop_1 = require("./ProjectWorkshop");
var ResearchGrant_1 = require("./ResearchGrant");
var ValuableGases_1 = require("./ValuableGases");
var VenusFirst_1 = require("./VenusFirst");
var AerospaceMission_1 = require("./AerospaceMission");
var TradeAdvance_1 = require("./TradeAdvance");
var PoliticalUprising_1 = require("./PoliticalUprising");
var ByElection_1 = require("./ByElection");
var Midas_1 = require("./Midas");
var CuriosityII_1 = require("./CuriosityII");
exports.COMMUNITY_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Community,
    projectCards: [],
    corporationCards: [
        { cardName: CardName_1.CardName.AGRICOLA_INC, Factory: AgricolaInc_1.AgricolaInc },
        { cardName: CardName_1.CardName.PROJECT_WORKSHOP, Factory: ProjectWorkshop_1.ProjectWorkshop },
        { cardName: CardName_1.CardName.INCITE, Factory: Incite_1.Incite, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.PLAYWRIGHTS, Factory: Playwrights_1.Playwrights },
        { cardName: CardName_1.CardName.CURIOSITY_II, Factory: CuriosityII_1.CuriosityII },
        { cardName: CardName_1.CardName.MIDAS, Factory: Midas_1.Midas },
    ],
    preludeCards: [
        { cardName: CardName_1.CardName.RESEARCH_GRANT, Factory: ResearchGrant_1.ResearchGrant },
        {
            cardName: CardName_1.CardName.VALUABLE_GASES,
            Factory: ValuableGases_1.ValuableGases,
            compatibility: GameModule_1.GameModule.Venus,
        },
        {
            cardName: CardName_1.CardName.VENUS_FIRST,
            Factory: VenusFirst_1.VenusFirst,
            compatibility: GameModule_1.GameModule.Venus,
        },
        {
            cardName: CardName_1.CardName.AEROSPACE_MISSION,
            Factory: AerospaceMission_1.AerospaceMission,
            compatibility: GameModule_1.GameModule.Colonies,
        },
        {
            cardName: CardName_1.CardName.TRADE_ADVANCE,
            Factory: TradeAdvance_1.TradeAdvance,
            compatibility: GameModule_1.GameModule.Colonies,
        },
        {
            cardName: CardName_1.CardName.POLITICAL_UPRISING,
            Factory: PoliticalUprising_1.PoliticalUprising,
            compatibility: GameModule_1.GameModule.Turmoil,
        },
        {
            cardName: CardName_1.CardName.BY_ELECTION,
            Factory: ByElection_1.ByElection,
            compatibility: GameModule_1.GameModule.Turmoil,
        },
    ],
});
