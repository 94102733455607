"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BioengineeringEnclosure = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var SelectCard_1 = require("../../inputs/SelectCard");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var BioengineeringEnclosure = (function (_super) {
    __extends(BioengineeringEnclosure, _super);
    function BioengineeringEnclosure() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.BIOENGINEERING_ENCLOSURE,
            tags: [Tags_1.Tags.ANIMAL],
            cost: 7,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE); }),
            metadata: {
                description: 'Requires 1 science tag to play. Add 2 animals to this card. OTHERS MAY NOT REMOVE ANIMALS FROM THIS CARD.',
                cardNumber: 'A01',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Remove 1 animal from THIS card to add 1 animal to ANOTHER card.', function (eb) {
                        eb.animals(1).asterix().startAction.animals(1).asterix();
                    }).br;
                    b.animals(2);
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    BioengineeringEnclosure.prototype.play = function (player) {
        player.addResourceTo(this, 2);
        return undefined;
    };
    BioengineeringEnclosure.prototype.canAct = function (player) {
        return this.resourceCount > 0 && player.getResourceCards(this.resourceType).length > 1;
    };
    BioengineeringEnclosure.prototype.action = function (player) {
        var _this = this;
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
            var resourceCards = player.getResourceCards(_this.resourceType).filter(function (card) { return card.name !== CardName_1.CardName.BIOENGINEERING_ENCLOSURE; });
            if (resourceCards.length === 0) {
                return undefined;
            }
            if (resourceCards.length === 1) {
                _this.resourceCount--;
                player.addResourceTo(resourceCards[0], 1);
                player.game.log('${0} moved 1 animal from Bioengineering Enclosure to ${1}.', function (b) { return b.player(player).card(resourceCards[0]); });
                return undefined;
            }
            return new SelectCard_1.SelectCard('Select card to add 1 animal', 'Add animal', resourceCards, function (foundCards) {
                _this.resourceCount--;
                player.addResourceTo(foundCards[0], 1);
                player.game.log('${0} moved 1 animal from Bioengineering Enclosure to ${1}.', function (b) { return b.player(player).card(foundCards[0]); });
                return undefined;
            });
        }));
        return undefined;
    };
    return BioengineeringEnclosure;
}(Card_1.Card));
exports.BioengineeringEnclosure = BioengineeringEnclosure;
