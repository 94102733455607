"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogHelper = void 0;
var TileType_1 = require("./TileType");
var LogHelper = (function () {
    function LogHelper() {
    }
    LogHelper.logAddResource = function (player, card, qty) {
        if (qty === void 0) { qty = 1; }
        var resourceType = 'resource(s)';
        if (card.resourceType) {
            resourceType = card.resourceType.toLowerCase() + '(s)';
        }
        player.game.log('${0} added ${1} ${2} to ${3}', function (b) {
            return b.player(player).number(qty).string(resourceType).card(card);
        });
    };
    LogHelper.logRemoveResource = function (player, card, qty, effect) {
        if (qty === void 0) { qty = 1; }
        var resourceType = 'resource(s)';
        if (card.resourceType) {
            resourceType = card.resourceType.toLowerCase() + '(s)';
        }
        player.game.log('${0} removed ${1} ${2} from ${3} to ${4}', function (b) {
            return b.player(player).number(qty).string(resourceType).card(card).string(effect);
        });
    };
    LogHelper.logTilePlacement = function (player, space, tileType) {
        this.logBoardTileAction(player, space, TileType_1.TileType.toString(tileType) + ' tile');
    };
    LogHelper.logBoardTileAction = function (player, space, description, action) {
        if (action === void 0) { action = 'placed'; }
        if (space.x === -1 && space.y === -1)
            return;
        if (player.name === 'neutral')
            return;
        var offset = Math.abs(space.y - 4);
        var row = space.y + 1;
        var position = space.x - offset + 1;
        player.game.log('${0} ${1} ${2} on row ${3} position ${4}', function (b) {
            return b.player(player).string(action).string(description).number(row).number(position);
        });
    };
    LogHelper.logColonyTrackIncrease = function (player, colony, steps) {
        if (steps === void 0) { steps = 1; }
        player.game.log('${0} increased ${1} colony track ${2} step(s)', function (b) {
            return b.player(player).colony(colony).number(steps);
        });
    };
    LogHelper.logVenusIncrease = function (player, steps) {
        player.game.log('${0} increased Venus scale ${1} step(s)', function (b) { return b.player(player).number(steps); });
    };
    LogHelper.logDiscardedCards = function (game, cards) {
        game.log('${0} card(s) were discarded', function (b) {
            b.rawString(cards.length.toString());
            for (var _i = 0, cards_1 = cards; _i < cards_1.length; _i++) {
                var card = cards_1[_i];
                if (typeof card === 'string') {
                    b.cardName(card);
                }
                else {
                    b.card(card);
                }
            }
        });
    };
    LogHelper.logDrawnCards = function (player, cards, privateMessage) {
        if (privateMessage === void 0) { privateMessage = false; }
        var message = '${0} drew ';
        if (cards.length === 0) {
            message += 'no cards';
        }
        else {
            for (var i = 0, length = cards.length; i < length; i++) {
                if (i > 0) {
                    if (i < length - 1) {
                        message += ', ';
                    }
                    else {
                        message += ' and ';
                    }
                }
                message += '${' + (i + 1) + '}';
            }
        }
        var options = privateMessage ? { reservedFor: player } : {};
        player.game.log(message, function (b) {
            if (privateMessage === false) {
                b.player(player);
            }
            else {
                b.string('You');
            }
            for (var _i = 0, cards_2 = cards; _i < cards_2.length; _i++) {
                var card = cards_2[_i];
                if (typeof card === 'string') {
                    b.cardName(card);
                }
                else {
                    b.card(card);
                }
            }
        }, options);
    };
    return LogHelper;
}());
exports.LogHelper = LogHelper;
