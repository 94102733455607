"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trimEmptyTextNodes = void 0;
function trimEmptyTextNodes(el) {
    for (var _i = 0, _a = el.childNodes; _i < _a.length; _i++) {
        var node = _a[_i];
        if (node.nodeType === Node.TEXT_NODE && node.data.trim() === '') {
            node.remove();
        }
    }
}
exports.trimEmptyTextNodes = trimEmptyTextNodes;
