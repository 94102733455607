"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandClaim = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var LogHelper_1 = require("../../LogHelper");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var LandClaim = (function (_super) {
    __extends(LandClaim, _super);
    function LandClaim() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.LAND_CLAIM,
            cost: 1,
            metadata: {
                cardNumber: '066',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Place your marker on a non-reserved area. Only you may place a tile there.', Size_1.Size.SMALL, true);
                }),
            },
        }) || this;
    }
    LandClaim.prototype.canPlay = function (player) {
        return player.game.board.getNonReservedLandSpaces().length > 0;
    };
    LandClaim.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for claim', player.game.board.getNonReservedLandSpaces(), function (foundSpace) {
            foundSpace.player = player;
            LogHelper_1.LogHelper.logBoardTileAction(player, foundSpace, 'land claim');
            return undefined;
        });
    };
    return LandClaim;
}(Card_1.Card));
exports.LandClaim = LandClaim;
