"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FoodFactory = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var FoodFactory = (function (_super) {
    __extends(FoodFactory, _super);
    function FoodFactory() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.FOOD_FACTORY,
            tags: [Tags_1.Tags.BUILDING],
            cost: 12,
            productionBox: Units_1.Units.of({ megacredits: 4, plants: -1 }),
            metadata: {
                cardNumber: '041',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().plants(1).br;
                        pb.plus().megacredits(4);
                    });
                }),
                description: 'Decrease your Plant production 1 step and increase your M€ production 4 steps',
                victoryPoints: 1,
            },
        }) || this;
    }
    FoodFactory.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.PLANTS) >= 1;
    };
    FoodFactory.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, -1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 4);
        return undefined;
    };
    FoodFactory.prototype.getVictoryPoints = function () {
        return 1;
    };
    return FoodFactory;
}(Card_1.Card));
exports.FoodFactory = FoodFactory;
