"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AirScrappingStandardProject = void 0;
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var constants_1 = require("../../constants");
var StandardProjectCard_1 = require("../StandardProjectCard");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants = require("../../constants");
var AirScrappingStandardProject = (function (_super) {
    __extends(AirScrappingStandardProject, _super);
    function AirScrappingStandardProject(properties) {
        if (properties === void 0) { properties = {
            name: CardName_1.CardName.AIR_SCRAPPING_STANDARD_PROJECT,
            cost: 15,
            metadata: {
                cardNumber: 'SP1',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 15 M€ to raise Venus 1 step.', function (eb) {
                        eb.megacredits(15).startAction.venus(1);
                    });
                }),
            },
        }; }
        return _super.call(this, properties) || this;
    }
    AirScrappingStandardProject.prototype.canAct = function (player) {
        if (player.game.getVenusScaleLevel() >= constants.MAX_VENUS_SCALE)
            return false;
        var cost = this.cost;
        cost -= this.discount(player);
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS))
            cost += constants_1.REDS_RULING_POLICY_COST;
        return player.canAfford(cost);
    };
    AirScrappingStandardProject.prototype.actionEssence = function (player) {
        player.game.increaseVenusScaleLevel(player, 1);
    };
    return AirScrappingStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.AirScrappingStandardProject = AirScrappingStandardProject;
