"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Timer_1 = require("@/Timer");
exports.default = vue_1.default.extend({
    name: 'PlayerTimer',
    props: {
        timer: {
            type: Object,
        },
    },
    data: function () {
        return {
            timerText: '',
        };
    },
    mounted: function () {
        this.updateTimer();
    },
    watch: {
        timerText: {
            handler: function () {
                var _this = this;
                setTimeout(function () {
                    _this.updateTimer();
                }, 1000);
            },
        },
    },
    methods: {
        updateTimer: function () {
            this.timerText = Timer_1.Timer.toString(this.timer);
        },
        hasHours: function () {
            if (this.timerText.split(':').length > 2) {
                return 1;
            }
            return 0;
        },
        getHours: function () {
            if (this.hasHours()) {
                return this.timerText.split(':')[0];
            }
            return '';
        },
        getMinutes: function () {
            if (this.hasHours()) {
                return this.timerText.split(':')[1];
            }
            return this.timerText.split(':')[0];
        },
        getSeconds: function () {
            if (this.hasHours()) {
                return this.timerText.split(':')[2];
            }
            return this.timerText.split(':')[1];
        },
    },
});
