"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkyDocks = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var CardRequirements_1 = require("../CardRequirements");
var SkyDocks = (function (_super) {
    __extends(SkyDocks, _super);
    function SkyDocks() {
        return _super.call(this, {
            cost: 18,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.EARTH],
            name: CardName_1.CardName.SKY_DOCKS,
            cardType: CardType_1.CardType.ACTIVE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.EARTH, 2); }),
            cardDiscount: { amount: 1 },
            metadata: {
                cardNumber: 'C36',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play a card, you pay 1 M€ less for it.', function (eb) {
                        eb.empty().startEffect.megacredits(-1);
                    }).br;
                    b.tradeFleet();
                }),
                description: 'Requires 2 Earth tags. Gain 1 Trade Fleet.',
                victoryPoints: 2,
            },
        }) || this;
    }
    SkyDocks.prototype.play = function (player) {
        player.increaseFleetSize();
        return undefined;
    };
    SkyDocks.prototype.getCardDiscount = function () {
        return 1;
    };
    SkyDocks.prototype.getVictoryPoints = function () {
        return 2;
    };
    SkyDocks.prototype.onDiscard = function (player) {
        player.decreaseFleetSize();
    };
    return SkyDocks;
}(Card_1.Card));
exports.SkyDocks = SkyDocks;
