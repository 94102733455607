"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventionContest = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var InventionContest = (function (_super) {
    __extends(InventionContest, _super);
    function InventionContest() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.INVENTION_CONTEST,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 2,
            metadata: {
                cardNumber: '192',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Look at the top 3 cards from the deck. Take 1 of them into hand and discard the other two', Size_1.Size.SMALL, true);
                }),
            },
        }) || this;
    }
    InventionContest.prototype.play = function (player) {
        return player.drawCardKeepSome(3, { keepMax: 1 });
    };
    return InventionContest;
}(Card_1.Card));
exports.InventionContest = InventionContest;
