"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceMoonColonyTile = void 0;
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var SelectSpace_1 = require("../inputs/SelectSpace");
var MoonExpansion_1 = require("./MoonExpansion");
var PlaceMoonColonyTile = (function () {
    function PlaceMoonColonyTile(player, title) {
        if (title === void 0) { title = 'Select a space on the Moon for a colony tile.'; }
        this.player = player;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    PlaceMoonColonyTile.prototype.getSpaces = function () {
        var moonData = MoonExpansion_1.MoonExpansion.moonData(this.player.game);
        return moonData.moon.getAvailableSpacesOnLand(this.player);
    };
    PlaceMoonColonyTile.prototype.execute = function () {
        var _this = this;
        var spaces = this.getSpaces();
        if (spaces.length === 0) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, spaces, function (space) {
            MoonExpansion_1.MoonExpansion.addColonyTile(_this.player, space.id);
            MoonExpansion_1.MoonExpansion.raiseColonyRate(_this.player);
            return undefined;
        });
    };
    return PlaceMoonColonyTile;
}());
exports.PlaceMoonColonyTile = PlaceMoonColonyTile;
