"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoseProduction = void 0;
var DeferredAction_1 = require("./DeferredAction");
var LoseProduction = (function () {
    function LoseProduction(player, resource, options) {
        if (options === void 0) { options = {}; }
        this.player = player;
        this.resource = resource;
        this.options = options;
        this.priority = DeferredAction_1.Priority.LOSE_RESOURCE_OR_PRODUCTION;
    }
    LoseProduction.prototype.execute = function () {
        if (this.options.count === undefined) {
            this.options.count = 1;
        }
        else if (this.options.count < 0) {
            throw new Error('LoseProduction count option must be >= 0');
        }
        else if (this.options.count === 0) {
            return undefined;
        }
        this.player.addProduction(this.resource, -this.options.count);
        if (this.options.logMessage !== undefined) {
            this.player.game.log(this.options.logMessage, this.options.logBuilder);
        }
        return undefined;
    };
    return LoseProduction;
}());
exports.LoseProduction = LoseProduction;
