"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NaturalPreserve = void 0;
var Tags_1 = require("../Tags");
var TileType_1 = require("../../TileType");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Board_1 = require("../../boards/Board");
var Options_1 = require("../Options");
var NaturalPreserve = (function (_super) {
    __extends(NaturalPreserve, _super);
    function NaturalPreserve(name, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.NATURAL_PRESERVE; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '044',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) { return pb.megacredits(1); }).nbsp.tile(TileType_1.TileType.NATURAL_PRESERVE, true).asterix();
            }),
            description: 'Oxygen must be 4% or less. Place this tile NEXT TO NO OTHER TILE. Increase your M€ production 1 step.',
            victoryPoints: 1,
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: name,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.BUILDING],
            cost: 9,
            productionBox: Units_1.Units.of({ megacredits: 1 }),
            adjacencyBonus: adjacencyBonus,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(4, { max: Options_1.max }); }),
            metadata: metadata,
        }) || this;
    }
    NaturalPreserve.prototype.getAvailableSpaces = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player)
            .filter(Board_1.nextToNoOtherTileFn(player.game.board));
    };
    NaturalPreserve.prototype.canPlay = function (player) {
        return this.getAvailableSpaces(player).length > 0;
    };
    NaturalPreserve.prototype.play = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace('Select space for special tile next to no other tile', this.getAvailableSpaces(player), function (foundSpace) {
            player.game.addTile(player, foundSpace.spaceType, foundSpace, { tileType: TileType_1.TileType.NATURAL_PRESERVE });
            foundSpace.adjacency = _this.adjacencyBonus;
            player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
            return undefined;
        });
    };
    NaturalPreserve.prototype.getVictoryPoints = function () {
        return 1;
    };
    return NaturalPreserve;
}(Card_1.Card));
exports.NaturalPreserve = NaturalPreserve;
