"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalShading = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var LocalShading = (function (_super) {
    __extends(LocalShading, _super);
    function LocalShading() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.LOCAL_SHADING,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS],
            cost: 4,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: '235',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Floater to this card.', function (eb) {
                        eb.empty().startAction.floaters(1);
                    }).br;
                    b.or().br;
                    b.action('Spend 1 Floater here to raise your M€ production 1 step.', function (eb) {
                        eb.floaters(1).startAction.production(function (pb) { return pb.megacredits(1); });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    LocalShading.prototype.play = function () {
        return undefined;
    };
    LocalShading.prototype.canAct = function () {
        return true;
    };
    LocalShading.prototype.action = function (player) {
        var _this = this;
        if (this.resourceCount < 1) {
            player.addResourceTo(this, 1);
            return undefined;
        }
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Add 1 floater to this card', 'Add floater', function () { return _this.addResource(player); });
        var spendResource = new SelectOption_1.SelectOption('Remove 1 floater to increase M€ production 1 step', 'Remove floater', function () { return _this.spendResource(player); });
        opts.push(spendResource);
        opts.push(addResource);
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts)))();
    };
    LocalShading.prototype.addResource = function (player) {
        player.addResourceTo(this, 1);
        return undefined;
    };
    LocalShading.prototype.spendResource = function (player) {
        player.removeResourceFrom(this);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        return undefined;
    };
    return LocalShading;
}(Card_1.Card));
exports.LocalShading = LocalShading;
