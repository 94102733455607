"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderItem = exports.AltSecondaryTag = void 0;
var AltSecondaryTag;
(function (AltSecondaryTag) {
    AltSecondaryTag["REQ"] = "req";
    AltSecondaryTag["OXYGEN"] = "oxygen";
    AltSecondaryTag["TURMOIL"] = "turmoil";
    AltSecondaryTag["FLOATER"] = "floater";
    AltSecondaryTag["BLUE"] = "blue";
    AltSecondaryTag["MOON_MINING_RATE"] = "moon-mine";
    AltSecondaryTag["MOON_COLONY_RATE"] = "moon-colony";
    AltSecondaryTag["MOON_LOGISTICS_RATE"] = "moon-road";
    AltSecondaryTag["INFLUENCE"] = "influence";
})(AltSecondaryTag = exports.AltSecondaryTag || (exports.AltSecondaryTag = {}));
var CardRenderItem = (function () {
    function CardRenderItem(type, amount, options) {
        if (amount === void 0) { amount = -1; }
        var _a;
        this.type = type;
        this.amount = amount;
        this.multiplier = false;
        this.cancelled = false;
        switch (options === null || options === void 0 ? void 0 : options.digit) {
            case true:
                this.showDigit = true;
                break;
            case false:
                break;
            case 'large':
            default:
                this.showDigit = Math.abs(this.amount) > 5 ? true : undefined;
        }
        if (options === undefined) {
            return this;
        }
        this.size = options.size;
        if (options.amount !== undefined) {
            this.amount = options.amount;
        }
        this.anyPlayer = options.all;
        this.isPlayed = options.played;
        this.secondaryTag = options.secondaryTag;
        if (options.multiplier === true) {
            this.amountInside = true;
            this.multiplier = true;
        }
        this.cancelled = (_a = options.cancelled) !== null && _a !== void 0 ? _a : false;
        this.over = options.over;
        return this;
    }
    return CardRenderItem;
}());
exports.CardRenderItem = CardRenderItem;
