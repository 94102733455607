"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MuseumofEarlyColonisation = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var MuseumofEarlyColonisation = (function (_super) {
    __extends(MuseumofEarlyColonisation, _super);
    function MuseumofEarlyColonisation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.MUSEUM_OF_EARLY_COLONISATION,
            cost: 20,
            tags: [Tags_1.Tags.BUILDING, Tags_1.Tags.MARS],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(1).cities(1, { all: Options_1.all }).greeneries(1, { all: Options_1.all }); }),
            productionBox: Units_1.Units.of({ energy: -1, steel: 1, titanium: 1, plants: 1 }),
            tr: { tr: 1 },
            metadata: {
                cardNumber: 'Pf11',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production((function (pb) { return pb.minus().energy(1).nbsp.steel(1).titanium(1).plants(1); }));
                    b.br.tr(1);
                }),
                description: 'Requires 1 ocean, 1 city and one greenery on Mars. ' +
                    'Decrease your energy production 1 step. Raise your steel, titanium, and plant production 1 step. ' +
                    'Gain 1 TR.',
            },
        }) || this;
    }
    MuseumofEarlyColonisation.prototype.canPlay = function (player) {
        return _super.prototype.canPlay.call(this, player) && player.canAdjustProduction(this.productionBox);
    };
    MuseumofEarlyColonisation.prototype.play = function (player) {
        player.adjustProduction(this.productionBox);
        player.increaseTerraformRating();
        return undefined;
    };
    return MuseumofEarlyColonisation;
}(Card_1.Card));
exports.MuseumofEarlyColonisation = MuseumofEarlyColonisation;
