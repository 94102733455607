"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SymbioticFungus = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var SymbioticFungus = (function (_super) {
    __extends(SymbioticFungus, _super);
    function SymbioticFungus() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.SYMBIOTIC_FUNGUS,
            tags: [Tags_1.Tags.MICROBE],
            cost: 4,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-14); }),
            metadata: {
                cardNumber: '133',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add a microbe to ANOTHER card.', function (eb) {
                        eb.empty().startAction.microbes(1).asterix();
                    });
                }),
                description: 'Requires -14 C° or warmer.',
            },
        }) || this;
    }
    SymbioticFungus.prototype.play = function () {
        return undefined;
    };
    SymbioticFungus.prototype.canAct = function () {
        return true;
    };
    SymbioticFungus.prototype.action = function (player) {
        var availableCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        if (availableCards.length === 0)
            return undefined;
        if (availableCards.length === 1) {
            player.addResourceTo(availableCards[0], { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add microbe', 'Add microbe', availableCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    return SymbioticFungus;
}(Card_1.Card));
exports.SymbioticFungus = SymbioticFungus;
