"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannedDelegate = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectDelegate_1 = require("../../inputs/SelectDelegate");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Turmoil_1 = require("../../turmoil/Turmoil");
var Options_1 = require("../Options");
var BannedDelegate = (function (_super) {
    __extends(BannedDelegate, _super);
    function BannedDelegate() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.BANNED_DELEGATE,
            cost: 0,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.chairman(); }),
            metadata: {
                cardNumber: 'T02',
                description: 'Requires that you are Chairman. Remove any NON-LEADER delegate.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().delegates(1, { all: Options_1.all });
                }),
            },
        }) || this;
    }
    BannedDelegate.prototype.play = function (player) {
        var _this = this;
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        var orOptions = [];
        turmoil.parties.forEach(function (party) {
            if (party.delegates.length > 1) {
                var delegates = party.delegates.slice();
                delegates.splice(party.delegates.indexOf(party.partyLeader), 1);
                var playersId = Array.from(new Set(delegates));
                var players_1 = [];
                playersId.forEach(function (playerId) {
                    if (playerId === 'NEUTRAL') {
                        players_1.push('NEUTRAL');
                    }
                    else {
                        players_1.push(player.game.getPlayerById(playerId));
                    }
                });
                if (players_1.length > 0) {
                    var selectDelegate = new SelectDelegate_1.SelectDelegate(players_1, 'Select player delegate to remove from ' + party.name + ' party', function (selectedPlayer) {
                        var playerToRemove = '';
                        if (selectedPlayer === 'NEUTRAL') {
                            playerToRemove = 'NEUTRAL';
                        }
                        else {
                            playerToRemove = selectedPlayer.id;
                        }
                        turmoil.removeDelegateFromParty(playerToRemove, party.name, player.game);
                        _this.log(player, party, selectedPlayer);
                        return undefined;
                    });
                    selectDelegate.buttonLabel = 'Remove delegate';
                    orOptions.push(selectDelegate);
                }
            }
        });
        if (orOptions.length === 0) {
            return undefined;
        }
        else if (orOptions.length === 1) {
            return orOptions[0];
        }
        else {
            var options = new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], orOptions)))();
            return options;
        }
    };
    BannedDelegate.prototype.log = function (player, party, selectedPlayer) {
        if (selectedPlayer === 'NEUTRAL') {
            player.game.log('${0} removed neutral delegate from ${1}', function (b) { return b.player(player).party(party); });
        }
        else {
            player.game.log('${0} removed ${1}\'s delegate from ${2}', function (b) { return b.player(player).player(selectedPlayer).party(party); });
        }
    };
    return BannedDelegate;
}(Card_1.Card));
exports.BannedDelegate = BannedDelegate;
