"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LavaFlows = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SpaceType_1 = require("../../SpaceType");
var TileType_1 = require("../../TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var LavaFlows = (function (_super) {
    __extends(LavaFlows, _super);
    function LavaFlows(name, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.LAVA_FLOWS; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '140',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.temperature(2).br;
                b.tile(TileType_1.TileType.LAVA_FLOWS, true, false).asterix();
            }),
            description: 'Raise temperature 2 steps and place this tile ON EITHER THARSIS THOLUS, ASCRAEUS MONS, PAVONIS MONS OR ARSIA MONS.',
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: name,
            cost: 18,
            adjacencyBonus: adjacencyBonus,
            metadata: metadata,
            tr: { temperature: 2 },
        }) || this;
    }
    LavaFlows.getVolcanicSpaces = function (player) {
        var board = player.game.board;
        var volcanicSpaceIds = board.getVolcanicSpaceIds();
        var spaces = (volcanicSpaceIds.length > 0) ?
            volcanicSpaceIds.map(function (id) { return board.getSpace(id); }) :
            board.getSpaces(SpaceType_1.SpaceType.LAND, player);
        return spaces.filter(function (space) { return space.tile === undefined && (space.player === undefined || space.player === player); });
    };
    LavaFlows.prototype.canPlay = function (player) {
        return LavaFlows.getVolcanicSpaces(player).length > 0;
    };
    LavaFlows.prototype.play = function (player) {
        var _this = this;
        player.game.increaseTemperature(player, 2);
        return new SelectSpace_1.SelectSpace('Select either Tharsis Tholus, Ascraeus Mons, Pavonis Mons or Arsia Mons', LavaFlows.getVolcanicSpaces(player), function (space) {
            player.game.addTile(player, SpaceType_1.SpaceType.LAND, space, { tileType: TileType_1.TileType.LAVA_FLOWS });
            space.adjacency = _this.adjacencyBonus;
            return undefined;
        });
    };
    return LavaFlows;
}(Card_1.Card));
exports.LavaFlows = LavaFlows;
