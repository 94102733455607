"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'CardRequirementsComponent',
    props: {
        requirements: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getClasses: function () {
            var classes = ['card-requirements'];
            if (this.requirements.hasMax()) {
                classes.push('card-requirements-max');
            }
            return classes.join(' ');
        },
    },
});
