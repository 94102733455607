"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeavyDutyRovers = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var TileType_1 = require("../../TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var HeavyDutyRovers = (function (_super) {
    __extends(HeavyDutyRovers, _super);
    function HeavyDutyRovers() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.HEAVY_DUTY_ROVERS,
            cost: 12,
            tr: { moonLogistics: 1 },
            metadata: {
                description: 'Gain 4 M€ for each mining tile adjacent to a road tile. Raise the Logistic Rate 1 step.',
                cardNumber: 'M39',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(4).slash().moonRoad({ size: Size_1.Size.SMALL, all: Options_1.all })
                        .moonMine({ size: Size_1.Size.SMALL, all: Options_1.all });
                    b.br;
                    b.moonLogisticsRate({ size: Size_1.Size.SMALL });
                }),
            },
        }) || this;
    }
    HeavyDutyRovers.prototype.play = function (player) {
        MoonExpansion_1.MoonExpansion.ifMoon(player.game, function (moonData) {
            var mines = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE);
            var minesNextToRoads = mines.filter(function (mine) {
                var spacesNextToMine = moonData.moon.getAdjacentSpaces(mine);
                var firstRoad = spacesNextToMine.find(function (s) { return MoonExpansion_1.MoonExpansion.spaceHasType(s, TileType_1.TileType.MOON_ROAD); });
                return firstRoad !== undefined;
            });
            var count = minesNextToRoads.length;
            player.addResource(Resources_1.Resources.MEGACREDITS, count * 4, { log: true });
            MoonExpansion_1.MoonExpansion.raiseLogisticRate(player);
        });
        return undefined;
    };
    return HeavyDutyRovers;
}(Card_1.Card));
exports.HeavyDutyRovers = HeavyDutyRovers;
