"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedInfluence = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.megacredits(-3).slash().tr(5, { digit: Options_1.digit, over: 10 }).br.br.production(function (pb) { return pb.megacredits(1); }).slash().influence();
});
var RedInfluence = (function () {
    function RedInfluence() {
        this.name = GlobalEventName_1.GlobalEventName.RED_INFLUENCE;
        this.description = 'Lose 3 M€ for each set of 5 TR over 10 (max 5 sets). Increase M€ production 1 step per influence.';
        this.revealedDelegate = PartyName_1.PartyName.KELVINISTS;
        this.currentDelegate = PartyName_1.PartyName.REDS;
        this.renderData = RENDER_DATA;
    }
    RedInfluence.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.floor((player.getTerraformRating() - 10) / 5);
            if (amount > 0) {
                player.addResource(Resources_1.Resources.MEGACREDITS, amount * -3, { log: true, from: _this.name });
            }
            player.addProduction(Resources_1.Resources.MEGACREDITS, turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    return RedInfluence;
}());
exports.RedInfluence = RedInfluence;
