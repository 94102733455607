"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceSpecialMoonTile = void 0;
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var SelectSpace_1 = require("../inputs/SelectSpace");
var MoonExpansion_1 = require("./MoonExpansion");
var PlaceSpecialMoonTile = (function () {
    function PlaceSpecialMoonTile(player, tile, title, priority) {
        if (title === void 0) { title = 'Select a space on the Moon for this tile.'; }
        if (priority === void 0) { priority = DeferredAction_1.Priority.DEFAULT; }
        this.player = player;
        this.tile = tile;
        this.title = title;
        this.priority = priority;
    }
    PlaceSpecialMoonTile.prototype.execute = function () {
        var _this = this;
        var moonData = MoonExpansion_1.MoonExpansion.moonData(this.player.game);
        var spaces = moonData.moon.getAvailableSpacesOnLand(this.player);
        if (spaces.length === 0) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, spaces, function (space) {
            MoonExpansion_1.MoonExpansion.addTile(_this.player, space.id, _this.tile);
            return undefined;
        });
    };
    return PlaceSpecialMoonTile;
}());
exports.PlaceSpecialMoonTile = PlaceSpecialMoonTile;
