"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_NAME = exports.LANGUAGES = exports.MAXIMUM_LOGISTICS_RATE = exports.MAXIMUM_MINING_RATE = exports.MAXIMUM_COLONY_RATE = exports.HELLAS_BONUS_OCEAN_COST = exports.POLITICAL_AGENDAS_MAX_ACTION_USES = exports.REDS_RULING_POLICY_COST = exports.PLAYER_DELEGATES_COUNT = exports.TITANIUM_TRADE_COST = exports.ENERGY_TRADE_COST = exports.MC_TRADE_COST = exports.MAX_FLEET_SIZE = exports.MAX_COLONY_TRACK_POSITION = exports.MAX_VENUS_SCALE = exports.MIN_VENUS_SCALE = exports.MIN_OXYGEN_LEVEL = exports.MIN_TEMPERATURE = exports.MAX_OXYGEN_LEVEL = exports.MAX_TEMPERATURE = exports.MAX_OCEAN_TILES = exports.HEAT_FOR_TEMPERATURE = exports.OCEAN_BONUS = exports.DEFAULT_MICROBES_VALUE = exports.DEFAULT_FLOATERS_VALUE = exports.DEFAULT_TITANIUM_VALUE = exports.DEFAULT_STEEL_VALUE = exports.MAX_AWARDS = exports.AWARD_COSTS = exports.MAX_MILESTONES = exports.MILESTONE_COST = exports.CARD_COST = void 0;
exports.CARD_COST = 3;
exports.MILESTONE_COST = 8;
exports.MAX_MILESTONES = 3;
exports.AWARD_COSTS = [8, 14, 20];
exports.MAX_AWARDS = 3;
exports.DEFAULT_STEEL_VALUE = 2;
exports.DEFAULT_TITANIUM_VALUE = 3;
exports.DEFAULT_FLOATERS_VALUE = 3;
exports.DEFAULT_MICROBES_VALUE = 2;
exports.OCEAN_BONUS = 2;
exports.HEAT_FOR_TEMPERATURE = 8;
exports.MAX_OCEAN_TILES = 9;
exports.MAX_TEMPERATURE = 8;
exports.MAX_OXYGEN_LEVEL = 14;
exports.MIN_TEMPERATURE = -30;
exports.MIN_OXYGEN_LEVEL = 0;
exports.MIN_VENUS_SCALE = 0;
exports.MAX_VENUS_SCALE = 30;
exports.MAX_COLONY_TRACK_POSITION = 6;
exports.MAX_FLEET_SIZE = 4;
exports.MC_TRADE_COST = 9;
exports.ENERGY_TRADE_COST = 3;
exports.TITANIUM_TRADE_COST = 3;
exports.PLAYER_DELEGATES_COUNT = 7;
exports.REDS_RULING_POLICY_COST = 3;
exports.POLITICAL_AGENDAS_MAX_ACTION_USES = 3;
exports.HELLAS_BONUS_OCEAN_COST = 6;
exports.MAXIMUM_COLONY_RATE = 8;
exports.MAXIMUM_MINING_RATE = 8;
exports.MAXIMUM_LOGISTICS_RATE = 8;
exports.LANGUAGES = [
    { 'id': 'en', 'title': 'English' },
    { 'id': 'de', 'title': 'German' },
    { 'id': 'fr', 'title': 'French' },
    { 'id': 'ru', 'title': 'Russian' },
    { 'id': 'cn', 'title': 'Chinese' },
    { 'id': 'pl', 'title': 'Polish' },
    { 'id': 'es', 'title': 'Spanish' },
    { 'id': 'br', 'title': 'Português Brasileiro' },
    { 'id': 'it', 'title': 'Italian' },
];
exports.APP_NAME = 'Terraforming Mars';
