"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeGrowAsOne = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var WeGrowAsOne = (function (_super) {
    __extends(WeGrowAsOne, _super);
    function WeGrowAsOne() {
        return _super.call(this, {
            name: CardName_1.CardName.WE_GROW_AS_ONE,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.SPACE],
            cost: 8,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.UNITY); }),
            metadata: {
                description: 'Requires that Unity are ruling or that you have 2 delegates there. ' +
                    'Increase ALL Colony Tile Tracks 1 step. ' +
                    'Increase each Colony Tile Track 1 step if you have a colony on that Colony Tile.',
                cardNumber: 'M59',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.placeColony({ all: Options_1.all }).text('+1').br;
                    b.colonies(1).asterix().slash().placeColony().text('+1');
                }),
            },
        }) || this;
    }
    ;
    WeGrowAsOne.prototype.play = function (player) {
        player.game.colonies.forEach(function (colony) {
            if (colony.colonies.includes(player.id)) {
                colony.increaseTrack(2);
            }
            else {
                colony.increaseTrack(1);
            }
        });
        return undefined;
    };
    return WeGrowAsOne;
}(Card_1.Card));
exports.WeGrowAsOne = WeGrowAsOne;
