"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SphereHabitats = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var PlaceMoonColonyTile_1 = require("../../moon/PlaceMoonColonyTile");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var TileType_1 = require("../../TileType");
var CardRenderItem_1 = require("../render/CardRenderItem");
var SphereHabitats = (function (_super) {
    __extends(SphereHabitats, _super);
    function SphereHabitats() {
        return _super.call(this, {
            name: CardName_1.CardName.SPHERE_HABITATS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.MOON],
            cost: 14,
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            tr: { moonColony: 1 },
            metadata: {
                description: 'Spend 1 titanium. Place a colony tile on the Moon and raise the Colony Rate 1 step.',
                cardNumber: 'M07',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(1).br;
                    b.moonColony({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE });
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_COLONY],
        }) || this;
    }
    ;
    SphereHabitats.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        player.game.defer(new PlaceMoonColonyTile_1.PlaceMoonColonyTile(player));
        return undefined;
    };
    return SphereHabitats;
}(MoonCard_1.MoonCard));
exports.SphereHabitats = SphereHabitats;
