"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playerIndex = void 0;
var vue_1 = require("vue");
var PlayerInfo_vue_1 = require("@/client/components/overview/PlayerInfo.vue");
var OverviewSettings_vue_1 = require("@/client/components/overview/OverviewSettings.vue");
var OtherPlayer_vue_1 = require("@/client/components/OtherPlayer.vue");
var ActionLabel_1 = require("@/client/components/overview/ActionLabel");
var Phase_1 = require("@/Phase");
var SHOW_NEXT_LABEL_MIN = 2;
var playerIndex = function (color, players) {
    for (var idx = 0; idx < players.length; idx++) {
        if (players[idx].color === color) {
            return idx;
        }
    }
    return -1;
};
exports.playerIndex = playerIndex;
exports.default = vue_1.default.extend({
    name: 'PlayersOverview',
    props: {
        playerView: {
            type: Object,
        },
    },
    computed: {
        players: function () {
            return this.playerView.players;
        },
        thisPlayer: function () {
            return this.playerView.thisPlayer;
        },
    },
    components: {
        'player-info': PlayerInfo_vue_1.default,
        'overview-settings': OverviewSettings_vue_1.default,
        'other-player': OtherPlayer_vue_1.default,
    },
    data: function () {
        return {};
    },
    methods: {
        hasPlayers: function () {
            return this.players.length > 0;
        },
        getIsFirstForGen: function (player) {
            return exports.playerIndex(player.color, this.players) === 0;
        },
        getPlayersInOrder: function () {
            var players = this.players;
            if (this.thisPlayer === undefined) {
                return players;
            }
            var result = [];
            var currentPlayerOffset = 0;
            var currentPlayerIndex = exports.playerIndex(this.thisPlayer.color, this.players);
            currentPlayerOffset = currentPlayerIndex + 1;
            result = players
                .slice(currentPlayerOffset)
                .concat(players.slice(0, currentPlayerOffset));
            return result.slice(0, -1);
        },
        getActionLabel: function (player) {
            var _this = this;
            if (this.playerView.game.phase === Phase_1.Phase.DRAFTING) {
                if (player.needsToDraft) {
                    return ActionLabel_1.ActionLabel.DRAFTING;
                }
                else {
                    return ActionLabel_1.ActionLabel.NONE;
                }
            }
            else if (this.playerView.game.phase === Phase_1.Phase.RESEARCH) {
                if (player.needsToResearch) {
                    return ActionLabel_1.ActionLabel.RESEARCHING;
                }
                else {
                    return ActionLabel_1.ActionLabel.NONE;
                }
            }
            if (this.playerView.game.passedPlayers.includes(player.color)) {
                return ActionLabel_1.ActionLabel.PASSED;
            }
            if (player.isActive)
                return ActionLabel_1.ActionLabel.ACTIVE;
            var notPassedPlayers = this.players.filter(function (p) { return !_this.playerView.game.passedPlayers.includes(p.color); });
            var currentPlayerIndex = exports.playerIndex(player.color, notPassedPlayers);
            if (currentPlayerIndex === -1) {
                return ActionLabel_1.ActionLabel.NONE;
            }
            var prevPlayerIndex = currentPlayerIndex === 0 ?
                notPassedPlayers.length - 1 :
                currentPlayerIndex - 1;
            var isNext = notPassedPlayers[prevPlayerIndex].isActive;
            if (isNext && this.players.length > SHOW_NEXT_LABEL_MIN) {
                return ActionLabel_1.ActionLabel.NEXT;
            }
            return ActionLabel_1.ActionLabel.NONE;
        },
    },
});
