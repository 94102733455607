"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoteOfNoConfidence = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Card_1 = require("../Card");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Turmoil_1 = require("../../turmoil/Turmoil");
var Options_1 = require("../Options");
var VoteOfNoConfidence = (function (_super) {
    __extends(VoteOfNoConfidence, _super);
    function VoteOfNoConfidence() {
        return _super.call(this, {
            name: CardName_1.CardName.VOTE_OF_NO_CONFIDENCE,
            cardType: CardType_1.CardType.EVENT,
            cost: 5,
            tr: { tr: 1 },
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.partyLeaders(); }),
            metadata: {
                cardNumber: 'T16',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().chairman({ all: Options_1.all }).asterix();
                    b.nbsp.plus().partyLeaders().br;
                    b.tr(1);
                }),
                description: 'Requires that you have a Party Leader in any party and that the sitting Chairman is neutral. ' +
                    'Remove the NEUTRAL Chairman and move your own delegate (from the reserve) there instead. Gain 1 TR.',
            },
        }) || this;
    }
    VoteOfNoConfidence.prototype.canPlay = function (player) {
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        if (!turmoil.hasAvailableDelegates(player.id))
            return false;
        return turmoil.chairman === 'NEUTRAL';
    };
    VoteOfNoConfidence.prototype.play = function (player) {
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        turmoil.chairman = player.id;
        var index = turmoil.delegateReserve.indexOf(player.id);
        if (index > -1) {
            turmoil.delegateReserve.splice(index, 1);
        }
        player.increaseTerraformRating();
        return undefined;
    };
    return VoteOfNoConfidence;
}(Card_1.Card));
exports.VoteOfNoConfidence = VoteOfNoConfidence;
