"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Leavitt = void 0;
var Colony_1 = require("../../colonies/Colony");
var ColonyName_1 = require("../../colonies/ColonyName");
var ColonyBenefit_1 = require("../../colonies/ColonyBenefit");
var Leavitt = (function (_super) {
    __extends(Leavitt, _super);
    function Leavitt() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = ColonyName_1.ColonyName.LEAVITT;
        _this.description = 'Science';
        _this.buildType = ColonyBenefit_1.ColonyBenefit.GAIN_SCIENCE_TAG;
        _this.tradeType = ColonyBenefit_1.ColonyBenefit.DRAW_CARDS_AND_KEEP_ONE;
        _this.tradeQuantity = [1, 2, 3, 4, 5, 6, 7];
        _this.colonyBonusType = ColonyBenefit_1.ColonyBenefit.DRAW_CARDS_AND_BUY_ONE;
        return _this;
    }
    return Leavitt;
}(Colony_1.Colony));
exports.Leavitt = Leavitt;
