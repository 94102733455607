"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Conscription = void 0;
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Conscription = (function (_super) {
    __extends(Conscription, _super);
    function Conscription() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            cost: 5,
            tags: [Tags_1.Tags.EARTH],
            name: CardName_1.CardName.CONSCRIPTION,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.EARTH, 2); }),
            metadata: {
                cardNumber: 'C05',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('next card', Size_1.Size.SMALL, true).colon().megacredits(-16);
                }),
                description: 'Requires 2 Earth tags. The next card you play this generation costs 16 M€ less.',
                victoryPoints: -1,
            },
        }) || this;
    }
    Conscription.prototype.getCardDiscount = function (player) {
        if (player.lastCardPlayed === this.name) {
            return 16;
        }
        return 0;
    };
    Conscription.prototype.play = function () {
        return undefined;
    };
    Conscription.prototype.getVictoryPoints = function () {
        return -1;
    };
    return Conscription;
}(Card_1.Card));
exports.Conscription = Conscription;
