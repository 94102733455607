"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftAresGlobalParameters = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var ShiftAresGlobalParameters = (function () {
    function ShiftAresGlobalParameters(player, aresData, cb) {
        this.player = player;
        this.aresData = aresData;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SHIFT_ARES_GLOBAL_PARAMETERS;
        this.title = 'Adjust Ares global parameters up to 1 step.';
        this.buttonLabel = 'Save';
    }
    return ShiftAresGlobalParameters;
}());
exports.ShiftAresGlobalParameters = ShiftAresGlobalParameters;
