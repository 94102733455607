"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Trees = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Trees = (function (_super) {
    __extends(Trees, _super);
    function Trees() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.TREES,
            tags: [Tags_1.Tags.PLANT],
            cost: 13,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-4); }),
            metadata: {
                cardNumber: '060',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.plants(3); }).plants(1);
                }),
                description: 'Requires -4 C or warmer. Increase your Plant production 3 steps. Gain 1 Plant.',
                victoryPoints: 1,
            },
        }) || this;
    }
    Trees.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, 3);
        player.plants++;
        return undefined;
    };
    Trees.prototype.getVictoryPoints = function () {
        return 1;
    };
    return Trees;
}(Card_1.Card));
exports.Trees = Trees;
