"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiningGuild = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Phase_1 = require("../../Phase");
var SpaceBonus_1 = require("../../SpaceBonus");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var GainProduction_1 = require("../../deferredActions/GainProduction");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var BoardType_1 = require("../../boards/BoardType");
var Options_1 = require("../Options");
var MiningGuild = (function (_super) {
    __extends(MiningGuild, _super);
    function MiningGuild() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.MINING_GUILD,
            tags: [Tags_1.Tags.BUILDING, Tags_1.Tags.BUILDING],
            startingMegaCredits: 30,
            productionBox: Units_1.Units.of({ steel: 1 }),
            metadata: {
                cardNumber: 'R24',
                description: 'You start with 30 M€, 5 steel and 1 steel production.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(30).nbsp.steel(5, { digit: Options_1.digit }).nbsp.production(function (pb) { return pb.steel(1); });
                    b.corpBox('effect', function (ce) {
                        ce.effect('Each time you get any steel or titanium as a placement bonus on the map, increase your steel production 1 step.', function (eb) {
                            eb.steel(1).asterix().slash().titanium(1).asterix();
                            eb.startEffect.production(function (pb) { return pb.steel(1); });
                        });
                    });
                }),
            },
        }) || this;
    }
    MiningGuild.prototype.onTilePlaced = function (cardOwner, activePlayer, space, boardType) {
        var _a;
        if (boardType !== BoardType_1.BoardType.MARS) {
            return;
        }
        if (cardOwner.id !== activePlayer.id || cardOwner.game.phase === Phase_1.Phase.SOLAR) {
            return;
        }
        if (((_a = space === null || space === void 0 ? void 0 : space.tile) === null || _a === void 0 ? void 0 : _a.covers) !== undefined) {
            return;
        }
        if (space.bonus.some(function (bonus) { return bonus === SpaceBonus_1.SpaceBonus.STEEL || bonus === SpaceBonus_1.SpaceBonus.TITANIUM; })) {
            cardOwner.game.defer(new GainProduction_1.GainProduction(cardOwner, Resources_1.Resources.STEEL));
        }
    };
    MiningGuild.prototype.play = function (player) {
        player.steel = 5;
        player.addProduction(Resources_1.Resources.STEEL, 1);
        return undefined;
    };
    return MiningGuild;
}(Card_1.Card));
exports.MiningGuild = MiningGuild;
