"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TurmoilPolicy = void 0;
var TurmoilPolicy;
(function (TurmoilPolicy) {
    TurmoilPolicy["MARS_FIRST_DEFAULT_POLICY"] = "mfp01";
    TurmoilPolicy["MARS_FIRST_POLICY_2"] = "mfp02";
    TurmoilPolicy["MARS_FIRST_POLICY_3"] = "mfp03";
    TurmoilPolicy["MARS_FIRST_POLICY_4"] = "mfp04";
    TurmoilPolicy["SCIENTISTS_DEFAULT_POLICY"] = "sp01";
    TurmoilPolicy["SCIENTISTS_POLICY_2"] = "sp02";
    TurmoilPolicy["SCIENTISTS_POLICY_3"] = "sp03";
    TurmoilPolicy["SCIENTISTS_POLICY_4"] = "sp04";
    TurmoilPolicy["UNITY_DEFAULT_POLICY"] = "up01";
    TurmoilPolicy["UNITY_POLICY_2"] = "up02";
    TurmoilPolicy["UNITY_POLICY_3"] = "up03";
    TurmoilPolicy["UNITY_POLICY_4"] = "up04";
    TurmoilPolicy["GREENS_DEFAULT_POLICY"] = "gp01";
    TurmoilPolicy["GREENS_POLICY_2"] = "gp02";
    TurmoilPolicy["GREENS_POLICY_3"] = "gp03";
    TurmoilPolicy["GREENS_POLICY_4"] = "gp04";
    TurmoilPolicy["REDS_DEFAULT_POLICY"] = "rp01";
    TurmoilPolicy["REDS_POLICY_2"] = "rp02";
    TurmoilPolicy["REDS_POLICY_3"] = "rp03";
    TurmoilPolicy["REDS_POLICY_4"] = "rp04";
    TurmoilPolicy["KELVINISTS_DEFAULT_POLICY"] = "kp01";
    TurmoilPolicy["KELVINISTS_POLICY_2"] = "kp02";
    TurmoilPolicy["KELVINISTS_POLICY_3"] = "kp03";
    TurmoilPolicy["KELVINISTS_POLICY_4"] = "kp04";
})(TurmoilPolicy = exports.TurmoilPolicy || (exports.TurmoilPolicy = {}));
