"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LargeConvoy = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectCard_1 = require("../../inputs/SelectCard");
var SelectOption_1 = require("../../inputs/SelectOption");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var LargeConvoy = (function (_super) {
    __extends(LargeConvoy, _super);
    function LargeConvoy() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.LARGE_CONVOY,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 36,
            tr: { oceans: 1 },
            metadata: {
                cardNumber: '143',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.oceans(1).cards(2).br;
                    b.plants(5, { digit: Options_1.digit }).or(Size_1.Size.MEDIUM).animals(4, { digit: Options_1.digit }).asterix();
                }),
                description: 'Place an ocean tile and draw 2 cards. Gain 5 Plants or add 4 Animals to ANOTHER card.',
                victoryPoints: 2,
            },
        }) || this;
    }
    LargeConvoy.prototype.play = function (player) {
        player.drawCard(2);
        var animalCards = player.getResourceCards(ResourceType_1.ResourceType.ANIMAL);
        var gainPlants = function () {
            player.addResource(Resources_1.Resources.PLANTS, 5, { log: true });
            player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
            return undefined;
        };
        if (animalCards.length === 0)
            return gainPlants();
        var availableActions = [];
        var gainPlantsOption = new SelectOption_1.SelectOption('Gain 5 plants', 'Gain plants', gainPlants);
        availableActions.push(gainPlantsOption);
        if (animalCards.length === 1) {
            var targetAnimalCard_1 = animalCards[0];
            availableActions.push(new SelectOption_1.SelectOption('Add 4 animals to ' + targetAnimalCard_1.name, 'Add animals', function () {
                player.addResourceTo(targetAnimalCard_1, { qty: 4, log: true });
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                return undefined;
            }));
        }
        else {
            availableActions.push(new SelectCard_1.SelectCard('Select card to add 4 animals', 'Add animals', animalCards, function (foundCards) {
                player.addResourceTo(foundCards[0], { qty: 4, log: true });
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                return undefined;
            }));
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], availableActions)))();
    };
    LargeConvoy.prototype.getVictoryPoints = function () {
        return 2;
    };
    return LargeConvoy;
}(Card_1.Card));
exports.LargeConvoy = LargeConvoy;
