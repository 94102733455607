"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Insulation = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Insulation = (function (_super) {
    __extends(Insulation, _super);
    function Insulation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.INSULATION,
            cost: 2,
            metadata: {
                cardNumber: '152',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.text('-X').heat(1).nbsp.text('+').megacredits(0, { multiplier: Options_1.multiplier });
                    });
                }),
                description: 'Decrease your heat production any number of steps and increase your M€ production the same number of steps.',
            },
        }) || this;
    }
    Insulation.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.HEAT) >= 1;
    };
    Insulation.prototype.play = function (player) {
        return new SelectAmount_1.SelectAmount('Select amount of heat production to decrease', 'Decrease', function (amount) {
            player.addProduction(Resources_1.Resources.HEAT, -amount, { log: true });
            player.addProduction(Resources_1.Resources.MEGACREDITS, amount, { log: true });
            return undefined;
        }, 1, player.getProduction(Resources_1.Resources.HEAT));
    };
    return Insulation;
}(Card_1.Card));
exports.Insulation = Insulation;
