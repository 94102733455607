"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvancedAlloys = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var AdvancedAlloys = (function (_super) {
    __extends(AdvancedAlloys, _super);
    function AdvancedAlloys() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ADVANCED_ALLOYS,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 9,
            metadata: {
                cardNumber: '071',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('Each titanium you have is worth 1 M€ extra.', function (be) {
                        be.titanium(1).startEffect.plus(Size_1.Size.SMALL).megacredits(1);
                    }).br;
                    b.effect('Each steel you have is worth 1 M€ extra.', function (be) {
                        be.steel(1).startEffect.plus(Size_1.Size.SMALL).megacredits(1);
                    });
                }),
            },
        }) || this;
    }
    AdvancedAlloys.prototype.play = function (player) {
        player.increaseTitaniumValue();
        player.increaseSteelValue();
        return undefined;
    };
    AdvancedAlloys.prototype.onDiscard = function (player) {
        player.decreaseTitaniumValue();
        player.decreaseSteelValue();
    };
    return AdvancedAlloys;
}(Card_1.Card));
exports.AdvancedAlloys = AdvancedAlloys;
