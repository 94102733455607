"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegoPlastics = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var RegoPlastics = (function (_super) {
    __extends(RegoPlastics, _super);
    function RegoPlastics() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.REGO_PLASTICS,
            tags: [Tags_1.Tags.BUILDING],
            cost: 10,
            metadata: {
                cardNumber: 'X10',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('Your steel resources are worth 1 M€ extra.', function (eb) {
                        eb.steel(1).startEffect.plus(Size_1.Size.SMALL).megacredits(1);
                    });
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    RegoPlastics.prototype.play = function (player) {
        player.increaseSteelValue();
        return undefined;
    };
    RegoPlastics.prototype.getVictoryPoints = function () {
        return 1;
    };
    RegoPlastics.prototype.onDiscard = function (player) {
        player.decreaseSteelValue();
    };
    return RegoPlastics;
}(Card_1.Card));
exports.RegoPlastics = RegoPlastics;
