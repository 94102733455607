"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValleyTrust = void 0;
var Tags_1 = require("../Tags");
var SelectCard_1 = require("../../inputs/SelectCard");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var ValleyTrust = (function (_super) {
    __extends(ValleyTrust, _super);
    function ValleyTrust() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.VALLEY_TRUST,
            tags: [Tags_1.Tags.EARTH],
            startingMegaCredits: 37,
            initialActionText: 'Draw 3 Prelude cards, and play one of them',
            cardDiscount: { tag: Tags_1.Tags.SCIENCE, amount: 2 },
            metadata: {
                cardNumber: 'R34',
                description: 'You start with 37 M€. As your first action, draw 3 Prelude cards, and play one of them. Discard the other two.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(37).nbsp.prelude().asterix();
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you play a Science tag, you pay 2M€ less for it.', function (eb) {
                            eb.science(1, { played: Options_1.played }).startEffect.megacredits(-2);
                        });
                    });
                }),
            },
        }) || this;
    }
    ValleyTrust.prototype.getCardDiscount = function (_player, card) {
        return card.tags.filter(function (tag) { return tag === Tags_1.Tags.SCIENCE; }).length * 2;
    };
    ValleyTrust.prototype.initialAction = function (player) {
        if (player.game.gameOptions.preludeExtension) {
            var cardsDrawn = [
                player.game.dealer.dealPreludeCard(),
                player.game.dealer.dealPreludeCard(),
                player.game.dealer.dealPreludeCard(),
            ];
            return new SelectCard_1.SelectCard('Choose prelude card to play', 'Play', cardsDrawn, function (foundCards) {
                if (foundCards[0].canPlay === undefined || foundCards[0].canPlay(player)) {
                    return player.playCard(foundCards[0]);
                }
                else {
                    throw new Error('You cannot pay for this card');
                }
            }, 1, 1);
        }
        else {
            console.warn('Prelude extension isn\'t selected.');
            return undefined;
        }
    };
    ValleyTrust.prototype.play = function () {
        return undefined;
    };
    return ValleyTrust;
}(Card_1.Card));
exports.ValleyTrust = ValleyTrust;
