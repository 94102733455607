"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var Button_vue_1 = require("@/client/components/common/Button.vue");
exports.default = vue_1.default.extend({
    name: 'SelectHowToPay',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    computed: {
        thisPlayer: function () {
            return this.playerView.thisPlayer;
        },
    },
    components: {
        Button: Button_vue_1.default,
    },
    data: function () {
        return {
            cost: 0,
            heat: 0,
            megaCredits: 0,
            steel: 0,
            titanium: 0,
            microbes: 0,
            floaters: 0,
            warning: undefined,
        };
    },
    mounted: function () {
        var _this = this;
        vue_1.default.nextTick(function () {
            _this.setInitialCost();
            _this.$data.megaCredits = _this.getMegaCreditsMax();
            _this.setDefaultSteelValue();
            _this.setDefaultTitaniumValue();
            _this.setDefaultHeatValue();
        });
    },
    methods: __assign(__assign({}, PaymentWidgetMixin_1.PaymentWidgetMixin.methods), { hasWarning: function () {
            return this.$data.warning !== undefined;
        },
        setInitialCost: function () {
            this.$data.cost = this.playerinput.amount;
        },
        setDefaultSteelValue: function () {
            if (!this.canAffordWithMcOnly() && this.canUseSteel()) {
                var requiredSteelQty = Math.ceil(Math.max(this.$data.cost - this.thisPlayer.megaCredits, 0) / this.thisPlayer.steelValue);
                if (requiredSteelQty > this.thisPlayer.steel) {
                    this.$data.steel = this.thisPlayer.steel;
                }
                else {
                    var currentSteelValue = requiredSteelQty * this.thisPlayer.steelValue;
                    while (currentSteelValue <= this.$data.cost - this.thisPlayer.steelValue && requiredSteelQty < this.thisPlayer.steel) {
                        requiredSteelQty++;
                        currentSteelValue = requiredSteelQty * this.thisPlayer.steelValue;
                    }
                    this.$data.steel = requiredSteelQty;
                }
                var discountedCost = this.$data.cost - (this.$data.steel * this.thisPlayer.steelValue);
                this.$data.megaCredits = Math.max(discountedCost, 0);
            }
            else {
                this.$data.steel = 0;
            }
        },
        setDefaultTitaniumValue: function () {
            if (!this.canAffordWithMcOnly() && this.canUseTitanium()) {
                var requiredTitaniumQty = Math.ceil(Math.max(this.$data.cost - this.thisPlayer.megaCredits - (this.$data.steel * this.thisPlayer.steelValue), 0) / this.thisPlayer.titaniumValue);
                if (requiredTitaniumQty > this.thisPlayer.titanium) {
                    this.$data.titanium = this.thisPlayer.titanium;
                }
                else {
                    var currentTitaniumValue = requiredTitaniumQty * this.thisPlayer.titaniumValue;
                    while (currentTitaniumValue <= this.$data.cost - this.thisPlayer.titaniumValue && requiredTitaniumQty < this.thisPlayer.titanium) {
                        requiredTitaniumQty++;
                        currentTitaniumValue = requiredTitaniumQty * this.thisPlayer.titaniumValue;
                    }
                    this.$data.titanium = requiredTitaniumQty;
                }
                var discountedCost = this.$data.cost - (this.$data.steel * this.thisPlayer.steelValue) - (this.$data.titanium * this.thisPlayer.titaniumValue);
                this.$data.megaCredits = Math.max(discountedCost, 0);
            }
            else {
                this.$data.titanium = 0;
            }
        },
        setDefaultHeatValue: function () {
            if (!this.canAffordWithMcOnly() && this.canUseHeat()) {
                this.$data.heat = Math.max(this.$data.cost - this.thisPlayer.megaCredits - (this.$data.steel * this.thisPlayer.steelValue) - (this.$data.titanium * this.thisPlayer.titaniumValue), 0);
            }
            else {
                this.$data.heat = 0;
            }
            var discountedCost = this.$data.cost - (this.$data.steel * this.thisPlayer.steelValue) - (this.$data.titanium * this.thisPlayer.titaniumValue) - this.$data.heat;
            this.$data.megaCredits = Math.max(discountedCost, 0);
        },
        canAffordWithMcOnly: function () {
            return this.thisPlayer.megaCredits >= this.$data.cost;
        },
        canUseHeat: function () {
            return this.playerinput.canUseHeat && this.thisPlayer.heat > 0;
        },
        canUseSteel: function () {
            return this.playerinput.canUseSteel && this.thisPlayer.steel > 0;
        },
        canUseTitanium: function () {
            return this.playerinput.canUseTitanium && this.thisPlayer.titanium > 0;
        },
        saveData: function () {
            var htp = {
                heat: this.$data.heat,
                megaCredits: this.$data.megaCredits,
                steel: this.$data.steel,
                titanium: this.$data.titanium,
                microbes: 0,
                floaters: 0,
                science: 0,
            };
            if (htp.megaCredits > this.thisPlayer.megaCredits) {
                this.$data.warning = 'You don\'t have that many M€';
                return;
            }
            if (htp.heat > this.thisPlayer.heat) {
                this.$data.warning = 'You don\'t have enough heat';
                return;
            }
            if (htp.titanium > this.thisPlayer.titanium) {
                this.$data.warning = 'You don\'t have enough titanium';
                return;
            }
            if (htp.steel > this.thisPlayer.steel) {
                this.$data.warning = 'You don\'t have enough steel';
                return;
            }
            var requiredAmt = this.playerinput.amount || 0;
            var totalSpentAmt = htp.heat + htp.megaCredits + (htp.steel * this.thisPlayer.steelValue) + (htp.titanium * this.thisPlayer.titaniumValue);
            if (requiredAmt > 0 && totalSpentAmt < requiredAmt) {
                this.$data.warning = 'Haven\'t spent enough';
                return;
            }
            if (requiredAmt === 0) {
                htp.heat = 0;
                htp.megaCredits = 0;
            }
            if (requiredAmt > 0 && totalSpentAmt > requiredAmt) {
                var diff = totalSpentAmt - requiredAmt;
                if (htp.titanium && diff >= this.thisPlayer.titaniumValue) {
                    this.$data.warning = 'You cannot overspend titanium';
                    return;
                }
                if (htp.steel && diff >= this.thisPlayer.steelValue) {
                    this.$data.warning = 'You cannot overspend steel';
                    return;
                }
                if (htp.heat && diff >= 1) {
                    this.$data.warning = 'You cannot overspend heat';
                    return;
                }
                if (htp.megaCredits && diff >= 1) {
                    this.$data.warning = 'You cannot overspend megaCredits';
                    return;
                }
            }
            var showAlert = PreferencesManager_1.PreferencesManager.load('show_alerts') === '1';
            if (requiredAmt > 0 && totalSpentAmt > requiredAmt && showAlert) {
                var diff = totalSpentAmt - requiredAmt;
                if (confirm('Warning: You are overpaying by ' + diff + ' M€')) {
                    this.onsave([[JSON.stringify(htp)]]);
                }
                else {
                    this.$data.warning = 'Please adjust payment amount';
                    return;
                }
            }
            else {
                this.onsave([[JSON.stringify(htp)]]);
            }
        } }),
});
