"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpacePort = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var Units_1 = require("../../Units");
var SpacePort = (function (_super) {
    __extends(SpacePort, _super);
    function SpacePort() {
        return _super.call(this, {
            cost: 22,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            name: CardName_1.CardName.SPACE_PORT,
            cardType: CardType_1.CardType.AUTOMATED,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 4 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.colonies(); }),
            metadata: {
                cardNumber: 'C39',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(4);
                    }).nbsp.city().br;
                    b.tradeFleet();
                }),
                description: 'Requires 1 colony. Decrease your Energy production 1 step and increase your M€ production 4 steps. Place a City tile. Gain 1 Trade Fleet.',
            },
        }) || this;
    }
    SpacePort.prototype.canPlay = function (player) {
        if (player.game.board.getAvailableSpacesForCity(player).length === 0)
            return false;
        var coloniesCount = 0;
        player.game.colonies.forEach(function (colony) {
            coloniesCount += colony.colonies.filter(function (owner) { return owner === player.id; }).length;
        });
        return coloniesCount > 0 && player.getProduction(Resources_1.Resources.ENERGY) > 0;
    };
    SpacePort.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 4);
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.increaseFleetSize();
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            return undefined;
        });
    };
    return SpacePort;
}(Card_1.Card));
exports.SpacePort = SpacePort;
