"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscardCards = void 0;
var SelectCard_1 = require("../inputs/SelectCard");
var DeferredAction_1 = require("./DeferredAction");
var DiscardCards = (function () {
    function DiscardCards(player, count, title) {
        if (count === void 0) { count = 1; }
        if (title === void 0) { title = 'Select ' + count + ' card' + (count > 1 ? 's' : '') + ' to discard'; }
        this.player = player;
        this.count = count;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DISCARD_CARDS;
    }
    DiscardCards.prototype.execute = function () {
        var _this = this;
        if (this.player.cardsInHand.length <= this.count) {
            var cards = this.player.cardsInHand.splice(0, this.player.cardsInHand.length);
            cards.forEach(function (card) { return _this.player.game.dealer.discard(card); });
            return undefined;
        }
        return new SelectCard_1.SelectCard(this.title, 'Discard', this.player.cardsInHand, function (foundCards) {
            for (var _i = 0, foundCards_1 = foundCards; _i < foundCards_1.length; _i++) {
                var card = foundCards_1[_i];
                _this.player.cardsInHand.splice(_this.player.cardsInHand.indexOf(card), 1);
                _this.player.game.dealer.discard(card);
            }
            return undefined;
        }, this.count, this.count);
    };
    return DiscardCards;
}());
exports.DiscardCards = DiscardCards;
