"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TharsisRepublic = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var SpaceType_1 = require("../../SpaceType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var GainResources_1 = require("../../deferredActions/GainResources");
var GainProduction_1 = require("../../deferredActions/GainProduction");
var Board_1 = require("../../boards/Board");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var TharsisRepublic = (function (_super) {
    __extends(TharsisRepublic, _super);
    function TharsisRepublic() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.THARSIS_REPUBLIC,
            tags: [Tags_1.Tags.BUILDING],
            initialActionText: 'Place a city tile',
            startingMegaCredits: 40,
            metadata: {
                cardNumber: 'R31',
                description: 'You start with 40 M€. As your first action in the game, place a city tile.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(40).nbsp.city();
                    b.corpBox('effect', function (ce) {
                        ce.effect('When any city tile is placed ON MARS, increase your M€ production 1 step. When you place a city tile, gain 3 M€.', function (eb) {
                            eb.city({ size: Size_1.Size.SMALL, all: Options_1.all }).asterix().colon();
                            eb.production(function (pb) { return pb.megacredits(1); }).nbsp;
                            eb.city({ size: Size_1.Size.SMALL }).startEffect.megacredits(3);
                        });
                    });
                }),
            },
        }) || this;
    }
    TharsisRepublic.prototype.initialAction = function (player) {
        return new SelectSpace_1.SelectSpace('Select space on mars for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            player.game.log('${0} placed a City tile', function (b) { return b.player(player); });
            return undefined;
        });
    };
    TharsisRepublic.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        if (Board_1.Board.isCitySpace(space)) {
            if (cardOwner.id === activePlayer.id) {
                cardOwner.game.defer(new GainResources_1.GainResources(cardOwner, Resources_1.Resources.MEGACREDITS, { count: 3 }));
            }
            if (space.spaceType !== SpaceType_1.SpaceType.COLONY) {
                cardOwner.game.defer(new GainProduction_1.GainProduction(cardOwner, Resources_1.Resources.MEGACREDITS), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : undefined);
            }
        }
        return;
    };
    TharsisRepublic.prototype.play = function (player) {
        if (player.game.getPlayers().length === 1) {
            player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        }
        return undefined;
    };
    return TharsisRepublic;
}(Card_1.Card));
exports.TharsisRepublic = TharsisRepublic;
