"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var HelpIconology_vue_1 = require("@/client/components/help/HelpIconology.vue");
var HelpPhases_vue_1 = require("@/client/components/help/HelpPhases.vue");
var HelpStandardProjects_vue_1 = require("@/client/components/help/HelpStandardProjects.vue");
exports.default = vue_1.default.extend({
    name: 'Help',
    data: function () {
        return {
            currentPage: 'iconology',
        };
    },
    components: {
        HelpIconology: HelpIconology_vue_1.default,
        HelpStandardProjects: HelpStandardProjects_vue_1.default,
        HelpPhases: HelpPhases_vue_1.default,
    },
    methods: {
        setTab: function (tab) {
            this.currentPage = tab;
        },
        isOpen: function (tab) {
            return tab === this.currentPage;
        },
    },
});
