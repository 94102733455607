"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EosChasmaNationalPark = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var EosChasmaNationalPark = (function (_super) {
    __extends(EosChasmaNationalPark, _super);
    function EosChasmaNationalPark() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.EOS_CHASMA_NATIONAL_PARK,
            tags: [Tags_1.Tags.PLANT, Tags_1.Tags.BUILDING],
            cost: 16,
            productionBox: Units_1.Units.of({ energy: 2 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-12); }),
            metadata: {
                cardNumber: '026',
                description: 'Requires -12 C or warmer. Add 1 Animal TO ANY ANIMAL CARD. Gain 3 Plants. Increase your M€ production 2 steps.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.animals(1).asterix().plants(3).br;
                    b.production(function (pb) { return pb.megacredits(2); });
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    EosChasmaNationalPark.prototype.play = function (player) {
        var cards = player.getResourceCards(ResourceType_1.ResourceType.ANIMAL);
        player.plants += 3;
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        if (cards.length < 1)
            return undefined;
        if (cards.length === 1) {
            player.addResourceTo(cards[0], { qty: 1, log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Add 1 animal to a card', 'Add animal', cards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    EosChasmaNationalPark.prototype.getVictoryPoints = function () {
        return 1;
    };
    return EosChasmaNationalPark;
}(Card_1.Card));
exports.EosChasmaNationalPark = EosChasmaNationalPark;
