"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardName_1 = require("@/CardName");
exports.default = vue_1.default.extend({
    name: 'CardCorporationLogo',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    methods: {
        getHtmlContent: function () {
            var title = this.title;
            switch (title) {
                case CardName_1.CardName.APHRODITE:
                    return '<div class="card-aphrodite-logo">APHRODITE</div>';
                case CardName_1.CardName.ARKLIGHT:
                    return '<div class="card-arklight-logo">ARKLIGHT</div>';
                case CardName_1.CardName.POSEIDON:
                    return '<div class="card-poseidon-logo">POSEIDON</div>';
                case CardName_1.CardName.SATURN_SYSTEMS:
                    return "<div class=\"card-saturn-logo\">\n        SATURN <span style=\"font-size:20px;display:inline-block;\">&#x25CF;</span> SYSTEMS\n        </div>";
                case CardName_1.CardName.CELESTIC:
                    return "<div class=\"card-celestic-logo\">\n        <span style=\"background: linear-gradient(to right, rgb(251,192,137),rgb(251,192,137),rgb(23,185,236));padding-left: 5px;\">CEL</span>\n        <span style=\"background:linear-gradient(to right,rgb(23,185,236),rgb(251,192,137))\">ES</span>\n        <span style=\"background:rgb(251,192,137);padding-right:5px;\">TIC</span>\n        </div>";
                case CardName_1.CardName.MORNING_STAR_INC:
                    return '<div class="card-morning-star-logo">MORNING STAR INC.</div>';
                case CardName_1.CardName.PRISTAR:
                    return '<div class="card-pristar-logo">PRISTAR</div>';
                case CardName_1.CardName.CHEUNG_SHING_MARS:
                    return "<div class=\"card-cheung-shing-logo\">\n        <span style=\"color:red;border:4px solid red;border-radius:50%;padding:3px 5px 3px 5px;font-size:30px;line-height:14px;box-shadow: 3px 3px 3px grey, inset 0 0 3px 3px grey;text-shadow: 3px 3px 3px grey;\">\u3A10</span></div>\n        <div style=\"display: inline-block; width:140px; font-size:19px; line-height: 22px; vertical-align: middle; margin-bottom: 15px;font-weight:normal;\">\n        &nbsp;Cheung Shing <br><div style=\"margin-left:10px\"> \u25A0\u25A0MARS\u25A0\u25A0 </div>\n        </div>";
                case CardName_1.CardName.CREDICOR:
                    return '<div class="card-credicor-logo">CREDICOR</div>';
                case CardName_1.CardName.ECOLINE:
                    return '<div class="card-ecoline-logo">ecoline</div>';
                case CardName_1.CardName.HELION:
                    return '<div class="card-helion-logo">helion</div>';
                case CardName_1.CardName.INTERPLANETARY_CINEMATICS:
                    return "<div style=\"color: #020202;font-size:17px;margin-top:10px;margin-left:-87px;\">INTERPLANETARY</div>\n        <div style=\"height:5px;margin-top:-2px;width:143px;background:linear-gradient(to right,yellow,black,yellow,black,yellow);border:5px solid #cc3333;box-shadow:3px 3px 6px grey;\"></div>\n        <div style=\"color: #020202;font-size:24px;margin-left:-89px;margin-top:-5px; display:inline-block; -webkit-transform:scale(0.5,1); -moz-transform:scale(0.5,1); -ms-transform:scale(0.5,1); -o-transform:scale(0.5,1); transform:scale(1,0.5); margin-bottom:15px;\">CINEMATICS</div>";
                case CardName_1.CardName.INVENTRIX:
                    return "<span class=\"card-inventrix-logo\">\n        <span style=\"color: #020202;background-color:#6bb5c7;padding-left:4px;padding-right:4px;font-size:26px;box-shadow: 6px 6px 10px grey;\">X</span>\n        INVENTRIX</span>";
                case CardName_1.CardName.PHOBOLOG:
                    return '<span class="card-phobolog-logo">PHOBOLOG</span>';
                case CardName_1.CardName.POINT_LUNA:
                    return '<span class="card-luna-logo">POINT LUNA</span>';
                case CardName_1.CardName.POLYPHEMOS:
                    return '<span class="card-polyphemos-logo">POLYPHEMOS</span>';
                case CardName_1.CardName.SEPTUM_TRIBUS:
                    return '<span class="card-septem-tribus-logo">Septem Tribus</span>';
                case CardName_1.CardName.TERRALABS_RESEARCH:
                    return "<div style=\"font-size: 13px;left:32px;top:10px;font-family:Prototype;color:#222;transform:scale(2,1);position:absolute;\">TERRALABS</div>\n        <div style=\"position:absolute;top:28px;left:46px;font-size:8px;letter-spacing:2px;font-family:Prototype;transform:scale(2,1)\">RESEARCH</div>";
                case CardName_1.CardName.THORGATE:
                    return '<span class="card-thorgate-logo">THORGATE</span>';
                case CardName_1.CardName.VIRON:
                    return '<span class="card-viron-logo">VIRON</span>';
                case CardName_1.CardName.ARIDOR:
                    return '<span class="card-aridor-logo">ARIDOR</span>';
                case CardName_1.CardName.ASTRODRILL:
                    return '<span class="card-astrodril-logo">Astrodrill</span>';
                case CardName_1.CardName.FACTORUM:
                    return '<span class="card-factorum-logo">FACTORUM</span>';
                case CardName_1.CardName.MANUTECH:
                    return '<span class="card-manutech-logo"><span style="color:white;background:#e63900;text-shadow:none;padding-left:2px;">MA</span>NUTECH</span>';
                case CardName_1.CardName.AGRICOLA_INC:
                    return '<span class="card-agricola-logo">Agricola Inc</span>';
                case CardName_1.CardName.ARCADIAN_COMMUNITIES:
                    return '<span class="card-arcadian-logo"><span>Arcadian</span></br><span>Communities</span></span>';
                case CardName_1.CardName.INCITE:
                    return '<span class="card-incite-logo">Incite</span>';
                case CardName_1.CardName.LAKEFRONT_RESORTS:
                    return '<div class="card-lakefront-logo">LAKEFRONT <br> &nbsp;&nbsp;RESORTS</div>';
                case CardName_1.CardName.MINING_GUILD:
                    return '<span class="card-mining-guild-logo">MINING<br>GUILD</span>';
                case CardName_1.CardName.PHILARES:
                    return '<div class="card-philares-logo">PHIL<span style="color:#ff5858">A</span>RES</div>';
                case CardName_1.CardName.RECYCLON:
                    return '<div class="card-recyclon-logo">Recyclon</div>';
                case CardName_1.CardName.ROBINSON_INDUSTRIES:
                    return "<div class=\"card-robinson-logo\"><div style=\"letter-spacing:4px;border-bottom:3px solid #ccc;margin-top:5px;\">ROBINSON</div>\n        <div style=\"border-bottom:3px solid #ccc;\">\u2022\u2014\u2022\u2014\u2022\u2014\u2022\u2014\u2022\u2014\u2022\u2014\u2022&nbsp;</div>\n        <div style=\"letter-spacing:2px;\">INDUSTRIES</div></div>";
                case CardName_1.CardName.SPLICE:
                    return "<div class=\"card-splice-logo\"><div>SPLI<span style=\"color:red\">C</span>E</div>\n        <div STYLE=\"height:3px;background:red;margin-top:-3px;\"></div>\n        <div STYLE=\"font-size:10px;line-height:18px;\">TACTICAL GENOMICS</div>\n        </div>";
                case CardName_1.CardName.STORMCRAFT_INCORPORATED:
                    return "<div class=\"card-stormcraft-logo\">\n        <div class=\"stormcraft1\">STORM</div><div class=\"stormcraft2\">CRAFT</div>\n        <div class=\"stormcraft3\">INCOR</div><div class=\"stormcraft4\">PORATED</div>\n        </div>";
                case CardName_1.CardName.TERACTOR:
                    return '<span class="card-teractor-logo">TERACTOR</span>';
                case CardName_1.CardName.THARSIS_REPUBLIC:
                    return "<div class=\"card-tharsis-logo\">\n        <div class=\"card-tharsis-logo-image\"></div>\n        <div class=\"card-tharsis-logo-text\">Tharsis Republic</div>\n        </div>";
                case CardName_1.CardName.UNITED_NATIONS_MARS_INITIATIVE:
                    return '<span class="card-unmi-logo">UNITED<br/>NATIONS<br/>MARS<br/>INITIATIVE</span>';
                case CardName_1.CardName.UTOPIA_INVEST:
                    return "<div class=\"card-utopia-logo\">\n        <div class=\"utopia-corp-name-1\">UTOPIA</div>\n        <div class=\"utopia-corp-name-2\">INVEST</div>\n        </div>";
                case CardName_1.CardName.VALLEY_TRUST:
                    return "<div class=\"card-valley-trust-logo\">\n        <div style=\"display:inline-block;margin-left:25px;padding-top: 2px;margin-bottom:0px;font-size:26px;text-shadow: 2px 2px #ccc;text-align:center\">VALLEY<br/> TRUST</div>\n        </div>";
                case CardName_1.CardName.VITOR:
                    return "<div class=\"card-vitor-logo\">\n        <span style=\"color:white;background:orangered;padding-left:3px;\">VIT</span>\n        <span style=\"background:linear-gradient(to right, orangered,white);\">O</span>\n        <span style=\"background:white;padding-right:3px;\">R</span></div>";
                case CardName_1.CardName.PHARMACY_UNION:
                    return '<div class="card-pharmacy-union-logo">Pharmacy<br/>Union</div>';
                case CardName_1.CardName.PLAYWRIGHTS:
                    return '<div class="card-playwrights-logo">Playwrights</div>';
                case CardName_1.CardName.MIDAS:
                    return '<div class="card-midas-logo">MIDAS</div>';
                case CardName_1.CardName.PROJECT_WORKSHOP:
                    return '<div class="card-project-workshop-logo">PROJECT<br/>WORKSHOP</div>';
                case CardName_1.CardName.MONS_INSURANCE:
                    return "<div class=\"card-mons-logo\">\n        <div class=\"mons0\">\u25B2</div>\n        <div class=\"mons1\">mons</div>\n        <div class=\"mons2\">INSURANCE</div>\n        </div>";
                case CardName_1.CardName.NANOTECH_INDUSTRIES:
                    return '<div class="card-nanotech-industries-logo"></div>';
                case CardName_1.CardName.TEMPEST_CONSULTANCY:
                    return '<div class="card-tempest-consultancy-logo"></div>';
                case CardName_1.CardName.THE_DARKSIDE_OF_THE_MOON_SYNDICATE:
                    return '<div class="card-the-darkside-of-the-moon-syndicate-logo"></div>';
                case CardName_1.CardName.LUNA_HYPERLOOP_CORPORATION:
                    return '<div class="card-luna-hyperloop-corporation-logo"></div>';
                case CardName_1.CardName.CRESCENT_RESEARCH_ASSOCIATION:
                    return '<div class="card-crescent-research-association-logo"></div>';
                case CardName_1.CardName.LUNA_FIRST_INCORPORATED:
                    return '<div class="card-luna-first-incorporated-logo"></div>';
                case CardName_1.CardName.THE_GRAND_LUNA_CAPITAL_GROUP:
                    return '<div class="card-the-grand-luna-capital-group-logo"></div>';
                case CardName_1.CardName.INTRAGEN_SANCTUARY_HEADQUARTERS:
                    return '<div class="card-intragen-sanctuary-headquarters-logo"></div>';
                case CardName_1.CardName.THE_ARCHAIC_FOUNDATION_INSTITUTE:
                    return '<div class="card-the-archaic-foundation-institute-logo"></div>';
                case CardName_1.CardName.CURIOSITY_II:
                    return '<div class="card-curiosity-ii-logo">Curiosity II</div>';
            }
            return '';
        },
    },
});
