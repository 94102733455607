"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonRoadStandardProjectVariant2 = exports.MoonMineStandardProjectVariant2 = exports.MoonColonyStandardProjectVariant2 = void 0;
var CardName_1 = require("../../CardName");
var Units_1 = require("../../Units");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MoonColonyStandardProject_1 = require("./MoonColonyStandardProject");
var MoonMineStandardProject_1 = require("./MoonMineStandardProject");
var MoonRoadStandardProject_1 = require("./MoonRoadStandardProject");
var MoonColonyStandardProjectVariant2 = (function (_super) {
    __extends(MoonColonyStandardProjectVariant2, _super);
    function MoonColonyStandardProjectVariant2() {
        return _super.call(this, {
            name: CardName_1.CardName.MOON_COLONY_STANDARD_PROJECT_V2,
            cost: 26,
            reserveUnits: Units_1.Units.EMPTY,
            tr: { moonColony: 1 },
            metadata: {
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 26 M€ place a colony on the moon and raise your M€ production 1 step.', function (eb) {
                        eb.megacredits(26).startAction.moonColony({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE }).production(function (pb) { return pb.megacredits(1); });
                    });
                }),
            },
        }) || this;
    }
    MoonColonyStandardProjectVariant2.prototype.canAct = function (player) {
        return player.game.gameOptions.moonStandardProjectVariant && _super.prototype.canAct.call(this, player);
    };
    return MoonColonyStandardProjectVariant2;
}(MoonColonyStandardProject_1.MoonColonyStandardProject));
exports.MoonColonyStandardProjectVariant2 = MoonColonyStandardProjectVariant2;
var MoonMineStandardProjectVariant2 = (function (_super) {
    __extends(MoonMineStandardProjectVariant2, _super);
    function MoonMineStandardProjectVariant2() {
        return _super.call(this, {
            name: CardName_1.CardName.MOON_MINE_STANDARD_PROJECT_V2,
            cost: 23,
            reserveUnits: Units_1.Units.EMPTY,
            tr: { moonMining: 1 },
            metadata: {
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 23 M€ to place a mine on the moon, raise the Mining Rate 1 step, and raise steel production 1 step.', function (eb) {
                        eb.megacredits(23).startAction.moonMine({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE }).production(function (pb) { return pb.steel(1); });
                    });
                }),
            },
        }) || this;
    }
    MoonMineStandardProjectVariant2.prototype.canAct = function (player) {
        return player.game.gameOptions.moonStandardProjectVariant && _super.prototype.canAct.call(this, player);
    };
    return MoonMineStandardProjectVariant2;
}(MoonMineStandardProject_1.MoonMineStandardProject));
exports.MoonMineStandardProjectVariant2 = MoonMineStandardProjectVariant2;
var MoonRoadStandardProjectVariant2 = (function (_super) {
    __extends(MoonRoadStandardProjectVariant2, _super);
    function MoonRoadStandardProjectVariant2() {
        return _super.call(this, {
            name: CardName_1.CardName.MOON_ROAD_STANDARD_PROJECT_V2,
            cost: 21,
            reserveUnits: Units_1.Units.EMPTY,
            tr: { moonLogistics: 1 },
            metadata: {
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 21 M€ place a road on the moon and raise the Logistics Rate 1 step.', function (eb) {
                        eb.megacredits(21).startAction.moonRoad({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_LOGISTICS_RATE });
                    });
                }),
            },
        }) || this;
    }
    MoonRoadStandardProjectVariant2.prototype.canAct = function (player) {
        return player.game.gameOptions.moonStandardProjectVariant && _super.prototype.canAct.call(this, player);
    };
    return MoonRoadStandardProjectVariant2;
}(MoonRoadStandardProject_1.MoonRoadStandardProject));
exports.MoonRoadStandardProjectVariant2 = MoonRoadStandardProjectVariant2;
