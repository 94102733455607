"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectProductionToLose = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectProductionToLose = (function () {
    function SelectProductionToLose(title, unitsToLose, player, cb, buttonLabel) {
        if (buttonLabel === void 0) { buttonLabel = 'Save'; }
        this.title = title;
        this.unitsToLose = unitsToLose;
        this.player = player;
        this.cb = cb;
        this.buttonLabel = buttonLabel;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_PRODUCTION_TO_LOSE;
        this.buttonLabel = buttonLabel;
    }
    return SelectProductionToLose;
}());
exports.SelectProductionToLose = SelectProductionToLose;
