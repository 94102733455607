"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeuteriumExport = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var DeuteriumExport = (function (_super) {
    __extends(DeuteriumExport, _super);
    function DeuteriumExport() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.DEUTERIUM_EXPORT,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.VENUS, Tags_1.Tags.ENERGY],
            cost: 11,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: '221',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Floater to this card.', function (eb) {
                        eb.empty().startAction.floaters(1);
                    }).br;
                    b.or(Size_1.Size.SMALL).br;
                    b.action('Spend 1 Floater here to increase your energy production 1 step.', function (be) {
                        be.floaters(1).startAction.production(function (pb) { return pb.energy(1); });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    DeuteriumExport.prototype.play = function () {
        return undefined;
    };
    DeuteriumExport.prototype.canAct = function () {
        return true;
    };
    DeuteriumExport.prototype.action = function (player) {
        var _this = this;
        if (this.resourceCount < 1) {
            player.addResourceTo(this, 1);
            return undefined;
        }
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Remove 1 floater to raise energy production 1 step', 'Remove floater', function () {
            _this.resourceCount--;
            player.addProduction(Resources_1.Resources.ENERGY, 1);
            return undefined;
        }), new SelectOption_1.SelectOption('Add 1 floater to this card', 'Add floater', function () {
            player.addResourceTo(_this, 1);
            return undefined;
        }));
    };
    return DeuteriumExport;
}(Card_1.Card));
exports.DeuteriumExport = DeuteriumExport;
