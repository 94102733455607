"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var constants_1 = require("@/constants");
var GlobalParameter_1 = require("@/GlobalParameter");
exports.default = vue_1.default.extend({
    name: 'global-parameter-value',
    props: {
        param: {
            type: Number,
        },
        value: {
            type: Number,
        },
    },
    methods: {
        isMax: function () {
            switch (this.param) {
                case GlobalParameter_1.GlobalParameter.TEMPERATURE:
                    return this.value === constants_1.MAX_TEMPERATURE;
                case GlobalParameter_1.GlobalParameter.OXYGEN:
                    return this.value === constants_1.MAX_OXYGEN_LEVEL;
                case GlobalParameter_1.GlobalParameter.OCEANS:
                    return this.value === constants_1.MAX_OCEAN_TILES;
                case GlobalParameter_1.GlobalParameter.VENUS:
                    return this.value === constants_1.MAX_VENUS_SCALE;
                default:
                    return false;
            }
        },
        getIconClass: function () {
            switch (this.param) {
                case GlobalParameter_1.GlobalParameter.TEMPERATURE:
                    return 'temperature-tile';
                case GlobalParameter_1.GlobalParameter.OXYGEN:
                    return 'oxygen-tile';
                case GlobalParameter_1.GlobalParameter.OCEANS:
                    return 'ocean-tile';
                case GlobalParameter_1.GlobalParameter.VENUS:
                    return 'venus-tile';
                default:
                    return '';
            }
        },
        suffix: function () {
            return this.param === GlobalParameter_1.GlobalParameter.OXYGEN ? '%' : '';
        },
    },
});
