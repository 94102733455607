"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MolecularPrinting = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var MolecularPrinting = (function (_super) {
    __extends(MolecularPrinting, _super);
    function MolecularPrinting() {
        return _super.call(this, {
            cost: 11,
            tags: [Tags_1.Tags.SCIENCE],
            name: CardName_1.CardName.MOLECULAR_PRINTING,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                cardNumber: 'C27',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(1).slash().city({ size: Size_1.Size.SMALL, all: Options_1.all }).br;
                    b.megacredits(1).slash().colonies(1, { size: Size_1.Size.SMALL, all: Options_1.all });
                }),
                description: 'Gain 1 M€ for each city tile in play. Gain 1 M€ for each colony in play.',
                victoryPoints: 1,
            },
        }) || this;
    }
    MolecularPrinting.prototype.play = function (player) {
        var coloniesCount = 0;
        player.game.colonies.forEach(function (colony) {
            coloniesCount += colony.colonies.length;
        });
        player.addResource(Resources_1.Resources.MEGACREDITS, player.game.getCitiesInPlay() + coloniesCount, { log: true });
        return undefined;
    };
    MolecularPrinting.prototype.getVictoryPoints = function () {
        return 1;
    };
    return MolecularPrinting;
}(Card_1.Card));
exports.MolecularPrinting = MolecularPrinting;
