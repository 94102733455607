"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VENUS_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var AerialMappers_1 = require("./AerialMappers");
var AerosportTournament_1 = require("./AerosportTournament");
var AirScrappingStandardProject_1 = require("./AirScrappingStandardProject");
var AirScrappingExpedition_1 = require("./AirScrappingExpedition");
var Aphrodite_1 = require("./Aphrodite");
var AtalantaPlanitiaLab_1 = require("./AtalantaPlanitiaLab");
var Atmoscoop_1 = require("./Atmoscoop");
var Celestic_1 = require("./Celestic");
var CometForVenus_1 = require("./CometForVenus");
var CorroderSuits_1 = require("./CorroderSuits");
var DawnCity_1 = require("./DawnCity");
var DeuteriumExport_1 = require("./DeuteriumExport");
var Dirigibles_1 = require("./Dirigibles");
var ExtractorBalloons_1 = require("./ExtractorBalloons");
var Extremophiles_1 = require("./Extremophiles");
var FloatingHabs_1 = require("./FloatingHabs");
var ForcedPrecipitation_1 = require("./ForcedPrecipitation");
var FreyjaBiodomes_1 = require("./FreyjaBiodomes");
var GHGImportFromVenus_1 = require("./GHGImportFromVenus");
var GiantSolarShade_1 = require("./GiantSolarShade");
var Gyropolis_1 = require("./Gyropolis");
var HydrogenToVenus_1 = require("./HydrogenToVenus");
var IoSulphurResearch_1 = require("./IoSulphurResearch");
var IshtarMining_1 = require("./IshtarMining");
var JetStreamMicroscrappers_1 = require("./JetStreamMicroscrappers");
var LocalShading_1 = require("./LocalShading");
var LunaMetropolis_1 = require("./LunaMetropolis");
var LuxuryFoods_1 = require("./LuxuryFoods");
var Manutech_1 = require("./Manutech");
var MaxwellBase_1 = require("./MaxwellBase");
var MiningQuota_1 = require("./MiningQuota");
var MorningStarInc_1 = require("./MorningStarInc");
var NeutralizerFactory_1 = require("./NeutralizerFactory");
var Omnicourt_1 = require("./Omnicourt");
var OrbitalReflectors_1 = require("./OrbitalReflectors");
var RotatorImpacts_1 = require("./RotatorImpacts");
var SisterPlanetSupport_1 = require("./SisterPlanetSupport");
var Solarnet_1 = require("./Solarnet");
var SpinInducingAsteroid_1 = require("./SpinInducingAsteroid");
var SponsoredAcademies_1 = require("./SponsoredAcademies");
var Stratopolis_1 = require("./Stratopolis");
var StratosphericBirds_1 = require("./StratosphericBirds");
var SulphurEatingBacteria_1 = require("./SulphurEatingBacteria");
var SulphurExports_1 = require("./SulphurExports");
var TerraformingContract_1 = require("./TerraformingContract");
var Thermophiles_1 = require("./Thermophiles");
var VenusGovernor_1 = require("./VenusGovernor");
var VenusianAnimals_1 = require("./VenusianAnimals");
var VenusianInsects_1 = require("./VenusianInsects");
var VenusianPlants_1 = require("./VenusianPlants");
var VenusMagnetizer_1 = require("./VenusMagnetizer");
var VenusSoils_1 = require("./VenusSoils");
var VenusWaystation_1 = require("./VenusWaystation");
var Viron_1 = require("./Viron");
var WaterToVenus_1 = require("./WaterToVenus");
var AirScrappingStandardProjectVariant_1 = require("./AirScrappingStandardProjectVariant");
exports.VENUS_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Venus,
    projectCards: [
        { cardName: CardName_1.CardName.AERIAL_MAPPERS, Factory: AerialMappers_1.AerialMappers },
        { cardName: CardName_1.CardName.AEROSPORT_TOURNAMENT, Factory: AerosportTournament_1.AerosportTournament },
        { cardName: CardName_1.CardName.AIR_SCRAPPING_EXPEDITION, Factory: AirScrappingExpedition_1.AirScrappingExpedition },
        { cardName: CardName_1.CardName.ATALANTA_PLANITIA_LAB, Factory: AtalantaPlanitiaLab_1.AtalantaPlanitiaLab },
        { cardName: CardName_1.CardName.ATMOSCOOP, Factory: Atmoscoop_1.Atmoscoop },
        { cardName: CardName_1.CardName.COMET_FOR_VENUS, Factory: CometForVenus_1.CometForVenus },
        { cardName: CardName_1.CardName.CORRODER_SUITS, Factory: CorroderSuits_1.CorroderSuits },
        { cardName: CardName_1.CardName.DAWN_CITY, Factory: DawnCity_1.DawnCity },
        { cardName: CardName_1.CardName.DEUTERIUM_EXPORT, Factory: DeuteriumExport_1.DeuteriumExport },
        { cardName: CardName_1.CardName.DIRIGIBLES, Factory: Dirigibles_1.Dirigibles },
        { cardName: CardName_1.CardName.EXTRACTOR_BALLOONS, Factory: ExtractorBalloons_1.ExtractorBalloons },
        { cardName: CardName_1.CardName.EXTREMOPHILES, Factory: Extremophiles_1.Extremophiles },
        { cardName: CardName_1.CardName.FLOATING_HABS, Factory: FloatingHabs_1.FloatingHabs },
        { cardName: CardName_1.CardName.FORCED_PRECIPITATION, Factory: ForcedPrecipitation_1.ForcedPrecipitation },
        { cardName: CardName_1.CardName.FREYJA_BIODOMES, Factory: FreyjaBiodomes_1.FreyjaBiodomes },
        { cardName: CardName_1.CardName.GIANT_SOLAR_SHADE, Factory: GiantSolarShade_1.GiantSolarShade },
        { cardName: CardName_1.CardName.GHG_IMPORT_FROM_VENUS, Factory: GHGImportFromVenus_1.GHGImportFromVenus },
        { cardName: CardName_1.CardName.GYROPOLIS, Factory: Gyropolis_1.Gyropolis },
        { cardName: CardName_1.CardName.HYDROGEN_TO_VENUS, Factory: HydrogenToVenus_1.HydrogenToVenus },
        { cardName: CardName_1.CardName.IO_SULPHUR_RESEARCH, Factory: IoSulphurResearch_1.IoSulphurResearch },
        { cardName: CardName_1.CardName.ISHTAR_MINING, Factory: IshtarMining_1.IshtarMining },
        { cardName: CardName_1.CardName.JET_STREAM_MICROSCRAPPERS, Factory: JetStreamMicroscrappers_1.JetStreamMicroscrappers },
        { cardName: CardName_1.CardName.LUNA_METROPOLIS, Factory: LunaMetropolis_1.LunaMetropolis },
        { cardName: CardName_1.CardName.LOCAL_SHADING, Factory: LocalShading_1.LocalShading },
        { cardName: CardName_1.CardName.MAXWELL_BASE, Factory: MaxwellBase_1.MaxwellBase },
        { cardName: CardName_1.CardName.ROTATOR_IMPACTS, Factory: RotatorImpacts_1.RotatorImpacts },
        { cardName: CardName_1.CardName.SISTER_PLANET_SUPPORT, Factory: SisterPlanetSupport_1.SisterPlanetSupport },
        { cardName: CardName_1.CardName.SOLARNET, Factory: Solarnet_1.Solarnet },
        { cardName: CardName_1.CardName.SPIN_INDUCING_ASTEROID, Factory: SpinInducingAsteroid_1.SpinInducingAsteroid },
        { cardName: CardName_1.CardName.SPONSORED_ACADEMIES, Factory: SponsoredAcademies_1.SponsoredAcademies },
        { cardName: CardName_1.CardName.STRATOPOLIS, Factory: Stratopolis_1.Stratopolis },
        { cardName: CardName_1.CardName.STRATOSPHERIC_BIRDS, Factory: StratosphericBirds_1.StratosphericBirds },
        { cardName: CardName_1.CardName.SULPHUR_EATING_BACTERIA, Factory: SulphurEatingBacteria_1.SulphurEatingBacteria },
        { cardName: CardName_1.CardName.SULPHUR_EXPORTS, Factory: SulphurExports_1.SulphurExports },
        { cardName: CardName_1.CardName.TERRAFORMING_CONTRACT, Factory: TerraformingContract_1.TerraformingContract },
        { cardName: CardName_1.CardName.THERMOPHILES, Factory: Thermophiles_1.Thermophiles },
        { cardName: CardName_1.CardName.VENUS_GOVERNOR, Factory: VenusGovernor_1.VenusGovernor },
        { cardName: CardName_1.CardName.VENUSIAN_ANIMALS, Factory: VenusianAnimals_1.VenusianAnimals },
        { cardName: CardName_1.CardName.VENUSIAN_INSECTS, Factory: VenusianInsects_1.VenusianInsects },
        { cardName: CardName_1.CardName.VENUSIAN_PLANTS, Factory: VenusianPlants_1.VenusianPlants },
        { cardName: CardName_1.CardName.VENUS_MAGNETIZER, Factory: VenusMagnetizer_1.VenusMagnetizer },
        { cardName: CardName_1.CardName.VENUS_SOILS, Factory: VenusSoils_1.VenusSoils },
        { cardName: CardName_1.CardName.VENUS_WAYSTATION, Factory: VenusWaystation_1.VenusWaystation },
        { cardName: CardName_1.CardName.WATER_TO_VENUS, Factory: WaterToVenus_1.WaterToVenus },
        { cardName: CardName_1.CardName.LUXURY_FOODS, Factory: LuxuryFoods_1.LuxuryFoods },
        { cardName: CardName_1.CardName.NEUTRALIZER_FACTORY, Factory: NeutralizerFactory_1.NeutralizerFactory },
        { cardName: CardName_1.CardName.ORBITAL_REFLECTORS, Factory: OrbitalReflectors_1.OrbitalReflectors },
        { cardName: CardName_1.CardName.OMNICOURT, Factory: Omnicourt_1.Omnicourt },
        { cardName: CardName_1.CardName.MINING_QUOTA, Factory: MiningQuota_1.MiningQuota },
    ],
    standardProjects: [
        { cardName: CardName_1.CardName.AIR_SCRAPPING_STANDARD_PROJECT, Factory: AirScrappingStandardProject_1.AirScrappingStandardProject },
        { cardName: CardName_1.CardName.AIR_SCRAPPING_STANDARD_PROJECT_VARIANT, Factory: AirScrappingStandardProjectVariant_1.AirScrappingStandardProjectVariant },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.APHRODITE, Factory: Aphrodite_1.Aphrodite },
        { cardName: CardName_1.CardName.CELESTIC, Factory: Celestic_1.Celestic },
        { cardName: CardName_1.CardName.MANUTECH, Factory: Manutech_1.Manutech },
        { cardName: CardName_1.CardName.MORNING_STAR_INC, Factory: MorningStarInc_1.MorningStarInc },
        { cardName: CardName_1.CardName.VIRON, Factory: Viron_1.Viron },
    ]
});
