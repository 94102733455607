"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OreProcessor = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var OreProcessor = (function (_super) {
    __extends(OreProcessor, _super);
    function OreProcessor() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ORE_PROCESSOR,
            tags: [Tags_1.Tags.BUILDING],
            cost: 13,
            metadata: {
                cardNumber: '104',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 4 energy to gain 1 titanium and increase oxygen 1 step.', function (eb) {
                        eb.energy(4, { digit: Options_1.digit }).startAction.titanium(1).oxygen(1);
                    });
                }),
            },
        }) || this;
    }
    OreProcessor.prototype.play = function (_player) {
        return undefined;
    };
    OreProcessor.prototype.canAct = function (player) {
        return player.energy >= 4 && player.canAfford(0, { tr: { oxygen: 1 } });
    };
    OreProcessor.prototype.action = function (player) {
        player.energy -= 4;
        player.titanium++;
        return player.game.increaseOxygenLevel(player, 1);
    };
    return OreProcessor;
}(Card_1.Card));
exports.OreProcessor = OreProcessor;
