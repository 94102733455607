"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardTechnology = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var StandardTechnology = (function (_super) {
    __extends(StandardTechnology, _super);
    function StandardTechnology() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.STANDARD_TECHNOLOGY,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 6,
            metadata: {
                cardNumber: '156',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('After you pay for a standard project, except selling patents, you gain 3 M€.', function (eb) {
                        eb.plate('Standard projects').startEffect.megacredits(3);
                    });
                }),
            },
        }) || this;
    }
    StandardTechnology.prototype.onStandardProject = function (player, projectType) {
        if (projectType.name !== CardName_1.CardName.SELL_PATENTS_STANDARD_PROJECT) {
            player.megaCredits += 3;
        }
    };
    StandardTechnology.prototype.play = function () {
        return undefined;
    };
    return StandardTechnology;
}(Card_1.Card));
exports.StandardTechnology = StandardTechnology;
