"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pets = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Board_1 = require("../../boards/Board");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var Pets = (function (_super) {
    __extends(Pets, _super);
    function Pets() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.PETS,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.ANIMAL],
            cost: 10,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            metadata: {
                cardNumber: '172',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When any City tile is placed, add an Animal to this card.', function (eb) {
                        eb.city({ size: Size_1.Size.SMALL, all: Options_1.all }).startEffect.animals(1);
                    }).br;
                    b.animals(1).br;
                    b.text('Animals may not be removed from this card', Size_1.Size.SMALL, true).br;
                    b.vpText('1 VP per 2 Animals here.');
                }),
                description: { text: 'Add 1 Animal to this card.', align: 'left' },
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.animals(1, 2),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Pets.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 2);
    };
    Pets.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        var _this = this;
        if (Board_1.Board.isCitySpace(space)) {
            cardOwner.game.defer(new AddResourcesToCard_1.AddResourcesToCard(cardOwner, ResourceType_1.ResourceType.ANIMAL, { filter: function (c) { return c.name === _this.name; } }), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : undefined);
        }
    };
    Pets.prototype.play = function (player) {
        player.addResourceTo(this);
        return undefined;
    };
    return Pets;
}(Card_1.Card));
exports.Pets = Pets;
