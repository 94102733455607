"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'CardNumber',
    props: {
        number: {
            type: String,
            required: true,
        },
    },
    methods: {
        showCardNumber: function () {
            return PreferencesManager_1.PreferencesManager.load('show_card_number') === '1';
        },
    },
});
