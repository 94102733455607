"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValuableGases = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var SelectHowToPayForProjectCard_1 = require("../../inputs/SelectHowToPayForProjectCard");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var ValuableGases = (function (_super) {
    __extends(ValuableGases, _super);
    function ValuableGases() {
        return _super.call(this, {
            name: CardName_1.CardName.VALUABLE_GASES,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.VENUS],
            metadata: {
                cardNumber: 'Y06',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(6).br.br;
                    b.text('play', Size_1.Size.MEDIUM, true).cards(1, { secondaryTag: Tags_1.Tags.VENUS }).colon();
                    b.floaters(4, { digit: Options_1.digit });
                }),
                description: 'Gain 6 M€. Play a Venus card from your hand and add 4 floaters to it.',
            },
        }) || this;
    }
    ValuableGases.prototype.play = function (player) {
        player.megaCredits += 6;
        var playableCards = player.getPlayableCards().filter(function (card) { return card.tags.includes(Tags_1.Tags.VENUS); });
        if (playableCards.length > 0) {
            return new SelectHowToPayForProjectCard_1.SelectHowToPayForProjectCard(player, playableCards, function (selectedCard, howToPay) {
                var result = player.checkHowToPayAndPlayCard(selectedCard, howToPay);
                if (selectedCard.resourceType === ResourceType_1.ResourceType.FLOATER) {
                    player.addResourceTo(selectedCard, 4);
                }
                return result;
            });
        }
        return undefined;
    };
    return ValuableGases;
}(PreludeCard_1.PreludeCard));
exports.ValuableGases = ValuableGases;
