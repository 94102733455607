"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SulphurExports = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var SulphurExports = (function (_super) {
    __extends(SulphurExports, _super);
    function SulphurExports() {
        return _super.call(this, {
            name: CardName_1.CardName.SULPHUR_EXPORTS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.SPACE],
            cost: 21,
            tr: { venus: 1 },
            metadata: {
                cardNumber: '250',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(1).br;
                    b.production(function (pb) { return pb.megacredits(1).slash().venus(1, { played: Options_1.played }); });
                }),
                description: 'Increase Venus 1 step. Increase your M€ production 1 step for each Venus tag you have, including this.',
            },
        }) || this;
    }
    ;
    SulphurExports.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, player.getTagCount(Tags_1.Tags.VENUS) + 1, { log: true });
        player.game.increaseVenusScaleLevel(player, 1);
        return undefined;
    };
    return SulphurExports;
}(Card_1.Card));
exports.SulphurExports = SulphurExports;
