"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JetStreamMicroscrappers = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var constants_1 = require("../../constants");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var LogHelper_1 = require("../../LogHelper");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var JetStreamMicroscrappers = (function (_super) {
    __extends(JetStreamMicroscrappers, _super);
    function JetStreamMicroscrappers() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.JET_STREAM_MICROSCRAPPERS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS],
            cost: 12,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: '234',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 titanium to add 2 Floaters here', function (eb) {
                        eb.titanium(1).startAction.floaters(2);
                    }).br;
                    b.or().br;
                    b.action('Spend 2 Floaters here to raise Venus 1 step', function (eb) {
                        eb.floaters(2).startAction.venus(1);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    JetStreamMicroscrappers.prototype.play = function () {
        return undefined;
    };
    JetStreamMicroscrappers.prototype.canAct = function (player) {
        var venusMaxed = player.game.getVenusScaleLevel() === constants_1.MAX_VENUS_SCALE;
        var canSpendResource = this.resourceCount > 1 && !venusMaxed;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !venusMaxed) {
            return player.titanium > 0 || (canSpendResource && player.canAfford(constants_1.REDS_RULING_POLICY_COST));
        }
        return player.titanium > 0 || canSpendResource;
    };
    JetStreamMicroscrappers.prototype.action = function (player) {
        var _this = this;
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Spend one titanium to add 2 floaters to this card', 'Spend titanium', function () { return _this.addResource(player); });
        var spendResource = new SelectOption_1.SelectOption('Remove 2 floaters to raise Venus 1 step', 'Remove floaters', function () { return _this.spendResource(player); });
        if (this.resourceCount > 1 && player.game.getVenusScaleLevel() < constants_1.MAX_VENUS_SCALE) {
            opts.push(spendResource);
        }
        else {
            return this.addResource(player);
        }
        if (player.titanium > 0) {
            opts.push(addResource);
        }
        else {
            return this.spendResource(player);
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts)))();
    };
    JetStreamMicroscrappers.prototype.addResource = function (player) {
        player.addResourceTo(this, { qty: 2, log: true });
        player.titanium--;
        return undefined;
    };
    JetStreamMicroscrappers.prototype.spendResource = function (player) {
        player.removeResourceFrom(this, 2);
        player.game.increaseVenusScaleLevel(player, 1);
        LogHelper_1.LogHelper.logVenusIncrease(player, 1);
        return undefined;
    };
    return JetStreamMicroscrappers;
}(Card_1.Card));
exports.JetStreamMicroscrappers = JetStreamMicroscrappers;
