"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SulphurEatingBacteria = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var CardName_1 = require("../../CardName");
var LogHelper_1 = require("../../LogHelper");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var SulphurEatingBacteria = (function (_super) {
    __extends(SulphurEatingBacteria, _super);
    function SulphurEatingBacteria() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.SULPHUR_EATING_BACTERIA,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.MICROBE],
            cost: 6,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(6); }),
            metadata: {
                cardNumber: '251',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Microbe to this card.', function (eb) {
                        eb.empty().startAction.microbes(1);
                    }).br;
                    b.or().br;
                    b.action('Spend any number of Microbes here to gain triple amount of MC.', function (eb) {
                        eb.text('x').microbes(1).startAction.megacredits(3, { multiplier: Options_1.multiplier });
                    });
                }),
                description: 'Requires Venus 6%',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    SulphurEatingBacteria.prototype.play = function () {
        return undefined;
    };
    SulphurEatingBacteria.prototype.canAct = function () {
        return true;
    };
    SulphurEatingBacteria.prototype.action = function (player) {
        var _this = this;
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Add 1 microbe to this card', 'Add microbe', function () {
            player.addResourceTo(_this, { log: true });
            return undefined;
        });
        var spendResource = new SelectAmount_1.SelectAmount('Remove any number of microbes to gain 3 M€ per microbe removed', 'Remove microbes', function (amount) { return _this.spendResource(player, amount); }, 1, this.resourceCount, true);
        opts.push(addResource);
        if (this.resourceCount > 0) {
            opts.push(spendResource);
        }
        else {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts)))();
    };
    SulphurEatingBacteria.prototype.spendResource = function (player, amount) {
        player.removeResourceFrom(this, amount);
        var megaCreditsGained = 3 * amount;
        player.megaCredits += megaCreditsGained;
        var logText = 'gain ' + megaCreditsGained + ' M€';
        LogHelper_1.LogHelper.logRemoveResource(player, this, amount, logText);
        return undefined;
    };
    return SulphurEatingBacteria;
}(Card_1.Card));
exports.SulphurEatingBacteria = SulphurEatingBacteria;
