"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturntoAbandonedTechnology = void 0;
var Card_1 = require("../Card");
var DrawCards_1 = require("../../deferredActions/DrawCards");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var Size_1 = require("../render/Size");
var ReturntoAbandonedTechnology = (function (_super) {
    __extends(ReturntoAbandonedTechnology, _super);
    function ReturntoAbandonedTechnology() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.RETURN_TO_ABANDONED_TECHNOLOGY,
            cost: 4,
            tags: [Tags_1.Tags.MARS],
            metadata: {
                cardNumber: 'Pf22',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Draw the top 4 cards from the discard deck. Choose 2 to keep and discard the rest.', Size_1.Size.SMALL).br;
                }),
            },
        }) || this;
    }
    ReturntoAbandonedTechnology.prototype.canPlay = function (player) {
        return player.game.dealer.discarded.length > 0;
    };
    ReturntoAbandonedTechnology.prototype.play = function (player) {
        var cards = [];
        for (var idx = 0; idx < 4; idx++) {
            var card = player.game.dealer.discarded.pop();
            if (card === undefined)
                break;
            cards.push(card);
        }
        var cardsToKeep = Math.min(2, cards.length);
        return DrawCards_1.DrawCards.choose(player, cards, { keepMax: cardsToKeep, logDrawnCard: true });
    };
    return ReturntoAbandonedTechnology;
}(Card_1.Card));
exports.ReturntoAbandonedTechnology = ReturntoAbandonedTechnology;
