"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var ColonyName_1 = require("@/colonies/ColonyName");
exports.default = vue_1.default.extend({
    name: 'colony',
    props: {
        colony: {
            type: Object,
        },
    },
    data: function () {
        return {
            PLUTO: ColonyName_1.ColonyName.PLUTO,
            GANYMEDE: ColonyName_1.ColonyName.GANYMEDE,
        };
    },
    methods: {
        getCubeXPosition: function (colony) {
            return colony.trackPosition * 56 + 27;
        },
        getColonyXPosition: function (index) {
            return index * 56 + 16;
        },
        getCubeYPosition: function (colony, isColonyCube) {
            if (isColonyCube === void 0) { isColonyCube = false; }
            var offset = isColonyCube ? 7 : 0;
            switch (colony.name) {
                case ColonyName_1.ColonyName.IAPETUS:
                case ColonyName_1.ColonyName.LEAVITT:
                    return 181 + offset;
                case ColonyName_1.ColonyName.VENUS:
                    return 186 + offset;
                case ColonyName_1.ColonyName.PALLAS:
                    return 168 + offset;
                case ColonyName_1.ColonyName.MERCURY:
                case ColonyName_1.ColonyName.HYGIEA:
                    return 144 + offset;
                case ColonyName_1.ColonyName.EUROPA:
                case ColonyName_1.ColonyName.MIRANDA:
                    return 166 + offset;
                case ColonyName_1.ColonyName.PLUTO:
                    return 165 + offset;
                case ColonyName_1.ColonyName.LUNA:
                    return 163 + offset;
                default:
                    return 164 + offset;
            }
        },
        getGanymede: function () {
            return ColonyName_1.ColonyName.GANYMEDE;
        },
        getEuropa: function () {
            return ColonyName_1.ColonyName.EUROPA;
        },
        getCeres: function () {
            return ColonyName_1.ColonyName.CERES;
        },
        getPluto: function () {
            return ColonyName_1.ColonyName.PLUTO;
        },
        getEnceladus: function () {
            return ColonyName_1.ColonyName.ENCELADUS;
        },
        getIo: function () {
            return ColonyName_1.ColonyName.IO;
        },
        getTriton: function () {
            return ColonyName_1.ColonyName.TRITON;
        },
        getTitan: function () {
            return ColonyName_1.ColonyName.TITAN;
        },
        getLuna: function () {
            return ColonyName_1.ColonyName.LUNA;
        },
        getMiranda: function () {
            return ColonyName_1.ColonyName.MIRANDA;
        },
        getCallisto: function () {
            return ColonyName_1.ColonyName.CALLISTO;
        },
        getColonyContentOffset: function (colony) {
            if (colony.name === ColonyName_1.ColonyName.PLUTO || colony.name === ColonyName_1.ColonyName.MIRANDA)
                return -12;
            return 0;
        },
        getIapetus: function () {
            return ColonyName_1.ColonyName.IAPETUS;
        },
        getMercury: function () {
            return ColonyName_1.ColonyName.MERCURY;
        },
        getHygiea: function () {
            return ColonyName_1.ColonyName.HYGIEA;
        },
        getTitania: function () {
            return ColonyName_1.ColonyName.TITANIA;
        },
        getVenus: function () {
            return ColonyName_1.ColonyName.VENUS;
        },
        getLeavitt: function () {
            return ColonyName_1.ColonyName.LEAVITT;
        },
        getPallas: function () {
            return ColonyName_1.ColonyName.PALLAS;
        },
    },
});
