"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeginnerCorporation = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var BeginnerCorporation = (function (_super) {
    __extends(BeginnerCorporation, _super);
    function BeginnerCorporation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.BEGINNER_CORPORATION,
            metadata: {
                cardNumber: 'R00',
                description: 'You start with 42 M€. Instead of choosing from 10 cards during setup, you get 10 cards for free.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(42).nbsp.cards(10, { digit: Options_1.digit });
                }),
            },
            startingMegaCredits: 42,
        }) || this;
    }
    BeginnerCorporation.prototype.play = function (player) {
        player.drawCard(10);
        return undefined;
    };
    return BeginnerCorporation;
}(Card_1.Card));
exports.BeginnerCorporation = BeginnerCorporation;
