"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NitrogenFromTitan = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var NitrogenFromTitan = (function (_super) {
    __extends(NitrogenFromTitan, _super);
    function NitrogenFromTitan() {
        return _super.call(this, {
            cost: 25,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.SPACE],
            name: CardName_1.CardName.NITROGEN_FROM_TITAN,
            cardType: CardType_1.CardType.AUTOMATED,
            tr: { tr: 2 },
            metadata: {
                cardNumber: 'C28',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.tr(2).floaters(2, { secondaryTag: Tags_1.Tags.JOVIAN });
                }),
                description: 'Raise your TR 2 steps. Add 2 floaters to a JOVIAN CARD.',
                victoryPoints: 1,
            },
        }) || this;
    }
    NitrogenFromTitan.prototype.play = function (player) {
        player.increaseTerraformRatingSteps(2);
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2, restrictedTag: Tags_1.Tags.JOVIAN }));
        return undefined;
    };
    NitrogenFromTitan.prototype.getVictoryPoints = function () {
        return 1;
    };
    return NitrogenFromTitan;
}(Card_1.Card));
exports.NitrogenFromTitan = NitrogenFromTitan;
