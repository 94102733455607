"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncreaseColonyTrack = void 0;
var OrOptions_1 = require("../inputs/OrOptions");
var SelectOption_1 = require("../inputs/SelectOption");
var DeferredAction_1 = require("./DeferredAction");
var LogHelper_1 = require("../LogHelper");
var IncreaseColonyTrack = (function () {
    function IncreaseColonyTrack(player, colony, steps, cb, title) {
        if (title === void 0) { title = 'Increase ' + colony.name + ' colony track before trade'; }
        this.player = player;
        this.colony = colony;
        this.steps = steps;
        this.cb = cb;
        this.title = title;
        this.priority = DeferredAction_1.Priority.INCREASE_COLONY_TRACK;
    }
    IncreaseColonyTrack.prototype.execute = function () {
        var _this = this;
        if (this.steps === 0) {
            this.cb();
            return undefined;
        }
        var options = new OrOptions_1.OrOptions();
        var _loop_1 = function (step) {
            options.options.push(new SelectOption_1.SelectOption('Increase colony track ' + step + ' step(s)', 'Confirm', function () {
                _this.colony.increaseTrack(step);
                LogHelper_1.LogHelper.logColonyTrackIncrease(_this.player, _this.colony, step);
                _this.cb();
                return undefined;
            }));
        };
        for (var step = this.steps; step > 0; step--) {
            _loop_1(step);
        }
        options.title = this.title;
        options.options.push(new SelectOption_1.SelectOption('Don\'t increase colony track', 'Confirm', function () {
            _this.cb();
            return undefined;
        }));
        return options;
    };
    return IncreaseColonyTrack;
}());
exports.IncreaseColonyTrack = IncreaseColonyTrack;
