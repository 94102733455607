"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcticAlgae = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var TileType_1 = require("../../TileType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var GainResources_1 = require("../../deferredActions/GainResources");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var ArcticAlgae = (function (_super) {
    __extends(ArcticAlgae, _super);
    function ArcticAlgae() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ARCTIC_ALGAE,
            tags: [Tags_1.Tags.PLANT],
            cost: 12,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-12, { max: Options_1.max }); }),
            metadata: {
                description: 'It must be -12 C or colder to play. Gain 1 plant.',
                cardNumber: '023',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When anyone places an ocean tile, gain 2 plants.', function (be) { return be.oceans(1, { all: Options_1.all }).startEffect.plants(2); }).br;
                    b.plants(1);
                }),
            },
        }) || this;
    }
    ArcticAlgae.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        var _this = this;
        var _a;
        if (((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.OCEAN) {
            cardOwner.game.defer(new GainResources_1.GainResources(cardOwner, Resources_1.Resources.PLANTS, {
                count: 2,
                cb: function () { return activePlayer.game.log('${0} gained 2 ${1} from ${2}', function (b) { return b.player(cardOwner).string(Resources_1.Resources.PLANTS).cardName(_this.name); }); },
            }), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : undefined);
        }
    };
    ArcticAlgae.prototype.play = function (player) {
        player.plants++;
        return undefined;
    };
    return ArcticAlgae;
}(Card_1.Card));
exports.ArcticAlgae = ArcticAlgae;
