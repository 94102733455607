"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketManipulation = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var CardRenderer_1 = require("../render/CardRenderer");
var MarketManipulation = (function (_super) {
    __extends(MarketManipulation, _super);
    function MarketManipulation() {
        return _super.call(this, {
            cost: 1,
            tags: [Tags_1.Tags.EARTH],
            name: CardName_1.CardName.MARKET_MANIPULATION,
            cardType: CardType_1.CardType.EVENT,
            metadata: {
                cardNumber: 'C23',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Increase one colony tile track 1 step. Decrease another colony tile track 1 step.', Size_1.Size.SMALL, true);
                }),
            },
        }) || this;
    }
    MarketManipulation.prototype.canPlay = function (player) {
        var increasableColonies = this.getIncreasableColonies(player.game);
        var decreasableColonies = this.getDecreasableColonies(player.game);
        if (increasableColonies.length === 0)
            return false;
        if (decreasableColonies.length === 0)
            return false;
        if (increasableColonies.length === 1 && decreasableColonies.length === 1 && increasableColonies[0] === decreasableColonies[0]) {
            return false;
        }
        return true;
    };
    MarketManipulation.prototype.getIncreasableColonies = function (game) {
        return game.colonies.filter(function (colony) { return colony.trackPosition < 6 && colony.isActive; });
    };
    MarketManipulation.prototype.getDecreasableColonies = function (game) {
        return game.colonies.filter(function (colony) { return colony.trackPosition > colony.colonies.length && colony.isActive; });
    };
    MarketManipulation.prototype.play = function (player) {
        var selectColonies = new OrOptions_1.OrOptions();
        selectColonies.title = 'Select colonies to increase and decrease tile track';
        var increasableColonies = this.getIncreasableColonies(player.game);
        var decreasableColonies = this.getDecreasableColonies(player.game);
        increasableColonies.forEach(function (c1) {
            decreasableColonies.forEach(function (c2) {
                if (c1.name !== c2.name) {
                    var description = 'Increase ' + c1.name + ' (' + c1.description + ') and decrease ' + c2.name + ' (' + c2.description + ')';
                    var colonySelect = new SelectOption_1.SelectOption(description, 'Select', function () {
                        c1.increaseTrack();
                        c2.decreaseTrack();
                        player.game.log('${0} increased ${1} track and decreased ${2} track', function (b) { return b.player(player).string(c1.name).string(c2.name); });
                        return undefined;
                    });
                    selectColonies.options.push(colonySelect);
                }
                ;
            });
        });
        return selectColonies;
    };
    return MarketManipulation;
}(Card_1.Card));
exports.MarketManipulation = MarketManipulation;
