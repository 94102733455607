"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Virus = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var CardName_1 = require("../../CardName");
var SelectOption_1 = require("../../inputs/SelectOption");
var ResourceType_1 = require("../../ResourceType");
var RemoveAnyPlants_1 = require("../../deferredActions/RemoveAnyPlants");
var RemoveResourcesFromCard_1 = require("../../deferredActions/RemoveResourcesFromCard");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var Virus = (function (_super) {
    __extends(Virus, _super);
    function Virus() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.VIRUS,
            tags: [Tags_1.Tags.MICROBE],
            cost: 1,
            metadata: {
                cardNumber: '050',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().animals(2, { all: Options_1.all, digit: Options_1.digit }).nbsp;
                    b.or().nbsp.minus().plants(5, { all: Options_1.all, digit: Options_1.digit });
                }),
                description: 'Remove up to 2 Animals or 5 Plants from any player.',
            },
        }) || this;
    }
    Virus.prototype.play = function (player) {
        var _a;
        if (player.game.getPlayers().length === 1) {
            player.game.someoneHasRemovedOtherPlayersPlants = true;
            return undefined;
        }
        var orOptionsAnimals = (new RemoveResourcesFromCard_1.RemoveResourcesFromCard(player, ResourceType_1.ResourceType.ANIMAL, 2, false, false)).execute();
        var removeAnimals = orOptionsAnimals !== undefined ?
            orOptionsAnimals.options[0] :
            undefined;
        var orOptionsPlants = (new RemoveAnyPlants_1.RemoveAnyPlants(player, 5)).execute();
        var removePlants = orOptionsPlants !== undefined ?
            orOptionsPlants.options.slice(0, -1) :
            undefined;
        if (removeAnimals === undefined && removePlants === undefined) {
            return undefined;
        }
        var orOptions = new OrOptions_1.OrOptions();
        if (removeAnimals !== undefined) {
            orOptions.options.push(removeAnimals);
        }
        if (removePlants !== undefined) {
            (_a = orOptions.options).push.apply(_a, removePlants);
        }
        orOptions.options.push(new SelectOption_1.SelectOption('Skip removal', 'Confirm', function () {
            return undefined;
        }));
        return orOptions;
    };
    return Virus;
}(Card_1.Card));
exports.Virus = Virus;
