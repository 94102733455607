"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var CardFinder_1 = require("@/CardFinder");
var CardName_1 = require("@/CardName");
var constants = require("@/constants");
var SelectCard_vue_1 = require("@/client/components/SelectCard.vue");
var ConfirmDialog_vue_1 = require("@/client/components/common/ConfirmDialog.vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'SelectInitialCards',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        Button: Button_vue_1.default,
        SelectCard: SelectCard_vue_1.default,
        'confirm-dialog': ConfirmDialog_vue_1.default,
    },
    data: function () {
        return {
            selectedCards: [],
            selectedCorporation: undefined,
            selectedPrelude: [],
        };
    },
    methods: {
        noop: function () {
            throw new Error('should not be called');
        },
        getAfterPreludes: function () {
            var _a;
            var result = 0;
            for (var _i = 0, _b = this.selectedPrelude; _i < _b.length; _i++) {
                var prelude = _b[_i];
                switch (prelude) {
                    case CardName_1.CardName.ALLIED_BANKS:
                        result += 3;
                        break;
                    case CardName_1.CardName.BUSINESS_EMPIRE:
                        result -= 6;
                        break;
                    case CardName_1.CardName.AQUIFER_TURBINES:
                        result -= 3;
                        break;
                    case CardName_1.CardName.DONATION:
                        result += 21;
                        break;
                    case CardName_1.CardName.GALILEAN_MINING:
                    case CardName_1.CardName.HUGE_ASTEROID:
                        result -= 5;
                        break;
                    case CardName_1.CardName.LOAN:
                        result += 30;
                        break;
                    case CardName_1.CardName.MARTIAN_INDUSTRIES:
                    case CardName_1.CardName.VALUABLE_GASES:
                        result += 6;
                        break;
                    case CardName_1.CardName.NITROGEN_SHIPMENT:
                        result += 5;
                        break;
                    case CardName_1.CardName.AEROSPACE_MISSION:
                        result -= 14;
                        break;
                    case CardName_1.CardName.RESEARCH_GRANT:
                        result += 8;
                        break;
                    case CardName_1.CardName.TRADE_ADVANCE:
                        result += 2;
                        break;
                }
                switch ((_a = this.selectedCorporation) === null || _a === void 0 ? void 0 : _a.name) {
                    case CardName_1.CardName.MANUTECH:
                        switch (prelude) {
                            case CardName_1.CardName.ALLIED_BANKS:
                                result += 4;
                                break;
                            case CardName_1.CardName.BUSINESS_EMPIRE:
                                result += 6;
                                break;
                            case CardName_1.CardName.DOME_FARMING:
                            case CardName_1.CardName.SELF_SUFFICIENT_SETTLEMENT:
                                result += 2;
                                break;
                            case CardName_1.CardName.METALS_COMPANY:
                            case CardName_1.CardName.RESEARCH_NETWORK:
                                result += 1;
                                break;
                        }
                        break;
                    case CardName_1.CardName.THARSIS_REPUBLIC:
                        switch (prelude) {
                            case CardName_1.CardName.SELF_SUFFICIENT_SETTLEMENT:
                            case CardName_1.CardName.EARLY_SETTLEMENT:
                                result += 3;
                                break;
                        }
                        break;
                    case CardName_1.CardName.PHARMACY_UNION:
                        switch (prelude) {
                            case CardName_1.CardName.BIOFUELS:
                            case CardName_1.CardName.ECOLOGY_EXPERTS:
                                result -= 4;
                                break;
                        }
                        break;
                    case CardName_1.CardName.SPLICE:
                        switch (prelude) {
                            case CardName_1.CardName.BIOFUELS:
                            case CardName_1.CardName.ECOLOGY_EXPERTS:
                                result += 2;
                                break;
                        }
                        break;
                    case CardName_1.CardName.APHRODITE:
                        switch (prelude) {
                            case CardName_1.CardName.VENUS_FIRST:
                                result += 4;
                                break;
                        }
                }
            }
            return result;
        },
        getOption: function (idx) {
            if (this.playerinput.options === undefined || this.playerinput.options[idx] === undefined) {
                throw new Error('invalid input, missing option');
            }
            return this.playerinput.options[idx];
        },
        getStartingMegacredits: function () {
            if (this.selectedCorporation === undefined) {
                return NaN;
            }
            var starting = this.selectedCorporation.startingMegaCredits;
            var cardCost = this.selectedCorporation.cardCost === undefined ? constants.CARD_COST : this.selectedCorporation.cardCost;
            starting -= this.selectedCards.length * cardCost;
            return starting;
        },
        saveIfConfirmed: function () {
            if (PreferencesManager_1.PreferencesManager.load('show_alerts') === '1' && this.selectedCards.length === 0) {
                this.$refs['confirmation'].show();
            }
            else {
                this.saveData();
            }
        },
        saveData: function () {
            var result = [];
            result.push([]);
            if (this.selectedCorporation !== undefined) {
                result[0].push(this.selectedCorporation.name);
            }
            if (this.hasPrelude()) {
                result.push(this.selectedPrelude);
            }
            result.push(this.selectedCards);
            this.onsave(result);
        },
        hasPrelude: function () {
            return this.playerinput.options !== undefined && this.playerinput.options.length === 3;
        },
        cardsChanged: function (cards) {
            this.selectedCards = cards;
        },
        corporationChanged: function (cards) {
            this.selectedCorporation = new CardFinder_1.CardFinder().getCorporationCardByName(cards[0]);
        },
        preludesChanged: function (cards) {
            this.selectedPrelude = cards;
        },
        confirmSelection: function () {
            this.saveData();
        },
    },
});
