"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenusWaystation = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var VenusWaystation = (function (_super) {
    __extends(VenusWaystation, _super);
    function VenusWaystation() {
        return _super.call(this, {
            name: CardName_1.CardName.VENUS_WAYSTATION,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.SPACE],
            cost: 9,
            cardDiscount: { tag: Tags_1.Tags.VENUS, amount: 2 },
            metadata: {
                cardNumber: '258',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play a Venus tag, you pay 2 M€ less for it.', function (eb) {
                        eb.venus(1, { played: Options_1.played }).startEffect.megacredits(-2);
                    });
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    ;
    VenusWaystation.prototype.play = function () {
        return undefined;
    };
    VenusWaystation.prototype.getCardDiscount = function (_player, card) {
        return card.tags.filter(function (tag) { return tag === Tags_1.Tags.VENUS; }).length * 2;
    };
    VenusWaystation.prototype.getVictoryPoints = function () {
        return 1;
    };
    return VenusWaystation;
}(Card_1.Card));
exports.VenusWaystation = VenusWaystation;
