"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapitalAres = void 0;
var SpaceBonus_1 = require("../../SpaceBonus");
var CardName_1 = require("../../CardName");
var TileType_1 = require("../../TileType");
var Capital_1 = require("../base/Capital");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var CapitalAres = (function (_super) {
    __extends(CapitalAres, _super);
    function CapitalAres() {
        return _super.call(this, CardName_1.CardName.CAPITAL_ARES, { bonus: [SpaceBonus_1.SpaceBonus.MEGACREDITS, SpaceBonus_1.SpaceBonus.MEGACREDITS] }, {
            cardNumber: 'A05',
            description: {
                text: 'Requires 4 ocean tiles. Place tile with ADJACENCY BONUS of 2 M€. Energy prod -2 and M€ prod +5.',
                align: 'left',
            },
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) {
                    pb.minus().energy(2).br;
                    pb.plus().megacredits(5);
                }).nbsp.tile(TileType_1.TileType.CAPITAL, false, true).br;
                b.vpText('1 additional VP for each ocean tile adjacent to this city tile.');
            }),
            victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.oceans(1, 1),
        }) || this;
    }
    return CapitalAres;
}(Capital_1.Capital));
exports.CapitalAres = CapitalAres;
