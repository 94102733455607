"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestrictedAreaAres = void 0;
var CardName_1 = require("../../CardName");
var SpaceBonus_1 = require("../../SpaceBonus");
var RestrictedArea_1 = require("../base/RestrictedArea");
var TileType_1 = require("../../TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var RestrictedAreaAres = (function (_super) {
    __extends(RestrictedAreaAres, _super);
    function RestrictedAreaAres() {
        return _super.call(this, CardName_1.CardName.RESTRICTED_AREA_ARES, { bonus: [SpaceBonus_1.SpaceBonus.DRAW_CARD] }, {
            cardNumber: 'A24',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.action('Spend 2 M€ to draw a card.', function (eb) {
                    eb.megacredits(2).startAction.cards(1);
                }).br;
                b.tile(TileType_1.TileType.RESTRICTED_AREA, false, true);
            }),
            description: 'Place this tile which grants an ADJACENCY BONUS of 1 card.',
        }) || this;
    }
    return RestrictedAreaAres;
}(RestrictedArea_1.RestrictedArea));
exports.RestrictedAreaAres = RestrictedAreaAres;
