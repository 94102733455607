"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurveyCard = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var TileType_1 = require("../../TileType");
var Resources_1 = require("../../Resources");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var GainResources_1 = require("../../deferredActions/GainResources");
var Phase_1 = require("../../Phase");
var BoardType_1 = require("../../boards/BoardType");
var SpaceType_1 = require("../../SpaceType");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var TurmoilPolicy_1 = require("../../turmoil/TurmoilPolicy");
var SurveyCard = (function (_super) {
    __extends(SurveyCard, _super);
    function SurveyCard(properties) {
        return _super.call(this, properties) || this;
    }
    SurveyCard.prototype.anyAdjacentSpaceGivesBonus = function (player, space, bonus) {
        return player.game.board.getAdjacentSpaces(space).some(function (adj) { var _a; return (_a = adj.adjacency) === null || _a === void 0 ? void 0 : _a.bonus.includes(bonus); });
    };
    SurveyCard.prototype.grantsBonusNow = function (space, bonus) {
        var _a;
        return ((_a = space.tile) === null || _a === void 0 ? void 0 : _a.covers) === undefined && space.bonus.includes(bonus);
    };
    SurveyCard.prototype.onTilePlaced = function (cardOwner, activePlayer, space, boardType) {
        if (boardType !== BoardType_1.BoardType.MARS) {
            return;
        }
        if (cardOwner.game.phase === Phase_1.Phase.SOLAR || cardOwner.id !== activePlayer.id) {
            return;
        }
        this.checkForBonuses(cardOwner, space);
    };
    SurveyCard.prototype.testForStandardResource = function (cardOwner, space, resource, bonus) {
        var _this = this;
        var _a;
        var grant = this.grantsBonusNow(space, bonus) || this.anyAdjacentSpaceGivesBonus(cardOwner, space, bonus);
        if (!grant) {
            switch (resource) {
                case Resources_1.Resources.STEEL:
                    grant = space.spaceType !== SpaceType_1.SpaceType.COLONY &&
                        PartyHooks_1.PartyHooks.shouldApplyPolicy(cardOwner, PartyName_1.PartyName.MARS, TurmoilPolicy_1.TurmoilPolicy.MARS_FIRST_DEFAULT_POLICY);
                    break;
                case Resources_1.Resources.PLANTS:
                    grant = ((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.OCEAN &&
                        cardOwner.playedCards.some(function (card) { return card.name === CardName_1.CardName.ARCTIC_ALGAE; });
            }
        }
        if (grant) {
            cardOwner.game.defer(new GainResources_1.GainResources(cardOwner, resource, {
                cb: function () { return cardOwner.game.log('${0} gained a bonus ${1} because of ${2}', function (b) { return b.player(cardOwner).string(resource).cardName(_this.name); }); },
            }));
        }
    };
    SurveyCard.prototype.testForCardResource = function (cardOwner, space, resource, bonus) {
        var _this = this;
        if (cardOwner.playedCards.some(function (card) { return card.resourceType === resource; }) &&
            (this.grantsBonusNow(space, bonus) || this.anyAdjacentSpaceGivesBonus(cardOwner, space, bonus))) {
            cardOwner.game.defer(new AddResourcesToCard_1.AddResourcesToCard(cardOwner, resource, {
                logMessage: '${0} gained a bonus ${1} because of ${2}',
                logBuilder: function (b) { return b.player(cardOwner).string(resource).cardName(_this.name); },
            }));
        }
    };
    SurveyCard.prototype.play = function () {
        return undefined;
    };
    return SurveyCard;
}(Card_1.Card));
exports.SurveyCard = SurveyCard;
