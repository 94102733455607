"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Recruitment = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var CardRenderer_1 = require("../render/CardRenderer");
var Turmoil_1 = require("../../turmoil/Turmoil");
var Options_1 = require("../Options");
var Recruitment = (function (_super) {
    __extends(Recruitment, _super);
    function Recruitment() {
        return _super.call(this, {
            name: CardName_1.CardName.RECRUITMENT,
            cost: 2,
            cardType: CardType_1.CardType.EVENT,
            metadata: {
                cardNumber: 'T11',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().delegates(1, { all: Options_1.all }).asterix().nbsp.plus().delegates(1);
                }),
                description: 'Exchange one NEUTRAL NON-LEADER delegate with one of your own from the reserve.',
            },
        }) || this;
    }
    Recruitment.prototype.canPlay = function (player) {
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        if (turmoil.hasAvailableDelegates(player.id) === false) {
            return false;
        }
        return turmoil.parties.some(function (party) {
            var neutralDelegates = party.getDelegates('NEUTRAL');
            return neutralDelegates > 1 || (neutralDelegates === 1 && party.partyLeader !== 'NEUTRAL');
        });
    };
    Recruitment.prototype.play = function (player) {
        player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, 'Select which Neutral delegate to remove', { replace: 'NEUTRAL', source: 'reserve' }));
        return undefined;
    };
    return Recruitment;
}(Card_1.Card));
exports.Recruitment = Recruitment;
