"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Tags_1 = require("@/cards/Tags");
exports.default = vue_1.default.extend({
    name: 'CardTag',
    props: {
        index: {
            type: Number,
            required: true,
            validator: function (i) { return i < 4; },
        },
        type: {
            type: String,
            required: true,
            validator: function (type) { return Object.values(Tags_1.Tags).includes(type); },
        },
    },
    methods: {
        getClasses: function () {
            var classes = ['card-tag'];
            classes.push("tag-" + this.type.toLocaleLowerCase());
            return classes.join(' ');
        },
    },
});
