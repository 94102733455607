"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataLeak = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var ResourceType_1 = require("../../ResourceType");
var DataLeak = (function (_super) {
    __extends(DataLeak, _super);
    function DataLeak() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.DATA_LEAK,
            cost: 5,
            metadata: {
                cardNumber: 'Pf30',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.data({ amount: 5 }); }),
                description: 'Add 5 data to ANY card.',
            },
        }) || this;
    }
    DataLeak.prototype.play = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.DATA, { count: 5 }));
        return undefined;
    };
    return DataLeak;
}(Card_1.Card));
exports.DataLeak = DataLeak;
