"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderSymbol = void 0;
var CardRenderSymbolType_1 = require("./CardRenderSymbolType");
var Size_1 = require("./Size");
var CardRenderSymbol = (function () {
    function CardRenderSymbol(type, size, isIcon) {
        if (isIcon === void 0) { isIcon = false; }
        this.type = type;
        this.size = size;
        this.isIcon = isIcon;
    }
    CardRenderSymbol.asterix = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.ASTERIX, size);
    };
    CardRenderSymbol.or = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.OR, size);
    };
    CardRenderSymbol.plus = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.PLUS, size, true);
    };
    CardRenderSymbol.minus = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.MINUS, size, true);
    };
    CardRenderSymbol.empty = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.EMPTY, size);
    };
    CardRenderSymbol.slash = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.SLASH, size);
    };
    CardRenderSymbol.colon = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.COLON, size);
    };
    CardRenderSymbol.arrow = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.ARROW, size, true);
    };
    CardRenderSymbol.bracketOpen = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.BRACKET_OPEN, size);
    };
    CardRenderSymbol.bracketClose = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.BRACKET_CLOSE, size);
    };
    CardRenderSymbol.nbsp = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.NBSP, size, true);
    };
    CardRenderSymbol.vSpace = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.VSPACE, size, true);
    };
    CardRenderSymbol.equals = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return new CardRenderSymbol(CardRenderSymbolType_1.CardRenderSymbolType.EQUALS, size);
    };
    return CardRenderSymbol;
}());
exports.CardRenderSymbol = CardRenderSymbol;
