"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetalRichAsteroid = void 0;
var PreludeCard_1 = require("./PreludeCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var MetalRichAsteroid = (function (_super) {
    __extends(MetalRichAsteroid, _super);
    function MetalRichAsteroid() {
        return _super.call(this, {
            name: CardName_1.CardName.METAL_RICH_ASTEROID,
            metadata: {
                cardNumber: 'P19',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.temperature(1).titanium(4).br;
                    b.steel(4);
                }),
                description: 'Increase temperature 1 step. Gain 4 titanium and 4 steel.',
            },
        }) || this;
    }
    MetalRichAsteroid.prototype.play = function (player) {
        player.titanium += 4;
        player.steel += 4;
        return player.game.increaseTemperature(player, 1);
    };
    return MetalRichAsteroid;
}(PreludeCard_1.PreludeCard));
exports.MetalRichAsteroid = MetalRichAsteroid;
