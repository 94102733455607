"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var ConfirmDialog_vue_1 = require("@/client/components/common/ConfirmDialog.vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'SelectSpace',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data: function () {
        return {
            availableSpaces: new Set(this.playerinput.availableSpaces),
            selectedTile: undefined,
            spaceId: undefined,
            warning: undefined,
        };
    },
    components: {
        'confirm-dialog': ConfirmDialog_vue_1.default,
    },
    methods: {
        animateSpace: function (tile, activate) {
            if (activate) {
                tile.classList.add('board-space--available');
            }
            else {
                tile.classList.remove('board-space--available');
            }
        },
        animateAvailableSpaces: function (tiles) {
            var _this = this;
            tiles.forEach(function (tile) {
                var spaceId = tile.getAttribute('data_space_id');
                if (spaceId !== null && _this.availableSpaces.has(spaceId)) {
                    _this.animateSpace(tile, true);
                }
            });
        },
        cancelPlacement: function () {
            if (this.selectedTile === undefined) {
                throw new Error('unexpected, no tile selected!');
            }
            this.animateSpace(this.selectedTile, false);
            this.animateAvailableSpaces(this.getSelectableSpaces());
        },
        confirmPlacement: function () {
            var tiles = this.getSelectableSpaces();
            tiles.forEach(function (tile) {
                tile.onclick = null;
            });
            if (this.selectedTile === undefined) {
                throw new Error('unexpected, no tile selected!');
            }
            this.$data.spaceId = this.selectedTile.getAttribute('data_space_id');
            this.selectedTile.classList.add('board-space--selected');
            this.saveData();
        },
        disableAvailableSpaceAnimation: function () {
            var tiles = this.getSelectableSpaces();
            tiles.forEach(function (tile) {
                tile.classList.remove('board-space--available', 'board-space--selected');
            });
        },
        getSelectableSpaces: function () {
            var spaces = [];
            var board = document.getElementById('main_board');
            if (board !== null) {
                var array = board.getElementsByClassName('board-space-selectable');
                for (var i = 0, length = array.length; i < length; i++) {
                    spaces.push(array[i]);
                }
            }
            board = document.getElementById('moon_board');
            if (board !== null) {
                var array = board.getElementsByClassName('board-space-selectable');
                for (var i = 0, length = array.length; i < length; i++) {
                    spaces.push(array[i]);
                }
            }
            return spaces;
        },
        hideDialog: function (hide) {
            PreferencesManager_1.PreferencesManager.save('hide_tile_confirmation', hide);
        },
        onTileSelected: function (tile) {
            this.selectedTile = tile;
            this.disableAvailableSpaceAnimation();
            this.animateSpace(tile, true);
            tile.classList.remove('board-space--available');
            var hideTileConfirmation = PreferencesManager_1.PreferencesManager.loadBoolean('hide_tile_confirmation');
            if (hideTileConfirmation) {
                this.confirmPlacement();
            }
            else {
                this.$refs['confirmation'].show();
            }
        },
        saveData: function () {
            if (this.$data.spaceId === undefined) {
                this.$data.warning = 'Must select a space';
                return;
            }
            this.onsave([[this.$data.spaceId]]);
        },
    },
    mounted: function () {
        var _this = this;
        this.disableAvailableSpaceAnimation();
        var tiles = this.getSelectableSpaces();
        this.animateAvailableSpaces(tiles);
        var _loop_1 = function (i, length) {
            var tile = tiles[i];
            var spaceId = tile.getAttribute('data_space_id');
            if (spaceId === null || this_1.availableSpaces.has(spaceId) === false) {
                return "continue";
            }
            ;
            tile.onclick = function () { return _this.onTileSelected(tile); };
        };
        var this_1 = this;
        for (var i = 0, length = tiles.length; i < length; i++) {
            _loop_1(i, length);
        }
    },
});
