"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATHFINDERS_CARD_MANIFEST = void 0;
var CardManifest_1 = require("../CardManifest");
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var AgroDrones_1 = require("./AgroDrones");
var AsteroidResources_1 = require("./AsteroidResources");
var BreedingFarms_1 = require("./BreedingFarms");
var CeresSpaceport_1 = require("./CeresSpaceport");
var Cryptocurrency_1 = require("./Cryptocurrency");
var CultivationOfVenus_1 = require("./CultivationOfVenus");
var Cyanobacteria_1 = require("./Cyanobacteria");
var DataLeak_1 = require("./DataLeak");
var DeclarationOfIndependence_1 = require("./DeclarationOfIndependence");
var DesignedOrganisms_1 = require("./DesignedOrganisms");
var DustStorm_1 = require("./DustStorm");
var DysonScreens_1 = require("./DysonScreens");
var EarlyExpedition_1 = require("./EarlyExpedition");
var EconomicEspionage_1 = require("./EconomicEspionage");
var FlatMarsTheory_1 = require("./FlatMarsTheory");
var HydrogenProcessingPlant_1 = require("./HydrogenProcessingPlant");
var InterplanetaryTransport_1 = require("./InterplanetaryTransport");
var LastResortIngenuity_1 = require("./LastResortIngenuity");
var LunarEmbassy_1 = require("./LunarEmbassy");
var LuxuryEstate_1 = require("./LuxuryEstate");
var MartianCulture_1 = require("./MartianCulture");
var MartianDustProcessingPlant_1 = require("./MartianDustProcessingPlant");
var MartianMonuments_1 = require("./MartianMonuments");
var MartianRepository_1 = require("./MartianRepository");
var MuseumofEarlyColonisation_1 = require("./MuseumofEarlyColonisation");
var NewVenice_1 = require("./NewVenice");
var OrbitalLaboratories_1 = require("./OrbitalLaboratories");
var OzoneGenerators_1 = require("./OzoneGenerators");
var PowerPlant_1 = require("./PowerPlant");
var PrefabricationofHumanHabitats_1 = require("./PrefabricationofHumanHabitats");
var PrivateSecurity_1 = require("./PrivateSecurity");
var RareEarthElements_1 = require("./RareEarthElements");
var ReturntoAbandonedTechnology_1 = require("./ReturntoAbandonedTechnology");
var RichDeposits_1 = require("./RichDeposits");
var SecretLabs_1 = require("./SecretLabs");
var SmallOpenPitMine_1 = require("./SmallOpenPitMine");
var SmallComet_1 = require("./SmallComet");
var SolarStorm_1 = require("./SolarStorm");
var SpaceDebrisCleaningOperation_1 = require("./SpaceDebrisCleaningOperation");
var SpaceRelay_1 = require("./SpaceRelay");
var TerraformingControlStation_1 = require("./TerraformingControlStation");
exports.PATHFINDERS_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Pathfinders,
    projectCards: [
        { cardName: CardName_1.CardName.BREEDING_FARMS, Factory: BreedingFarms_1.BreedingFarms },
        { cardName: CardName_1.CardName.PREFABRICATION_OF_HUMAN_HABITATS, Factory: PrefabricationofHumanHabitats_1.PrefabricationofHumanHabitats },
        { cardName: CardName_1.CardName.NEW_VENICE, Factory: NewVenice_1.NewVenice },
        { cardName: CardName_1.CardName.AGRO_DRONES, Factory: AgroDrones_1.AgroDrones },
        { cardName: CardName_1.CardName.RARE_EARTH_ELEMENTS, Factory: RareEarthElements_1.RareEarthElements },
        { cardName: CardName_1.CardName.ORBITAL_LABORATORIES, Factory: OrbitalLaboratories_1.OrbitalLaboratories },
        { cardName: CardName_1.CardName.DUST_STORM, Factory: DustStorm_1.DustStorm },
        { cardName: CardName_1.CardName.MARTIAN_MONUMENTS, Factory: MartianMonuments_1.MartianMonuments },
        { cardName: CardName_1.CardName.MUSEUM_OF_EARLY_COLONISATION, Factory: MuseumofEarlyColonisation_1.MuseumofEarlyColonisation },
        { cardName: CardName_1.CardName.TERRAFORMING_CONTROL_STATION, Factory: TerraformingControlStation_1.TerraformingControlStation, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.CERES_SPACEPORT, Factory: CeresSpaceport_1.CeresSpaceport },
        { cardName: CardName_1.CardName.DYSON_SCREENS, Factory: DysonScreens_1.DysonScreens },
        { cardName: CardName_1.CardName.LUNAR_EMBASSY, Factory: LunarEmbassy_1.LunarEmbassy },
        { cardName: CardName_1.CardName.EARLY_EXPEDITION, Factory: EarlyExpedition_1.EarlyExpedition },
        { cardName: CardName_1.CardName.HYDROGEN_PROCESSING_PLANT, Factory: HydrogenProcessingPlant_1.HydrogenProcessingPlant },
        { cardName: CardName_1.CardName.POWER_PLANT_PATHFINDERS, Factory: PowerPlant_1.PowerPlant },
        { cardName: CardName_1.CardName.LUXURY_ESTATE, Factory: LuxuryEstate_1.LuxuryEstate },
        { cardName: CardName_1.CardName.RETURN_TO_ABANDONED_TECHNOLOGY, Factory: ReturntoAbandonedTechnology_1.ReturntoAbandonedTechnology },
        { cardName: CardName_1.CardName.DESIGNED_ORGANISMS, Factory: DesignedOrganisms_1.DesignedOrganisms },
        { cardName: CardName_1.CardName.SPACE_DEBRIS_CLEANING_OPERATION, Factory: SpaceDebrisCleaningOperation_1.SpaceDebrisCleaningOperation },
        { cardName: CardName_1.CardName.PRIVATE_SECURITY, Factory: PrivateSecurity_1.PrivateSecurity },
        { cardName: CardName_1.CardName.SECRET_LABS, Factory: SecretLabs_1.SecretLabs },
        { cardName: CardName_1.CardName.CYANOBACTERIA, Factory: Cyanobacteria_1.Cyanobacteria },
        { cardName: CardName_1.CardName.MARTIAN_REPOSITORY, Factory: MartianRepository_1.MartianRepository },
        { cardName: CardName_1.CardName.DATA_LEAK, Factory: DataLeak_1.DataLeak },
        { cardName: CardName_1.CardName.SMALL_OPEN_PIT_MINE, Factory: SmallOpenPitMine_1.SmallOpenPitMine },
        { cardName: CardName_1.CardName.SOLAR_STORM, Factory: SolarStorm_1.SolarStorm },
        { cardName: CardName_1.CardName.SPACE_RELAY, Factory: SpaceRelay_1.SpaceRelay },
        { cardName: CardName_1.CardName.DECLARATION_OF_INDEPENDENCE, Factory: DeclarationOfIndependence_1.DeclarationOfIndependence, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.MARTIAN_CULTURE, Factory: MartianCulture_1.MartianCulture },
        { cardName: CardName_1.CardName.OZONE_GENERATORS, Factory: OzoneGenerators_1.OzoneGenerators },
        { cardName: CardName_1.CardName.SMALL_COMET, Factory: SmallComet_1.SmallComet },
        { cardName: CardName_1.CardName.ECONOMIC_ESPIONAGE, Factory: EconomicEspionage_1.EconomicEspionage },
        { cardName: CardName_1.CardName.FLAT_MARS_THEORY, Factory: FlatMarsTheory_1.FlatMarsTheory },
        { cardName: CardName_1.CardName.ASTEROID_RESOURCES, Factory: AsteroidResources_1.AsteroidResources },
        { cardName: CardName_1.CardName.INTERPLANETARY_TRANSPORT, Factory: InterplanetaryTransport_1.InterplanetaryTransport },
        { cardName: CardName_1.CardName.MARTIAN_DUST_PROCESSING_PLANT, Factory: MartianDustProcessingPlant_1.MartianDustProcessingPlant },
        { cardName: CardName_1.CardName.CULTIVATION_OF_VENUS, Factory: CultivationOfVenus_1.CultivationOfVenus, compatibility: GameModule_1.GameModule.Venus },
        { cardName: CardName_1.CardName.LAST_RESORT_INGENUITY, Factory: LastResortIngenuity_1.LastResortIngenuity },
        { cardName: CardName_1.CardName.CRYPTOCURRENCY, Factory: Cryptocurrency_1.Cryptocurrency },
        { cardName: CardName_1.CardName.RICH_DEPOSITS, Factory: RichDeposits_1.RichDeposits },
    ],
    corporationCards: [],
    preludeCards: [],
    cardsToRemove: [],
});
