"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonBoard = void 0;
var Board_1 = require("../boards/Board");
var SpaceBonus_1 = require("../SpaceBonus");
var SpaceType_1 = require("../SpaceType");
var MoonSpaces_1 = require("./MoonSpaces");
var Space = (function () {
    function Space(id, spaceType, x, y, bonus) {
        this.id = id;
        this.spaceType = spaceType;
        this.x = x;
        this.y = y;
        this.bonus = bonus;
    }
    Space.mine = function (id, x, y, bonus) {
        return new Space(id, SpaceType_1.SpaceType.LUNAR_MINE, x, y, bonus);
    };
    Space.surface = function (id, x, y, bonus) {
        return new Space(id, SpaceType_1.SpaceType.LAND, x, y, bonus);
    };
    Space.colony = function (id) {
        return new Space(id, SpaceType_1.SpaceType.COLONY, -1, -1, []);
    };
    return Space;
}());
var MoonBoard = (function (_super) {
    __extends(MoonBoard, _super);
    function MoonBoard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MoonBoard.prototype.getNoctisCitySpaceIds = function () {
        return [];
    };
    MoonBoard.prototype.getVolcanicSpaceIds = function () {
        return [];
    };
    MoonBoard.prototype.getAvailableSpacesForMine = function (player) {
        var spaces = this.spaces.filter(function (space) {
            var val = space.tile === undefined &&
                space.spaceType === SpaceType_1.SpaceType.LUNAR_MINE &&
                space.id !== MoonSpaces_1.MoonSpaces.MARE_IMBRIUM &&
                space.id !== MoonSpaces_1.MoonSpaces.MARE_SERENITATIS &&
                space.id !== MoonSpaces_1.MoonSpaces.MARE_NUBIUM &&
                space.id !== MoonSpaces_1.MoonSpaces.MARE_NECTARIS &&
                (space.player === undefined || space.player.id === player.id);
            return val;
        });
        return spaces;
    };
    MoonBoard.newInstance = function () {
        var STEEL = SpaceBonus_1.SpaceBonus.STEEL;
        var DRAW_CARD = SpaceBonus_1.SpaceBonus.DRAW_CARD;
        var TITANIUM = SpaceBonus_1.SpaceBonus.TITANIUM;
        var b = new Builder();
        b.colony();
        b.row(2).land().land(STEEL, DRAW_CARD).land().mine(TITANIUM);
        b.row(1).mine(TITANIUM, TITANIUM).mine().land(STEEL).land().land();
        b.row(0).mine().land(STEEL).land(STEEL, TITANIUM).mine().mine(TITANIUM).land(STEEL, STEEL);
        b.row(0).land(STEEL).land().land().mine(TITANIUM).mine(TITANIUM);
        b.row(0).land().mine(TITANIUM).mine().land().mine().land(STEEL);
        b.row(1).land().land(STEEL).land(STEEL).land(DRAW_CARD, DRAW_CARD).land(STEEL);
        b.row(2).land(DRAW_CARD, DRAW_CARD).mine(TITANIUM).mine(TITANIUM, TITANIUM).land();
        b.colony();
        return new MoonBoard(b.spaces);
    };
    MoonBoard.deserialize = function (board, players) {
        var spaces = Board_1.Board.deserializeSpaces(board.spaces, players);
        return new MoonBoard(spaces);
    };
    return MoonBoard;
}(Board_1.Board));
exports.MoonBoard = MoonBoard;
var Builder = (function () {
    function Builder() {
        this.y = -1;
        this.x = 0;
        this.spaces = [];
        this.idx = 0;
    }
    Builder.prototype.row = function (startX) {
        this.y++;
        this.x = startX;
        return new Row(this);
    };
    Builder.prototype.colony = function () {
        this.spaces.push(Space.colony(this.nextId()));
    };
    Builder.prototype.nextId = function () {
        this.idx++;
        var strId = this.idx.toString().padStart(2, '0');
        return 'm' + strId;
    };
    return Builder;
}());
var Row = (function () {
    function Row(builder) {
        this.builder = builder;
    }
    Row.prototype.land = function () {
        var bonuses = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            bonuses[_i] = arguments[_i];
        }
        var space = Space.surface(this.builder.nextId(), this.builder.x++, this.builder.y, bonuses);
        this.builder.spaces.push(space);
        return this;
    };
    Row.prototype.mine = function () {
        var bonuses = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            bonuses[_i] = arguments[_i];
        }
        var space = Space.mine(this.builder.nextId(), this.builder.x++, this.builder.y, bonuses);
        this.builder.spaces.push(space);
        return this;
    };
    return Row;
}());
