"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardName = void 0;
var CardName;
(function (CardName) {
    CardName["SELL_PATENTS_STANDARD_PROJECT"] = "Sell Patents";
    CardName["POWER_PLANT_STANDARD_PROJECT"] = "Power Plant:SP";
    CardName["ASTEROID_STANDARD_PROJECT"] = "Asteroid:SP";
    CardName["BUFFER_GAS_STANDARD_PROJECT"] = "Buffer Gas";
    CardName["BUILD_COLONY_STANDARD_PROJECT"] = "Colony";
    CardName["AQUIFER_STANDARD_PROJECT"] = "Aquifer";
    CardName["GREENERY_STANDARD_PROJECT"] = "Greenery";
    CardName["CITY_STANDARD_PROJECT"] = "City";
    CardName["AIR_SCRAPPING_STANDARD_PROJECT"] = "Air Scrapping";
    CardName["AIR_SCRAPPING_STANDARD_PROJECT_VARIANT"] = "Air Scrapping (Var)";
    CardName["CONVERT_PLANTS"] = "Convert Plants";
    CardName["CONVERT_HEAT"] = "Convert Heat";
    CardName["ACQUIRED_COMPANY"] = "Acquired Company";
    CardName["ADAPTATION_TECHNOLOGY"] = "Adaptation Technology";
    CardName["ADAPTED_LICHEN"] = "Adapted Lichen";
    CardName["ADVANCED_ALLOYS"] = "Advanced Alloys";
    CardName["ADVANCED_ECOSYSTEMS"] = "Advanced Ecosystems";
    CardName["AEROBRAKED_AMMONIA_ASTEROID"] = "Aerobraked Ammonia Asteroid";
    CardName["AI_CENTRAL"] = "AI Central";
    CardName["AIR_RAID"] = "Air Raid";
    CardName["AIRLINERS"] = "Airliners";
    CardName["ALGAE"] = "Algae";
    CardName["ANTI_GRAVITY_TECHNOLOGY"] = "Anti-Gravity Technology";
    CardName["ANTS"] = "Ants";
    CardName["AQUIFER_PUMPING"] = "Aquifer Pumping";
    CardName["AQUIFER_TURBINES"] = "Aquifer Turbines";
    CardName["ARCHAEBACTERIA"] = "ArchaeBacteria";
    CardName["ARTIFICIAL_LAKE"] = "Artificial Lake";
    CardName["ARTIFICIAL_PHOTOSYNTHESIS"] = "Artificial Photosynthesis";
    CardName["ARCTIC_ALGAE"] = "Arctic Algae";
    CardName["ASTEROID"] = "Asteroid";
    CardName["ASTEROID_MINING"] = "Asteroid Mining";
    CardName["ASTEROID_MINING_CONSORTIUM"] = "Asteroid Mining Consortium";
    CardName["ATMO_COLLECTORS"] = "Atmo Collectors";
    CardName["BREATHING_FILTERS"] = "Breathing Filters";
    CardName["BRIBED_COMMITTEE"] = "Bribed Committee";
    CardName["BEAM_FROM_A_THORIUM_ASTEROID"] = "Beam From A Thorium Asteroid";
    CardName["BIG_ASTEROID"] = "Big Asteroid";
    CardName["BIOMASS_COMBUSTORS"] = "Biomass Combustors";
    CardName["BIRDS"] = "Birds";
    CardName["BLACK_POLAR_DUST"] = "Black Polar Dust";
    CardName["BUILDING_INDUSTRIES"] = "Building Industries";
    CardName["BUSHES"] = "Bushes";
    CardName["BUSINESS_CONTACTS"] = "Business Contacts";
    CardName["BUSINESS_NETWORK"] = "Business Network";
    CardName["CALLISTO_PENAL_MINES"] = "Callisto Penal Mines";
    CardName["CARBONATE_PROCESSING"] = "Carbonate Processing";
    CardName["CAPITAL"] = "Capital";
    CardName["CARETAKER_CONTRACT"] = "Caretaker Contract";
    CardName["CARTEL"] = "Cartel";
    CardName["CEOS_FAVORITE_PROJECT"] = "CEO's Favorite Project";
    CardName["CLOUD_SEEDING"] = "Cloud Seeding";
    CardName["COLONIZER_TRAINING_CAMP"] = "Colonizer Training Camp";
    CardName["COMET"] = "Comet";
    CardName["COMMERCIAL_DISTRICT"] = "Commercial District";
    CardName["COMMUNITY_SERVICES"] = "Community Services";
    CardName["CONSCRIPTION"] = "Conscription";
    CardName["CONVOY_FROM_EUROPA"] = "Convoy From Europa";
    CardName["CORONA_EXTRACTOR"] = "Corona Extractor";
    CardName["CORPORATE_STRONGHOLD"] = "Corporate Stronghold";
    CardName["CRYO_SLEEP"] = "Cryo Sleep";
    CardName["CUPOLA_CITY"] = "Cupola City";
    CardName["DECOMPOSERS"] = "Decomposers";
    CardName["DEEP_WELL_HEATING"] = "Deep Well Heating";
    CardName["DEIMOS_DOWN"] = "Deimos Down";
    CardName["DESIGNED_MICRO_ORGANISMS"] = "Designed Micro-organisms";
    CardName["DEVELOPMENT_CENTER"] = "Development Center";
    CardName["DIRIGIBLES"] = "Dirigibles";
    CardName["DOME_FARMING"] = "Dome Farming";
    CardName["DOMED_CRATER"] = "Domed Crater";
    CardName["DUST_SEALS"] = "Dust Seals";
    CardName["EARLY_SETTLEMENT"] = "Early Settlement";
    CardName["EARTH_CATAPULT"] = "Earth Catapult";
    CardName["EARTH_ELEVATOR"] = "Earth Elevator";
    CardName["EARTH_OFFICE"] = "Earth Office";
    CardName["ECCENTRIC_SPONSOR"] = "Eccentric Sponsor";
    CardName["ECOLOGICAL_ZONE"] = "Ecological Zone";
    CardName["ECOLOGY_EXPERTS"] = "Ecology Experts";
    CardName["ECOLOGY_RESEARCH"] = "Ecology Research";
    CardName["ELECTRO_CATAPULT"] = "Electro Catapult";
    CardName["ENERGY_SAVING"] = "Energy Saving";
    CardName["ENERGY_TAPPING"] = "Energy Tapping";
    CardName["EOS_CHASMA_NATIONAL_PARK"] = "Eos Chasma National Park";
    CardName["EQUATORIAL_MAGNETIZER"] = "Equatorial Magnetizer";
    CardName["EXTREME_COLD_FUNGUS"] = "Extreme-Cold Fungus";
    CardName["FARMING"] = "Farming";
    CardName["FISH"] = "Fish";
    CardName["FLOATER_LEASING"] = "Floater Leasing";
    CardName["FLOATER_PROTOTYPES"] = "Floater Prototypes";
    CardName["FLOATER_TECHNOLOGY"] = "Floater Technology";
    CardName["FLOODING"] = "Flooding";
    CardName["FOOD_FACTORY"] = "Food Factory";
    CardName["FUEL_FACTORY"] = "Fuel Factory";
    CardName["FUELED_GENERATORS"] = "Fueled Generators";
    CardName["FUSION_POWER"] = "Fusion Power";
    CardName["GALILEAN_WAYSTATION"] = "Galilean Waystation";
    CardName["GANYMEDE_COLONY"] = "Ganymede Colony";
    CardName["GENE_REPAIR"] = "Gene Repair";
    CardName["GEOTHERMAL_POWER"] = "Geothermal Power";
    CardName["GHG_PRODUCING_BACTERIA"] = "GHG Producing Bacteria";
    CardName["GHG_FACTORIES"] = "GHG Factories";
    CardName["GIANT_ICE_ASTEROID"] = "Giant Ice Asteroid";
    CardName["GIANT_SPACE_MIRROR"] = "Giant Space Mirror";
    CardName["GRASS"] = "Grass";
    CardName["GREAT_AQUIFER"] = "Great Aquifer";
    CardName["GREAT_DAM"] = "Great Dam";
    CardName["GREAT_ESCARPMENT_CONSORTIUM"] = "Great Escarpment Consortium";
    CardName["GREENHOUSES"] = "Greenhouses";
    CardName["GYROPOLIS"] = "Gyropolis";
    CardName["HACKERS"] = "Hackers";
    CardName["HEATHER"] = "Heather";
    CardName["HEAT_TRAPPERS"] = "Heat Trappers";
    CardName["HEAVY_TAXATION"] = "Heavy Taxation";
    CardName["HERBIVORES"] = "Herbivores";
    CardName["HIRED_RAIDERS"] = "Hired Raiders";
    CardName["HOUSE_PRINTING"] = "House Printing";
    CardName["ICE_ASTEROID"] = "Ice Asteroid";
    CardName["ICE_CAP_MELTING"] = "Ice Cap Melting";
    CardName["ICE_MOON_COLONY"] = "Ice Moon Colony";
    CardName["IMMIGRANT_CITY"] = "Immigrant City";
    CardName["IMMIGRATION_SHUTTLES"] = "Immigration Shuttles";
    CardName["IMPACTOR_SWARM"] = "Impactor Swarm";
    CardName["IMPORTED_GHG"] = "Imported GHG";
    CardName["IMPORTED_HYDROGEN"] = "Imported Hydrogen";
    CardName["IMPORTED_NITROGEN"] = "Imported Nitrogen";
    CardName["IMPORT_OF_ADVANCED_GHG"] = "Import of Advanced GHG";
    CardName["INDENTURED_WORKERS"] = "Indentured Workers";
    CardName["INDUSTRIAL_MICROBES"] = "Industrial Microbes";
    CardName["INSECTS"] = "Insects";
    CardName["INSULATION"] = "Insulation";
    CardName["INTERPLANETARY_COLONY_SHIP"] = "Interplanetary Colony Ship";
    CardName["INTERSTELLAR_COLONY_SHIP"] = "Interstellar Colony Ship";
    CardName["INVENTION_CONTEST"] = "Invention Contest";
    CardName["INVENTORS_GUILD"] = "Inventors Guild";
    CardName["INVESTMENT_LOAN"] = "Investment Loan";
    CardName["IO_MINING_INDUSTRIES"] = "Io Mining Industries";
    CardName["IRONWORKS"] = "Ironworks";
    CardName["JOVIAN_LANTERNS"] = "Jovian Lanterns";
    CardName["JUPITER_FLOATING_STATION"] = "Jupiter Floating Station";
    CardName["KELP_FARMING"] = "Kelp Farming";
    CardName["LAGRANGE_OBSERVATORY"] = "Lagrange Observatory";
    CardName["LAKE_MARINERIS"] = "Lake Marineris";
    CardName["LAND_CLAIM"] = "Land Claim";
    CardName["LARGE_CONVOY"] = "Large Convoy";
    CardName["LAVA_FLOWS"] = "Lava Flows";
    CardName["LAVA_TUBE_SETTLEMENT"] = "Lava Tube Settlement";
    CardName["LICHEN"] = "Lichen";
    CardName["LIGHTNING_HARVEST"] = "Lightning Harvest";
    CardName["LIVESTOCK"] = "Livestock";
    CardName["LOCAL_HEAT_TRAPPING"] = "Local Heat Trapping";
    CardName["LUNAR_BEAM"] = "Lunar Beam";
    CardName["LUNA_GOVERNOR"] = "Luna Governor";
    CardName["LUNAR_EXPORTS"] = "Lunar Exports";
    CardName["LUNAR_MINING"] = "Lunar Mining";
    CardName["MAGNETIC_FIELD_DOME"] = "Magnetic Field Dome";
    CardName["MAGNETIC_FIELD_GENERATORS"] = "Magnetic Field Generators";
    CardName["MARKET_MANIPULATION"] = "Market Manipulation";
    CardName["MARTIAN_INDUSTRIES"] = "Martian Industries";
    CardName["MARTIAN_ZOO"] = "Martian Zoo";
    CardName["MANGROVE"] = "Mangrove";
    CardName["MARS_UNIVERSITY"] = "Mars University";
    CardName["MARTIAN_RAILS"] = "Martian Rails";
    CardName["MASS_CONVERTER"] = "Mass Converter";
    CardName["MEDIA_ARCHIVES"] = "Media Archives";
    CardName["MEDIA_GROUP"] = "Media Group";
    CardName["MEDICAL_LAB"] = "Medical Lab";
    CardName["METHANE_FROM_TITAN"] = "Methane From Titan";
    CardName["MICRO_MILLS"] = "Micro-Mills";
    CardName["MINE"] = "Mine";
    CardName["MINERAL_DEPOSIT"] = "Mineral Deposit";
    CardName["MIRANDA_RESORT"] = "Miranda Resort";
    CardName["MINING_AREA"] = "Mining Area";
    CardName["MINING_COLONY"] = "Mining Colony";
    CardName["MINING_EXPEDITION"] = "Mining Expedition";
    CardName["MINING_OPERATIONS"] = "Mining Operations";
    CardName["MINING_QUOTA"] = "Mining Quota";
    CardName["MINING_RIGHTS"] = "Mining Rights";
    CardName["MINORITY_REFUGE"] = "Minority Refuge";
    CardName["MOHOLE"] = "Mohole";
    CardName["MOHOLE_AREA"] = "Mohole Area";
    CardName["MOHOLE_EXCAVATION"] = "Mohole Excavation";
    CardName["MOLECULAR_PRINTING"] = "Molecular Printing";
    CardName["MOSS"] = "Moss";
    CardName["NATURAL_PRESERVE"] = "Natural Preserve";
    CardName["NITRITE_REDUCING_BACTERIA"] = "Nitrite Reducing Bacteria";
    CardName["NITROGEN_RICH_ASTEROID"] = "Nitrogen-Rich Asteroid";
    CardName["NITROGEN_FROM_TITAN"] = "Nitrogen from Titan";
    CardName["NITROPHILIC_MOSS"] = "Nitrophilic Moss";
    CardName["NOCTIS_CITY"] = "Noctis City";
    CardName["NOCTIS_FARMING"] = "Noctis Farming";
    CardName["NUCLEAR_POWER"] = "Nuclear Power";
    CardName["NUCLEAR_ZONE"] = "Nuclear Zone";
    CardName["OLYMPUS_CONFERENCE"] = "Olympus Conference";
    CardName["OMNICOURT"] = "Omnicourt";
    CardName["OPEN_CITY"] = "Open City";
    CardName["OPTIMAL_AEROBRAKING"] = "Optimal Aerobraking";
    CardName["ORE_PROCESSOR"] = "Ore Processor";
    CardName["PERMAFROST_EXTRACTION"] = "Permafrost Extraction";
    CardName["PEROXIDE_POWER"] = "Peroxide Power";
    CardName["PETS"] = "Pets";
    CardName["PHOBOS_SPACE_HAVEN"] = "Phobos Space Haven";
    CardName["PHYSICS_COMPLEX"] = "Physics Complex";
    CardName["PIONEER_SETTLEMENT"] = "Pioneer Settlement";
    CardName["PLANTATION"] = "Plantation";
    CardName["POLAR_INDUSTRIES"] = "Polar Industries";
    CardName["POWER_GRID"] = "Power Grid";
    CardName["POWER_INFRASTRUCTURE"] = "Power Infrastructure";
    CardName["POWER_PLANT"] = "Power Plant";
    CardName["POWER_SUPPLY_CONSORTIUM"] = "Power Supply Consortium";
    CardName["PREDATORS"] = "Predators";
    CardName["PRODUCTIVE_OUTPOST"] = "Productive Outpost";
    CardName["PROTECTED_HABITATS"] = "Protected Habitats";
    CardName["PROTECTED_VALLEY"] = "Protected Valley";
    CardName["PSYCHROPHILES"] = "Psychrophiles";
    CardName["QUANTUM_COMMUNICATIONS"] = "Quantum Communications";
    CardName["QUANTUM_EXTRACTOR"] = "Quantum Extractor";
    CardName["RAD_CHEM_FACTORY"] = "Rad-Chem Factory";
    CardName["RAD_SUITS"] = "Rad-Suits";
    CardName["RED_SPOT_OBSERVATORY"] = "Red Spot Observatory";
    CardName["REFUGEE_CAMP"] = "Refugee Camp";
    CardName["REGOLITH_EATERS"] = "Regolith Eaters";
    CardName["RELEASE_OF_INERT_GASES"] = "Release of Inert Gases";
    CardName["RESEARCH"] = "Research";
    CardName["RESEARCH_OUTPOST"] = "Research Outpost";
    CardName["RESEARCH_COLONY"] = "Research Colony";
    CardName["RESTRICTED_AREA"] = "Restricted Area";
    CardName["ROBOTIC_WORKFORCE"] = "Robotic Workforce";
    CardName["ROVER_CONSTRUCTION"] = "Rover Construction";
    CardName["RIM_FREIGHTERS"] = "Rim Freighters";
    CardName["SABOTAGE"] = "Sabotage";
    CardName["SATELLITES"] = "Satellites";
    CardName["SEARCH_FOR_LIFE"] = "Search For Life";
    CardName["SECURITY_FLEET"] = "Security Fleet";
    CardName["SELF_SUFFICIENT_SETTLEMENT"] = "Self-Sufficient Settlement";
    CardName["SISTER_PLANET_SUPPORT"] = "Sister Planet Support";
    CardName["SKY_DOCKS"] = "Sky Docks";
    CardName["SMALL_ANIMALS"] = "Small Animals";
    CardName["SOIL_FACTORY"] = "Soil Factory";
    CardName["SOLAR_POWER"] = "Solar Power";
    CardName["SOLAR_PROBE"] = "Solar Probe";
    CardName["SOLAR_REFLECTORS"] = "Solar Reflectors";
    CardName["SOLARNET"] = "Solarnet";
    CardName["SPACE_ELEVATOR"] = "Space Elevator";
    CardName["SPACE_PORT"] = "Space Port";
    CardName["SPACE_PORT_COLONY"] = "Space Port Colony";
    CardName["SPINOFF_DEPARTMENT"] = "Spin-off Department";
    CardName["STRIP_MINE"] = "Strip Mine";
    CardName["SUBTERRANEAN_RESERVOIR"] = "Subterranean Reservoir";
    CardName["SUBZERO_SALT_FISH"] = "Sub-zero Salt Fish";
    CardName["SHUTTLES"] = "Shuttles";
    CardName["SOLAR_WIND_POWER"] = "Solar Wind Power";
    CardName["SOLETTA"] = "Soletta";
    CardName["SPACE_MIRRORS"] = "Space Mirrors";
    CardName["SPACE_STATION"] = "Space Station";
    CardName["SPECIAL_DESIGN"] = "Special Design";
    CardName["SPONSORS"] = "Sponsors";
    CardName["STEELWORKS"] = "Steelworks";
    CardName["STANDARD_TECHNOLOGY"] = "Standard Technology";
    CardName["SYMBIOTIC_FUNGUS"] = "Symbiotic Fungus";
    CardName["TARDIGRADES"] = "Tardigrades";
    CardName["TECHNOLOGY_DEMONSTRATION"] = "Technology Demonstration";
    CardName["TECTONIC_STRESS_POWER"] = "Tectonic Stress Power";
    CardName["TERRAFORMING_GANYMEDE"] = "Terraforming Ganymede";
    CardName["TITAN_AIRSCRAPPING"] = "Titan Air-scrapping";
    CardName["TITAN_FLOATING_LAUNCHPAD"] = "Titan Floating Launch-pad";
    CardName["TITAN_SHUTTLES"] = "Titan Shuttles";
    CardName["TITANIUM_MINE"] = "Titanium Mine";
    CardName["TOLL_STATION"] = "Toll Station";
    CardName["TOWING_A_COMET"] = "Towing A Comet";
    CardName["TRADE_ENVOYS"] = "Trade Envoys";
    CardName["TRADING_COLONY"] = "Trading Colony";
    CardName["TRANS_NEPTUNE_PROBE"] = "Trans-Neptune Probe";
    CardName["TREES"] = "Trees";
    CardName["TROPICAL_RESORT"] = "Tropical Resort";
    CardName["TUNDRA_FARMING"] = "Tundra Farming";
    CardName["UNDERGROUND_CITY"] = "Underground City";
    CardName["UNDERGROUND_DETONATIONS"] = "Underground Detonations";
    CardName["URBAN_DECOMPOSERS"] = "Urban Decomposers";
    CardName["URBANIZED_AREA"] = "Urbanized Area";
    CardName["VESTA_SHIPYARD"] = "Vesta Shipyard";
    CardName["VIRAL_ENHANCERS"] = "Viral Enhancers";
    CardName["VIRUS"] = "Virus";
    CardName["WARP_DRIVE"] = "Warp Drive";
    CardName["WATER_IMPORT_FROM_EUROPA"] = "Water Import From Europa";
    CardName["AERIAL_MAPPERS"] = "Aerial Mappers";
    CardName["AEROSPORT_TOURNAMENT"] = "Aerosport Tournament";
    CardName["AIR_SCRAPPING_EXPEDITION"] = "Air-Scrapping Expedition";
    CardName["APHRODITE"] = "Aphrodite";
    CardName["ATALANTA_PLANITIA_LAB"] = "Atalanta Planitia Lab";
    CardName["ATMOSCOOP"] = "Atmoscoop";
    CardName["CELESTIC"] = "Celestic";
    CardName["COMET_FOR_VENUS"] = "Comet for Venus";
    CardName["CORRODER_SUITS"] = "Corroder Suits";
    CardName["DAWN_CITY"] = "Dawn City";
    CardName["DEUTERIUM_EXPORT"] = "Deuterium Export";
    CardName["EXTRACTOR_BALLOONS"] = "Extractor Balloons";
    CardName["EXTREMOPHILES"] = "Extremophiles";
    CardName["FLOATING_HABS"] = "Floating Habs";
    CardName["FORCED_PRECIPITATION"] = "Forced Precipitation";
    CardName["FREYJA_BIODOMES"] = "Freyja Biodomes";
    CardName["GHG_IMPORT_FROM_VENUS"] = "GHG Import From Venus";
    CardName["GIANT_SOLAR_SHADE"] = "Giant Solar Shade";
    CardName["HYDROGEN_TO_VENUS"] = "Hydrogen to Venus";
    CardName["IO_SULPHUR_RESEARCH"] = "Io Sulphur Research";
    CardName["ISHTAR_MINING"] = "Ishtar Mining";
    CardName["JET_STREAM_MICROSCRAPPERS"] = "Jet Stream Microscrappers";
    CardName["LOCAL_SHADING"] = "Local Shading";
    CardName["LUNA_METROPOLIS"] = "Luna Metropolis";
    CardName["LUXURY_FOODS"] = "Luxury Foods";
    CardName["MAXWELL_BASE"] = "Maxwell Base";
    CardName["MORNING_STAR_INC"] = "Morning Star Inc.";
    CardName["NEUTRALIZER_FACTORY"] = "Neutralizer Factory";
    CardName["ORBITAL_REFLECTORS"] = "Orbital Reflectors";
    CardName["ROTATOR_IMPACTS"] = "Rotator Impacts";
    CardName["SPIN_INDUCING_ASTEROID"] = "Spin-Inducing Asteroid";
    CardName["SPONSORED_ACADEMIES"] = "Sponsored Academies";
    CardName["STRATOPOLIS"] = "Stratopolis";
    CardName["STRATOSPHERIC_BIRDS"] = "Stratospheric Birds";
    CardName["SULPHUR_EATING_BACTERIA"] = "Sulphur-Eating Bacteria";
    CardName["SULPHUR_EXPORTS"] = "Sulphur Exports";
    CardName["TERRAFORMING_CONTRACT"] = "Terraforming Contract";
    CardName["THERMOPHILES"] = "Thermophiles";
    CardName["VENUS_GOVERNOR"] = "Venus Governor";
    CardName["VENUSIAN_ANIMALS"] = "Venusian Animals";
    CardName["VENUSIAN_INSECTS"] = "Venusian Insects";
    CardName["VENUSIAN_PLANTS"] = "Venusian Plants";
    CardName["VENUS_MAGNETIZER"] = "Venus Magnetizer";
    CardName["VENUS_SOILS"] = "Venus Soils";
    CardName["VENUS_WAYSTATION"] = "Venus Waystation";
    CardName["VIRON"] = "Viron";
    CardName["WATER_TO_VENUS"] = "Water to Venus";
    CardName["WATER_SPLITTING_PLANT"] = "Water Splitting Plant";
    CardName["WAVE_POWER"] = "Wave Power";
    CardName["WINDMILLS"] = "Windmills";
    CardName["WORMS"] = "Worms";
    CardName["ZEPPELINS"] = "Zeppelins";
    CardName["BEGINNER_CORPORATION"] = "Beginner Corporation";
    CardName["CREDICOR"] = "CrediCor";
    CardName["ECOLINE"] = "EcoLine";
    CardName["HELION"] = "Helion";
    CardName["INTERPLANETARY_CINEMATICS"] = "Interplanetary Cinematics";
    CardName["INVENTRIX"] = "Inventrix";
    CardName["MINING_GUILD"] = "Mining Guild";
    CardName["PHOBOLOG"] = "PhoboLog";
    CardName["SATURN_SYSTEMS"] = "Saturn Systems";
    CardName["TERACTOR"] = "Teractor";
    CardName["THARSIS_REPUBLIC"] = "Tharsis Republic";
    CardName["THORGATE"] = "Thorgate";
    CardName["UNITED_NATIONS_MARS_INITIATIVE"] = "United Nations Mars Initiative";
    CardName["ACQUIRED_SPACE_AGENCY"] = "Acquired Space Agency";
    CardName["ALLIED_BANKS"] = "Allied Banks";
    CardName["BIOFUELS"] = "Biofuels";
    CardName["BIOLAB"] = "Biolab";
    CardName["BIOSPHERE_SUPPORT"] = "Biosphere Support";
    CardName["BUSINESS_EMPIRE"] = "Business Empire";
    CardName["CHEUNG_SHING_MARS"] = "Cheung Shing MARS";
    CardName["DONATION"] = "Donation";
    CardName["EXPERIMENTAL_FOREST"] = "Experimental Forest";
    CardName["GALILEAN_MINING"] = "Galilean Mining";
    CardName["HUGE_ASTEROID"] = "Huge Asteroid";
    CardName["IO_RESEARCH_OUTPOST"] = "Io Research Outpost";
    CardName["LOAN"] = "Loan";
    CardName["MARTIAN_SURVEY"] = "Martian Survey";
    CardName["METAL_RICH_ASTEROID"] = "Metal-Rich Asteroid";
    CardName["METALS_COMPANY"] = "Metals Company";
    CardName["NITROGEN_SHIPMENT"] = "Nitrogen Shipment";
    CardName["ORBITAL_CONSTRUCTION_YARD"] = "Orbital Construction Yard";
    CardName["POINT_LUNA"] = "Point Luna";
    CardName["POWER_GENERATION"] = "Power Generation";
    CardName["RESEARCH_COORDINATION"] = "Research Coordination";
    CardName["RESEARCH_NETWORK"] = "Research Network";
    CardName["ROBINSON_INDUSTRIES"] = "Robinson Industries";
    CardName["SF_MEMORIAL"] = "SF Memorial";
    CardName["SMELTING_PLANT"] = "Smelting Plant";
    CardName["SOCIETY_SUPPORT"] = "Society Support";
    CardName["SPACE_HOTELS"] = "Space Hotels";
    CardName["SUPPLIER"] = "Supplier";
    CardName["SUPPLY_DROP"] = "Supply Drop";
    CardName["UNMI_CONTRACTOR"] = "UNMI Contractor";
    CardName["VALLEY_TRUST"] = "Valley Trust";
    CardName["VITOR"] = "Vitor";
    CardName["ARIDOR"] = "Aridor";
    CardName["ARKLIGHT"] = "Arklight";
    CardName["POSEIDON"] = "Poseidon";
    CardName["STORMCRAFT_INCORPORATED"] = "Stormcraft Incorporated";
    CardName["ARCADIAN_COMMUNITIES"] = "Arcadian Communities";
    CardName["ASTRODRILL"] = "Astrodrill";
    CardName["ADVERTISING"] = "Advertising";
    CardName["PHARMACY_UNION"] = "Pharmacy Union";
    CardName["INDUSTRIAL_CENTER"] = "Industrial Center";
    CardName["FACTORUM"] = "Factorum";
    CardName["LAKEFRONT_RESORTS"] = "Lakefront Resorts";
    CardName["TERRALABS_RESEARCH"] = "Terralabs Research";
    CardName["SEPTUM_TRIBUS"] = "Septem Tribus";
    CardName["MONS_INSURANCE"] = "Mons Insurance";
    CardName["SPLICE"] = "Splice";
    CardName["PHILARES"] = "Philares";
    CardName["PRISTAR"] = "Pristar";
    CardName["RECYCLON"] = "Recyclon";
    CardName["UTOPIA_INVEST"] = "Utopia Invest";
    CardName["MANUTECH"] = "Manutech";
    CardName["SELF_REPLICATING_ROBOTS"] = "Self-replicating Robots";
    CardName["POLYPHEMOS"] = "Polyphemos";
    CardName["PENGUINS"] = "Penguins";
    CardName["SMALL_ASTEROID"] = "Small Asteroid";
    CardName["SNOW_ALGAE"] = "Snow Algae";
    CardName["AERIAL_LENSES"] = "Aerial Lenses";
    CardName["BANNED_DELEGATE"] = "Banned Delegate";
    CardName["CULTURAL_METROPOLIS"] = "Cultural Metropolis";
    CardName["DIASPORA_MOVEMENT"] = "Diaspora Movement";
    CardName["EVENT_ANALYSTS"] = "Event Analysts";
    CardName["GMO_CONTRACT"] = "GMO Contract";
    CardName["MARTIAN_MEDIA_CENTER"] = "Martian Media Center";
    CardName["PARLIAMENT_HALL"] = "Parliament Hall";
    CardName["PR_OFFICE"] = "PR Office";
    CardName["PUBLIC_CELEBRATIONS"] = "Public Celebrations";
    CardName["RECRUITMENT"] = "Recruitment";
    CardName["RED_TOURISM_WAVE"] = "Red Tourism Wave";
    CardName["SPONSORED_MOHOLE"] = "Sponsored Mohole";
    CardName["SUPPORTED_RESEARCH"] = "Supported Research";
    CardName["WILDLIFE_DOME"] = "Wildlife Dome";
    CardName["VOTE_OF_NO_CONFIDENCE"] = "Vote Of No Confidence";
    CardName["DUSK_LASER_MINING"] = "Dusk Laser Mining";
    CardName["PROJECT_INSPECTION"] = "Project Inspection";
    CardName["ENERGY_MARKET"] = "Energy Market";
    CardName["HI_TECH_LAB"] = "Hi-Tech Lab";
    CardName["INTERPLANETARY_TRADE"] = "Interplanetary Trade";
    CardName["LAW_SUIT"] = "Law Suit";
    CardName["MERCURIAN_ALLOYS"] = "Mercurian Alloys";
    CardName["ORBITAL_CLEANUP"] = "Orbital Cleanup";
    CardName["POLITICAL_ALLIANCE"] = "Political Alliance";
    CardName["REGO_PLASTICS"] = "Rego Plastics";
    CardName["SATURN_SURFING"] = "Saturn Surfing";
    CardName["STANFORD_TORUS"] = "Stanford Torus";
    CardName["ASTEROID_HOLLOWING"] = "Asteroid Hollowing";
    CardName["COMET_AIMING"] = "Comet Aiming";
    CardName["CRASH_SITE_CLEANUP"] = "Crash Site Cleanup";
    CardName["CUTTING_EDGE_TECHNOLOGY"] = "Cutting Edge Technology";
    CardName["DIRECTED_IMPACTORS"] = "Directed Impactors";
    CardName["FIELD_CAPPED_CITY"] = "Field-Capped City";
    CardName["MAGNETIC_SHIELD"] = "Magnetic Shield";
    CardName["MELTWORKS"] = "Meltworks";
    CardName["MOHOLE_LAKE"] = "Mohole Lake";
    CardName["DIVERSITY_SUPPORT"] = "Diversity Support";
    CardName["JOVIAN_EMBASSY"] = "Jovian Embassy";
    CardName["TOPSOIL_CONTRACT"] = "Topsoil Contract";
    CardName["IMPORTED_NUTRIENTS"] = "Imported Nutrients";
    CardName["ASTEROID_DEFLECTION_SYSTEM"] = "Asteroid Deflection System";
    CardName["SUB_CRUST_MEASUREMENTS"] = "Sub-Crust Measurements";
    CardName["POTATOES"] = "Potatoes";
    CardName["MEAT_INDUSTRY"] = "Meat Industry";
    CardName["DEIMOS_DOWN_PROMO"] = "Deimos Down:promo";
    CardName["GREAT_DAM_PROMO"] = "Great Dam:promo";
    CardName["MAGNETIC_FIELD_GENERATORS_PROMO"] = "Magnetic Field Generators:promo";
    CardName["ASTEROID_RIGHTS"] = "Asteroid Rights";
    CardName["BIO_PRINTING_FACILITY"] = "Bio Printing Facility";
    CardName["BACTOVIRAL_RESEARCH"] = "Bactoviral Research";
    CardName["HARVEST"] = "Harvest";
    CardName["AGRICOLA_INC"] = "Agricola Inc";
    CardName["CURIOSITY_II"] = "Curiosity II";
    CardName["INCITE"] = "Incite";
    CardName["MIDAS"] = "Midas";
    CardName["PLAYWRIGHTS"] = "Playwrights";
    CardName["PROJECT_WORKSHOP"] = "Project Workshop";
    CardName["VALUABLE_GASES"] = "Valuable Gases";
    CardName["VENUS_FIRST"] = "Venus First";
    CardName["RESEARCH_GRANT"] = "Research Grant";
    CardName["AEROSPACE_MISSION"] = "Aerospace Mission";
    CardName["TRADE_ADVANCE"] = "Trade Advance";
    CardName["POLITICAL_UPRISING"] = "Political Uprising";
    CardName["BY_ELECTION"] = "By-Election";
    CardName["SCIENCE_TAG_BLANK_CARD"] = "";
    CardName["BIOENGINEERING_ENCLOSURE"] = "Bioengineering Enclosure";
    CardName["BIOFERTILIZER_FACILITY"] = "Bio-Fertilizer Facility";
    CardName["BUTTERFLY_EFFECT"] = "Butterfly Effect";
    CardName["CAPITAL_ARES"] = "Capital:ares";
    CardName["COMMERCIAL_DISTRICT_ARES"] = "Commercial District:ares";
    CardName["DESPERATE_MEASURES"] = "Desperate Measures";
    CardName["ECOLOGICAL_SURVEY"] = "Ecological Survey";
    CardName["ECOLOGICAL_ZONE_ARES"] = "Ecological Zone:ares";
    CardName["GEOLOGICAL_SURVEY"] = "Geological Survey";
    CardName["INDUSTRIAL_CENTER_ARES"] = "Industrial Center:ares";
    CardName["LAVA_FLOWS_ARES"] = "Lava Flows:ares";
    CardName["MARKETING_EXPERTS"] = "Marketing Experts";
    CardName["METALLIC_ASTEROID"] = "Metallic Asteroid";
    CardName["MINING_AREA_ARES"] = "Mining Area:ares";
    CardName["MINING_RIGHTS_ARES"] = "Mining Rights:ares";
    CardName["MOHOLE_AREA_ARES"] = "Mohole Area:ares";
    CardName["NATURAL_PRESERVE_ARES"] = "Natural Preserve:ares";
    CardName["NUCLEAR_ZONE_ARES"] = "Nuclear Zone:ares";
    CardName["OCEAN_CITY"] = "Ocean City";
    CardName["OCEAN_FARM"] = "Ocean Farm";
    CardName["OCEAN_SANCTUARY"] = "Ocean Sanctuary";
    CardName["RESTRICTED_AREA_ARES"] = "Restricted Area:ares";
    CardName["SOLAR_FARM"] = "Solar Farm";
    CardName["MARE_NECTARIS_MINE"] = "Mare Nectaris Mine";
    CardName["MARE_NUBIUM_MINE"] = "Mare Nubium Mine";
    CardName["MARE_IMBRIUM_MINE"] = "Mare Imbrium Mine";
    CardName["MARE_SERENITATIS_MINE"] = "Mare Serenitatis Mine";
    CardName["HABITAT_14"] = "Habitat 14";
    CardName["GEODESIC_TENTS"] = "Geodesic Tents";
    CardName["SPHERE_HABITATS"] = "Sphere Habitats";
    CardName["THE_WOMB"] = "The Womb";
    CardName["TYCHO_ROAD_NETWORK"] = "Tycho Road Network";
    CardName["ARISTARCHUS_ROAD_NETWORK"] = "Aristarchus Road Network";
    CardName["SINUS_IRIDIUM_ROAD_NETWORK"] = "Sinus Iridium Road Network";
    CardName["MOMENTUM_VIRUM_HABITAT"] = "Momentum Virium Habitat";
    CardName["LUNA_TRADE_STATION"] = "Luna Trade Station";
    CardName["LUNA_MINING_HUB"] = "Luna Mining Hub";
    CardName["LUNA_TRAIN_STATION"] = "Luna Train Station";
    CardName["COLONIST_SHUTTLES"] = "Colonist Shuttles";
    CardName["LUNAR_DUST_PROCESSING_PLANT"] = "Lunar Dust Processing Plant";
    CardName["DEEP_LUNAR_MINING"] = "Deep Lunar Mining";
    CardName["ANCIENT_SHIPYARDS"] = "Ancient Shipyards";
    CardName["LUNA_PROJECT_OFFICE"] = "Luna Project Office";
    CardName["LUNA_RESORT"] = "Luna Resort";
    CardName["LUNAR_OBSERVATION_POST"] = "Lunar Observation Post";
    CardName["MINING_ROBOTS_MANUF_CENTER"] = "Mining Robots Manuf. Center";
    CardName["PRIDE_OF_THE_EARTH_ARKSHIP"] = "Pride of the Earth Arkship";
    CardName["IRON_EXTRACTION_CENTER"] = "Iron Extraction Center";
    CardName["TITANIUM_EXTRACTION_CENTER"] = "Titanium Extraction Center";
    CardName["ARCHIMEDES_HYDROPONICS_STATION"] = "Archimedes Hydroponics Station";
    CardName["STEEL_MARKET_MONOPOLISTS"] = "Steel Market Monopolists";
    CardName["TITANIUM_MARKET_MONOPOLISTS"] = "Titanium Market Monopolists";
    CardName["LUNA_STAGING_STATION"] = "Luna Staging Station";
    CardName["NEW_COLONY_PLANNING_INITIAITIVES"] = "New Colony Planning Initiaitives";
    CardName["AI_CONTROLLED_MINE_NETWORK"] = "AI Controlled Mine Network";
    CardName["DARKSIDE_METEOR_BOMBARDMENT"] = "Darkside Meteor Bombardment";
    CardName["UNDERGROUND_DETONATORS"] = "Underground Detonators";
    CardName["LUNAR_TRADE_FLEET"] = "Lunar Trade Fleet";
    CardName["SUBTERRANEAN_HABITATS"] = "Subterranean Habitats";
    CardName["IMPROVED_MOON_CONCRETE"] = "Improved Moon Concrete";
    CardName["MOONCRATE_BLOCK_FACTORY"] = "Mooncrate Block Factory";
    CardName["HEAVY_DUTY_ROVERS"] = "Heavy Duty Rovers";
    CardName["MICROSINGULARITY_PLANT"] = "Microsingularity Plant";
    CardName["HELIOSTAT_MIRROR_ARRAY"] = "Heliostat Mirror Array";
    CardName["LUNAR_SECURITY_STATIONS"] = "Lunar Security Stations";
    CardName["HYPERSENSITIVE_SILICON_CHIP_FACTORY"] = "Hypersensitive Silicon Chip Factory";
    CardName["COPERNICUS_SOLAR_ARRAYS"] = "Copernicus Solar Arrays";
    CardName["DARKSIDE_INCUBATION_PLANT"] = "Darkside Incubation Plant";
    CardName["WATER_TREATMENT_COMPLEX"] = "Water Treatment Complex";
    CardName["ALGAE_BIOREACTORS"] = "Algae Bioreactors";
    CardName["HE3_FUSION_PLANT"] = "HE3 Fusion Plant";
    CardName["HE3_REFINERY"] = "HE3 Refinery";
    CardName["HE3_LOBBYISTS"] = "HE3 Lobbyists";
    CardName["REVOLTING_COLONISTS"] = "Revolting Colonists";
    CardName["COSMIC_RADIATION"] = "Cosmic Radiation";
    CardName["OFF_WORLD_CITY_LIVING"] = "Off-World City Living";
    CardName["ROAD_PIRACY"] = "Road Piracy";
    CardName["LUNAR_MINE_URBANIZATION"] = "Lunar Mine Urbanization";
    CardName["THORIUM_RUSH"] = "Thorium Rush";
    CardName["HE3_PRODUCTION_QUOTAS"] = "HE3 Production Quotas";
    CardName["LUNA_CONFERENCE"] = "Luna Conference";
    CardName["WE_GROW_AS_ONE"] = "We Grow As One";
    CardName["MOONCRATE_CONVOYS_TO_MARS"] = "Mooncrate Convoys To Mars";
    CardName["LUNAR_INDEPENDENCE_WAR"] = "Lunar Independence War";
    CardName["AN_OFFER_YOU_CANT_REFUSE"] = "An Offer You Can't Refuse";
    CardName["PRELIMINARY_DARKSIDE"] = "Preliminary Darkside";
    CardName["HOSTILE_TAKEOVER"] = "Hostile Takeover";
    CardName["SYNDICATE_PIRATE_RAIDS"] = "Syndicate Pirate Raids";
    CardName["DARKSIDE_MINING_SYNDICATE"] = "Darkside Mining Syndicate";
    CardName["HE3_PROPULSION"] = "HE3 Propulsion";
    CardName["STAGING_STATION_BEHEMOTH"] = "Staging Station \"Behemoth\"";
    CardName["LUNA_ARCHIVES"] = "Luna Archives";
    CardName["LUNA_SENATE"] = "Luna Senate";
    CardName["LUNA_POLITICAL_INSTITUTE"] = "Luna Political Institute";
    CardName["COPERNICUS_TOWER"] = "Copernicus Tower";
    CardName["SMALL_DUTY_ROVERS"] = "Small Duty Rovers";
    CardName["LUNAR_INDUSTRY_COMPLEX"] = "Lunar Industry Complex";
    CardName["DARKSIDE_OBSERVATORY"] = "Darkside Observatory";
    CardName["MARTIAN_EMBASSY"] = "Martian Embassy";
    CardName["EARTH_EMBASSY"] = "Earth Embassy";
    CardName["ROVER_DRIVERS_UNION"] = "Rover Drivers Union";
    CardName["LTF_HEADQUARTERS"] = "L.T.F. Headquarters";
    CardName["DARKSIDE_SMUGGLERS_UNION"] = "Darkside Smugglers' Union";
    CardName["UNDERMOON_DRUG_LORDS_NETWORK"] = "Undermoon Drug Lords Network";
    CardName["LTF_PRIVILEGES"] = "L.T.F. Privileges";
    CardName["GRAND_LUNA_ACADEMY"] = "Grand Luna Academy";
    CardName["LUNA_ECUMENOPOLIS"] = "Luna Ecumenopolis";
    CardName["ORBITAL_POWER_GRID"] = "Orbital Power Grid";
    CardName["PROCESSOR_FACTORY"] = "Processor Factory";
    CardName["LUNAR_STEEL"] = "Lunar Steel";
    CardName["RUST_EATING_BACTERIA"] = "Rust Eating Bacteria";
    CardName["SOLAR_PANEL_FOUNDRY"] = "Solar Panel Foundry";
    CardName["MOON_TETHER"] = "Moon Tether";
    CardName["NANOTECH_INDUSTRIES"] = "Nanotech Industries";
    CardName["TEMPEST_CONSULTANCY"] = "Tempest Consultancy";
    CardName["THE_DARKSIDE_OF_THE_MOON_SYNDICATE"] = "The Darkside of The Moon Syndicate";
    CardName["LUNA_HYPERLOOP_CORPORATION"] = "Luna Hyperloop Corporation";
    CardName["CRESCENT_RESEARCH_ASSOCIATION"] = "Crescent Research Association";
    CardName["LUNA_FIRST_INCORPORATED"] = "Luna First Incorporated";
    CardName["THE_GRAND_LUNA_CAPITAL_GROUP"] = "The Grand Luna Capital Group";
    CardName["INTRAGEN_SANCTUARY_HEADQUARTERS"] = "Intragen Sanctuary Headquarters";
    CardName["LUNA_TRADE_FEDERATION"] = "Luna Trade Federation";
    CardName["THE_ARCHAIC_FOUNDATION_INSTITUTE"] = "The Archaic Foundation Institute";
    CardName["FIRST_LUNAR_SETTLEMENT"] = "First Lunar Settlement";
    CardName["CORE_MINE"] = "Core Mine";
    CardName["BASIC_INFRASTRUCTURE"] = "Basic Infrastructure";
    CardName["LUNAR_PlANNING_OFFICE"] = "Lunar Planning Office";
    CardName["MINING_COMPLEX"] = "Mining Complex";
    CardName["MOON_ROAD_STANDARD_PROJECT"] = "Road Infrastructure";
    CardName["MOON_MINE_STANDARD_PROJECT"] = "Lunar Mine";
    CardName["MOON_COLONY_STANDARD_PROJECT"] = "Lunar Habitat";
    CardName["MOON_ROAD_STANDARD_PROJECT_V2"] = "Road Infrastructure (var.)";
    CardName["MOON_MINE_STANDARD_PROJECT_V2"] = "Lunar Mine (var.)";
    CardName["MOON_COLONY_STANDARD_PROJECT_V2"] = "Lunar Habitat (var.)";
    CardName["BREEDING_FARMS"] = "Breeding Farms";
    CardName["PREFABRICATION_OF_HUMAN_HABITATS"] = "Prefabrication of Human Habitats";
    CardName["NEW_VENICE"] = "New Venice";
    CardName["AGRO_DRONES"] = "Agro-Drones";
    CardName["WETLANDS"] = "Wetlands";
    CardName["RARE_EARTH_ELEMENTS"] = "Rare-Earth Elements";
    CardName["ORBITAL_LABORATORIES"] = "Orbital Laboratories";
    CardName["DUST_STORM"] = "Dust Storm";
    CardName["MARTIAN_MONUMENTS"] = "Martian Monuments";
    CardName["MARTIAN_NATURE_WONDERS"] = "Martian Nature Wonders";
    CardName["MUSEUM_OF_EARLY_COLONISATION"] = "Museum of Early Colonisation";
    CardName["TERRAFORMING_CONTROL_STATION"] = "Terraforming Control Station";
    CardName["MARTIAN_TRANSHIPMENT_STATION"] = "Martian Transhipment Station";
    CardName["CERES_SPACEPORT"] = "Ceres Spaceport";
    CardName["DYSON_SCREENS"] = "Dyson Screens";
    CardName["LUNAR_EMBASSY"] = "Lunar Embassy";
    CardName["GEOLOGICAL_EXPEDITION"] = "Geological Expedition";
    CardName["EARLY_EXPEDITION"] = "Early Expedition";
    CardName["HYDROGEN_PROCESSING_PLANT"] = "Hydrogen Processing Plant";
    CardName["POWER_PLANT_PATHFINDERS"] = "Power Plant:Pathfinders";
    CardName["LUXURY_ESTATE"] = "Luxury Estate";
    CardName["RETURN_TO_ABANDONED_TECHNOLOGY"] = "Return to Abandoned Technology";
    CardName["DESIGNED_ORGANISMS"] = "Designed Organisms";
    CardName["SPACE_DEBRIS_CLEANING_OPERATION"] = "Space Debris Cleaning Operation";
    CardName["PRIVATE_SECURITY"] = "Private Security";
    CardName["SECRET_LABS"] = "Secret Labs";
    CardName["CYANOBACTERIA"] = "Cyanobacteria";
    CardName["COMMUNICATION_CENTER"] = "Communication Center";
    CardName["MARTIAN_REPOSITORY"] = "Martian Repository";
    CardName["DATA_LEAK"] = "Data Leak";
    CardName["SMALL_OPEN_PIT_MINE"] = "Small Open Pit Mine";
    CardName["SOLAR_STORM"] = "Solar Storm";
    CardName["SPACE_RELAY"] = "Space Relay";
    CardName["DECLARATION_OF_INDEPENDENCE"] = "Declaration of Independence";
    CardName["MARTIAN_CULTURE"] = "Martian Culture";
    CardName["OZONE_GENERATORS"] = "Ozone Generators";
    CardName["SMALL_COMET"] = "Small Comet";
    CardName["ECONOMIC_ESPIONAGE"] = "Economic Espionage";
    CardName["FLAT_MARS_THEORY"] = "Flat Mars Theory";
    CardName["ASTEROID_RESOURCES"] = "Asteroid Resources";
    CardName["KICKSTARTER"] = "Kickstarter";
    CardName["ECONOMIC_HELP"] = "Economic Help";
    CardName["INTERPLANETARY_TRANSPORT"] = "Interplanetary Transport";
    CardName["MARTIAN_DUST_PROCESSING_PLANT"] = "Martian Dust Processing Plant";
    CardName["CULTIVATION_OF_VENUS"] = "Cultivation of Venus";
    CardName["EXPEDITION_TO_THE_SURFACE_VENUS"] = "Expedition to the Surface - Venus";
    CardName["LAST_RESORT_INGENUITY"] = "Last Resort Ingenuity";
    CardName["CRASHLANDING"] = "Crashlanding";
    CardName["THINK_TANK"] = "Think Tank";
    CardName["BOTANICAL_EXPERIENCE"] = "Botanical Experience";
    CardName["CRYPTOCURRENCY"] = "Cryptocurrency";
    CardName["RICH_DEPOSITS"] = "Rich Deposits";
    CardName["OUMUAMUA_TYPE_OBJECT_SURVEY"] = "Oumuamua Type Object Survey";
    CardName["SOLARPEDIA"] = "Solarpedia";
    CardName["ANTHOZOA"] = "Anthozoa";
    CardName["ADVANCED_POWER_GRID"] = "Advanced Power Grid";
    CardName["SPECIALIZED_SETTLEMENT"] = "Specialized Settlement";
    CardName["CHARITY_DONATION"] = "Charity Donation";
    CardName["CURIOSITY_LABS"] = "Curiosity Labs";
    CardName["NOBEL_LABS"] = "Nobel Labs";
    CardName["HUYGENS_OBSERVATORY"] = "Huygens Observatory";
    CardName["CASSINI_STATION"] = "Cassini Station";
    CardName["MICROBIOLOGY_PATENTS"] = "Microbiology Patents";
    CardName["COORDINATED_RAID"] = "Coordinated Raid";
    CardName["LOBBY_HALLS"] = "Lobby Halls";
    CardName["RED_CITY"] = "Red City";
    CardName["VENERA_BASE"] = "Venera Base";
    CardName["GATEWAY_STATION"] = "Gateway Station";
    CardName["FLOATER_URBANISM"] = "Floater-Urbanism";
    CardName["SOIL_DETOXIFICATION"] = "Soil Detoxification";
    CardName["HIGH_TEMP_SUPERCONDUCTORS"] = "High Temp. Superconductors";
    CardName["PUBLIC_SPONSORED_GRANT"] = "Public Sponsored Grant";
    CardName["POLLINATORS"] = "Pollinators";
    CardName["SOCIAL_EVENTS"] = "Social Events";
    CardName["CONTROLLED_BLOOM"] = "Controlled Bloom";
    CardName["TERRAFORMING_ROBOTS"] = "Terraforming Robots";
    CardName["VENUS_FIRST_PATHFINDERS"] = "Venus First:Pathfinders";
    CardName["VALUABLE_GASES_PATHFINDERS"] = "Valuable Gases:Pathfinders";
    CardName["CO2_REDUCERS"] = "CO\u00B2 Reducers";
    CardName["HYDROGEN_BOMBARDMENT"] = "Hydrogen Bombardment";
    CardName["RESEARCH_GRANT_PATHFINDERS"] = "Research Grant:Pathfinders";
    CardName["CREW_TRAINING"] = "Crew Training";
    CardName["SURVEY_MISSION"] = "Survey Mission";
    CardName["DESIGN_COMPANY"] = "Design Company";
    CardName["CONSOLIDATION"] = "Consolidation";
    CardName["PERSONAL_AGENDA"] = "Personal Agenda";
    CardName["VITAL_COLONY"] = "Vital Colony";
    CardName["STRATEGIC_BASE_PLANNING"] = "Strategic Base Planning";
    CardName["DEEP_SPACE_OPERATIONS"] = "Deep Space Operations";
    CardName["ANTI_DESERTIFICATION_TECHNIQUES"] = "Anti-desertification Techniques";
    CardName["EXPERIENCED_MARTIANS"] = "Experienced Martians";
    CardName["THE_NEW_SPACE_RACE"] = "The New Space Race";
    CardName["POLARIS"] = "Polaris";
    CardName["PLANET_PR"] = "planet pr";
    CardName["AMBIENT"] = "Ambient";
    CardName["RINGCOM"] = "Ringcom";
    CardName["CHIIMERA"] = "Chiimera";
    CardName["SISTEMAS_SEEBECK"] = "Sistemas Seebeck";
    CardName["SPIRE"] = "Spire";
    CardName["SOYLENT_SEEDLING_SYSTEMS"] = "Soylent Seedling Systems";
    CardName["STEELARIS"] = "Steelaris";
    CardName["MARS_MATHS"] = "Mars Maths";
    CardName["MARS_DIRECT"] = "Mars Direct";
    CardName["MARTIAN_INSURANCE_GROUP"] = "Martian Insurance Group";
    CardName["SOLBANK"] = "SolBank";
    CardName["BIO_SOL"] = "Bio-Sol";
    CardName["AURORAI"] = "Aurorai";
    CardName["COLLEGIUM_COPERNICUS"] = "Collegium Copernicus";
    CardName["ROBIN_HAULINGS"] = "Robin Haulings";
    CardName["ODYSSEY_EFFECT"] = "Odyssey Effect";
    CardName["GAGARIN_MOBILE_BASE"] = "Gagarin Mobile Base";
    CardName["MARS_FRONTIER_ALLIANCE"] = "Mars Frontier Alliance";
    CardName["MIND_SET_MARS"] = "Mind Set Mars";
    CardName["HABITAT_MARTAE"] = "Habitat Martae";
    CardName["ADHAI_HIGH_ORBIT_CONSTRUCTION"] = "Adhai High Orbit Construction";
})(CardName = exports.CardName || (exports.CardName = {}));
