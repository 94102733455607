"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'CardCost',
    props: {
        amount: {
            type: Number,
        },
        newCost: {
            type: Number,
        },
    },
    methods: {
        getClasses: function () {
            var classes = ['card-cost'];
            if (this.amount === undefined) {
                classes.push('visibility-hidden');
            }
            return classes.join(' ');
        },
        displayTwoCosts: function () {
            var hideDiscount = PreferencesManager_1.PreferencesManager.loadBoolean('hide_discount_on_cards');
            return this.newCost !== undefined && this.newCost !== this.amount && !hideDiscount;
        },
    },
});
