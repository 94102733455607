"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaResort = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var Options_1 = require("../Options");
var LunaResort = (function (_super) {
    __extends(LunaResort, _super);
    function LunaResort() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_RESORT,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON],
            cost: 11,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 3 }),
            reserveUnits: Units_1.Units.of({ titanium: 2 }),
            tr: { moonColony: 1 },
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.colonyTiles(2, { all: Options_1.all }); }),
            metadata: {
                description: 'Requires 2 colonies on the Moon. Spend 2 titanium. Decrease your energy production 1 step and increase your M€ production 3 steps. Raise the Colony Rate 1 step.',
                cardNumber: 'M21',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(2).production(function (pb) {
                        pb.minus().energy(1).nbsp.megacredits(3);
                    }).br;
                    b.moonColonyRate();
                }),
            },
        }) || this;
    }
    ;
    LunaResort.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.raiseColonyRate(player);
        return undefined;
    };
    return LunaResort;
}(MoonCard_1.MoonCard));
exports.LunaResort = LunaResort;
