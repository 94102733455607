"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvancedEcosystems = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var AdvancedEcosystems = (function (_super) {
    __extends(AdvancedEcosystems, _super);
    function AdvancedEcosystems() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.ADVANCED_ECOSYSTEMS,
            tags: [Tags_1.Tags.PLANT, Tags_1.Tags.MICROBE, Tags_1.Tags.ANIMAL],
            cost: 11,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.PLANT).tag(Tags_1.Tags.ANIMAL).tag(Tags_1.Tags.MICROBE); }),
            metadata: {
                description: 'Requires a Plant tag, a Microbe tag, and an Animal tag.',
                cardNumber: '135',
                victoryPoints: 3,
            },
        }) || this;
    }
    AdvancedEcosystems.prototype.play = function () {
        return undefined;
    };
    AdvancedEcosystems.prototype.getVictoryPoints = function () {
        return 3;
    };
    return AdvancedEcosystems;
}(Card_1.Card));
exports.AdvancedEcosystems = AdvancedEcosystems;
