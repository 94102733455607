"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpaceName = void 0;
var SpaceName;
(function (SpaceName) {
    SpaceName["ARSIA_MONS"] = "29";
    SpaceName["ASCRAEUS_MONS"] = "14";
    SpaceName["GANYMEDE_COLONY"] = "01";
    SpaceName["NOCTIS_CITY"] = "31";
    SpaceName["PAVONIS_MONS"] = "21";
    SpaceName["PHOBOS_SPACE_HAVEN"] = "02";
    SpaceName["THARSIS_THOLUS"] = "09";
    SpaceName["LUNA_METROPOLIS"] = "70";
    SpaceName["DAWN_CITY"] = "71";
    SpaceName["STRATOPOLIS"] = "72";
    SpaceName["MAXWELL_BASE"] = "73";
    SpaceName["ARSIA_MONS_ELYSIUM"] = "37";
    SpaceName["HECATES_THOLUS"] = "08";
    SpaceName["ELYSIUM_MONS"] = "14";
    SpaceName["OLYMPUS_MONS"] = "20";
    SpaceName["HELLAS_OCEAN_TILE"] = "61";
    SpaceName["STANFORD_TORUS"] = "69";
    SpaceName["TIKHONAROV"] = "20";
    SpaceName["LADON"] = "55";
    SpaceName["FLAUGERGUES"] = "58";
    SpaceName["CHARYBDIS"] = "63";
    SpaceName["CERES_SPACEPORT"] = "75";
    SpaceName["DYSON_SCREENS"] = "76";
    SpaceName["LUNAR_EMBASSY"] = "77";
    SpaceName["VENERA_BASE"] = "78";
})(SpaceName = exports.SpaceName || (exports.SpaceName = {}));
