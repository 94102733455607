"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LawSuit = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var SelectPlayer_1 = require("../../inputs/SelectPlayer");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Options_1 = require("../Options");
var LawSuit = (function (_super) {
    __extends(LawSuit, _super);
    function LawSuit() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.LAW_SUIT,
            tags: [Tags_1.Tags.EARTH],
            cost: 2,
            metadata: {
                cardNumber: 'X06',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('steal', Size_1.Size.SMALL, true).megacredits(3, { all: Options_1.all }).asterix();
                }),
                description: 'Steal 3 M€ from a player that REMOVED YOUR RESOURCES OR DECREASED YOUR PRODUCTION this generation. Place this card face down in THAT PLAYER\'S EVENT PILE.',
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.any(-1),
            },
        }) || this;
    }
    LawSuit.prototype.targets = function (player) {
        return player.game.getPlayersById(player.removingPlayers).filter(function (player) { return !player.megaCreditsAreProtected(); });
    };
    LawSuit.prototype.canPlay = function (player) {
        return this.targets(player).length > 0;
    };
    LawSuit.prototype.play = function (player) {
        var _this = this;
        return new SelectPlayer_1.SelectPlayer(this.targets(player), 'Select player to sue (steal 3 M€ from)', 'Steal M€', function (suedPlayer) {
            var amount = Math.min(3, suedPlayer.megaCredits);
            player.addResource(Resources_1.Resources.MEGACREDITS, amount);
            suedPlayer.deductResource(Resources_1.Resources.MEGACREDITS, amount, { log: true, from: player });
            suedPlayer.playedCards.push(_this);
            return undefined;
        });
    };
    LawSuit.prototype.getVictoryPoints = function () {
        return -1;
    };
    LawSuit.resourceHook = function (player, _resource, amount, from) {
        if (from === player || amount >= 0) {
            return;
        }
        if (player.removingPlayers.includes(from.id) === false) {
            player.removingPlayers.push(from.id);
        }
    };
    return LawSuit;
}(Card_1.Card));
exports.LawSuit = LawSuit;
