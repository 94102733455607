"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImmigrantCity = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var GainProduction_1 = require("../../deferredActions/GainProduction");
var LoseProduction_1 = require("../../deferredActions/LoseProduction");
var Board_1 = require("../../boards/Board");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var ImmigrantCity = (function (_super) {
    __extends(ImmigrantCity, _super);
    function ImmigrantCity() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.IMMIGRANT_CITY,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 13,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: -2 }),
            metadata: {
                cardNumber: '200',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When a City tile is placed, including this, increase your M€ production 1 step.', function (eb) {
                        eb.city({ all: Options_1.all }).startEffect.production(function (pb) { return pb.megacredits(1); });
                    }).br;
                    b.production(function (pb) { return pb.minus().energy(1).megacredits(-2); }).city();
                }),
                description: 'Decrease your Energy production 1 step and decrease your M€ production 2 steps. Place a City tile.',
            },
        }) || this;
    }
    ImmigrantCity.prototype.canPlay = function (player) {
        var hasEnergyProduction = player.getProduction(Resources_1.Resources.ENERGY) >= 1;
        var canPlaceCityOnMars = player.game.board.getAvailableSpacesForCity(player).length > 0;
        var canDecreaseMcProduction = player.getProduction(Resources_1.Resources.MEGACREDITS) >= -4 || player.isCorporation(CardName_1.CardName.THARSIS_REPUBLIC);
        return hasEnergyProduction && canDecreaseMcProduction && canPlaceCityOnMars;
    };
    ImmigrantCity.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        if (Board_1.Board.isCitySpace(space)) {
            cardOwner.game.defer(new GainProduction_1.GainProduction(cardOwner, Resources_1.Resources.MEGACREDITS), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : undefined);
        }
    };
    ImmigrantCity.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            player.game.defer(new LoseProduction_1.LoseProduction(player, Resources_1.Resources.ENERGY, { count: 1 }));
            player.game.defer(new LoseProduction_1.LoseProduction(player, Resources_1.Resources.MEGACREDITS, { count: 2 }));
            return undefined;
        });
    };
    return ImmigrantCity;
}(Card_1.Card));
exports.ImmigrantCity = ImmigrantCity;
