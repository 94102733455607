"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopernicusSolarArrays = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var CopernicusSolarArrays = (function (_super) {
    __extends(CopernicusSolarArrays, _super);
    function CopernicusSolarArrays() {
        return _super.call(this, {
            name: CardName_1.CardName.COPERNICUS_SOLAR_ARRAYS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.SPACE],
            cost: 8,
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            metadata: {
                description: 'Spend 1 titanium. Gain 2 heat. Incease your energy production 1 step.',
                cardNumber: 'M44',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(1);
                    b.br;
                    b.heat(2);
                    b.br;
                    b.production(function (pb) { return pb.energy(1); });
                }),
            },
        }) || this;
    }
    ;
    CopernicusSolarArrays.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        player.heat += 2;
        player.addProduction(Resources_1.Resources.ENERGY, 1, { log: true });
        return undefined;
    };
    return CopernicusSolarArrays;
}(MoonCard_1.MoonCard));
exports.CopernicusSolarArrays = CopernicusSolarArrays;
