"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EarthElevator = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var EarthElevator = (function (_super) {
    __extends(EarthElevator, _super);
    function EarthElevator() {
        return _super.call(this, {
            cost: 43,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.EARTH],
            name: CardName_1.CardName.EARTH_ELEVATOR,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                description: 'Increase your titanium production 3 steps.',
                cardNumber: 'C08',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.titanium(3); });
                }),
                victoryPoints: 4,
            },
        }) || this;
    }
    EarthElevator.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.TITANIUM, 3);
        return undefined;
    };
    EarthElevator.prototype.getVictoryPoints = function () {
        return 4;
    };
    return EarthElevator;
}(Card_1.Card));
exports.EarthElevator = EarthElevator;
