"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpaceMirrors = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var SpaceMirrors = (function (_super) {
    __extends(SpaceMirrors, _super);
    function SpaceMirrors() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.SPACE_MIRRORS,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.SPACE],
            cost: 3,
            metadata: {
                cardNumber: '076',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 7 M€ to increase your energy production 1 step.', function (eb) {
                        eb.megacredits(7).startAction.production(function (pb) { return pb.energy(1); });
                    });
                }),
            },
        }) || this;
    }
    SpaceMirrors.prototype.play = function () {
        return undefined;
    };
    SpaceMirrors.prototype.canAct = function (player) {
        return player.canAfford(7);
    };
    SpaceMirrors.prototype.action = function (player) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 7, { title: 'Select how to pay for action' }));
        player.addProduction(Resources_1.Resources.ENERGY, 1);
        return undefined;
    };
    return SpaceMirrors;
}(Card_1.Card));
exports.SpaceMirrors = SpaceMirrors;
