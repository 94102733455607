"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrosiveRain = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var CorrosiveRainDeferredAction_1 = require("../../deferredActions/CorrosiveRainDeferredAction");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().floaters(2).or().megacredits(-10).br.cards(1).slash().influence();
});
var CorrosiveRain = (function () {
    function CorrosiveRain() {
        this.name = GlobalEventName_1.GlobalEventName.CORROSIVE_RAIN;
        this.description = 'Lose 2 floaters from a card or 10 M€. Draw 1 card for each influence.';
        this.revealedDelegate = PartyName_1.PartyName.KELVINISTS;
        this.currentDelegate = PartyName_1.PartyName.GREENS;
        this.renderData = RENDER_DATA;
    }
    CorrosiveRain.prototype.resolve = function (game, turmoil) {
        game.getPlayers().forEach(function (player) {
            player.drawCard(turmoil.getPlayerInfluence(player));
            game.defer(new CorrosiveRainDeferredAction_1.CorrosiveRainDeferredAction(player));
        });
    };
    return CorrosiveRain;
}());
exports.CorrosiveRain = CorrosiveRain;
