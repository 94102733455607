"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gyropolis = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var Gyropolis = (function (_super) {
    __extends(Gyropolis, _super);
    function Gyropolis() {
        return _super.call(this, {
            name: CardName_1.CardName.GYROPOLIS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 20,
            metadata: {
                cardNumber: '230',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(2).br;
                        pb.plus().megacredits(1).slash().venus(1, { played: Options_1.played }).br;
                        pb.plus().megacredits(1).slash().earth(1, { played: Options_1.played }).br;
                    }).nbsp.city();
                }),
                description: 'Decrease your energy production 2 steps. Increase your M€ production 1 step for each Venus and Earth tag you have. Place a City tile.',
            },
        }) || this;
    }
    ;
    Gyropolis.prototype.canPlay = function (player) {
        if (player.game.board.getAvailableSpacesForCity(player).length === 0)
            return false;
        return player.getProduction(Resources_1.Resources.ENERGY) >= 2;
    };
    Gyropolis.prototype.produce = function (player) {
        var tags = [Tags_1.Tags.VENUS, Tags_1.Tags.EARTH];
        player.addProduction(Resources_1.Resources.ENERGY, -2);
        player.addProduction(Resources_1.Resources.MEGACREDITS, player.getMultipleTagCount(tags), { log: true });
    };
    Gyropolis.prototype.play = function (player) {
        this.produce(player);
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            return undefined;
        });
    };
    return Gyropolis;
}(Card_1.Card));
exports.Gyropolis = Gyropolis;
