"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JovianTaxRights = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.production(function (pb) { return pb.megacredits(1); }).slash().colonies(1).br;
    b.titanium(1).slash().influence();
});
var JovianTaxRights = (function () {
    function JovianTaxRights() {
        this.name = GlobalEventName_1.GlobalEventName.JOVIAN_TAX_RIGHTS;
        this.description = 'Increase M€ production 1 step for each colony. Gain 1 titanium for each influence.';
        this.revealedDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.currentDelegate = PartyName_1.PartyName.UNITY;
        this.renderData = RENDER_DATA;
    }
    JovianTaxRights.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var coloniesCount = 0;
            game.colonies.forEach(function (colony) {
                coloniesCount += colony.colonies.filter(function (owner) { return owner === player.id; }).length;
            });
            player.addProduction(Resources_1.Resources.MEGACREDITS, coloniesCount, { log: true, from: _this.name });
            player.addResource(Resources_1.Resources.TITANIUM, turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    return JovianTaxRights;
}());
exports.JovianTaxRights = JovianTaxRights;
