"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeclarationOfIndependence = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var Turmoil_1 = require("../../turmoil/Turmoil");
var DeclarationOfIndependence = (function (_super) {
    __extends(DeclarationOfIndependence, _super);
    function DeclarationOfIndependence() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.DECLARATION_OF_INDEPENDENCE,
            cost: 20,
            tags: [Tags_1.Tags.MARS],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.MARS, 6); }),
            metadata: {
                cardNumber: 'Pf34',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.delegates(2).asterix; }),
                description: 'Have at least 6 Mars tags in play. Place 2 delegates in 1 party.',
                victoryPoints: 4,
            },
        }) || this;
    }
    DeclarationOfIndependence.prototype.canPlay = function (player) {
        return Turmoil_1.Turmoil.getTurmoil(player.game).getDelegatesInReserve(player.id) >= 2;
    };
    DeclarationOfIndependence.prototype.play = function (player) {
        var title = 'Select a party to place 2 delegates.';
        player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, title, { count: 2, source: 'reserve' }));
        return undefined;
    };
    return DeclarationOfIndependence;
}(Card_1.Card));
exports.DeclarationOfIndependence = DeclarationOfIndependence;
