"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalEventDealer = exports.getGlobalEventByName = exports.ALL_EVENTS = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var GlobalDustStorm_1 = require("./GlobalDustStorm");
var SponsoredProjects_1 = require("./SponsoredProjects");
var AsteroidMining_1 = require("./AsteroidMining");
var GenerousFunding_1 = require("./GenerousFunding");
var SuccessfulOrganisms_1 = require("./SuccessfulOrganisms");
var Productivity_1 = require("./Productivity");
var EcoSabotage_1 = require("./EcoSabotage");
var HomeworldSupport_1 = require("./HomeworldSupport");
var MinersOnStrike_1 = require("./MinersOnStrike");
var MudSlides_1 = require("./MudSlides");
var Revolution_1 = require("./Revolution");
var Riots_1 = require("./Riots");
var Sabotage_1 = require("./Sabotage");
var SnowCover_1 = require("./SnowCover");
var VolcanicEruptions_1 = require("./VolcanicEruptions");
var InterplanetaryTrade_1 = require("./InterplanetaryTrade");
var ImprovedEnergyTemplates_1 = require("./ImprovedEnergyTemplates");
var WarOnEarth_1 = require("./WarOnEarth");
var Pandemic_1 = require("./Pandemic");
var Diversity_1 = require("./Diversity");
var CelebrityLeaders_1 = require("./CelebrityLeaders");
var SpinoffProducts_1 = require("./SpinoffProducts");
var Election_1 = require("./Election");
var AquiferReleasedByPublicCouncil_1 = require("./AquiferReleasedByPublicCouncil");
var ParadigmBreakdown_1 = require("./ParadigmBreakdown");
var CorrosiveRain_1 = require("./CorrosiveRain");
var JovianTaxRights_1 = require("./JovianTaxRights");
var DryDeserts_1 = require("./DryDeserts");
var ScientificCommunity_1 = require("./ScientificCommunity");
var RedInfluence_1 = require("./RedInfluence");
var SolarnetShutdown_1 = require("./SolarnetShutdown");
var StrongSociety_1 = require("./StrongSociety");
var SolarFlare_1 = require("./SolarFlare");
var VenusInfrastructure_1 = require("./VenusInfrastructure");
var CloudSocieties_1 = require("./CloudSocieties");
var MicrogravityHealthProblems_1 = require("./MicrogravityHealthProblems");
var LeadershipSummit_1 = require("./LeadershipSummit");
var COLONY_ONLY_POSITIVE_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.JOVIAN_TAX_RIGHTS, JovianTaxRights_1.JovianTaxRights],
]);
var COLONY_ONLY_NEGATIVE_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.MICROGRAVITY_HEALTH_PROBLEMS, MicrogravityHealthProblems_1.MicrogravityHealthProblems],
]);
var VENUS_COLONY_POSITIVE_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.CLOUD_SOCIETIES, CloudSocieties_1.CloudSocieties],
]);
var VENUS_COLONY_NEGATIVE_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.CORROSIVE_RAIN, CorrosiveRain_1.CorrosiveRain],
]);
var VENUS_POSITIVE_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.VENUS_INFRASTRUCTURE, VenusInfrastructure_1.VenusInfrastructure],
]);
var POSITIVE_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.SPONSORED_PROJECTS, SponsoredProjects_1.SponsoredProjects],
    [GlobalEventName_1.GlobalEventName.ASTEROID_MINING, AsteroidMining_1.AsteroidMining],
    [GlobalEventName_1.GlobalEventName.GENEROUS_FUNDING, GenerousFunding_1.GenerousFunding],
    [GlobalEventName_1.GlobalEventName.SUCCESSFUL_ORGANISMS, SuccessfulOrganisms_1.SuccessfulOrganisms],
    [GlobalEventName_1.GlobalEventName.PRODUCTIVITY, Productivity_1.Productivity],
    [GlobalEventName_1.GlobalEventName.HOMEWORLD_SUPPORT, HomeworldSupport_1.HomeworldSupport],
    [GlobalEventName_1.GlobalEventName.VOLCANIC_ERUPTIONS, VolcanicEruptions_1.VolcanicEruptions],
    [GlobalEventName_1.GlobalEventName.DIVERSITY, Diversity_1.Diversity],
    [GlobalEventName_1.GlobalEventName.IMPROVED_ENERGY_TEMPLATES, ImprovedEnergyTemplates_1.ImprovedEnergyTemplates],
    [GlobalEventName_1.GlobalEventName.INTERPLANETARY_TRADE, InterplanetaryTrade_1.InterplanetaryTrade],
    [GlobalEventName_1.GlobalEventName.CELEBRITY_LEADERS, CelebrityLeaders_1.CelebrityLeaders],
    [GlobalEventName_1.GlobalEventName.SPINOFF_PRODUCTS, SpinoffProducts_1.SpinoffProducts],
    [GlobalEventName_1.GlobalEventName.ELECTION, Election_1.Election],
    [GlobalEventName_1.GlobalEventName.AQUIFER_RELEASED_BY_PUBLIC_COUNCIL, AquiferReleasedByPublicCouncil_1.AquiferReleasedByPublicCouncil],
    [GlobalEventName_1.GlobalEventName.SCIENTIFIC_COMMUNITY, ScientificCommunity_1.ScientificCommunity],
    [GlobalEventName_1.GlobalEventName.STRONG_SOCIETY, StrongSociety_1.StrongSociety],
]);
var NEGATIVE_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.GLOBAL_DUST_STORM, GlobalDustStorm_1.GlobalDustStorm],
    [GlobalEventName_1.GlobalEventName.ECO_SABOTAGE, EcoSabotage_1.EcoSabotage],
    [GlobalEventName_1.GlobalEventName.MINERS_ON_STRIKE, MinersOnStrike_1.MinersOnStrike],
    [GlobalEventName_1.GlobalEventName.MUD_SLIDES, MudSlides_1.MudSlides],
    [GlobalEventName_1.GlobalEventName.REVOLUTION, Revolution_1.Revolution],
    [GlobalEventName_1.GlobalEventName.RIOTS, Riots_1.Riots],
    [GlobalEventName_1.GlobalEventName.SABOTAGE, Sabotage_1.Sabotage],
    [GlobalEventName_1.GlobalEventName.SNOW_COVER, SnowCover_1.SnowCover],
    [GlobalEventName_1.GlobalEventName.PANDEMIC, Pandemic_1.Pandemic],
    [GlobalEventName_1.GlobalEventName.WAR_ON_EARTH, WarOnEarth_1.WarOnEarth],
    [GlobalEventName_1.GlobalEventName.PARADIGM_BREAKDOWN, ParadigmBreakdown_1.ParadigmBreakdown],
    [GlobalEventName_1.GlobalEventName.DRY_DESERTS, DryDeserts_1.DryDeserts],
    [GlobalEventName_1.GlobalEventName.RED_INFLUENCE, RedInfluence_1.RedInfluence],
    [GlobalEventName_1.GlobalEventName.SOLARNET_SHUTDOWN, SolarnetShutdown_1.SolarnetShutdown],
    [GlobalEventName_1.GlobalEventName.SOLAR_FLARE, SolarFlare_1.SolarFlare],
]);
var COMMUNITY_GLOBAL_EVENTS = new Map([
    [GlobalEventName_1.GlobalEventName.LEADERSHIP_SUMMIT, LeadershipSummit_1.LeadershipSummit],
]);
var RENAMED_GLOBAL_EVENTS = new Map([]);
exports.ALL_EVENTS = new Map(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], Array.from(POSITIVE_GLOBAL_EVENTS)), Array.from(NEGATIVE_GLOBAL_EVENTS)), Array.from(COLONY_ONLY_POSITIVE_GLOBAL_EVENTS)), Array.from(COLONY_ONLY_NEGATIVE_GLOBAL_EVENTS)), Array.from(VENUS_COLONY_POSITIVE_GLOBAL_EVENTS)), Array.from(VENUS_COLONY_NEGATIVE_GLOBAL_EVENTS)), Array.from(VENUS_POSITIVE_GLOBAL_EVENTS)), Array.from(COMMUNITY_GLOBAL_EVENTS)), Array.from(RENAMED_GLOBAL_EVENTS)));
function getGlobalEventByName(globalEventName) {
    var Factory = exports.ALL_EVENTS.get(globalEventName);
    if (Factory !== undefined)
        return new Factory();
    return undefined;
}
exports.getGlobalEventByName = getGlobalEventByName;
var GlobalEventDealer = (function () {
    function GlobalEventDealer(globalEventsDeck, discardedGlobalEvents) {
        this.globalEventsDeck = globalEventsDeck;
        this.discardedGlobalEvents = discardedGlobalEvents;
    }
    GlobalEventDealer.newInstance = function (game) {
        var events = Array.from(POSITIVE_GLOBAL_EVENTS);
        if (!game.gameOptions.removeNegativeGlobalEventsOption) {
            events.push.apply(events, Array.from(NEGATIVE_GLOBAL_EVENTS));
            if (game.gameOptions.coloniesExtension)
                events.push.apply(events, Array.from(COLONY_ONLY_NEGATIVE_GLOBAL_EVENTS));
            if (game.gameOptions.venusNextExtension && game.gameOptions.coloniesExtension) {
                events.push.apply(events, Array.from(VENUS_COLONY_NEGATIVE_GLOBAL_EVENTS));
            }
            ;
        }
        if (game.gameOptions.venusNextExtension)
            events.push.apply(events, Array.from(VENUS_POSITIVE_GLOBAL_EVENTS));
        if (game.gameOptions.coloniesExtension)
            events.push.apply(events, Array.from(COLONY_ONLY_POSITIVE_GLOBAL_EVENTS));
        if (game.gameOptions.venusNextExtension && game.gameOptions.coloniesExtension) {
            events.push.apply(events, Array.from(VENUS_COLONY_POSITIVE_GLOBAL_EVENTS));
        }
        if (game.gameOptions.communityCardsOption)
            events.push.apply(events, Array.from(COMMUNITY_GLOBAL_EVENTS));
        var globalEventsDeck = this.shuffle(events.map(function (cf) { return new cf[1]; }));
        return new GlobalEventDealer(globalEventsDeck, []);
    };
    ;
    GlobalEventDealer.shuffle = function (cards) {
        var deck = [];
        var copy = cards.slice();
        while (copy.length) {
            deck.push(copy.splice(Math.floor(Math.random() * copy.length), 1)[0]);
        }
        return deck;
    };
    GlobalEventDealer.prototype.draw = function () {
        return this.globalEventsDeck.pop();
    };
    GlobalEventDealer.prototype.serialize = function () {
        return {
            deck: this.globalEventsDeck.map(function (card) { return card.name; }),
            discarded: this.discardedGlobalEvents.map(function (card) { return card.name; }),
        };
    };
    GlobalEventDealer.deserialize = function (d) {
        var deck = d.deck.map(function (element) {
            return getGlobalEventByName(element);
        });
        var discardPile = d.discarded.map(function (element) {
            return getGlobalEventByName(element);
        });
        return new GlobalEventDealer(deck, discardPile);
    };
    return GlobalEventDealer;
}());
exports.GlobalEventDealer = GlobalEventDealer;
