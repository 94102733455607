"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var utils_1 = require("@/utils/utils");
exports.default = vue_1.default.extend({
    name: 'CardParty',
    props: {
        party: {
            required: true,
            type: String,
        },
    },
    methods: {
        getClasses: function () {
            var classes = ['card-description', 'card-party--' + this.party.toLowerCase().replace(' ', '-')];
            return utils_1.generateClassString(classes);
        },
    },
});
