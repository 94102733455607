"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Color_1 = require("@/Color");
var BoardName_1 = require("@/boards/BoardName");
var CorporationsFilter_vue_1 = require("@/client/components/create/CorporationsFilter.vue");
var i18n_1 = require("@/client/directives/i18n");
var ColoniesFilter_vue_1 = require("@/client/components/create/ColoniesFilter.vue");
var CardsFilter_vue_1 = require("@/client/components/create/CardsFilter.vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var utils_1 = require("@/utils/utils");
var RandomMAOptionType_1 = require("@/RandomMAOptionType");
var PoliticalAgendas_1 = require("@/turmoil/PoliticalAgendas");
var constants = require("@/constants");
exports.default = vue_1.default.extend({
    name: 'CreateGameForm',
    data: function () {
        return {
            constants: constants,
            firstIndex: 1,
            playersCount: 1,
            players: [
                { index: 1, name: '', color: Color_1.Color.RED, beginner: false, handicap: 0, first: false },
                { index: 2, name: '', color: Color_1.Color.GREEN, beginner: false, handicap: 0, first: false },
                { index: 3, name: '', color: Color_1.Color.YELLOW, beginner: false, handicap: 0, first: false },
                { index: 4, name: '', color: Color_1.Color.BLUE, beginner: false, handicap: 0, first: false },
                { index: 5, name: '', color: Color_1.Color.BLACK, beginner: false, handicap: 0, first: false },
                { index: 6, name: '', color: Color_1.Color.PURPLE, beginner: false, handicap: 0, first: false },
                { index: 7, name: '', color: Color_1.Color.ORANGE, beginner: false, handicap: 0, first: false },
                { index: 8, name: '', color: Color_1.Color.PINK, beginner: false, handicap: 0, first: false },
            ],
            corporateEra: true,
            prelude: false,
            draftVariant: true,
            initialDraft: false,
            randomMA: RandomMAOptionType_1.RandomMAOptionType.NONE,
            randomFirstPlayer: true,
            showOtherPlayersVP: false,
            beginnerOption: false,
            venusNext: false,
            colonies: false,
            showColoniesList: false,
            showCorporationList: false,
            showCardsBlackList: false,
            turmoil: false,
            customCorporationsList: [],
            customColoniesList: [],
            cardsBlackList: [],
            isSoloModePage: false,
            board: BoardName_1.BoardName.ORIGINAL,
            boards: [
                BoardName_1.BoardName.ORIGINAL,
                BoardName_1.BoardName.HELLAS,
                BoardName_1.BoardName.ELYSIUM,
                BoardName_1.RandomBoardOption.OFFICIAL,
                BoardName_1.BoardName.ARABIA_TERRA,
                BoardName_1.RandomBoardOption.ALL,
            ],
            seed: Math.random(),
            seededGame: false,
            solarPhaseOption: false,
            shuffleMapOption: false,
            promoCardsOption: false,
            communityCardsOption: false,
            aresExtension: false,
            politicalAgendasExtension: PoliticalAgendas_1.AgendaStyle.STANDARD,
            moonExpansion: false,
            undoOption: false,
            showTimers: true,
            fastModeOption: false,
            removeNegativeGlobalEventsOption: false,
            includeVenusMA: true,
            startingCorporations: 2,
            soloTR: false,
            clonedGameData: undefined,
            cloneGameData: [],
            allOfficialExpansions: false,
            requiresVenusTrackCompletion: false,
            requiresMoonTrackCompletion: false,
            moonStandardProjectVariant: false,
            altVenusBoard: false,
        };
    },
    components: {
        Button: Button_vue_1.default,
        CardsFilter: CardsFilter_vue_1.default,
        ColoniesFilter: ColoniesFilter_vue_1.default,
        CorporationsFilter: CorporationsFilter_vue_1.default,
    },
    mounted: function () {
        var _this = this;
        if (window.location.pathname === '/solo') {
            this.isSoloModePage = true;
        }
        var onSucces = function (response) {
            _this.$data.cloneGameData = response;
        };
        fetch('/api/clonablegames')
            .then(function (response) { return response.json(); })
            .then(onSucces)
            .catch(function (_) { return alert('Unexpected server response'); });
    },
    methods: {
        downloadCurrentSettings: function () {
            var serializedData = this.serializeSettings();
            if (serializedData) {
                var a = document.createElement('a');
                var blob = new Blob([serializedData], { 'type': 'application/json' });
                a.href = window.URL.createObjectURL(blob);
                a.download = 'tm_settings.json';
                a.click();
            }
        },
        handleSettingsUpload: function () {
            var refs = this.$refs;
            var file = refs.file.files[0];
            var reader = new FileReader();
            var component = this.$data;
            reader.addEventListener('load', function () {
                var readerResults = reader.result;
                if (typeof (readerResults) === 'string') {
                    var results_1 = JSON.parse(readerResults);
                    var players = results_1['players'];
                    component.playersCount = players.length;
                    component.showCorporationList = results_1['customCorporationsList'].length > 0;
                    component.showColoniesList = results_1['customColoniesList'].length > 0;
                    component.showCardsBlackList = results_1['cardsBlackList'].length > 0;
                    for (var k in results_1) {
                        if (['customCorporationsList', 'customColoniesList', 'cardsBlackList', 'players'].includes(k))
                            continue;
                        component[k] = results_1[k];
                    }
                    for (var i = 0; i < players.length; i++) {
                        component.players[i] = players[i];
                    }
                    vue_1.default.nextTick(function () {
                        if (component.showColoniesList) {
                            refs.coloniesFilter.updateColoniesByNames(results_1['customColoniesList']);
                        }
                        if (component.showCorporationList) {
                            refs.corporationsFilter.selectedCorporations = results_1['customCorporationsList'];
                        }
                        if (component.showCardsBlackList) {
                            refs.cardsFilter.selectedCardNames = results_1['cardsBlackList'];
                        }
                        if (!component.seededGame) {
                            component.seed = Math.random();
                        }
                    });
                }
            }, false);
            if (file) {
                if (/\.json$/i.test(file.name)) {
                    reader.readAsText(file);
                }
            }
        },
        getPlayerNamePlaceholder: function (player) {
            return i18n_1.translateTextWithParams('Player ${0} name', [String(player.index)]);
        },
        updateCustomCorporationsList: function (newCustomCorporationsList) {
            var component = this;
            component.customCorporationsList = newCustomCorporationsList;
        },
        updateCardsBlackList: function (newCardsBlackList) {
            var component = this;
            component.cardsBlackList = newCardsBlackList;
        },
        updateCustomColoniesList: function (newCustomColoniesList) {
            var component = this;
            component.customColoniesList = newCustomColoniesList;
        },
        getPlayers: function () {
            var component = this;
            return component.players.slice(0, component.playersCount);
        },
        isRandomMAEnabled: function () {
            return this.randomMA !== RandomMAOptionType_1.RandomMAOptionType.NONE;
        },
        randomMAToggle: function () {
            var component = this;
            if (component.randomMA === RandomMAOptionType_1.RandomMAOptionType.NONE) {
                component.randomMA = RandomMAOptionType_1.RandomMAOptionType.LIMITED;
                this.randomMA = RandomMAOptionType_1.RandomMAOptionType.LIMITED;
            }
            else {
                component.randomMA = RandomMAOptionType_1.RandomMAOptionType.NONE;
                this.randomMA = RandomMAOptionType_1.RandomMAOptionType.NONE;
            }
        },
        getRandomMaOptionType: function (type) {
            if (type === 'limited') {
                return RandomMAOptionType_1.RandomMAOptionType.LIMITED;
            }
            else if (type === 'full') {
                return RandomMAOptionType_1.RandomMAOptionType.UNLIMITED;
            }
            else {
                return RandomMAOptionType_1.RandomMAOptionType.NONE;
            }
        },
        isPoliticalAgendasExtensionEnabled: function () {
            return this.politicalAgendasExtension !== PoliticalAgendas_1.AgendaStyle.STANDARD;
        },
        politicalAgendasExtensionToggle: function () {
            if (this.politicalAgendasExtension === PoliticalAgendas_1.AgendaStyle.STANDARD) {
                this.politicalAgendasExtension = PoliticalAgendas_1.AgendaStyle.RANDOM;
            }
            else {
                this.politicalAgendasExtension = PoliticalAgendas_1.AgendaStyle.STANDARD;
            }
        },
        getPoliticalAgendasExtensionAgendaStyle: function (type) {
            if (type === 'random') {
                return PoliticalAgendas_1.AgendaStyle.RANDOM;
            }
            else if (type === 'chairman') {
                return PoliticalAgendas_1.AgendaStyle.CHAIRMAN;
            }
            else {
                console.warn('AgendaStyle not found');
                return PoliticalAgendas_1.AgendaStyle.STANDARD;
            }
        },
        isBeginnerToggleEnabled: function () {
            return !(this.initialDraft || this.prelude || this.venusNext || this.colonies || this.turmoil);
        },
        selectAll: function () {
            this.corporateEra = this.$data.allOfficialExpansions;
            this.prelude = this.$data.allOfficialExpansions;
            this.venusNext = this.$data.allOfficialExpansions;
            this.colonies = this.$data.allOfficialExpansions;
            this.turmoil = this.$data.allOfficialExpansions;
            this.promoCardsOption = this.$data.allOfficialExpansions;
            this.solarPhaseOption = this.$data.allOfficialExpansions;
        },
        toggleVenusNext: function () {
            this.solarPhaseOption = this.$data.venusNext;
        },
        deselectPoliticalAgendasWhenDeselectingTurmoil: function () {
            if (this.$data.turmoil === false) {
                this.politicalAgendasExtension = PoliticalAgendas_1.AgendaStyle.STANDARD;
            }
        },
        deselectVenusCompletion: function () {
            if (this.$data.venusNext === false) {
                this.requiresVenusTrackCompletion = false;
            }
        },
        deselectMoonCompletion: function () {
            if (this.$data.moonExpansion === false) {
                this.requiresMoonTrackCompletion = false;
                this.moonStandardProjectVariant = false;
            }
        },
        getBoardColorClass: function (boardName) {
            if (boardName === BoardName_1.BoardName.ORIGINAL) {
                return 'create-game-board-hexagon create-game-tharsis';
            }
            else if (boardName === BoardName_1.BoardName.HELLAS) {
                return 'create-game-board-hexagon create-game-hellas';
            }
            else if (boardName === BoardName_1.BoardName.ELYSIUM) {
                return 'create-game-board-hexagon create-game-elysium';
            }
            else if (boardName === BoardName_1.BoardName.ARABIA_TERRA) {
                return 'create-game-board-hexagon create-game-arabia-terra';
            }
            else {
                return 'create-game-board-hexagon create-game-random';
            }
        },
        getPlayerCubeColorClass: function (color) {
            return utils_1.playerColorClass(color.toLowerCase(), 'bg');
        },
        getPlayerContainerColorClass: function (color) {
            return utils_1.playerColorClass(color.toLowerCase(), 'bg_transparent');
        },
        serializeSettings: function () {
            var component = this;
            var players = component.players.slice(0, component.playersCount);
            if (component.randomFirstPlayer) {
                players = players.map(function (a) { return ({ sort: Math.random(), value: a }); })
                    .sort(function (a, b) { return a.sort - b.sort; })
                    .map(function (a) { return a.value; });
                component.firstIndex = Math.floor(component.seed * component.playersCount) + 1;
            }
            var uniqueColors = players.map(function (player) { return player.color; }).filter(function (v, i, a) { return a.indexOf(v) === i; });
            if (uniqueColors.length !== players.length) {
                var allColors_1 = [Color_1.Color.BLUE, Color_1.Color.RED, Color_1.Color.YELLOW, Color_1.Color.GREEN, Color_1.Color.BLACK, Color_1.Color.PURPLE, Color_1.Color.ORANGE, Color_1.Color.PINK];
                players.forEach(function (player) {
                    if (allColors_1.includes(player.color)) {
                        allColors_1.splice(allColors_1.indexOf(player.color), 1);
                    }
                    else {
                        player.color = allColors_1.shift();
                    }
                });
            }
            var isSoloMode = component.playersCount === 1;
            component.players.forEach(function (player) {
                if (player.name === '') {
                    if (isSoloMode) {
                        player.name = 'You';
                    }
                    else {
                        var defaultPlayerName = player.color.charAt(0).toUpperCase() + player.color.slice(1);
                        player.name = defaultPlayerName;
                    }
                }
            });
            players.map(function (player) {
                player.first = (component.firstIndex === player.index);
                return player;
            });
            var corporateEra = component.corporateEra;
            var prelude = component.prelude;
            var draftVariant = component.draftVariant;
            var initialDraft = component.initialDraft;
            var randomMA = component.randomMA;
            var showOtherPlayersVP = component.showOtherPlayersVP;
            var venusNext = component.venusNext;
            var colonies = component.colonies;
            var turmoil = component.turmoil;
            var solarPhaseOption = this.solarPhaseOption;
            var shuffleMapOption = this.shuffleMapOption;
            var customCorporationsList = component.customCorporationsList;
            var customColoniesList = component.customColoniesList;
            var cardsBlackList = component.cardsBlackList;
            var board = component.board;
            var seed = component.seed;
            var promoCardsOption = component.promoCardsOption;
            var communityCardsOption = component.communityCardsOption;
            var aresExtension = component.aresExtension;
            var politicalAgendasExtension = this.politicalAgendasExtension;
            var moonExpansion = component.moonExpansion;
            var undoOption = component.undoOption;
            var showTimers = component.showTimers;
            var fastModeOption = component.fastModeOption;
            var removeNegativeGlobalEventsOption = this.removeNegativeGlobalEventsOption;
            var includeVenusMA = component.includeVenusMA;
            var startingCorporations = component.startingCorporations;
            var soloTR = component.soloTR;
            var beginnerOption = component.beginnerOption;
            var randomFirstPlayer = component.randomFirstPlayer;
            var requiresVenusTrackCompletion = component.requiresVenusTrackCompletion;
            var clonedGamedId = undefined;
            if (customColoniesList.length > 0) {
                var playersCount = players.length;
                var neededColoniesCount = playersCount + 2;
                if (playersCount === 1) {
                    neededColoniesCount = 4;
                }
                else if (playersCount === 2) {
                    neededColoniesCount = 5;
                }
                if (customColoniesList.length < neededColoniesCount) {
                    window.alert(i18n_1.translateTextWithParams('Must select at least ${0} colonies', [neededColoniesCount.toString()]));
                    return;
                }
            }
            if (customCorporationsList.length > 0) {
                var neededCorpsCount = players.length * startingCorporations;
                if (customCorporationsList.length < neededCorpsCount) {
                    window.alert(i18n_1.translateTextWithParams('Must select at least ${0} corporations', [neededCorpsCount.toString()]));
                    return;
                }
            }
            if (component.clonedGameData !== undefined && component.seededGame) {
                clonedGamedId = component.clonedGameData.gameId;
                if (component.clonedGameData.playerCount !== players.length) {
                    window.alert(this.$t('Player count mismatch'));
                    this.$data.playersCount = component.clonedGameData.playerCount;
                    return;
                }
            }
            else if (!component.seededGame) {
                clonedGamedId = undefined;
            }
            var dataToSend = JSON.stringify({
                players: players,
                corporateEra: corporateEra,
                prelude: prelude,
                draftVariant: draftVariant,
                showOtherPlayersVP: showOtherPlayersVP,
                venusNext: venusNext,
                colonies: colonies,
                turmoil: turmoil,
                customCorporationsList: customCorporationsList,
                customColoniesList: customColoniesList,
                cardsBlackList: cardsBlackList,
                board: board,
                seed: seed,
                solarPhaseOption: solarPhaseOption,
                promoCardsOption: promoCardsOption,
                communityCardsOption: communityCardsOption,
                aresExtension: aresExtension,
                politicalAgendasExtension: politicalAgendasExtension,
                moonExpansion: moonExpansion,
                undoOption: undoOption,
                showTimers: showTimers,
                fastModeOption: fastModeOption,
                removeNegativeGlobalEventsOption: removeNegativeGlobalEventsOption,
                includeVenusMA: includeVenusMA,
                startingCorporations: startingCorporations,
                soloTR: soloTR,
                clonedGamedId: clonedGamedId,
                initialDraft: initialDraft,
                randomMA: randomMA,
                shuffleMapOption: shuffleMapOption,
                beginnerOption: beginnerOption,
                randomFirstPlayer: randomFirstPlayer,
                requiresVenusTrackCompletion: requiresVenusTrackCompletion,
                requiresMoonTrackCompletion: component.requiresMoonTrackCompletion,
                moonStandardProjectVariant: component.moonStandardProjectVariant,
                altVenusBoard: component.altVenusBoard,
            }, undefined, 4);
            return dataToSend;
        },
        createGame: function () {
            var _this = this;
            var dataToSend = this.serializeSettings();
            if (dataToSend === undefined)
                return;
            var onSucces = function (response) {
                if (response.players.length === 1) {
                    window.location.href = '/player?id=' + response.players[0].id;
                    return;
                }
                else {
                    window.history.replaceState(response, constants.APP_NAME + " - Game", '/game?id=' + response.id);
                    _this.$root.$data.game = response;
                    _this.$root.$data.screen = 'game-home';
                }
            };
            fetch('/game', { 'method': 'PUT', 'body': dataToSend, 'headers': { 'Content-Type': 'application/json' } })
                .then(function (response) { return response.json(); })
                .then(onSucces)
                .catch(function (_) { return alert('Unexpected server response'); });
        },
    },
});
