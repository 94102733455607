"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Party = void 0;
var Party = (function () {
    function Party() {
        this.partyLeader = undefined;
        this.delegates = [];
    }
    Party.prototype.sendDelegate = function (playerId, game) {
        this.delegates.push(playerId);
        this.checkPartyLeader(playerId, game);
    };
    Party.prototype.removeDelegate = function (playerId, game) {
        this.delegates.splice(this.delegates.indexOf(playerId), 1);
        this.checkPartyLeader(playerId, game);
    };
    Party.prototype.checkPartyLeader = function (newPlayer, game) {
        var _this = this;
        if (this.partyLeader) {
            if (game) {
                var sortedPlayers = __spreadArray([], this.getPresentPlayers()).sort(function (p1, p2) { return _this.getDelegates(p2) - _this.getDelegates(p1); });
                var max_1 = this.getDelegates(sortedPlayers[0]);
                if (this.getDelegates(this.partyLeader) !== max_1) {
                    var currentIndex = 0;
                    if (this.partyLeader === 'NEUTRAL') {
                        currentIndex = game.getPlayers().indexOf(game.getPlayerById(game.activePlayer));
                    }
                    else {
                        currentIndex = game.getPlayers().indexOf(game.getPlayerById(this.partyLeader));
                    }
                    var playersToCheck = [];
                    if (game.getPlayers().length === 1 || currentIndex === 0) {
                        playersToCheck = game.getPlayers();
                    }
                    else if (currentIndex === game.getPlayers().length - 1) {
                        playersToCheck = game.getPlayers().slice(0, currentIndex);
                        playersToCheck.unshift(game.getPlayers()[currentIndex]);
                    }
                    else {
                        var left = game.getPlayers().slice(0, currentIndex);
                        var right = game.getPlayers().slice(currentIndex);
                        playersToCheck = right.concat(left);
                    }
                    playersToCheck.push('NEUTRAL');
                    playersToCheck.some(function (nextPlayer) {
                        var nextPlayerId = '';
                        if (nextPlayer === 'NEUTRAL') {
                            nextPlayerId = 'NEUTRAL';
                        }
                        else {
                            nextPlayerId = nextPlayer.id;
                        }
                        if (_this.getDelegates(nextPlayerId) === max_1) {
                            _this.partyLeader = nextPlayerId;
                            return true;
                        }
                        return false;
                    });
                }
            }
        }
        else {
            this.partyLeader = newPlayer;
        }
    };
    Party.prototype.getPresentPlayers = function () {
        return Array.from(new Set(this.delegates));
    };
    Party.prototype.getDelegates = function (player) {
        var delegates = this.delegates.filter(function (p) { return p === player; }).length;
        return delegates;
    };
    return Party;
}());
exports.Party = Party;
