"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaTradeStation = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var MoonSpaces_1 = require("../../moon/MoonSpaces");
var TileType_1 = require("../../TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var LunaTradeStation = (function (_super) {
    __extends(LunaTradeStation, _super);
    function LunaTradeStation() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_TRADE_STATION,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.MOON, Tags_1.Tags.SPACE],
            cost: 10,
            reserveUnits: Units_1.Units.of({ titanium: 2 }),
            metadata: {
                description: 'Spend 2 titanium. Place this tile ON THE RESERVED AREA.',
                cardNumber: 'M13',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Gain 2 M€ for each colony tile on the Moon.', function (eb) {
                        return eb.empty().startAction.megacredits(2).slash().moonColony({ all: Options_1.all });
                    });
                    b.br.minus().titanium(2).tile(TileType_1.TileType.LUNA_TRADE_STATION, true).asterix();
                }),
            },
        }) || this;
    }
    ;
    LunaTradeStation.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.addTile(player, MoonSpaces_1.MoonSpaces.LUNA_TRADE_STATION, {
            tileType: TileType_1.TileType.LUNA_TRADE_STATION,
            card: this.name,
        });
        return undefined;
    };
    LunaTradeStation.prototype.surfaceColonyCount = function (game) {
        return MoonExpansion_1.MoonExpansion.tiles(game, TileType_1.TileType.MOON_COLONY, { surfaceOnly: true }).length;
    };
    LunaTradeStation.prototype.canAct = function (player) {
        return this.surfaceColonyCount(player.game) > 0;
    };
    LunaTradeStation.prototype.action = function (player) {
        var surfaceColonies = this.surfaceColonyCount(player.game);
        player.addResource(Resources_1.Resources.MEGACREDITS, 2 * surfaceColonies, { log: true });
        return undefined;
    };
    return LunaTradeStation;
}(MoonCard_1.MoonCard));
exports.LunaTradeStation = LunaTradeStation;
