"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UtopiaInvest = void 0;
var Tags_1 = require("../Tags");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var UtopiaInvest = (function (_super) {
    __extends(UtopiaInvest, _super);
    function UtopiaInvest() {
        return _super.call(this, {
            name: CardName_1.CardName.UTOPIA_INVEST,
            tags: [Tags_1.Tags.BUILDING],
            startingMegaCredits: 40,
            cardType: CardType_1.CardType.CORPORATION,
            productionBox: Units_1.Units.of({ steel: 1, titanium: 1 }),
            metadata: {
                cardNumber: 'R33',
                description: 'You start with 40 M€. Increase your steel and titanium production 1 step each.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(40).nbsp.production(function (pb) { return pb.steel(1).titanium(1); });
                    b.corpBox('action', function (ce) {
                        ce.action('Decrease any production to gain 4 resources of that kind.', function (eb) {
                            eb.production(function (eb) { return eb.wild(1); }).startAction.wild(4, { digit: Options_1.digit });
                        });
                    });
                }),
            },
        }) || this;
    }
    UtopiaInvest.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.STEEL, 1);
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        return undefined;
    };
    UtopiaInvest.prototype.canAct = function (player) {
        return player.getProduction(Resources_1.Resources.MEGACREDITS) +
            player.getProduction(Resources_1.Resources.STEEL) +
            player.getProduction(Resources_1.Resources.TITANIUM) +
            player.getProduction(Resources_1.Resources.PLANTS) +
            player.getProduction(Resources_1.Resources.ENERGY) +
            player.getProduction(Resources_1.Resources.HEAT) > -5;
    };
    UtopiaInvest.prototype.log = function (player, type) {
        player.game.log('${0} decreased ${1} production 1 step to gain 4 ${2}', function (b) { return b.player(player).string(type).string(type); });
    };
    UtopiaInvest.prototype.action = function (player) {
        var _this = this;
        var result = new OrOptions_1.OrOptions();
        result.title = 'Select production to decrease one step and gain 4 resources';
        var options = [];
        var reduceMegacredits = new SelectOption_1.SelectOption('Decrease M€ production', 'Decrease production', function () {
            player.addProduction(Resources_1.Resources.MEGACREDITS, -1);
            player.megaCredits += 4;
            _this.log(player, 'megacredit');
            return undefined;
        });
        var reduceSteel = new SelectOption_1.SelectOption('Decrease steel production', 'Decrease production', function () {
            player.addProduction(Resources_1.Resources.STEEL, -1);
            player.steel += 4;
            _this.log(player, 'steel');
            return undefined;
        });
        var reduceTitanium = new SelectOption_1.SelectOption('Decrease titanium production', 'Decrease production', function () {
            player.addProduction(Resources_1.Resources.TITANIUM, -1);
            player.titanium += 4;
            _this.log(player, 'titanium');
            return undefined;
        });
        var reducePlants = new SelectOption_1.SelectOption('Decrease plants production', 'Decrease production', function () {
            player.addProduction(Resources_1.Resources.PLANTS, -1);
            player.plants += 4;
            _this.log(player, 'plant');
            return undefined;
        });
        var reduceEnergy = new SelectOption_1.SelectOption('Decrease energy production', 'Decrease production', function () {
            player.addProduction(Resources_1.Resources.ENERGY, -1);
            player.energy += 4;
            _this.log(player, 'energy');
            return undefined;
        });
        var reduceHeat = new SelectOption_1.SelectOption('Decrease heat production', 'Decrease production', function () {
            player.addProduction(Resources_1.Resources.HEAT, -1);
            player.heat += 4;
            _this.log(player, 'heat');
            return undefined;
        });
        if (player.getProduction(Resources_1.Resources.MEGACREDITS) > -5) {
            options.push(reduceMegacredits);
        }
        if (player.getProduction(Resources_1.Resources.STEEL) > 0) {
            options.push(reduceSteel);
        }
        if (player.getProduction(Resources_1.Resources.TITANIUM) > 0) {
            options.push(reduceTitanium);
        }
        if (player.getProduction(Resources_1.Resources.PLANTS) > 0) {
            options.push(reducePlants);
        }
        if (player.getProduction(Resources_1.Resources.ENERGY) > 0) {
            options.push(reduceEnergy);
        }
        if (player.getProduction(Resources_1.Resources.HEAT) > 0) {
            options.push(reduceHeat);
        }
        result.options = options;
        return result;
    };
    return UtopiaInvest;
}(Card_1.Card));
exports.UtopiaInvest = UtopiaInvest;
