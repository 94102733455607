"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PlayerInputTypes_1 = require("@/PlayerInputTypes");
var AndOptions_vue_1 = require("@/client/components/AndOptions.vue");
var OrOptions_vue_1 = require("@/client/components/OrOptions.vue");
var SelectAmount_vue_1 = require("@/client/components/SelectAmount.vue");
var SelectCard_vue_1 = require("@/client/components/SelectCard.vue");
var SelectHowToPay_vue_1 = require("@/client/components/SelectHowToPay.vue");
var SelectHowToPayForProjectCard_vue_1 = require("@/client/components/SelectHowToPayForProjectCard.vue");
var SelectInitialCards_vue_1 = require("@/client/components/SelectInitialCards.vue");
var SelectOption_vue_1 = require("@/client/components/SelectOption.vue");
var SelectPlayer_vue_1 = require("@/client/components/SelectPlayer.vue");
var SelectSpace_vue_1 = require("@/client/components/SelectSpace.vue");
var SelectPartyPlayer_vue_1 = require("@/client/components/SelectPartyPlayer.vue");
var SelectPartyToSendDelegate_vue_1 = require("@/client/components/SelectPartyToSendDelegate.vue");
var SelectColony_vue_1 = require("@/client/components/SelectColony.vue");
var SelectProductionToLose_vue_1 = require("@/client/components/SelectProductionToLose.vue");
var ShiftAresGlobalParameters_vue_1 = require("@/client/components/ShiftAresGlobalParameters.vue");
exports.default = vue_1.default.component('player-input-factory', {
    props: {
        players: {
            type: Array,
        },
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        'and-options': AndOptions_vue_1.default,
        'or-options': OrOptions_vue_1.default,
        'select-amount': SelectAmount_vue_1.default,
        SelectCard: SelectCard_vue_1.default,
        'select-option': SelectOption_vue_1.default,
        SelectHowToPay: SelectHowToPay_vue_1.default,
        SelectHowToPayForProjectCard: SelectHowToPayForProjectCard_vue_1.default,
        SelectInitialCards: SelectInitialCards_vue_1.default,
        'select-player': SelectPlayer_vue_1.default,
        'select-space': SelectSpace_vue_1.default,
        'select-party-player': SelectPartyPlayer_vue_1.default,
        'select-party-to-send-delegate': SelectPartyToSendDelegate_vue_1.default,
        'select-colony': SelectColony_vue_1.default,
        SelectProductionToLose: SelectProductionToLose_vue_1.default,
        ShiftAresGlobalParameters: ShiftAresGlobalParameters_vue_1.default,
    },
    methods: {
        saveData: function () {
            this.$children[0].saveData();
        },
        canSave: function () {
            var canSave = this.$children[0].canSave;
            return canSave ? canSave() : true;
        },
        getComponentName: function (inputType) {
            switch (inputType) {
                case PlayerInputTypes_1.PlayerInputTypes.AND_OPTIONS:
                    return 'and-options';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_CARD:
                    return 'SelectCard';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_HOW_TO_PAY_FOR_PROJECT_CARD:
                    return 'SelectHowToPayForProjectCard';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_INITIAL_CARDS:
                    return 'SelectInitialCards';
                case PlayerInputTypes_1.PlayerInputTypes.OR_OPTIONS:
                    return 'or-options';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_OPTION:
                    return 'select-option';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_HOW_TO_PAY:
                    return 'SelectHowToPay';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_SPACE:
                    return 'select-space';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_PLAYER:
                    return 'select-player';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_AMOUNT:
                    return 'select-amount';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_DELEGATE:
                    return 'select-party-player';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_PARTY_TO_SEND_DELEGATE:
                    return 'select-party-to-send-delegate';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_COLONY:
                    return 'select-colony';
                case PlayerInputTypes_1.PlayerInputTypes.SELECT_PRODUCTION_TO_LOSE:
                    return 'select-production-to-lose';
                case PlayerInputTypes_1.PlayerInputTypes.SHIFT_ARES_GLOBAL_PARAMETERS:
                    return 'shift-ares-global-parameters';
                default:
                    throw 'Unsupported input type: ' + inputType;
            }
        },
    },
});
