"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectPartyToSendDelegate = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectPartyToSendDelegate = (function () {
    function SelectPartyToSendDelegate(title, buttonLabel, availableParties, cb) {
        if (buttonLabel === void 0) { buttonLabel = 'Send delegate'; }
        this.title = title;
        this.buttonLabel = buttonLabel;
        this.availableParties = availableParties;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_PARTY_TO_SEND_DELEGATE;
    }
    return SelectPartyToSendDelegate;
}());
exports.SelectPartyToSendDelegate = SelectPartyToSendDelegate;
