"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALL_PRELUDE_CARD_NAMES = exports.ALL_CORPORATION_CARD_NAMES = exports.ALL_STANDARD_PROJECT_CARD_NAMES = exports.ALL_PROJECT_CARD_NAMES = exports.ALL_STANDARD_PROJECT_DECKS = exports.ALL_PRELUDE_DECKS = exports.ALL_PROJECT_DECKS = exports.MANIFEST_BY_MODULE = exports.ALL_CARD_MANIFESTS = void 0;
var AresCardManifest_1 = require("./ares/AresCardManifest");
var ColoniesCardManifest_1 = require("./colonies/ColoniesCardManifest");
var CommunityCardManifest_1 = require("./community/CommunityCardManifest");
var PreludeCardManifest_1 = require("./prelude/PreludeCardManifest");
var PromoCardManifest_1 = require("./promo/PromoCardManifest");
var StandardCardManifests_1 = require("./StandardCardManifests");
var TurmoilCardManifest_1 = require("./turmoil/TurmoilCardManifest");
var VenusCardManifest_1 = require("./venusNext/VenusCardManifest");
var MoonCardManifest_1 = require("./moon/MoonCardManifest");
var PathfindersCardManifest_1 = require("./pathfinders/PathfindersCardManifest");
exports.ALL_CARD_MANIFESTS = [
    StandardCardManifests_1.BASE_CARD_MANIFEST,
    StandardCardManifests_1.CORP_ERA_CARD_MANIFEST,
    PromoCardManifest_1.PROMO_CARD_MANIFEST,
    VenusCardManifest_1.VENUS_CARD_MANIFEST,
    ColoniesCardManifest_1.COLONIES_CARD_MANIFEST,
    PreludeCardManifest_1.PRELUDE_CARD_MANIFEST,
    TurmoilCardManifest_1.TURMOIL_CARD_MANIFEST,
    CommunityCardManifest_1.COMMUNITY_CARD_MANIFEST,
    AresCardManifest_1.ARES_CARD_MANIFEST,
    MoonCardManifest_1.MOON_CARD_MANIFEST,
    PathfindersCardManifest_1.PATHFINDERS_CARD_MANIFEST,
];
function allCardNames(decks) {
    var cardNames = [];
    for (var _i = 0, decks_1 = decks; _i < decks_1.length; _i++) {
        var deck = decks_1[_i];
        deck.factories.forEach(function (cf) { return cardNames.push(cf.cardName); });
    }
    return cardNames;
}
exports.MANIFEST_BY_MODULE = new Map(exports.ALL_CARD_MANIFESTS.map(function (manifest) { return [manifest.module, manifest]; }));
exports.ALL_PROJECT_DECKS = exports.ALL_CARD_MANIFESTS.map(function (deck) { return deck.projectCards; });
var ALL_CORPORATION_DECKS = exports.ALL_CARD_MANIFESTS.map(function (deck) { return deck.corporationCards; });
exports.ALL_PRELUDE_DECKS = exports.ALL_CARD_MANIFESTS.map(function (deck) { return deck.preludeCards; });
exports.ALL_STANDARD_PROJECT_DECKS = exports.ALL_CARD_MANIFESTS.map(function (deck) { return deck.standardProjects; });
exports.ALL_PROJECT_CARD_NAMES = allCardNames(exports.ALL_PROJECT_DECKS);
exports.ALL_STANDARD_PROJECT_CARD_NAMES = allCardNames(exports.ALL_STANDARD_PROJECT_DECKS);
exports.ALL_CORPORATION_CARD_NAMES = allCardNames(ALL_CORPORATION_DECKS);
exports.ALL_PRELUDE_CARD_NAMES = allCardNames(exports.ALL_PRELUDE_DECKS);
