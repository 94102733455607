"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Callisto_1 = require("@/colonies/Callisto");
var Ceres_1 = require("@/colonies/Ceres");
var Europa_1 = require("@/colonies/Europa");
var Ganymede_1 = require("@/colonies/Ganymede");
var Io_1 = require("@/colonies/Io");
var Luna_1 = require("@/colonies/Luna");
var Miranda_1 = require("@/colonies/Miranda");
var Pluto_1 = require("@/colonies/Pluto");
var Titan_1 = require("@/colonies/Titan");
var Triton_1 = require("@/colonies/Triton");
var Enceladus_1 = require("@/colonies/Enceladus");
var ColonyName_1 = require("@/colonies/ColonyName");
var Iapetus_1 = require("@/cards/community/Iapetus");
var Mercury_1 = require("@/cards/community/Mercury");
var Hygiea_1 = require("@/cards/community/Hygiea");
var Titania_1 = require("@/cards/community/Titania");
var Venus_1 = require("@/cards/community/Venus");
var Leavitt_1 = require("@/cards/community/Leavitt");
var Pallas_1 = require("@/cards/community/Pallas");
var OFFICIAL_COLONIES = [
    new Callisto_1.Callisto(),
    new Ceres_1.Ceres(),
    new Enceladus_1.Enceladus(),
    new Europa_1.Europa(),
    new Ganymede_1.Ganymede(),
    new Io_1.Io(),
    new Luna_1.Luna(),
    new Miranda_1.Miranda(),
    new Pluto_1.Pluto(),
    new Titan_1.Titan(),
    new Triton_1.Triton(),
];
var COMMUNITY_COLONIES = [
    new Iapetus_1.Iapetus(),
    new Mercury_1.Mercury(),
    new Hygiea_1.Hygiea(),
    new Titania_1.Titania(),
    new Leavitt_1.Leavitt(),
    new Venus_1.Venus(),
    new Pallas_1.Pallas(),
];
exports.default = vue_1.default.extend({
    name: 'ColoniesFilter',
    props: {
        communityCardsOption: {
            type: Boolean,
        },
        venusNext: {
            type: Boolean,
        },
        turmoil: {
            type: Boolean,
        },
    },
    data: function () {
        return {
            allColonies: OFFICIAL_COLONIES.concat(COMMUNITY_COLONIES),
            officialColonies: OFFICIAL_COLONIES,
            communityColonies: COMMUNITY_COLONIES,
            selectedColonies: __spreadArray(__spreadArray([], OFFICIAL_COLONIES), this.communityCardsOption ? COMMUNITY_COLONIES : []),
        };
    },
    methods: {
        updateColoniesByNames: function (colonyNames) {
            this.selectedColonies = [];
            for (var _i = 0, _a = this.allColonies; _i < _a.length; _i++) {
                var colony = _a[_i];
                if (colonyNames.includes(colony.name)) {
                    this.selectedColonies.push(colony);
                }
            }
        },
    },
    watch: {
        selectedColonies: function (value) {
            var colonyNames = [];
            value.forEach(function (el) {
                colonyNames.push(el.name);
            });
            this.$emit('colonies-list-changed', colonyNames);
        },
        communityCardsOption: function (enabled) {
            if (enabled) {
                this.selectedColonies = OFFICIAL_COLONIES.concat(COMMUNITY_COLONIES).slice();
                if (this.venusNext === false)
                    this.selectedColonies = this.selectedColonies.filter(function (c) { return c.name !== ColonyName_1.ColonyName.VENUS; });
                if (this.turmoil === false)
                    this.selectedColonies = this.selectedColonies.filter(function (c) { return c.name !== ColonyName_1.ColonyName.PALLAS; });
            }
            else {
                this.selectedColonies = OFFICIAL_COLONIES.slice();
            }
        },
        venusNext: function (enabled) {
            if (this.communityCardsOption && Array.isArray(this.selectedColonies)) {
                if (enabled === false) {
                    this.selectedColonies = this.selectedColonies.filter(function (c) { return c.name !== ColonyName_1.ColonyName.VENUS; });
                }
                else if (this.selectedColonies.find(function (c) { return c.name === ColonyName_1.ColonyName.VENUS; }) === undefined) {
                    this.selectedColonies.push(new Venus_1.Venus());
                }
            }
        },
        turmoil: function (enabled) {
            if (this.communityCardsOption && Array.isArray(this.selectedColonies)) {
                if (enabled === false) {
                    this.selectedColonies = this.selectedColonies.filter(function (c) { return c.name !== ColonyName_1.ColonyName.PALLAS; });
                }
                else if (this.selectedColonies.find(function (c) { return c.name === ColonyName_1.ColonyName.PALLAS; }) === undefined) {
                    this.selectedColonies.push(new Pallas_1.Pallas());
                }
            }
        },
    },
});
