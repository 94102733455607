"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Award_vue_1 = require("@/client/components/Award.vue");
var constants_1 = require("@/constants");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'Awards',
    components: { Award: Award_vue_1.default },
    props: {
        awards: {
            type: Array,
            required: true,
        },
        showScores: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            showAwards: true,
            PreferencesManager: PreferencesManager_1.PreferencesManager,
        };
    },
    methods: {
        toggleList: function () {
            this.showAwards = !this.showAwards;
        },
    },
    computed: {
        fundedAwards: function () {
            var isFunded = function (award) { return !!award.player_name; };
            return this.awards.filter(isFunded);
        },
        availableAwardSpots: function () {
            return constants_1.AWARD_COSTS.slice(this.fundedAwards.length);
        },
        isLearnerModeOn: function () {
            return this.PreferencesManager.loadBoolean('learner_mode');
        },
    },
});
