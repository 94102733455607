"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsteroidMining = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.titanium(1).slash().jovian({ played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var AsteroidMining = (function () {
    function AsteroidMining() {
        this.name = GlobalEventName_1.GlobalEventName.ASTEROID_MINING;
        this.description = 'Gain 1 titanium for each Jovian tag (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.REDS;
        this.currentDelegate = PartyName_1.PartyName.UNITY;
        this.renderData = RENDER_DATA;
    }
    AsteroidMining.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.TITANIUM, Math.min(5, player.getTagCount(Tags_1.Tags.JOVIAN, false, false)) + turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    return AsteroidMining;
}());
exports.AsteroidMining = AsteroidMining;
