"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolarFlare = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().megacredits(3).slash().space({ played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var SolarFlare = (function () {
    function SolarFlare() {
        this.name = GlobalEventName_1.GlobalEventName.SOLAR_FLARE;
        this.description = 'Lose 3 M€ for each space tag (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.UNITY;
        this.currentDelegate = PartyName_1.PartyName.KELVINISTS;
        this.renderData = RENDER_DATA;
    }
    SolarFlare.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.min(5, player.getTagCount(Tags_1.Tags.SPACE, false, false)) - turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.addResource(Resources_1.Resources.MEGACREDITS, amount * -3, { log: true, from: _this.name });
            }
        });
    };
    return SolarFlare;
}());
exports.SolarFlare = SolarFlare;
