"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NitrogenRichAsteroid = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var NitrogenRichAsteroid = (function (_super) {
    __extends(NitrogenRichAsteroid, _super);
    function NitrogenRichAsteroid() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.NITROGEN_RICH_ASTEROID,
            tags: [Tags_1.Tags.SPACE],
            cost: 31,
            tr: { tr: 2, temperature: 1 },
            metadata: {
                cardNumber: '037',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.plants(1).nbsp.or().br;
                        pb.plants(3, { played: Options_1.played, digit: Options_1.digit }).colon().nbsp.plants(4, { digit: Options_1.digit });
                    }).br;
                    b.tr(2).temperature(1);
                }),
                description: 'Raise your terraforming rating 2 steps and temperature 1 step. Increase your Plant production 1 step, or 4 steps if you have 3 Plant tags.',
            },
        }) || this;
    }
    NitrogenRichAsteroid.prototype.play = function (player) {
        player.increaseTerraformRatingSteps(2);
        if (player.getTagCount(Tags_1.Tags.PLANT) < 3) {
            player.addProduction(Resources_1.Resources.PLANTS, 1, { log: true });
        }
        else {
            player.addProduction(Resources_1.Resources.PLANTS, 4, { log: true });
        }
        return player.game.increaseTemperature(player, 1);
    };
    return NitrogenRichAsteroid;
}(Card_1.Card));
exports.NitrogenRichAsteroid = NitrogenRichAsteroid;
