"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardManifest = void 0;
var Deck_1 = require("../Deck");
var CardManifest = (function () {
    function CardManifest(arg) {
        this.module = arg.module;
        this.projectCards = new Deck_1.Deck(arg.projectCards || []);
        this.cardsToRemove = new Set(arg.cardsToRemove || []);
        this.corporationCards = new Deck_1.Deck(arg.corporationCards || []);
        this.preludeCards = new Deck_1.Deck(arg.preludeCards || []);
        this.standardProjects = new Deck_1.Deck(arg.standardProjects || []);
        this.standardActions = new Deck_1.Deck(arg.standardActions || []);
    }
    return CardManifest;
}());
exports.CardManifest = CardManifest;
