"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SponsoredProjects = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.cards(1).text('w/rsrs').colon().wild(1).br.cards(1).slash().influence();
});
var SponsoredProjects = (function () {
    function SponsoredProjects() {
        this.name = GlobalEventName_1.GlobalEventName.SPONSORED_PROJECTS;
        this.description = 'All cards with resources on them gain 1 resource. Draw 1 card for each influence.';
        this.revealedDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.currentDelegate = PartyName_1.PartyName.GREENS;
        this.renderData = RENDER_DATA;
    }
    SponsoredProjects.prototype.resolve = function (game, turmoil) {
        game.getPlayers().forEach(function (player) {
            player.getCardsWithResources().forEach(function (card) { return card.resourceCount && player.addResourceTo(card); });
            player.drawCard(turmoil.getPlayerInfluence(player));
        });
    };
    return SponsoredProjects;
}());
exports.SponsoredProjects = SponsoredProjects;
