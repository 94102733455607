"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var constants_1 = require("@/constants");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'Milestone',
    props: {
        milestones_list: {
            type: Array,
        },
        show_scores: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        var showDescription = {};
        for (var _i = 0, _a = this.milestones_list; _i < _a.length; _i++) {
            var milestone = _a[_i];
            showDescription[milestone.milestone.name] = false;
        }
        return {
            showList: this.milestones_list.filter(function (milestone) { return milestone.player_name; }).length === constants_1.MAX_MILESTONES ? false : true,
            showDescription: showDescription,
        };
    },
    methods: {
        getNameCss: function (milestoneName) {
            return ('ma-name ma-name--' + milestoneName.replace(/ /g, '-').toLowerCase());
        },
        shouldShow: function (milestone) {
            return this.showDescription[milestone.milestone.name] === true;
        },
        shouldShowList: function () {
            return this.showList;
        },
        toggle: function (milestone) {
            this.showDescription[milestone.milestone.name] = !this.showDescription[milestone.milestone.name];
        },
        toggleList: function () {
            this.showList = !this.showList;
        },
        getAvailableMilestoneSpots: function () {
            var count = this.milestones_list.filter(function (milestone) { return milestone.player_name; }).length;
            return Array(constants_1.MAX_MILESTONES - count).fill(constants_1.MILESTONE_COST);
        },
        isLearnerModeOn: function () {
            return PreferencesManager_1.PreferencesManager.load('learner_mode') === '1';
        },
    },
});
