"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitaniumExtractionCenter = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var TitaniumExtractionCenter = (function (_super) {
    __extends(TitaniumExtractionCenter, _super);
    function TitaniumExtractionCenter() {
        return _super.call(this, {
            name: CardName_1.CardName.TITANIUM_EXTRACTION_CENTER,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.BUILDING],
            cost: 14,
            reserveUnits: Units_1.Units.of({ titanium: 2 }),
            metadata: {
                description: 'Spend 2 titanium. Increase your titanium production 1 step for every 2 raised steps of Mining Rate.',
                cardNumber: 'M26',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(2).br;
                    b.production(function (pb) { return pb.titanium(1); }).slash().moonMiningRate({ amount: 2 });
                }),
            },
        }) || this;
    }
    TitaniumExtractionCenter.prototype.produce = function (player) {
        var miningRate = MoonExpansion_1.MoonExpansion.moonData(player.game).miningRate;
        var productionIncrease = Math.floor(miningRate / 2);
        player.addProduction(Resources_1.Resources.TITANIUM, productionIncrease, { log: true });
    };
    TitaniumExtractionCenter.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        this.produce(player);
        return undefined;
    };
    return TitaniumExtractionCenter;
}(MoonCard_1.MoonCard));
exports.TitaniumExtractionCenter = TitaniumExtractionCenter;
