"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var LanguageSwitcher_vue_1 = require("@/client/components/LanguageSwitcher.vue");
exports.default = vue_1.default.extend({
    name: 'start-screen',
    props: {
        version: {
            type: String,
        },
    },
    components: {
        LanguageSwitcher: LanguageSwitcher_vue_1.default,
    },
    methods: {
        getAppVersion: function () {
            var versionParts = this.version.split(' ');
            return versionParts[0];
        },
        getAppDate: function () {
            var versionParts = this.version.split(' ');
            if (versionParts.length > 1) {
                return versionParts.slice(1).join(' ');
            }
            return '';
        },
    },
});
