"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRowComponent_vue_1 = require("@/client/components/card/CardRowComponent.vue");
exports.default = vue_1.default.extend({
    name: 'CardRowData',
    props: {
        rowData: {
            type: Array,
            required: true,
        },
    },
    components: {
        CardRowComponent: CardRowComponent_vue_1.default,
    },
});
