"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Riots = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Board_1 = require("../../boards/Board");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().megacredits(4).slash().city().influence({ size: Size_1.Size.SMALL });
});
var Riots = (function () {
    function Riots() {
        this.name = GlobalEventName_1.GlobalEventName.RIOTS;
        this.description = 'Lose 4 M€ for each City tile (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.MARS;
        this.currentDelegate = PartyName_1.PartyName.REDS;
        this.renderData = RENDER_DATA;
    }
    Riots.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var city = game.board.spaces.filter(function (space) { return Board_1.Board.isCitySpace(space) &&
                space.player === player; }).length;
            var amount = Math.min(5, city) - turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.deductResource(Resources_1.Resources.MEGACREDITS, 4 * amount, { log: true, from: _this.name });
            }
        });
    };
    return Riots;
}());
exports.Riots = Riots;
