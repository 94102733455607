"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectDelegate = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectDelegate = (function () {
    function SelectDelegate(players, title, cb) {
        this.players = players;
        this.title = title;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_DELEGATE;
        this.buttonLabel = 'Save';
    }
    return SelectDelegate;
}());
exports.SelectDelegate = SelectDelegate;
