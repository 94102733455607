"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PowerPlantStandardProject = void 0;
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var StandardProjectCard_1 = require("../../StandardProjectCard");
var Resources_1 = require("../../../Resources");
var PowerPlantStandardProject = (function (_super) {
    __extends(PowerPlantStandardProject, _super);
    function PowerPlantStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.POWER_PLANT_STANDARD_PROJECT,
            cost: 11,
            metadata: {
                cardNumber: 'SP7',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 11 M€ to increase your Energy production 1 step.', function (eb) {
                        eb.megacredits(11).startAction.production(function (pb) {
                            pb.energy(1);
                        });
                    });
                }),
            },
        }) || this;
    }
    PowerPlantStandardProject.prototype.discount = function (player) {
        if (player.isCorporation(CardName_1.CardName.THORGATE)) {
            return 3;
        }
        return _super.prototype.discount.call(this, player);
    };
    PowerPlantStandardProject.prototype.actionEssence = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 1);
    };
    return PowerPlantStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.PowerPlantStandardProject = PowerPlantStandardProject;
