"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardName_1 = require("@/CardName");
var AllCards_1 = require("@/cards/AllCards");
var GameModule_1 = require("@/GameModule");
function corpCardNames(module) {
    var manifest = AllCards_1.MANIFEST_BY_MODULE.get(module);
    if (manifest === undefined) {
        console.log('manifest %s not found', manifest);
        return [];
    }
    else {
        var cards_1 = [];
        manifest.corporationCards.factories.forEach(function (cf) {
            if (cf.cardName !== CardName_1.CardName.BEGINNER_CORPORATION) {
                cards_1.push(cf.cardName);
            }
        });
        return cards_1;
    }
}
var allItems = [];
AllCards_1.ALL_CARD_MANIFESTS.forEach(function (manifest) {
    manifest.corporationCards.factories.forEach(function (cf) {
        allItems.push(cf.cardName);
    });
});
exports.default = vue_1.default.extend({
    name: 'CorporationsFilter',
    props: {
        corporateEra: {
            type: Boolean,
        },
        prelude: {
            type: Boolean,
        },
        venusNext: {
            type: Boolean,
        },
        colonies: {
            type: Boolean,
        },
        turmoil: {
            type: Boolean,
        },
        promoCardsOption: {
            type: Boolean,
        },
        communityCardsOption: {
            type: Boolean,
        },
        moonExpansion: {
            type: Boolean,
        },
    },
    data: function () {
        var cardsByModuleMap = new Map(AllCards_1.ALL_CARD_MANIFESTS.map(function (m) { return [m.module, corpCardNames(m.module)]; }));
        return {
            cardsByModuleMap: cardsByModuleMap,
            customCorporationsList: false,
            selectedCorporations: __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], cardsByModuleMap.get(GameModule_1.GameModule.Base)), this.corporateEra ? cardsByModuleMap.get(GameModule_1.GameModule.CorpEra) : []), this.prelude ? cardsByModuleMap.get(GameModule_1.GameModule.Prelude) : []), this.venusNext ? cardsByModuleMap.get(GameModule_1.GameModule.Venus) : []), this.colonies ? cardsByModuleMap.get(GameModule_1.GameModule.Colonies) : []), this.turmoil ? cardsByModuleMap.get(GameModule_1.GameModule.Turmoil) : []), this.promoCardsOption ? cardsByModuleMap.get(GameModule_1.GameModule.Promo) : []), this.communityCardsOption ? cardsByModuleMap.get(GameModule_1.GameModule.Community) : []), this.moonExpansion ? cardsByModuleMap.get(GameModule_1.GameModule.Moon) : []),
            corpsByModule: Array.from(cardsByModuleMap),
        };
    },
    methods: {
        getSelected: function () {
            if (Array.isArray(this.selectedCorporations)) {
                return this.selectedCorporations;
            }
            console.warn('unexpectedly got boolean for selectedCorporations');
            return [];
        },
        getItemsByGroup: function (group) {
            if (group === 'All')
                return allItems.slice();
            var corps = this.cardsByModuleMap.get(group);
            if (corps === undefined) {
                console.log('module %s not found', group);
                return [];
            }
            else {
                return corps.slice();
            }
        },
        selectAll: function (group) {
            var items = this.getItemsByGroup(group);
            for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                var item = items_1[_i];
                if (this.getSelected().includes(item) === false) {
                    this.getSelected().push(item);
                }
            }
        },
        removeFromSelection: function (cardName) {
            var itemIdx = this.getSelected().indexOf(cardName);
            if (itemIdx !== -1) {
                this.getSelected().splice(itemIdx, 1);
            }
        },
        selectNone: function (group) {
            var items = this.getItemsByGroup(group);
            for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                var item = items_2[_i];
                this.removeFromSelection(item);
            }
        },
        invertSelection: function (group) {
            var items = this.getItemsByGroup(group);
            for (var idx in items) {
                if (this.getSelected().includes(items[idx])) {
                    this.removeFromSelection(items[idx]);
                }
                else {
                    this.getSelected().push(items[idx]);
                }
            }
        },
    },
    watch: {
        selectedCorporations: function (value) {
            this.$emit('corporation-list-changed', value);
        },
        corporateEra: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.CorpEra) : this.selectNone(GameModule_1.GameModule.CorpEra);
        },
        prelude: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Prelude) : this.selectNone(GameModule_1.GameModule.Prelude);
        },
        venusNext: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Venus) : this.selectNone(GameModule_1.GameModule.Venus);
        },
        colonies: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Colonies) : this.selectNone(GameModule_1.GameModule.Colonies);
        },
        turmoil: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Turmoil) : this.selectNone(GameModule_1.GameModule.Turmoil);
        },
        promoCardsOption: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Promo) : this.selectNone(GameModule_1.GameModule.Promo);
        },
        communityCardsOption: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Community) : this.selectNone(GameModule_1.GameModule.Community);
        },
        moonExpansion: function (enabled) {
            enabled ? this.selectAll(GameModule_1.GameModule.Moon) : this.selectNone(GameModule_1.GameModule.Moon);
        },
    },
});
