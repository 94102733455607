"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunarExports = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var LunarExports = (function (_super) {
    __extends(LunarExports, _super);
    function LunarExports() {
        return _super.call(this, {
            cost: 19,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            name: CardName_1.CardName.LUNAR_EXPORTS,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                cardNumber: 'C21',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.plants(2).or(Size_1.Size.SMALL).megacredits(5);
                    });
                }),
                description: 'Increase your plant production 2 steps, or your M€ production 5 steps.',
            },
        }) || this;
    }
    LunarExports.prototype.play = function (player) {
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Increase your M€ production by 5', 'Increase +MC', function () {
            player.addProduction(Resources_1.Resources.MEGACREDITS, 5, { log: true });
            return undefined;
        }), new SelectOption_1.SelectOption('Increase your plant production by 2', 'Increase +plants', function () {
            player.addProduction(Resources_1.Resources.PLANTS, 2, { log: true });
            return undefined;
        }));
    };
    return LunarExports;
}(Card_1.Card));
exports.LunarExports = LunarExports;
