"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinorityRefuge = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var ColonyName_1 = require("../../colonies/ColonyName");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var MinorityRefuge = (function (_super) {
    __extends(MinorityRefuge, _super);
    function MinorityRefuge() {
        return _super.call(this, {
            cost: 5,
            tags: [Tags_1.Tags.SPACE],
            name: CardName_1.CardName.MINORITY_REFUGE,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                cardNumber: 'C26',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(-2); }).colonies(1);
                }),
                description: 'Decrease your M€ production 2 steps. Place a colony.',
            },
        }) || this;
    }
    MinorityRefuge.prototype.canPlay = function (player) {
        if (player.hasAvailableColonyTileToBuildOn() === false) {
            return false;
        }
        var megaCreditsProduction = player.getProduction(Resources_1.Resources.MEGACREDITS);
        if (megaCreditsProduction === -4 && player.isCorporation(CardName_1.CardName.POSEIDON)) {
            return true;
        }
        else if (megaCreditsProduction <= -4) {
            var lunaIsAvailable = player.game.colonies.some(function (colony) {
                return colony.name === ColonyName_1.ColonyName.LUNA &&
                    colony.isColonyFull() === false &&
                    colony.colonies.includes(player.id) === false;
            });
            if (lunaIsAvailable === false) {
                return false;
            }
            this.warning = 'You will only be able to build the colony on Luna.';
        }
        return true;
    };
    MinorityRefuge.prototype.play = function (player) {
        var openColonies = player.getProduction(Resources_1.Resources.MEGACREDITS) <= -4 ?
            player.game.colonies.filter(function (colony) { return colony.name === ColonyName_1.ColonyName.LUNA; }) :
            undefined;
        player.game.defer(new BuildColony_1.BuildColony(player, false, 'Select colony for Minority Refuge', openColonies));
        player.addProduction(Resources_1.Resources.MEGACREDITS, -2);
        return undefined;
    };
    return MinorityRefuge;
}(Card_1.Card));
exports.MinorityRefuge = MinorityRefuge;
