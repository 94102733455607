"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfReplicatingRobots = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var SelectCard_1 = require("../../inputs/SelectCard");
var OrOptions_1 = require("../../inputs/OrOptions");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Size_1 = require("../render/Size");
var SelfReplicatingRobots = (function (_super) {
    __extends(SelfReplicatingRobots, _super);
    function SelfReplicatingRobots() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.SELF_REPLICATING_ROBOTS,
            cost: 7,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 2); }),
            metadata: {
                cardNumber: '210',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Reveal and place a SPACE OR BUILDING card here from hand, and place 2 resources on it, OR double the resources on a card here.', function (eb) {
                        eb.empty().startAction.selfReplicatingRobots();
                        eb.nbsp.or().nbsp.arrow().multiplierWhite().text('x2');
                    }).br;
                    b.text('Effect: Card here may be played as if from hand with its cost reduced by the number of resources on it.', Size_1.Size.TINY, true);
                }),
                description: 'Requires 2 Science tags.',
            },
        }) || this;
        _this.targetCards = [];
        return _this;
    }
    SelfReplicatingRobots.prototype.getCardDiscount = function (_player, card) {
        for (var _i = 0, _a = this.targetCards; _i < _a.length; _i++) {
            var targetCard = _a[_i];
            if (targetCard.card.name === card.name) {
                return targetCard.resourceCount;
            }
        }
        return 0;
    };
    SelfReplicatingRobots.prototype.play = function () {
        return undefined;
    };
    SelfReplicatingRobots.prototype.canAct = function (player) {
        return this.targetCards.length > 0 ||
            player.cardsInHand.some(function (card) { return card.tags.some(function (tag) { return tag === Tags_1.Tags.SPACE || tag === Tags_1.Tags.BUILDING; }); });
    };
    SelfReplicatingRobots.prototype.action = function (player) {
        var _this = this;
        var orOptions = new OrOptions_1.OrOptions();
        var selectableCards = player.cardsInHand.filter(function (card) { return card.tags.some(function (tag) { return tag === Tags_1.Tags.SPACE || tag === Tags_1.Tags.BUILDING; }); });
        if (this.targetCards.length > 0) {
            var robotCards = this.targetCards.map(function (targetCard) { return targetCard.card; });
            orOptions.options.push(new SelectCard_1.SelectCard('Select card to double robots resource', 'Double resource', robotCards, function (foundCards) {
                var resourceCount = 0;
                for (var _i = 0, _a = _this.targetCards; _i < _a.length; _i++) {
                    var targetCard = _a[_i];
                    if (targetCard.card.name === foundCards[0].name) {
                        resourceCount = targetCard.resourceCount;
                        targetCard.resourceCount *= 2;
                    }
                }
                player.game.log('${0} doubled resources on ${1} from ${2} to ${3}', function (b) {
                    b.player(player).card(foundCards[0]).number(resourceCount).number(resourceCount * 2);
                });
                return undefined;
            }));
        }
        if (selectableCards.length > 0) {
            orOptions.options.push(new SelectCard_1.SelectCard('Select card to link with Self-Replicating Robots', 'Link card', selectableCards, function (foundCards) {
                var projectCardIndex = player.cardsInHand.findIndex(function (card) { return card.name === foundCards[0].name; });
                player.cardsInHand.splice(projectCardIndex, 1);
                _this.targetCards.push({
                    card: foundCards[0],
                    resourceCount: 2,
                });
                player.game.log('${0} linked ${1} with ${2}', function (b) { return b.player(player).card(foundCards[0]).card(_this); });
                return undefined;
            }));
        }
        return orOptions;
    };
    return SelfReplicatingRobots;
}(Card_1.Card));
exports.SelfReplicatingRobots = SelfReplicatingRobots;
