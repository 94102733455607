"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectCard = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectCard = (function () {
    function SelectCard(title, buttonLabel, cards, cb, maxCardsToSelect, minCardsToSelect, selectBlueCardAction, enabled, played, showOwner) {
        if (buttonLabel === void 0) { buttonLabel = 'Save'; }
        if (maxCardsToSelect === void 0) { maxCardsToSelect = 1; }
        if (minCardsToSelect === void 0) { minCardsToSelect = 1; }
        if (selectBlueCardAction === void 0) { selectBlueCardAction = false; }
        if (played === void 0) { played = true; }
        this.title = title;
        this.buttonLabel = buttonLabel;
        this.cards = cards;
        this.cb = cb;
        this.maxCardsToSelect = maxCardsToSelect;
        this.minCardsToSelect = minCardsToSelect;
        this.selectBlueCardAction = selectBlueCardAction;
        this.enabled = enabled;
        this.played = played;
        this.showOwner = showOwner;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_CARD;
        this.buttonLabel = buttonLabel;
    }
    return SelectCard;
}());
exports.SelectCard = SelectCard;
