"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LavaFlowsAres = void 0;
var CardName_1 = require("../../CardName");
var SpaceBonus_1 = require("../../SpaceBonus");
var LavaFlows_1 = require("../base/LavaFlows");
var TileType_1 = require("../../TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var LavaFlowsAres = (function (_super) {
    __extends(LavaFlowsAres, _super);
    function LavaFlowsAres() {
        return _super.call(this, CardName_1.CardName.LAVA_FLOWS_ARES, { bonus: [SpaceBonus_1.SpaceBonus.HEAT, SpaceBonus_1.SpaceBonus.HEAT] }, {
            cardNumber: 'A11',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.temperature(2).br;
                b.tile(TileType_1.TileType.LAVA_FLOWS, false, true);
            }),
            description: 'Raise temperature 2 steps and place this tile ON EITHER THARSIS THOLUS, ASCRAEUS MONS, PAVONIS MONS OR ARSIA MONS. This tile grants an ADJACENCY BONUS of 2 heat.',
        }) || this;
    }
    return LavaFlowsAres;
}(LavaFlows_1.LavaFlows));
exports.LavaFlowsAres = LavaFlowsAres;
