"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var constants_1 = require("@/constants");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'language-switcher',
    data: function () {
        return {
            languages: constants_1.LANGUAGES,
            PreferencesManager: PreferencesManager_1.PreferencesManager,
        };
    },
    methods: {
        switchLanguageTo: function (langId) {
            this.PreferencesManager.save('lang', langId);
            window.location.reload();
        },
    },
});
