"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Philares = void 0;
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var AndOptions_1 = require("../../inputs/AndOptions");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var BoardType_1 = require("../../boards/BoardType");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var Philares = (function (_super) {
    __extends(Philares, _super);
    function Philares() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.PHILARES,
            tags: [Tags_1.Tags.BUILDING],
            startingMegaCredits: 47,
            initialActionText: 'Place a greenery tile and raise the oxygen 1 step',
            metadata: {
                cardNumber: 'R25',
                description: 'You start with 47 M€. As your first action, place a greenery tile and raise the oxygen 1 step.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(47).nbsp.greenery();
                    b.corpBox('effect', function (ce) {
                        ce.effect('Each new adjacency between your tile and an opponent\'s tile gives you a standard resource of your choice [regardless of who just placed a tile].', function (eb) {
                            eb.emptyTile('normal', { size: Size_1.Size.SMALL, all: Options_1.all }).nbsp;
                            eb.emptyTile('normal', { size: Size_1.Size.SMALL }).startEffect.wild(1);
                        });
                    });
                }),
            },
        }) || this;
    }
    Philares.prototype.initialAction = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for greenery tile', player.game.board.getAvailableSpacesForGreenery(player), function (space) {
            player.game.addGreenery(player, space.id);
            player.game.log('${0} placed a Greenery tile', function (b) { return b.player(player); });
            return undefined;
        });
    };
    Philares.prototype.selectResources = function (philaresPlayer, resourceCount) {
        var megacreditsAmount = 0;
        var steelAmount = 0;
        var titaniumAmount = 0;
        var plantsAmount = 0;
        var energyAmount = 0;
        var heatAmount = 0;
        var selectMegacredit = new SelectAmount_1.SelectAmount('Megacredits', 'Select', function (amount) {
            megacreditsAmount = amount;
            return undefined;
        }, 0, resourceCount);
        var selectSteel = new SelectAmount_1.SelectAmount('Steel', 'Select', function (amount) {
            steelAmount = amount;
            return undefined;
        }, 0, resourceCount);
        var selectTitanium = new SelectAmount_1.SelectAmount('Titanium', 'Select', function (amount) {
            titaniumAmount = amount;
            return undefined;
        }, 0, resourceCount);
        var selectPlants = new SelectAmount_1.SelectAmount('Plants', 'Select', function (amount) {
            plantsAmount = amount;
            return undefined;
        }, 0, resourceCount);
        var selectEnergy = new SelectAmount_1.SelectAmount('Energy', 'Select', function (amount) {
            energyAmount = amount;
            return undefined;
        }, 0, resourceCount);
        var selectHeat = new SelectAmount_1.SelectAmount('Heat', 'Select', function (amount) {
            heatAmount = amount;
            return undefined;
        }, 0, resourceCount);
        var selectResources = new AndOptions_1.AndOptions(function () {
            if (megacreditsAmount +
                steelAmount +
                titaniumAmount +
                plantsAmount +
                energyAmount +
                heatAmount > resourceCount) {
                throw new Error('Need to select ' + resourceCount + ' resource(s)');
            }
            philaresPlayer.addResource(Resources_1.Resources.MEGACREDITS, megacreditsAmount, { log: true });
            philaresPlayer.addResource(Resources_1.Resources.STEEL, steelAmount, { log: true });
            philaresPlayer.addResource(Resources_1.Resources.TITANIUM, titaniumAmount, { log: true });
            philaresPlayer.addResource(Resources_1.Resources.PLANTS, plantsAmount, { log: true });
            philaresPlayer.addResource(Resources_1.Resources.ENERGY, energyAmount, { log: true });
            philaresPlayer.addResource(Resources_1.Resources.HEAT, heatAmount, { log: true });
            return undefined;
        }, selectMegacredit, selectSteel, selectTitanium, selectPlants, selectEnergy, selectHeat);
        selectResources.title = 'Philares effect: select ' + resourceCount + ' resource(s)';
        return selectResources;
    };
    Philares.prototype.onTilePlaced = function (cardOwner, activePlayer, space, boardType) {
        var _this = this;
        if (boardType !== BoardType_1.BoardType.MARS) {
            return;
        }
        if (space.player === undefined) {
            return;
        }
        var adjacentSpaces = cardOwner.game.board.getAdjacentSpaces(space);
        var adjacentSpacesWithPlayerTiles = adjacentSpaces.filter(function (space) { return space.tile !== undefined && space.player !== undefined; });
        var eligibleTiles = (cardOwner.id === activePlayer.id) ?
            adjacentSpacesWithPlayerTiles.filter(function (space) { var _a; return ((_a = space.player) === null || _a === void 0 ? void 0 : _a.id) !== cardOwner.id; }) :
            adjacentSpacesWithPlayerTiles.filter(function (space) { var _a; return ((_a = space.player) === null || _a === void 0 ? void 0 : _a.id) === cardOwner.id; });
        if (eligibleTiles.length > 0) {
            cardOwner.game.defer(new DeferredAction_1.DeferredAction(cardOwner, function () {
                return _this.selectResources(cardOwner, eligibleTiles.length);
            }), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : DeferredAction_1.Priority.GAIN_RESOURCE_OR_PRODUCTION);
        }
    };
    Philares.prototype.play = function () {
        return undefined;
    };
    return Philares;
}(Card_1.Card));
exports.Philares = Philares;
