"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.REDS_POLICY_4 = exports.REDS_POLICY_3 = exports.REDS_POLICY_2 = exports.REDS_POLICY_1 = exports.REDS_BONUS_2 = exports.REDS_BONUS_1 = exports.Reds = void 0;
var Party_1 = require("./Party");
var PartyName_1 = require("./PartyName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var RemoveOceanTile_1 = require("../../deferredActions/RemoveOceanTile");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var TurmoilPolicy_1 = require("../TurmoilPolicy");
var Reds = (function (_super) {
    __extends(Reds, _super);
    function Reds() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = PartyName_1.PartyName.REDS;
        _this.description = 'Wishes to preserve the red planet.';
        _this.bonuses = [exports.REDS_BONUS_1, exports.REDS_BONUS_2];
        _this.policies = [exports.REDS_POLICY_1, exports.REDS_POLICY_2, exports.REDS_POLICY_3, exports.REDS_POLICY_4];
        return _this;
    }
    return Reds;
}(Party_1.Party));
exports.Reds = Reds;
var RedsBonus01 = (function () {
    function RedsBonus01() {
        this.id = 'rb01';
        this.description = 'The player(s) with the lowest TR gains 1 TR';
        this.isDefault = true;
    }
    RedsBonus01.prototype.getScore = function (player) {
        var game = player.game;
        var players = game.getPlayers();
        if (game.isSoloMode() && players[0].getTerraformRating() <= 20)
            return 1;
        players.sort(function (p1, p2) { return p1.getTerraformRating() - p2.getTerraformRating(); });
        var min = players[0].getTerraformRating();
        if (player.getTerraformRating() === min)
            return 1;
        return 0;
    };
    RedsBonus01.prototype.grant = function (game) {
        var _this = this;
        var players = game.getPlayers();
        var scores = players.map(function (player) { return _this.getScore(player); });
        players.forEach(function (player, idx) {
            if (scores[idx] > 0)
                player.increaseTerraformRating();
        });
    };
    return RedsBonus01;
}());
var RedsBonus02 = (function () {
    function RedsBonus02() {
        this.id = 'rb02';
        this.description = 'The player(s) with the highest TR loses 1 TR';
        this.isDefault = false;
    }
    RedsBonus02.prototype.getScore = function (player) {
        var game = player.game;
        var players = game.getPlayers();
        if (game.isSoloMode() && players[0].getTerraformRating() > 20)
            return -1;
        players.sort(function (p1, p2) { return p2.getTerraformRating() - p1.getTerraformRating(); });
        var max = players[0].getTerraformRating();
        if (player.getTerraformRating() === max)
            return -1;
        return 0;
    };
    RedsBonus02.prototype.grant = function (game) {
        var _this = this;
        var players = game.getPlayers();
        var scores = players.map(function (player) { return _this.getScore(player); });
        players.forEach(function (player, idx) {
            if (scores[idx] < 0)
                player.decreaseTerraformRating();
        });
    };
    return RedsBonus02;
}());
var RedsPolicy01 = (function () {
    function RedsPolicy01() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.REDS_DEFAULT_POLICY;
        this.isDefault = true;
        this.description = 'When you take an action that raises TR, you MUST pay 3 M€ per step raised';
    }
    return RedsPolicy01;
}());
var RedsPolicy02 = (function () {
    function RedsPolicy02() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.REDS_POLICY_2;
        this.description = 'When you place a tile, pay 3 M€ or as much as possible';
        this.isDefault = false;
    }
    RedsPolicy02.prototype.onTilePlaced = function (player) {
        var amountPlayerHas = player.megaCredits;
        if (player.isCorporation(CardName_1.CardName.HELION))
            amountPlayerHas += player.heat;
        var amountToPay = Math.min(amountPlayerHas, 3);
        if (amountToPay > 0) {
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, amountToPay, { title: 'Select how to pay for tile placement' }));
        }
    };
    return RedsPolicy02;
}());
var RedsPolicy03 = (function () {
    function RedsPolicy03() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.REDS_POLICY_3;
        this.description = 'Pay 4 M€ to reduce a non-maxed global parameter 1 step (do not gain any track bonuses)';
        this.isDefault = false;
    }
    RedsPolicy03.prototype.canAct = function (player) {
        var game = player.game;
        if (game.marsIsTerraformed())
            return false;
        var temperature = game.getTemperature();
        var oceansPlaced = game.board.getOceansOnBoard();
        var oxygenLevel = game.getOxygenLevel();
        var venusScaleLevel = game.getVenusScaleLevel();
        if (temperature === constants_1.MIN_TEMPERATURE && oceansPlaced === 0 && oxygenLevel === constants_1.MIN_OXYGEN_LEVEL && venusScaleLevel === constants_1.MIN_VENUS_SCALE) {
            return false;
        }
        return player.canAfford(4) && player.politicalAgendasActionUsedCount < constants_1.POLITICAL_AGENDAS_MAX_ACTION_USES;
    };
    RedsPolicy03.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Reds action', function (b) { return b.player(player); });
        player.politicalAgendasActionUsedCount += 1;
        game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 4, {
            title: 'Select how to pay for Turmoil Reds action',
            afterPay: function () {
                var orOptions = new OrOptions_1.OrOptions();
                var temperature = game.getTemperature();
                var canDecreaseTemperature = temperature > constants_1.MIN_TEMPERATURE && temperature !== constants_1.MAX_TEMPERATURE;
                if (canDecreaseTemperature) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Decrease temperature', 'Confirm', function () {
                        game.increaseTemperature(player, -1);
                        game.log('${0} decreased temperature 1 step', function (b) { return b.player(player); });
                        return undefined;
                    }));
                }
                var oceansPlaced = game.board.getOceansOnBoard();
                var canRemoveOcean = oceansPlaced > 0 && oceansPlaced !== constants_1.MAX_OCEAN_TILES;
                if (canRemoveOcean) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Remove an ocean tile', 'Confirm', function () {
                        game.defer(new RemoveOceanTile_1.RemoveOceanTile(player, 'Turmoil Reds action - Remove an Ocean tile from the board'));
                        return undefined;
                    }));
                }
                var oxygenLevel = game.getOxygenLevel();
                var canDecreaseOxygen = oxygenLevel > constants_1.MIN_OXYGEN_LEVEL && oxygenLevel !== constants_1.MAX_OXYGEN_LEVEL;
                if (canDecreaseOxygen) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Decrease oxygen level', 'Confirm', function () {
                        game.increaseOxygenLevel(player, -1);
                        game.log('${0} decreased oxygen level 1 step', function (b) { return b.player(player); });
                        return undefined;
                    }));
                }
                var venusScaleLevel = game.getVenusScaleLevel();
                var canDecreaseVenus = game.gameOptions.venusNextExtension === true && venusScaleLevel > constants_1.MIN_VENUS_SCALE && venusScaleLevel !== constants_1.MAX_VENUS_SCALE;
                if (canDecreaseVenus) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Decrease Venus scale', 'Confirm', function () {
                        game.increaseVenusScaleLevel(player, -1);
                        game.log('${0} decreased Venus scale level 1 step', function (b) { return b.player(player); });
                        return undefined;
                    }));
                }
                if (orOptions.options.length === 1)
                    return orOptions.options[0].cb();
                game.defer(new DeferredAction_1.DeferredAction(player, function () { return orOptions; }));
                return undefined;
            },
        }));
        return undefined;
    };
    return RedsPolicy03;
}());
var RedsPolicy04 = (function () {
    function RedsPolicy04() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.REDS_POLICY_4;
        this.description = 'When you raise a global parameter, decrease your M€ production 1 step per step raised if possible';
        this.isDefault = false;
    }
    return RedsPolicy04;
}());
exports.REDS_BONUS_1 = new RedsBonus01();
exports.REDS_BONUS_2 = new RedsBonus02();
exports.REDS_POLICY_1 = new RedsPolicy01();
exports.REDS_POLICY_2 = new RedsPolicy02();
exports.REDS_POLICY_3 = new RedsPolicy03();
exports.REDS_POLICY_4 = new RedsPolicy04();
