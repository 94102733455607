"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeAdvance = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var TradeAdvance = (function (_super) {
    __extends(TradeAdvance, _super);
    function TradeAdvance() {
        return _super.call(this, {
            name: CardName_1.CardName.TRADE_ADVANCE,
            tags: [Tags_1.Tags.EARTH],
            metadata: {
                cardNumber: 'Y05',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(2).text('[ solo').colon().megacredits(10).text(']').br;
                    b.text('Trade all colonies with').br;
                    b.trade().colon().text('+1');
                }),
                description: 'Gain 2 M€ [SOLO: Gain 10 M€]. Immediately trade with all active colonies. You may increase the Colony Tile track 1 step before each of these trades.',
            },
        }) || this;
    }
    TradeAdvance.prototype.play = function (player) {
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
            var openColonies = player.game.colonies.filter(function (colony) { return colony.isActive; });
            openColonies.forEach(function (colony) {
                colony.trade(player, 1, false);
            });
            return undefined;
        }));
        if (player.game.isSoloMode()) {
            player.megaCredits += 10;
        }
        else {
            player.megaCredits += 2;
        }
        return undefined;
    };
    return TradeAdvance;
}(PreludeCard_1.PreludeCard));
exports.TradeAdvance = TradeAdvance;
