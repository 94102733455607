"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomedCrater = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var DomedCrater = (function (_super) {
    __extends(DomedCrater, _super);
    function DomedCrater() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.DOMED_CRATER,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 24,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 3 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(7, { max: Options_1.max }); }),
            metadata: {
                cardNumber: 'T03',
                description: {
                    text: 'Oxygen must be 7% or less. Gain 3 plants. Place a City tile. Decrease your Energy production 1 step and increase your M€ production 3 steps.',
                    align: 'left',
                },
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(3);
                    }).nbsp.city().plants(3, { digit: Options_1.digit }).br;
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    DomedCrater.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1 &&
            player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    DomedCrater.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            player.plants += 3;
            player.addProduction(Resources_1.Resources.ENERGY, -1);
            player.addProduction(Resources_1.Resources.MEGACREDITS, 3);
            return undefined;
        });
    };
    DomedCrater.prototype.getVictoryPoints = function () {
        return 1;
    };
    return DomedCrater;
}(Card_1.Card));
exports.DomedCrater = DomedCrater;
