"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardActionCard = void 0;
var CardType_1 = require("./CardType");
var Card_1 = require("./Card");
var StandardActionCard = (function (_super) {
    __extends(StandardActionCard, _super);
    function StandardActionCard(properties) {
        return _super.call(this, __assign({ cardType: CardType_1.CardType.STANDARD_ACTION }, properties)) || this;
    }
    StandardActionCard.prototype.actionUsed = function (player) {
        var _this = this;
        player.game.log('${0} used ${1} standard action', function (b) { return b.player(player).card(_this); });
    };
    StandardActionCard.prototype.play = function () {
        return undefined;
    };
    return StandardActionCard;
}(Card_1.Card));
exports.StandardActionCard = StandardActionCard;
