"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARES_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var BioengineeringEnclosure_1 = require("./BioengineeringEnclosure");
var BiofertilizerFacility_1 = require("./BiofertilizerFacility");
var ButterflyEffect_1 = require("./ButterflyEffect");
var CapitalAres_1 = require("./CapitalAres");
var CommercialDistrictAres_1 = require("./CommercialDistrictAres");
var DesperateMeasures_1 = require("./DesperateMeasures");
var EcologicalSurvey_1 = require("./EcologicalSurvey");
var EcologicalZoneAres_1 = require("./EcologicalZoneAres");
var GeologicalSurvey_1 = require("./GeologicalSurvey");
var IndustrialCenterAres_1 = require("./IndustrialCenterAres");
var LavaFlowsAres_1 = require("./LavaFlowsAres");
var MarketingExperts_1 = require("./MarketingExperts");
var MetallicAsteroid_1 = require("./MetallicAsteroid");
var MiningAreaAres_1 = require("./MiningAreaAres");
var MiningRightsAres_1 = require("./MiningRightsAres");
var MoholeAreaAres_1 = require("./MoholeAreaAres");
var NaturalPreserveAres_1 = require("./NaturalPreserveAres");
var NuclearZoneAres_1 = require("./NuclearZoneAres");
var OceanCity_1 = require("./OceanCity");
var OceanFarm_1 = require("./OceanFarm");
var OceanSanctuary_1 = require("./OceanSanctuary");
var RestrictedAreaAres_1 = require("./RestrictedAreaAres");
var SolarFarm_1 = require("./SolarFarm");
exports.ARES_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Ares,
    projectCards: [
        { cardName: CardName_1.CardName.BIOENGINEERING_ENCLOSURE, Factory: BioengineeringEnclosure_1.BioengineeringEnclosure },
        { cardName: CardName_1.CardName.BIOFERTILIZER_FACILITY, Factory: BiofertilizerFacility_1.BiofertilizerFacility },
        { cardName: CardName_1.CardName.BUTTERFLY_EFFECT, Factory: ButterflyEffect_1.ButterflyEffect },
        { cardName: CardName_1.CardName.CAPITAL_ARES, Factory: CapitalAres_1.CapitalAres },
        { cardName: CardName_1.CardName.COMMERCIAL_DISTRICT_ARES, Factory: CommercialDistrictAres_1.CommercialDistrictAres },
        { cardName: CardName_1.CardName.DESPERATE_MEASURES, Factory: DesperateMeasures_1.DesperateMeasures },
        { cardName: CardName_1.CardName.ECOLOGICAL_SURVEY, Factory: EcologicalSurvey_1.EcologicalSurvey },
        { cardName: CardName_1.CardName.ECOLOGICAL_ZONE_ARES, Factory: EcologicalZoneAres_1.EcologicalZoneAres },
        { cardName: CardName_1.CardName.GEOLOGICAL_SURVEY, Factory: GeologicalSurvey_1.GeologicalSurvey },
        { cardName: CardName_1.CardName.INDUSTRIAL_CENTER_ARES, Factory: IndustrialCenterAres_1.IndustrialCenterAres },
        { cardName: CardName_1.CardName.LAVA_FLOWS_ARES, Factory: LavaFlowsAres_1.LavaFlowsAres },
        { cardName: CardName_1.CardName.MARKETING_EXPERTS, Factory: MarketingExperts_1.MarketingExperts },
        { cardName: CardName_1.CardName.METALLIC_ASTEROID, Factory: MetallicAsteroid_1.MetallicAsteroid },
        { cardName: CardName_1.CardName.MINING_AREA_ARES, Factory: MiningAreaAres_1.MiningAreaAres },
        { cardName: CardName_1.CardName.MINING_RIGHTS_ARES, Factory: MiningRightsAres_1.MiningRightsAres },
        { cardName: CardName_1.CardName.MOHOLE_AREA_ARES, Factory: MoholeAreaAres_1.MoholeAreaAres },
        { cardName: CardName_1.CardName.NATURAL_PRESERVE_ARES, Factory: NaturalPreserveAres_1.NaturalPreserveAres },
        { cardName: CardName_1.CardName.NUCLEAR_ZONE_ARES, Factory: NuclearZoneAres_1.NuclearZoneAres },
        { cardName: CardName_1.CardName.OCEAN_CITY, Factory: OceanCity_1.OceanCity },
        { cardName: CardName_1.CardName.OCEAN_FARM, Factory: OceanFarm_1.OceanFarm },
        { cardName: CardName_1.CardName.OCEAN_SANCTUARY, Factory: OceanSanctuary_1.OceanSanctuary },
        { cardName: CardName_1.CardName.RESTRICTED_AREA_ARES, Factory: RestrictedAreaAres_1.RestrictedAreaAres },
        { cardName: CardName_1.CardName.SOLAR_FARM, Factory: SolarFarm_1.SolarFarm },
    ],
    cardsToRemove: [
        CardName_1.CardName.CAPITAL,
        CardName_1.CardName.COMMERCIAL_DISTRICT,
        CardName_1.CardName.ECOLOGICAL_ZONE,
        CardName_1.CardName.INDUSTRIAL_CENTER,
        CardName_1.CardName.LAVA_FLOWS,
        CardName_1.CardName.MINING_AREA,
        CardName_1.CardName.MINING_RIGHTS,
        CardName_1.CardName.MOHOLE_AREA,
        CardName_1.CardName.NATURAL_PRESERVE,
        CardName_1.CardName.NUCLEAR_ZONE,
        CardName_1.CardName.RESTRICTED_AREA,
    ],
});
