"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AtmoCollectors = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var AtmoCollectors = (function (_super) {
    __extends(AtmoCollectors, _super);
    function AtmoCollectors() {
        var _this = _super.call(this, {
            cost: 15,
            name: CardName_1.CardName.ATMO_COLLECTORS,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                description: 'Add 2 floaters to ANY card.',
                cardNumber: 'C03',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add one floater here.', function (eb) {
                        eb.empty().startAction.floaters(1).or(Size_1.Size.SMALL);
                    }).br;
                    b.action('Spend 1 floater here to gain 2 titanium, or 3 energy, or 4 heat.', function (eb) {
                        eb.floaters(1).startAction.titanium(2, { digit: Options_1.digit }).slash(Size_1.Size.SMALL).energy(3, { digit: Options_1.digit }).slash(Size_1.Size.SMALL).heat(4, { digit: Options_1.digit });
                    }).br;
                    b.floaters(2).asterix();
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    AtmoCollectors.prototype.canAct = function () {
        return true;
    };
    AtmoCollectors.prototype.action = function (player) {
        var _this = this;
        if (this.resourceCount < 1) {
            player.addResourceTo(this, 1);
            return undefined;
        }
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Remove 1 floater to gain 2 titanium', 'Remove floater', function () {
            _this.resourceCount--;
            player.addResource(Resources_1.Resources.TITANIUM, 2, { log: true });
            return undefined;
        }), new SelectOption_1.SelectOption('Remove 1 floater to gain 3 energy', 'Remove floater', function () {
            _this.resourceCount--;
            player.addResource(Resources_1.Resources.ENERGY, 3, { log: true });
            return undefined;
        }), new SelectOption_1.SelectOption('Remove 1 floater to gain 4 heat', 'Remove floater', function () {
            _this.resourceCount--;
            player.addResource(Resources_1.Resources.HEAT, 4, { log: true });
            return undefined;
        }), new SelectOption_1.SelectOption('Add 1 floater to this card', 'Add floater', function () {
            player.addResourceTo(_this, { log: true });
            return undefined;
        }));
    };
    AtmoCollectors.prototype.play = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2 }));
        return undefined;
    };
    return AtmoCollectors;
}(Card_1.Card));
exports.AtmoCollectors = AtmoCollectors;
