"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MicrogravityHealthProblems = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.megacredits(-3).slash().colonies(1).influence({ size: Size_1.Size.SMALL });
});
var MicrogravityHealthProblems = (function () {
    function MicrogravityHealthProblems() {
        this.name = GlobalEventName_1.GlobalEventName.MICROGRAVITY_HEALTH_PROBLEMS;
        this.description = 'Lose 3 M€ for each colony (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.MARS;
        this.currentDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.renderData = RENDER_DATA;
    }
    MicrogravityHealthProblems.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var coloniesCount = 0;
            game.colonies.forEach(function (colony) {
                coloniesCount += colony.colonies.filter(function (owner) { return owner === player.id; }).length;
            });
            player.deductResource(Resources_1.Resources.MEGACREDITS, 3 * Math.max(0, Math.min(5, coloniesCount) - turmoil.getPlayerInfluence(player)), { log: true, from: _this.name });
        });
    };
    return MicrogravityHealthProblems;
}());
exports.MicrogravityHealthProblems = MicrogravityHealthProblems;
