"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransNeptuneProbe = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var TransNeptuneProbe = (function (_super) {
    __extends(TransNeptuneProbe, _super);
    function TransNeptuneProbe() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.TRANS_NEPTUNE_PROBE,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.SPACE],
            cost: 6,
            metadata: {
                cardNumber: '084',
                victoryPoints: 1,
            },
        }) || this;
    }
    TransNeptuneProbe.prototype.play = function () {
        return undefined;
    };
    TransNeptuneProbe.prototype.getVictoryPoints = function () {
        return 1;
    };
    return TransNeptuneProbe;
}(Card_1.Card));
exports.TransNeptuneProbe = TransNeptuneProbe;
