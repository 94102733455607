"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResearchNetwork = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var ResearchNetwork = (function (_super) {
    __extends(ResearchNetwork, _super);
    function ResearchNetwork() {
        return _super.call(this, {
            name: CardName_1.CardName.RESEARCH_NETWORK,
            tags: [Tags_1.Tags.WILDCARD],
            productionBox: Units_1.Units.of({ megacredits: 1 }),
            metadata: {
                cardNumber: 'P28',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).br;
                    b.cards(3);
                }),
                description: 'Increase your M€ production 1 step. Draw 3 cards. After being played, when you perform an action, the wild tag counts as any tag of your choice.',
            },
        }) || this;
    }
    ResearchNetwork.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        player.drawCard(3);
        return undefined;
    };
    return ResearchNetwork;
}(PreludeCard_1.PreludeCard));
exports.ResearchNetwork = ResearchNetwork;
