"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderCorpBoxAction = exports.CardRenderCorpBoxEffect = exports.CardRenderEffect = exports.CardRenderTile = exports.CardRenderProductionBox = exports.CardRenderer = void 0;
var CardRenderItem_1 = require("./CardRenderItem");
var CardRenderSymbol_1 = require("./CardRenderSymbol");
var Size_1 = require("./Size");
var CardRenderItemType_1 = require("./CardRenderItemType");
var CardRenderer = (function () {
    function CardRenderer(_rows) {
        if (_rows === void 0) { _rows = [[]]; }
        this._rows = _rows;
    }
    Object.defineProperty(CardRenderer.prototype, "rows", {
        get: function () {
            return this._rows;
        },
        enumerable: false,
        configurable: true
    });
    CardRenderer.builder = function (f) {
        var builder = new Builder();
        f(builder);
        return builder.build();
    };
    return CardRenderer;
}());
exports.CardRenderer = CardRenderer;
var CardRenderProductionBox = (function (_super) {
    __extends(CardRenderProductionBox, _super);
    function CardRenderProductionBox(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderProductionBox.builder = function (f) {
        var builder = new ProductionBoxBuilder();
        f(builder);
        return builder.build();
    };
    return CardRenderProductionBox;
}(CardRenderer));
exports.CardRenderProductionBox = CardRenderProductionBox;
var CardRenderTile = (function () {
    function CardRenderTile(tile, hasSymbol, isAres) {
        this.tile = tile;
        this.hasSymbol = hasSymbol;
        this.isAres = isAres;
    }
    ;
    return CardRenderTile;
}());
exports.CardRenderTile = CardRenderTile;
var CardRenderEffect = (function (_super) {
    __extends(CardRenderEffect, _super);
    function CardRenderEffect(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderEffect.builder = function (f) {
        var builder = new EffectBuilder();
        f(builder);
        return builder.build();
    };
    CardRenderEffect.prototype._validate = function () {
        if (this.rows.length !== 3) {
            throw new Error('Card effect must have 3 arrays representing cause, delimiter and effect. If there is no cause, start with `empty`.');
        }
        if (this.rows[1].length !== 1) {
            throw new Error('Card effect delimiter array must contain exactly 1 item');
        }
        if (!(this.rows[1][0] instanceof CardRenderSymbol_1.CardRenderSymbol)) {
            throw new Error('Effect delimiter must be a symbol');
        }
    };
    Object.defineProperty(CardRenderEffect.prototype, "cause", {
        get: function () {
            this._validate();
            return this.rows[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRenderEffect.prototype, "delimiter", {
        get: function () {
            var _a;
            this._validate();
            if (((_a = this.cause) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                return undefined;
            }
            return this.rows[1][0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRenderEffect.prototype, "effect", {
        get: function () {
            this._validate();
            return this.rows[2];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRenderEffect.prototype, "description", {
        get: function () {
            this._validate();
            return this.rows[2].slice(-1)[0];
        },
        set: function (content) {
            this.rows[2].push(content);
        },
        enumerable: false,
        configurable: true
    });
    return CardRenderEffect;
}(CardRenderer));
exports.CardRenderEffect = CardRenderEffect;
var CardRenderCorpBoxEffect = (function (_super) {
    __extends(CardRenderCorpBoxEffect, _super);
    function CardRenderCorpBoxEffect(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderCorpBoxEffect.builder = function (f) {
        var builder = new CorpEffectBuilderEffect();
        f(builder);
        return builder.build();
    };
    return CardRenderCorpBoxEffect;
}(CardRenderer));
exports.CardRenderCorpBoxEffect = CardRenderCorpBoxEffect;
var CardRenderCorpBoxAction = (function (_super) {
    __extends(CardRenderCorpBoxAction, _super);
    function CardRenderCorpBoxAction(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderCorpBoxAction.builder = function (f) {
        var builder = new CorpEffectBuilderAction();
        f(builder);
        return builder.build();
    };
    return CardRenderCorpBoxAction;
}(CardRenderer));
exports.CardRenderCorpBoxAction = CardRenderCorpBoxAction;
var Builder = (function () {
    function Builder() {
        this._data = [[]];
    }
    Builder.prototype.build = function () {
        return new CardRenderer(this._data);
    };
    Builder.prototype._currentRow = function () {
        if (this._data.length === 0) {
            throw new Error('No items in builder data!');
        }
        return this._data[this._data.length - 1];
    };
    Builder.prototype._appendToRow = function (thing) {
        this._currentRow().push(thing);
        return this;
    };
    Builder.prototype.temperature = function (amount) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TEMPERATURE, amount));
    };
    Builder.prototype.oceans = function (amount, options) {
        var _a;
        var opts = options !== null && options !== void 0 ? options : { size: Size_1.Size.MEDIUM };
        opts.size = (_a = opts.size) !== null && _a !== void 0 ? _a : Size_1.Size.MEDIUM;
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.OCEANS, amount, options);
        return this._appendToRow(item);
    };
    Builder.prototype.oxygen = function (amount) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.OXYGEN, amount));
    };
    Builder.prototype.venus = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.VENUS, amount, options));
    };
    Builder.prototype.plants = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PLANTS, amount, options));
    };
    Builder.prototype.microbes = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MICROBES, amount, options));
    };
    Builder.prototype.animals = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ANIMALS, amount, options));
    };
    Builder.prototype.heat = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.HEAT, amount, options));
    };
    Builder.prototype.energy = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ENERGY, amount, options));
    };
    Builder.prototype.titanium = function (amount, options) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TITANIUM, amount, options);
        return this._appendToRow(item);
    };
    Builder.prototype.steel = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.STEEL, amount, options));
    };
    Builder.prototype.tr = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TR, amount, options));
    };
    Builder.prototype.megacredits = function (amount, options) {
        var _a;
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MEGACREDITS, amount, options);
        item.amountInside = true;
        item.showDigit = false;
        item.size = (_a = options === null || options === void 0 ? void 0 : options.size) !== null && _a !== void 0 ? _a : Size_1.Size.MEDIUM;
        return this._appendToRow(item);
    };
    Builder.prototype.cards = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CARDS, amount, options));
    };
    Builder.prototype.floaters = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.FLOATERS, amount, options));
    };
    Builder.prototype.asteroids = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ASTEROIDS, amount, options));
    };
    Builder.prototype.event = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EVENT, -1, options));
    };
    Builder.prototype.space = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SPACE, -1, options));
    };
    Builder.prototype.earth = function (amount, options) {
        if (amount === void 0) { amount = -1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EARTH, amount, options));
    };
    Builder.prototype.building = function (amount, options) {
        if (amount === void 0) { amount = -1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.BUILDING, amount, options));
    };
    Builder.prototype.jovian = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.JOVIAN, -1, options));
    };
    Builder.prototype.science = function (amount, options) {
        if (amount === void 0) { amount = 1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SCIENCE, amount, options));
    };
    Builder.prototype.trade = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TRADE));
    };
    Builder.prototype.tradeFleet = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TRADE_FLEET));
    };
    Builder.prototype.colonies = function (amount, options) {
        var _a;
        if (amount === void 0) { amount = 1; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.COLONIES, amount, options);
        item.size = (_a = options === null || options === void 0 ? void 0 : options.size) !== null && _a !== void 0 ? _a : Size_1.Size.MEDIUM;
        return this._appendToRow(item);
    };
    Builder.prototype.tradeDiscount = function (amount) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TRADE_DISCOUNT, amount * -1);
        item.amountInside = true;
        return this._appendToRow(item);
    };
    Builder.prototype.placeColony = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PLACE_COLONY, -1, options));
    };
    Builder.prototype.influence = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.INFLUENCE, 1, options));
    };
    Builder.prototype.city = function (options) {
        var _a;
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CITY, -1, options);
        item.size = (_a = options === null || options === void 0 ? void 0 : options.size) !== null && _a !== void 0 ? _a : Size_1.Size.MEDIUM;
        return this._appendToRow(item);
    };
    Builder.prototype.greenery = function (size, withO2) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        if (withO2 === void 0) { withO2 = true; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.GREENERY);
        item.size = size;
        if (withO2) {
            item.secondaryTag = CardRenderItem_1.AltSecondaryTag.OXYGEN;
        }
        return this._appendToRow(item);
    };
    Builder.prototype.delegates = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DELEGATES, amount, options));
    };
    Builder.prototype.partyLeaders = function (amount) {
        if (amount === void 0) { amount = -1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PARTY_LEADERS, amount));
    };
    Builder.prototype.chairman = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CHAIRMAN, -1, options));
    };
    Builder.prototype.noTags = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.NO_TAGS, -1));
    };
    Builder.prototype.wild = function (amount, options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.WILD, amount, options));
    };
    Builder.prototype.preservation = function (amount) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PRESERVATION, amount));
    };
    Builder.prototype.diverseTag = function (amount) {
        if (amount === void 0) { amount = 1; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DIVERSE_TAG, amount);
        item.isPlayed = true;
        return this._appendToRow(item);
    };
    Builder.prototype.fighter = function (amount) {
        if (amount === void 0) { amount = 1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.FIGHTER, amount));
    };
    Builder.prototype.camps = function (amount) {
        if (amount === void 0) { amount = 1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CAMPS, amount));
    };
    Builder.prototype.selfReplicatingRobots = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SELF_REPLICATING));
    };
    Builder.prototype.prelude = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PRELUDE));
    };
    Builder.prototype.award = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.AWARD));
    };
    Builder.prototype.vpIcon = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.VP));
    };
    Builder.prototype.community = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.COMMUNITY));
    };
    Builder.prototype.disease = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DISEASE));
    };
    Builder.prototype.data = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DATA_RESOURCE, 1, options));
    };
    Builder.prototype.multiplierWhite = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MULTIPLIER_WHITE));
    };
    Builder.prototype.description = function (description) {
        if (description === void 0) { description = undefined; }
        return this._appendToRow(description);
    };
    Builder.prototype.moon = function (amount, options) {
        if (amount === void 0) { amount = -1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON, amount, options));
    };
    Builder.prototype.resourceCube = function (amount) {
        if (amount === void 0) { amount = 1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE, amount));
    };
    Builder.prototype.moonColony = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_COLONY, 1, options));
    };
    Builder.prototype.moonColonyRate = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_COLONY_RATE, 1, options));
    };
    Builder.prototype.moonRoad = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_ROAD, 1, options));
    };
    Builder.prototype.moonLogisticsRate = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_LOGISTICS_RATE, 1, options));
    };
    Builder.prototype.moonMine = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_MINE, 1, options));
    };
    Builder.prototype.moonMiningRate = function (options) {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_MINING_RATE, 1, options));
    };
    Builder.prototype.syndicateFleet = function (amount) {
        if (amount === void 0) { amount = 1; }
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SYNDICATE_FLEET, amount));
    };
    Builder.prototype.mars = function (amount, options) {
        this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MARS, amount, options));
        return this;
    };
    Builder.prototype.emptyTile = function (type, options) {
        var _a, _b;
        if (type === void 0) { type = 'normal'; }
        if (type === 'normal') {
            var normal = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EMPTY_TILE, -1, options);
            normal.size = (_a = options === null || options === void 0 ? void 0 : options.size) !== null && _a !== void 0 ? _a : Size_1.Size.MEDIUM;
            this._appendToRow(normal);
        }
        else if (type === 'golden') {
            var golden = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EMPTY_TILE_GOLDEN, -1, options);
            golden.size = (_b = options === null || options === void 0 ? void 0 : options.size) !== null && _b !== void 0 ? _b : Size_1.Size.MEDIUM;
            this._appendToRow(golden);
        }
        return this;
    };
    Builder.prototype.production = function (pb) {
        return this._appendToRow(CardRenderProductionBox.builder(pb));
    };
    Builder.prototype.standardProject = function (description, eb) {
        var builder = CardRenderEffect.builder(eb);
        builder.description = description;
        return this._appendToRow(builder);
    };
    Builder.prototype.action = function (description, eb) {
        var builder = CardRenderEffect.builder(eb);
        builder.description = description !== undefined ? 'Action: ' + description : undefined;
        return this._appendToRow(builder);
    };
    Builder.prototype.effect = function (description, eb) {
        var builder = CardRenderEffect.builder(eb);
        builder.description = description !== undefined ? 'Effect: ' + description : undefined;
        return this._appendToRow(builder);
    };
    Builder.prototype.corpBox = function (type, eb) {
        this.br;
        if (type === 'action') {
            return this._appendToRow(CardRenderCorpBoxAction.builder(eb));
        }
        else {
            return this._appendToRow(CardRenderCorpBoxEffect.builder(eb));
        }
    };
    Builder.prototype.or = function (size) {
        if (size === void 0) { size = Size_1.Size.SMALL; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.or(size));
    };
    Builder.prototype.asterix = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.asterix(size));
    };
    Builder.prototype.plus = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.plus(size));
    };
    Builder.prototype.minus = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.minus(size));
    };
    Builder.prototype.slash = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.slash(size));
    };
    Builder.prototype.colon = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.colon(size));
    };
    Builder.prototype.arrow = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.arrow(size));
    };
    Builder.prototype.equals = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.equals(size));
    };
    Builder.prototype.empty = function () {
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.empty());
    };
    Builder.prototype.plate = function (text) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PLATE);
        item.text = text;
        item.isPlate = true;
        item.isBold = true;
        return this._appendToRow(item);
    };
    Builder.prototype.text = function (text, size, uppercase, isBold) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        if (uppercase === void 0) { uppercase = false; }
        if (isBold === void 0) { isBold = true; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TEXT);
        item.text = text;
        item.size = size;
        item.isUppercase = uppercase;
        item.isBold = isBold;
        return this._appendToRow(item);
    };
    Builder.prototype.vpText = function (text) {
        return this.text(text, Size_1.Size.TINY, true);
    };
    Object.defineProperty(Builder.prototype, "br", {
        get: function () {
            this._data.push([]);
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Builder.prototype.tile = function (tile, hasSymbol, isAres) {
        if (hasSymbol === void 0) { hasSymbol = false; }
        if (isAres === void 0) { isAres = false; }
        return this._appendToRow(new CardRenderTile(tile, hasSymbol, isAres));
    };
    Builder.prototype.projectRequirements = function () {
        return this._appendToRow(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PROJECT_REQUIREMENTS));
    };
    Object.defineProperty(Builder.prototype, "nbsp", {
        get: function () {
            return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.nbsp());
        },
        enumerable: false,
        configurable: true
    });
    Builder.prototype.vSpace = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.vSpace(size));
    };
    Object.defineProperty(Builder.prototype, "openBrackets", {
        get: function () {
            return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.bracketOpen());
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "closeBrackets", {
        get: function () {
            return this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.bracketClose());
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "startEffect", {
        get: function () {
            this.br;
            this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.colon());
            this.br;
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "startAction", {
        get: function () {
            this.br;
            this._appendToRow(CardRenderSymbol_1.CardRenderSymbol.arrow());
            this.br;
            return this;
        },
        enumerable: false,
        configurable: true
    });
    return Builder;
}());
var ProductionBoxBuilder = (function (_super) {
    __extends(ProductionBoxBuilder, _super);
    function ProductionBoxBuilder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    ProductionBoxBuilder.prototype.build = function () {
        return new CardRenderProductionBox(this._data);
    };
    return ProductionBoxBuilder;
}(Builder));
var EffectBuilder = (function (_super) {
    __extends(EffectBuilder, _super);
    function EffectBuilder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    EffectBuilder.prototype.build = function () {
        return new CardRenderEffect(this._data);
    };
    return EffectBuilder;
}(Builder));
var CorpEffectBuilderEffect = (function (_super) {
    __extends(CorpEffectBuilderEffect, _super);
    function CorpEffectBuilderEffect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    CorpEffectBuilderEffect.prototype.build = function () {
        return new CardRenderCorpBoxEffect(this._data);
    };
    return CorpEffectBuilderEffect;
}(Builder));
var CorpEffectBuilderAction = (function (_super) {
    __extends(CorpEffectBuilderAction, _super);
    function CorpEffectBuilderAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    CorpEffectBuilderAction.prototype.build = function () {
        return new CardRenderCorpBoxAction(this._data);
    };
    return CorpEffectBuilderAction;
}(Builder));
