"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var ActionLabel_1 = require("@/client/components/overview/ActionLabel");
var PlayerTimer_vue_1 = require("@/client/components/overview/PlayerTimer.vue");
exports.default = vue_1.default.extend({
    name: 'player-status',
    props: {
        timer: {
            type: Object,
        },
        actionLabel: {
            type: String,
        },
        showTimers: {
            type: Boolean,
        },
    },
    components: {
        PlayerTimer: PlayerTimer_vue_1.default,
    },
    methods: {
        getLabelAndTimerClasses: function () {
            var classes = [];
            var baseClass = 'player-action-status-container';
            classes.push(baseClass);
            if (!this.showTimers) {
                classes.push('no-timer');
            }
            if (this.actionLabel === ActionLabel_1.ActionLabel.PASSED) {
                classes.push(baseClass + "--passed");
            }
            else if (this.actionLabel === ActionLabel_1.ActionLabel.ACTIVE || this.actionLabel === ActionLabel_1.ActionLabel.DRAFTING || this.actionLabel === ActionLabel_1.ActionLabel.RESEARCHING) {
                classes.push(baseClass + "--active");
            }
            return classes.join(' ');
        },
        getActionStatusClasses: function () {
            var classes = ['player-action-status'];
            if (this.actionLabel === ActionLabel_1.ActionLabel.NONE) {
                classes.push('visibility-hidden');
            }
            return classes.join(' ');
        },
    },
});
