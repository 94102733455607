"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.WildlifeDome = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var WildlifeDome = (function (_super) {
    __extends(WildlifeDome, _super);
    function WildlifeDome() {
        return _super.call(this, {
            name: CardName_1.CardName.WILDLIFE_DOME,
            cost: 15,
            tags: [Tags_1.Tags.ANIMAL, Tags_1.Tags.PLANT, Tags_1.Tags.BUILDING],
            cardType: CardType_1.CardType.AUTOMATED,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.GREENS); }),
            tr: { oxygen: 1 },
            metadata: {
                cardNumber: 'T15',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.greenery();
                }),
                description: 'Requires that Greens are ruling or that you have 2 delegates there. Place a greenery tile and raise oxygen 1 step.',
            },
        }) || this;
    }
    WildlifeDome.prototype.canPlay = function (player) {
        return player.game.board.getAvailableSpacesForGreenery(player).length > 0;
    };
    WildlifeDome.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for greenery tile', player.game.board.getAvailableSpacesForGreenery(player), function (space) {
            return player.game.addGreenery(player, space.id);
        });
    };
    return WildlifeDome;
}(Card_1.Card));
exports.WildlifeDome = WildlifeDome;
