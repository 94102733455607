"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeimosDown = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var RemoveAnyPlants_1 = require("../../deferredActions/RemoveAnyPlants");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var DeimosDown = (function (_super) {
    __extends(DeimosDown, _super);
    function DeimosDown() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.DEIMOS_DOWN,
            tags: [Tags_1.Tags.SPACE],
            cost: 31,
            tr: { temperature: 3 },
            metadata: {
                cardNumber: '039',
                description: 'Raise temperature 3 steps and gain 4 steel. Remove up to 8 Plants from any player.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.temperature(3).br;
                    b.steel(4).br;
                    b.minus().plants(-8, { all: Options_1.all });
                }),
            },
        }) || this;
    }
    DeimosDown.prototype.play = function (player) {
        player.game.increaseTemperature(player, 3);
        player.game.defer(new RemoveAnyPlants_1.RemoveAnyPlants(player, 8));
        player.steel += 4;
        return undefined;
    };
    return DeimosDown;
}(Card_1.Card));
exports.DeimosDown = DeimosDown;
