"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardType_1 = require("@/cards/CardType");
var LogMessageType_1 = require("@/LogMessageType");
var LogMessageDataType_1 = require("@/LogMessageDataType");
var Card_vue_1 = require("@/client/components/card/Card.vue");
var CardFinder_1 = require("@/CardFinder");
var TileType_1 = require("@/TileType");
var utils_1 = require("@/utils/utils");
var SoundManager_1 = require("@/client/utils/SoundManager");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var GlobalEvent_vue_1 = require("@/client/components/GlobalEvent.vue");
var GlobalEventDealer_1 = require("@/turmoil/globalEvents/GlobalEventDealer");
var PartyName_1 = require("@/turmoil/parties/PartyName");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var Log_1 = require("@/Log");
var logRequest;
exports.default = vue_1.default.extend({
    name: 'log-panel',
    props: {
        id: {
            type: String,
        },
        generation: {
            type: Number,
        },
        lastSoloGeneration: {
            type: Number,
        },
        players: {
            type: Array,
        },
        color: {
            type: String,
        },
    },
    data: function () {
        return {
            cards: [],
            globalEventNames: [],
            messages: [],
            selectedGeneration: this.generation,
        };
    },
    components: {
        Button: Button_vue_1.default,
        Card: Card_vue_1.default,
        GlobalEvent: GlobalEvent_vue_1.default,
    },
    methods: {
        scrollToEnd: function () {
            var scrollablePanel = document.getElementById('logpanel-scrollable');
            if (scrollablePanel !== null) {
                scrollablePanel.scrollTop = scrollablePanel.scrollHeight;
            }
        },
        cardToHtml: function (cardType, cardName) {
            var cardNameString = this.$t(cardName);
            var suffixFreeCardName = cardNameString.split(':')[0];
            var className;
            if (cardType === CardType_1.CardType.EVENT) {
                className = 'background-color-events';
            }
            else if (cardType === CardType_1.CardType.ACTIVE) {
                className = 'background-color-active';
            }
            else if (cardType === CardType_1.CardType.AUTOMATED) {
                className = 'background-color-automated';
            }
            else if (cardType === CardType_1.CardType.PRELUDE) {
                className = 'background-color-prelude';
            }
            else if (cardType === CardType_1.CardType.STANDARD_PROJECT || cardType === CardType_1.CardType.STANDARD_ACTION) {
                className = 'background-color-standard-project';
            }
            if (className === undefined) {
                return suffixFreeCardName;
            }
            return '<span class="log-card ' + className + '">' + suffixFreeCardName + '</span>';
        },
        messageDataToHTML: function (data) {
            var translatableMessageDataTypes = [
                LogMessageDataType_1.LogMessageDataType.STRING,
                LogMessageDataType_1.LogMessageDataType.STANDARD_PROJECT,
                LogMessageDataType_1.LogMessageDataType.MILESTONE,
                LogMessageDataType_1.LogMessageDataType.AWARD,
                LogMessageDataType_1.LogMessageDataType.COLONY,
                LogMessageDataType_1.LogMessageDataType.PARTY,
                LogMessageDataType_1.LogMessageDataType.TILE_TYPE,
                LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT,
            ];
            if (data.type === undefined || data.value === undefined) {
                return '';
            }
            switch (data.type) {
                case LogMessageDataType_1.LogMessageDataType.PLAYER:
                    for (var _i = 0, _a = this.players; _i < _a.length; _i++) {
                        var player = _a[_i];
                        if (data.value === player.color || data.value === player.id) {
                            return '<span class="log-player player_bg_color_' + player.color + '">' + player.name + '</span>';
                        }
                    }
                    break;
                case LogMessageDataType_1.LogMessageDataType.CARD:
                    var cardName = data.value;
                    for (var _b = 0, _c = this.players; _b < _c.length; _b++) {
                        var player = _c[_b];
                        if (player.corporationCard !== undefined && cardName === player.corporationCard.name) {
                            return '<span class="log-card background-color-global-event">' + this.$t(cardName) + '</span>';
                        }
                        else {
                            var robotCards = player.playedCards.concat(player.selfReplicatingRobotsCards);
                            for (var _d = 0, robotCards_1 = robotCards; _d < robotCards_1.length; _d++) {
                                var robotCard = robotCards_1[_d];
                                if (cardName === robotCard.name && robotCard.cardType !== undefined) {
                                    return this.cardToHtml(robotCard.cardType, cardName);
                                }
                            }
                        }
                    }
                    var card = new CardFinder_1.CardFinder().getCardByName(cardName, function (manifest) { return [
                        manifest.projectCards,
                        manifest.preludeCards,
                        manifest.standardProjects,
                        manifest.standardActions,
                    ]; });
                    if (card && card.cardType) {
                        return this.cardToHtml(card.cardType, data.value);
                    }
                    break;
                case LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT:
                    var globalEventName = data.value;
                    return '<span class="log-card background-color-global-event">' + this.$t(globalEventName) + '</span>';
                case LogMessageDataType_1.LogMessageDataType.TILE_TYPE:
                    var tileType = +data.value;
                    return this.$t(TileType_1.TileType.toString(tileType));
                default:
                    if (translatableMessageDataTypes.includes(data.type)) {
                        return this.$t(data.value);
                    }
            }
            return data.value;
        },
        safeMessage: function (message) {
            try {
                if (message === undefined) {
                    return 'undefined';
                }
                if (message.data === undefined) {
                    return "BUG: Unparseable message: " + message.message;
                }
                var data = message.data.map(function (datum) {
                    return (datum === undefined) ?
                        'undefined' :
                        ('(' + datum.type + ') ' + datum.value);
                });
                return "BUG: Unparseable message: " + message.message + ", (" + data.join(', ') + ")";
            }
            catch (err) {
                return "BUG: Unparseable message: " + message.message + " " + err.toString();
            }
        },
        messageToHTML: function (message) {
            try {
                var logEntryBullet = '';
                if (message.type !== LogMessageType_1.LogMessageType.NEW_GENERATION) {
                    var when = new Date(message.timestamp).toLocaleString();
                    var icon = message.playerId === undefined ? '&#x1f551;' : '&#x1f4ac;';
                    logEntryBullet = "<span title=\"" + when + "\">" + icon + "</span>";
                }
                if (message.type !== undefined && message.message !== undefined) {
                    message.message = this.$t(message.message);
                    return logEntryBullet + Log_1.Log.applyData(message, this.messageDataToHTML);
                }
            }
            catch (err) {
                return this.safeMessage(message);
            }
            return '';
        },
        messageClicked: function (message) {
            var _this = this;
            var datas = message.data;
            datas.forEach(function (data) {
                if (data.value === undefined) {
                    return;
                }
                if (data.type === LogMessageDataType_1.LogMessageDataType.CARD) {
                    var cardName = data.value;
                    var index = _this.cards.indexOf(cardName);
                    if (index === -1) {
                        _this.cards.push(cardName);
                    }
                    else {
                        _this.cards.splice(index, 1);
                    }
                }
                if (data.type === LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT) {
                    var globalEventName = data.value;
                    var index = _this.globalEventNames.indexOf(globalEventName);
                    if (index === -1) {
                        _this.globalEventNames.push(globalEventName);
                    }
                    else {
                        _this.globalEventNames.splice(index, 1);
                    }
                }
            });
        },
        hideMe: function () {
            this.cards = [];
            this.globalEventNames = [];
        },
        getCrossHtml: function () {
            return '<i class=\'icon icon-cross\' />';
        },
        selectGeneration: function (gen) {
            if (gen !== this.selectedGeneration) {
                this.getLogsForGeneration(gen);
            }
            this.selectedGeneration = gen;
        },
        getLogsForGeneration: function (generation) {
            var _this = this;
            var messages = this.messages;
            if (logRequest !== undefined) {
                logRequest.abort();
                logRequest = undefined;
            }
            var xhr = new XMLHttpRequest();
            logRequest = xhr;
            xhr.open('GET', "/api/game/logs?id=" + this.id + "&generation=" + generation);
            xhr.onerror = function () {
                console.error('error updating messages, unable to reach server');
            };
            xhr.onload = function () {
                if (xhr.status === 200) {
                    messages.splice(0, messages.length);
                    messages.push.apply(messages, xhr.response);
                    if (PreferencesManager_1.PreferencesManager.loadBoolean('enable_sounds') && window.location.search.includes('experimental=1')) {
                        SoundManager_1.SoundManager.newLog();
                    }
                    if (generation === _this.generation) {
                        _this.$nextTick(_this.scrollToEnd);
                    }
                }
                else {
                    console.error("error updating messages, response code " + xhr.status);
                }
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        getClassesGenIndicator: function (gen) {
            var classes = ['log-gen-indicator'];
            if (gen === this.selectedGeneration) {
                classes.push('log-gen-indicator--selected');
            }
            return classes.join(' ');
        },
        getGenerationsRange: function () {
            var generations = [];
            for (var i = 1; i <= this.generation; i++) {
                generations.push(i);
            }
            return generations;
        },
        getTitleClasses: function () {
            var classes = ['log-title'];
            classes.push(utils_1.playerColorClass(this.color.toLowerCase(), 'shadow'));
            return classes.join(' ');
        },
        lastGenerationClass: function () {
            return this.lastSoloGeneration === this.generation ? 'last-generation blink-animation' : '';
        },
        getGlobalEvent: function (globalEventName) {
            var globalEvent = GlobalEventDealer_1.getGlobalEventByName(globalEventName);
            if (globalEvent) {
                return {
                    name: globalEvent.name,
                    description: globalEvent.description,
                    revealed: globalEvent.revealedDelegate,
                    current: globalEvent.currentDelegate,
                };
            }
            return {
                name: globalEventName,
                description: 'global event not found',
                revealed: PartyName_1.PartyName.GREENS,
                current: PartyName_1.PartyName.GREENS,
            };
        },
        getResourcesOnCard: function (cardName) {
            for (var _i = 0, _a = this.players; _i < _a.length; _i++) {
                var player = _a[_i];
                var foundCard = player.playedCards.find(function (card) { return card.name === cardName; });
                if (foundCard !== undefined)
                    return foundCard.resources;
            }
            return undefined;
        },
    },
    mounted: function () {
        this.getLogsForGeneration(this.generation);
    },
});
