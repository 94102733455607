"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenusianInsects = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Card_1 = require("../Card");
var VenusianInsects = (function (_super) {
    __extends(VenusianInsects, _super);
    function VenusianInsects() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.VENUSIAN_INSECTS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.MICROBE],
            cost: 5,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(12); }),
            metadata: {
                cardNumber: '260',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Microbe to this card.', function (eb) {
                        eb.empty().startAction.microbes(1);
                    }).br;
                    b.vpText('1 VP for every 2nd Microbe on this card.');
                }),
                description: 'Requires Venus 12%.',
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.microbes(1, 2),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    VenusianInsects.prototype.play = function () {
        return undefined;
    };
    VenusianInsects.prototype.canAct = function () {
        return true;
    };
    VenusianInsects.prototype.action = function (player) {
        player.addResourceTo(this);
        return undefined;
    };
    VenusianInsects.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 2);
    };
    return VenusianInsects;
}(Card_1.Card));
exports.VenusianInsects = VenusianInsects;
