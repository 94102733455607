"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaFirstIncorporated = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var LunaFirstIncorporated = (function (_super) {
    __extends(LunaFirstIncorporated, _super);
    function LunaFirstIncorporated() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.LUNA_FIRST_INCORPORATED,
            tags: [Tags_1.Tags.MOON],
            startingMegaCredits: 40,
            metadata: {
                description: 'You start with 40 M€, 2 steel, and 2 Titanium.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(40).steel(2).titanium(2).br;
                    b.effect('When you raise any Moon Rate, increase your M€ production 1 step per step.', function (eb) {
                        eb.moonColonyRate({ size: Size_1.Size.SMALL }).slash()
                            .moonMiningRate({ size: Size_1.Size.SMALL }).slash()
                            .moonLogisticsRate({ size: Size_1.Size.SMALL })
                            .startEffect.production(function (pb) { return pb.megacredits(1); });
                    }).br,
                        b.effect('When any player raises any Moon Rate, gain 1M€ per step.', function (eb) {
                            eb.moonColonyRate({ size: Size_1.Size.SMALL, all: Options_1.all }).slash()
                                .moonMiningRate({ size: Size_1.Size.SMALL, all: Options_1.all }).slash()
                                .moonLogisticsRate({ size: Size_1.Size.SMALL, all: Options_1.all })
                                .startEffect.megacredits(1);
                        }).br;
                }),
            },
        }) || this;
    }
    LunaFirstIncorporated.prototype.play = function (player) {
        MoonExpansion_1.MoonExpansion.moonData(player.game).lunaFirstPlayer = player;
        player.steel = 2;
        player.titanium = 2;
        return undefined;
    };
    return LunaFirstIncorporated;
}(Card_1.Card));
exports.LunaFirstIncorporated = LunaFirstIncorporated;
