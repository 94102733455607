"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSpectatorId = exports.isPlayerId = exports.generateClassString = exports.intersection = exports.range = exports.playerColorClass = void 0;
var playerColorClass = function (color, type) {
    var prefix = {
        shadow: 'player_shadow_color_',
        bg_transparent: 'player_translucent_bg_color_',
        bg: 'player_bg_color_',
    }[type];
    return "" + prefix + color;
};
exports.playerColorClass = playerColorClass;
var range = function (n) { return Array.from(Array(n).keys()); };
exports.range = range;
function intersection(a, b) {
    return a.filter(function (e) { return b.includes(e); });
}
exports.intersection = intersection;
;
var generateClassString = function (classes) { return classes.join(' ').trimStart(); };
exports.generateClassString = generateClassString;
function isPlayerId(id) {
    return id.charAt(0) === 'p';
}
exports.isPlayerId = isPlayerId;
function isSpectatorId(id) {
    return id.charAt(0) === 's';
}
exports.isSpectatorId = isSpectatorId;
