"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var BoardName_1 = require("@/boards/BoardName");
var RandomMAOptionType_1 = require("@/RandomMAOptionType");
var PoliticalAgendas_1 = require("@/turmoil/PoliticalAgendas");
exports.default = vue_1.default.extend({
    name: 'game-setup-detail',
    props: {
        playerNumber: {
            type: Number,
        },
        gameOptions: {
            type: Object,
        },
        lastSoloGeneration: {
            type: Number,
        },
    },
    methods: {
        isPoliticalAgendasOn: function () {
            return (this.gameOptions.politicalAgendasExtension !== PoliticalAgendas_1.AgendaStyle.STANDARD);
        },
        getBoardColorClass: function (boardName) {
            if (boardName === BoardName_1.BoardName.ORIGINAL) {
                return 'game-config board-tharsis map';
            }
            else if (boardName === BoardName_1.BoardName.HELLAS) {
                return 'game-config board-hellas map';
            }
            else if (boardName === BoardName_1.BoardName.ELYSIUM) {
                return 'game-config board-elysium map';
            }
            else {
                return 'game-config board-other map';
            }
        },
        isRandomMANone: function () {
            return this.gameOptions.randomMA === RandomMAOptionType_1.RandomMAOptionType.NONE;
        },
        isRandomMALimited: function () {
            return this.gameOptions.randomMA === RandomMAOptionType_1.RandomMAOptionType.LIMITED;
        },
        isRandomMAUnlimited: function () {
            return this.gameOptions.randomMA === RandomMAOptionType_1.RandomMAOptionType.UNLIMITED;
        },
    },
});
