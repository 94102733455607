"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImmigrationShuttles = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var ImmigrationShuttles = (function (_super) {
    __extends(ImmigrationShuttles, _super);
    function ImmigrationShuttles() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.IMMIGRATION_SHUTTLES,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 31,
            metadata: {
                cardNumber: '198',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(5); }).br;
                    b.vpText('1 VP for every 3rd City in play.');
                }),
                description: 'Increase your M€ production 5 steps.',
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.cities(1, 3, true),
            },
        }) || this;
    }
    ImmigrationShuttles.prototype.getVictoryPoints = function (player) {
        return Math.floor(player.game.getCitiesInPlay() / 3);
    };
    ImmigrationShuttles.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 5);
        return undefined;
    };
    return ImmigrationShuttles;
}(Card_1.Card));
exports.ImmigrationShuttles = ImmigrationShuttles;
