"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsteroidDeflectionSystem = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Size_1 = require("../render/Size");
var Units_1 = require("../../Units");
var Options_1 = require("../Options");
var AsteroidDeflectionSystem = (function (_super) {
    __extends(AsteroidDeflectionSystem, _super);
    function AsteroidDeflectionSystem() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ASTEROID_DEFLECTION_SYSTEM,
            tags: [Tags_1.Tags.SPACE, Tags_1.Tags.EARTH, Tags_1.Tags.BUILDING],
            cost: 13,
            resourceType: ResourceType_1.ResourceType.ASTEROID,
            productionBox: Units_1.Units.of({ energy: -1 }),
            metadata: {
                cardNumber: 'X14',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('REVEAL AND DISCARD the top card of the deck. If it has a space tag, add an asteroid here.', function (eb) {
                        eb.empty().startAction.cards(1).asterix().nbsp.space({ played: Options_1.played }).colon().asteroids(1);
                    }).br;
                    b.production(function (pb) { return pb.minus().energy(1); }).text('opponents may not remove your plants', Size_1.Size.SMALL, true);
                }),
                description: {
                    text: 'Decrease your energy production 1 step. 1VP per asteroid on this card.',
                    align: 'left',
                },
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.asteroids(1, 1),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    AsteroidDeflectionSystem.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    AsteroidDeflectionSystem.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        return undefined;
    };
    AsteroidDeflectionSystem.prototype.canAct = function () {
        return true;
    };
    AsteroidDeflectionSystem.prototype.action = function (player) {
        var topCard = player.game.dealer.dealCard(player.game);
        if (topCard.tags.includes(Tags_1.Tags.SPACE))
            player.addResourceTo(this);
        player.game.log('${0} revealed and discarded ${1}', function (b) { return b.player(player).card(topCard); });
        player.game.dealer.discard(topCard);
        return undefined;
    };
    AsteroidDeflectionSystem.prototype.getVictoryPoints = function () {
        return this.resourceCount;
    };
    return AsteroidDeflectionSystem;
}(Card_1.Card));
exports.AsteroidDeflectionSystem = AsteroidDeflectionSystem;
