"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Log = void 0;
var Log = (function () {
    function Log() {
    }
    Log.applyData = function (message, cb) {
        return message.message.replace(/\$\{(\d{1,2})\}/gi, function (_match, idx) {
            return cb(message.data[idx], idx);
        });
    };
    return Log;
}());
exports.Log = Log;
