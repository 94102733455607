"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CuttingEdgeTechnology = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderItem_1 = require("../render/CardRenderItem");
var CuttingEdgeTechnology = (function (_super) {
    __extends(CuttingEdgeTechnology, _super);
    function CuttingEdgeTechnology() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.CUTTING_EDGE_TECHNOLOGY,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 12,
            metadata: {
                cardNumber: 'X18',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When playing a card with a requirement, you pay 2 M€ less for it.', function (eb) {
                        eb.cards(1, { secondaryTag: CardRenderItem_1.AltSecondaryTag.REQ }).startEffect.megacredits(-2);
                    });
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    CuttingEdgeTechnology.prototype.play = function () {
        return undefined;
    };
    CuttingEdgeTechnology.prototype.getCardDiscount = function (_player, card) {
        if (card.requirements !== undefined)
            return 2;
        return 0;
    };
    CuttingEdgeTechnology.prototype.getVictoryPoints = function () {
        return 1;
    };
    return CuttingEdgeTechnology;
}(Card_1.Card));
exports.CuttingEdgeTechnology = CuttingEdgeTechnology;
