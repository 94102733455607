"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RotatorImpacts = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var constants_1 = require("../../constants");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var RotatorImpacts = (function (_super) {
    __extends(RotatorImpacts, _super);
    function RotatorImpacts() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.ROTATOR_IMPACTS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.SPACE],
            cost: 6,
            resourceType: ResourceType_1.ResourceType.ASTEROID,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(14, { max: Options_1.max }); }),
            metadata: {
                cardNumber: '243',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 6 M€ to add an asteroid resource to this card [TITANIUM MAY BE USED].', function (eb) {
                        eb.megacredits(6).openBrackets.titanium(1).closeBrackets.startAction.asteroids(1);
                    }).br;
                    b.action('Spend 1 resource from this card to increase Venus 1 step.', function (eb) {
                        eb.or().asteroids(1).startAction.venus(1);
                    });
                }),
                description: 'Venus must be 14% or lower',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    RotatorImpacts.prototype.play = function () {
        return undefined;
    };
    RotatorImpacts.prototype.canAct = function (player) {
        var venusMaxed = player.game.getVenusScaleLevel() === constants_1.MAX_VENUS_SCALE;
        var canSpendResource = this.resourceCount > 0 && !venusMaxed;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !venusMaxed) {
            return player.canAfford(6, { titanium: true }) || (canSpendResource && player.canAfford(constants_1.REDS_RULING_POLICY_COST));
        }
        return player.canAfford(6, { titanium: true }) || canSpendResource;
    };
    RotatorImpacts.prototype.action = function (player) {
        var _this = this;
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Pay 6 to add 1 asteroid to this card', 'Pay', function () { return _this.addResource(player); });
        var spendResource = new SelectOption_1.SelectOption('Remove 1 asteroid to raise Venus 1 step', 'Remove asteroid', function () { return _this.spendResource(player); });
        if (this.resourceCount > 0 && player.game.getVenusScaleLevel() < constants_1.MAX_VENUS_SCALE) {
            opts.push(spendResource);
        }
        else {
            return this.addResource(player);
        }
        if (player.canAfford(6, { titanium: true })) {
            opts.push(addResource);
        }
        else {
            return this.spendResource(player);
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts)))();
    };
    RotatorImpacts.prototype.addResource = function (player) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 6, { canUseTitanium: true, title: 'Select how to pay for action' }));
        player.addResourceTo(this, { log: true });
        return undefined;
    };
    RotatorImpacts.prototype.spendResource = function (player) {
        player.removeResourceFrom(this);
        player.game.increaseVenusScaleLevel(player, 1);
        player.game.log('${0} removed an asteroid resource to increase Venus scale 1 step', function (b) { return b.player(player); });
        return undefined;
    };
    return RotatorImpacts;
}(Card_1.Card));
exports.RotatorImpacts = RotatorImpacts;
