"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HydrogenProcessingPlant = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var Resources_1 = require("../../Resources");
var HydrogenProcessingPlant = (function (_super) {
    __extends(HydrogenProcessingPlant, _super);
    function HydrogenProcessingPlant() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.HYDROGEN_PROCESSING_PLANT,
            cost: 9,
            tags: [Tags_1.Tags.BUILDING, Tags_1.Tags.ENERGY],
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(3); }),
            metadata: {
                cardNumber: 'Pf19',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.energy(1); }).slash().oceans(2).br;
                    b.minus().oxygen(1).br;
                }),
                victoryPoints: -1,
                description: 'Oxygen level must be 3% or higher. Decrease oxygen level 1% ' +
                    'Raise your energy production 1 step for every two ocean tiles on Mars.',
            },
        }) || this;
    }
    HydrogenProcessingPlant.prototype.getVictoryPoints = function () {
        return -1;
    };
    HydrogenProcessingPlant.prototype.produce = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, Math.floor(player.game.board.getOceansOnBoard() / 2), { log: true });
    };
    HydrogenProcessingPlant.prototype.play = function (player) {
        player.game.increaseOxygenLevel(player, -1);
        this.produce(player);
        return undefined;
    };
    return HydrogenProcessingPlant;
}(Card_1.Card));
exports.HydrogenProcessingPlant = HydrogenProcessingPlant;
