"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Colony_vue_1 = require("@/client/components/Colony.vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
exports.default = vue_1.default.extend({
    name: 'SelectColony',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data: function () {
        return {
            selectedColony: undefined,
        };
    },
    components: {
        'colony': Colony_vue_1.default,
        Button: Button_vue_1.default,
    },
    methods: {
        canSave: function () {
            return this.selectedColony !== undefined;
        },
        saveData: function () {
            var _a;
            var result = [];
            result.push([]);
            if (this.canSave()) {
                result[0].push((_a = this.selectedColony) !== null && _a !== void 0 ? _a : '');
            }
            this.onsave(result);
        },
    },
});
