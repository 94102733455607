"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectProductionToLoseDeferred = void 0;
var SelectProductionToLose_1 = require("../inputs/SelectProductionToLose");
var DeferredAction_1 = require("./DeferredAction");
var Units_1 = require("../Units");
var SelectProductionToLoseDeferred = (function () {
    function SelectProductionToLoseDeferred(player, unitsToLose, title) {
        if (title === void 0) { title = "Choose " + unitsToLose + " unit(s) of production to lose"; }
        this.player = player;
        this.unitsToLose = unitsToLose;
        this.title = title;
        this.priority = DeferredAction_1.Priority.LOSE_RESOURCE_OR_PRODUCTION;
    }
    SelectProductionToLoseDeferred.prototype.execute = function () {
        var _this = this;
        return new SelectProductionToLose_1.SelectProductionToLose(this.title, this.unitsToLose, this.player, function (production) {
            _this.player.adjustProduction(Units_1.Units.negative(production), { log: true });
            return undefined;
        });
    };
    return SelectProductionToLoseDeferred;
}());
exports.SelectProductionToLoseDeferred = SelectProductionToLoseDeferred;
