"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
var CardRenderSymbolComponent_vue_1 = require("@/client/components/card/CardRenderSymbolComponent.vue");
var CardRenderer_1 = require("@/cards/render/CardRenderer");
var CardProductionBoxComponent_vue_1 = require("@/client/components/card/CardProductionBoxComponent.vue");
var CardRenderTileComponent_vue_1 = require("@/client/components/card/CardRenderTileComponent.vue");
var CardRenderSymbol_1 = require("@/cards/render/CardRenderSymbol");
var CardRenderItem_1 = require("@/cards/render/CardRenderItem");
var CardDescription_vue_1 = require("@/client/components/card/CardDescription.vue");
exports.default = vue_1.default.extend({
    name: 'CardRenderEffectBoxComponent',
    props: {
        effectData: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
        CardRenderSymbolComponent: CardRenderSymbolComponent_vue_1.default,
        CardProductionBoxComponent: CardProductionBoxComponent_vue_1.default,
        CardRenderTileComponent: CardRenderTileComponent_vue_1.default,
        CardDescription: CardDescription_vue_1.default,
    },
    methods: {
        getClasses: function () {
            return 'card-effect-box';
        },
        isItem: function (rowItem) {
            return rowItem instanceof CardRenderItem_1.CardRenderItem;
        },
        isSymbol: function (rowItem) {
            return rowItem instanceof CardRenderSymbol_1.CardRenderSymbol;
        },
        isProductionBox: function (rowItem) {
            return rowItem instanceof CardRenderer_1.CardRenderProductionBox;
        },
        isTile: function (rowItem) {
            return rowItem instanceof CardRenderer_1.CardRenderTile;
        },
        hasDescription: function () {
            return this.effectData.description !== undefined;
        },
    },
});
