"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Phase_1 = require("@/Phase");
var constants = require("@/constants");
exports.default = vue_1.default.extend({
    name: 'games-overview',
    data: function () {
        return {
            constants: constants,
            serverId: '',
            games: {},
        };
    },
    mounted: function () {
        this.serverId = (new URL(location.href)).searchParams.get('serverId') || '';
        this.getGames();
    },
    methods: {
        getGames: function () {
            var vueApp = this;
            var xhr = new XMLHttpRequest();
            xhr.open('GET', '/api/games?serverId=' + this.serverId);
            xhr.onerror = function () {
                alert('Error getting games data');
            };
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var result = xhr.response;
                    if (result instanceof Array) {
                        result.forEach(function (gameId) {
                            vueApp.getGame(gameId.id);
                        });
                    }
                    else {
                        alert('Unexpected response fetching games from API');
                    }
                }
                else {
                    alert('Unexpected response fetching games from API');
                }
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        getGame: function (gameId) {
            var vueApp = this;
            var xhr = new XMLHttpRequest();
            xhr.open('GET', '/api/game?id=' + gameId);
            xhr.onerror = function () {
                alert('Error getting game data');
            };
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var result = xhr.response;
                    if (result instanceof Object) {
                        vue_1.default.set(vueApp.games, gameId, result);
                    }
                    else {
                        alert('Unexpected response fetching game \'+gameId+\' from API');
                    }
                }
                else {
                    alert('Unexpected response fetching game \'+gameId+\' from API');
                }
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        isGameRunning: function (gamePhase) {
            return (gamePhase === Phase_1.Phase.END) ? false : true;
        },
    },
});
