"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterplanetaryTransport = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var SpaceType_1 = require("../../SpaceType");
var TileType_1 = require("../../TileType");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var InterplanetaryTransport = (function (_super) {
    __extends(InterplanetaryTransport, _super);
    function InterplanetaryTransport() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.INTERPLANETARY_TRANSPORT,
            cost: 15,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.JOVIAN, Tags_1.Tags.SPACE],
            metadata: {
                cardNumber: 'Pf43',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).slash().city({ all: Options_1.all, secondaryTag: Tags_1.Tags.SPACE }).asterix;
                }),
                description: 'Increase your MC production 1 step for every offworld city tile.',
                victoryPoints: 1,
            },
        }) || this;
    }
    InterplanetaryTransport.prototype.play = function (player) {
        var offWorldCities = player.game.board.getSpaces(SpaceType_1.SpaceType.COLONY, player).filter(function (space) { var _a; return ((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.CITY; });
        player.addProduction(Resources_1.Resources.MEGACREDITS, offWorldCities.length, { log: true });
        return undefined;
    };
    return InterplanetaryTransport;
}(Card_1.Card));
exports.InterplanetaryTransport = InterplanetaryTransport;
