"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitanAirScrapping = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants_1 = require("../../constants");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var TitanAirScrapping = (function (_super) {
    __extends(TitanAirScrapping, _super);
    function TitanAirScrapping() {
        var _this = _super.call(this, {
            cost: 21,
            tags: [Tags_1.Tags.JOVIAN],
            name: CardName_1.CardName.TITAN_AIRSCRAPPING,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: 'C43',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 titanium to add 2 floaters here.', function (eb) {
                        eb.titanium(1).startAction.floaters(2);
                    }).br;
                    b.or().br;
                    b.action('Spend 2 floaters here to increase your TR 1 step.', function (eb) {
                        eb.floaters(2).startAction.tr(1);
                    });
                }),
                victoryPoints: 2,
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    TitanAirScrapping.prototype.canAct = function (player) {
        var hasTitanium = player.titanium > 0;
        var hasResources = this.resourceCount >= 2;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return hasTitanium || (player.canAfford(constants_1.REDS_RULING_POLICY_COST) && hasResources);
        }
        return hasTitanium || hasResources;
    };
    TitanAirScrapping.prototype.action = function (player) {
        var _this = this;
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Spend 1 titanium to add 2 floaters on this card', 'Spend titanium', function () { return _this.addResource(player); });
        var spendResource = new SelectOption_1.SelectOption('Remove 2 floaters on this card to increase your TR 1 step', 'Remove floaters', function () { return _this.spendResource(player); });
        if (this.resourceCount >= 2 && player.titanium > 0) {
            var redsAreRuling = PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS);
            if (!redsAreRuling || (redsAreRuling && player.canAfford(constants_1.REDS_RULING_POLICY_COST))) {
                opts.push(spendResource);
            }
            opts.push(addResource);
        }
        else if (player.titanium > 0) {
            return this.addResource(player);
        }
        else {
            return this.spendResource(player);
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts)))();
    };
    TitanAirScrapping.prototype.addResource = function (player) {
        player.addResourceTo(this, 2);
        player.titanium--;
        return undefined;
    };
    TitanAirScrapping.prototype.spendResource = function (player) {
        player.removeResourceFrom(this, 2);
        player.increaseTerraformRating();
        return undefined;
    };
    TitanAirScrapping.prototype.play = function () {
        return undefined;
    };
    TitanAirScrapping.prototype.getVictoryPoints = function () {
        return 2;
    };
    return TitanAirScrapping;
}(Card_1.Card));
exports.TitanAirScrapping = TitanAirScrapping;
