"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var dialogPolyfill = require('dialog-polyfill');
exports.default = vue_1.default.extend({
    name: 'ConfirmDialog',
    props: {
        message: {
            type: String,
        },
        enableDontShowAgainCheckbox: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            hide: false,
        };
    },
    watch: {
        hide: function () {
            this.$emit('hide', this.hide);
        },
    },
    methods: {
        accept: function () {
            this.$emit('accept');
        },
        dismiss: function () {
            this.$emit('dismiss');
        },
        show: function () {
            this.$refs['dialog'].showModal();
        },
    },
    mounted: function () {
        if (!window.HTMLDialogElement)
            dialogPolyfill.default.registerDialog(this.$refs['dialog']);
    },
});
