"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartyHooks = void 0;
var PartyName_1 = require("./PartyName");
var SpaceType_1 = require("../../SpaceType");
var Phase_1 = require("../../Phase");
var Resources_1 = require("../../Resources");
var Greens_1 = require("./Greens");
var TurmoilPolicy_1 = require("../TurmoilPolicy");
var PoliticalAgendas_1 = require("../PoliticalAgendas");
var Turmoil_1 = require("../Turmoil");
var PartyHooks = (function () {
    function PartyHooks() {
    }
    PartyHooks.applyMarsFirstRulingPolicy = function (player, spaceType) {
        if (this.shouldApplyPolicy(player, PartyName_1.PartyName.MARS, TurmoilPolicy_1.TurmoilPolicy.MARS_FIRST_DEFAULT_POLICY) &&
            spaceType !== SpaceType_1.SpaceType.COLONY) {
            player.addResource(Resources_1.Resources.STEEL, 1);
        }
    };
    PartyHooks.applyGreensRulingPolicy = function (player, space) {
        if (this.shouldApplyPolicy(player, PartyName_1.PartyName.GREENS, TurmoilPolicy_1.TurmoilPolicy.GREENS_DEFAULT_POLICY)) {
            var greensPolicy = Greens_1.GREENS_POLICY_1;
            greensPolicy.onTilePlaced(player, space);
        }
    };
    PartyHooks.shouldApplyPolicy = function (player, partyName, policyId) {
        var game = player.game;
        return Turmoil_1.Turmoil.ifTurmoilElse(game, function (turmoil) {
            if (game.phase !== Phase_1.Phase.ACTION)
                return false;
            var rulingParty = turmoil.rulingParty;
            if (rulingParty === undefined)
                return false;
            if (policyId === undefined) {
                policyId = rulingParty.policies[0].id;
            }
            var currentPolicyId = (turmoil.politicalAgendasData === undefined) ?
                rulingParty.policies[0].id :
                PoliticalAgendas_1.PoliticalAgendas.currentAgenda(turmoil).policyId;
            return rulingParty.name === partyName && currentPolicyId === policyId;
        }, function () { return false; });
    };
    return PartyHooks;
}());
exports.PartyHooks = PartyHooks;
