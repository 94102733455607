"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Shared_1 = require("@/client/components/overview/Shared");
exports.default = vue_1.default.extend({
    name: 'OverviewSettings',
    methods: {
        toggleTagsView: function () {
            this.$root.setVisibilityState('tags_concise', !this.$root.getVisibilityState('tags_concise'));
        },
        getTagToggleLabel: function () {
            return Shared_1.Shared.isTagsViewConcise(this.$root) ? 'full' : 'concise';
        },
    },
});
