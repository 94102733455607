"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaConference = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var TileType_1 = require("../../TileType");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var LunaConference = (function (_super) {
    __extends(LunaConference, _super);
    function LunaConference() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_CONFERENCE,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.MOON],
            cost: 5,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.SCIENTISTS); }),
            metadata: {
                description: 'Requires that Scientists are ruling or that you have 2 delegates there. ' +
                    'Gain 2 M€ per road tile on the Moon. Gain 2M€ per colony tile on the Moon.',
                cardNumber: 'M58',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(2).slash().moonRoad({ size: Size_1.Size.SMALL, all: Options_1.all }).br;
                    b.megacredits(2).slash().moonColony({ size: Size_1.Size.SMALL, all: Options_1.all }).br;
                }),
            },
        }) || this;
    }
    ;
    LunaConference.prototype.play = function (player) {
        var moonRoadCount = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_ROAD, { surfaceOnly: true }).length;
        var moonColonyCount = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_COLONY, { surfaceOnly: true }).length;
        player.addResource(Resources_1.Resources.MEGACREDITS, (moonRoadCount + moonColonyCount) * 2, { log: true });
        return undefined;
    };
    return LunaConference;
}(Card_1.Card));
exports.LunaConference = LunaConference;
