"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLAYER_INTERFACE_TAGS_ORDER = void 0;
var vue_1 = require("vue");
var TagCount_vue_1 = require("@/client/components/TagCount.vue");
var Tags_1 = require("@/cards/Tags");
var CardName_1 = require("@/CardName");
var SpecialTags_1 = require("@/cards/SpecialTags");
var PlayerTagDiscount_vue_1 = require("@/client/components/overview/PlayerTagDiscount.vue");
var JovianMultiplier_vue_1 = require("@/client/components/overview/JovianMultiplier.vue");
var PartyName_1 = require("@/turmoil/parties/PartyName");
var TurmoilPolicy_1 = require("@/turmoil/TurmoilPolicy");
var Shared_1 = require("@/client/components/overview/Shared");
var JOVIAN_MULTIPLIERS = [
    CardName_1.CardName.IO_MINING_INDUSTRIES,
    CardName_1.CardName.GANYMEDE_COLONY,
    CardName_1.CardName.WATER_IMPORT_FROM_EUROPA,
];
var hasDiscount = function (tag, card) {
    if (tag === SpecialTags_1.SpecialTags.COLONY_COUNT || tag === SpecialTags_1.SpecialTags.CITY_COUNT)
        return false;
    if (card.discount === undefined) {
        return false;
    }
    if (tag === 'all') {
        return card.discount.tag === undefined;
    }
    return card.discount.tag === tag;
};
var getDiscountAmount = function (tag, card) {
    var _a;
    if (hasDiscount(tag, card)) {
        return ((_a = card === null || card === void 0 ? void 0 : card.discount) === null || _a === void 0 ? void 0 : _a.amount) || 0;
    }
    return 0;
};
exports.PLAYER_INTERFACE_TAGS_ORDER = [
    Tags_1.Tags.BUILDING,
    Tags_1.Tags.SPACE,
    Tags_1.Tags.SCIENCE,
    Tags_1.Tags.ENERGY,
    Tags_1.Tags.EARTH,
    Tags_1.Tags.JOVIAN,
    Tags_1.Tags.VENUS,
    Tags_1.Tags.PLANT,
    Tags_1.Tags.MICROBE,
    Tags_1.Tags.ANIMAL,
    Tags_1.Tags.CITY,
    Tags_1.Tags.MOON,
    'separator',
    Tags_1.Tags.EVENT,
    SpecialTags_1.SpecialTags.NONE,
    Tags_1.Tags.WILDCARD,
    SpecialTags_1.SpecialTags.INFLUENCE,
    SpecialTags_1.SpecialTags.CITY_COUNT,
    SpecialTags_1.SpecialTags.COLONY_COUNT,
];
var isTagInGame = function (tag, game) {
    if (game.gameOptions.coloniesExtension === false && tag === SpecialTags_1.SpecialTags.COLONY_COUNT) {
        return false;
    }
    if (game.turmoil === undefined && tag === SpecialTags_1.SpecialTags.INFLUENCE) {
        return false;
    }
    if (game.gameOptions.venusNextExtension === false && tag === Tags_1.Tags.VENUS) {
        return false;
    }
    if (game.gameOptions.moonExpansion === false && tag === Tags_1.Tags.MOON) {
        return false;
    }
    return true;
};
exports.default = vue_1.default.extend({
    name: 'PlayerTags',
    props: {
        playerView: {
            type: Object,
        },
        player: {
            type: Object,
        },
        hideZeroTags: {
            type: Boolean,
        },
    },
    components: {
        'tag-count': TagCount_vue_1.default,
        PlayerTagDiscount: PlayerTagDiscount_vue_1.default,
        JovianMultiplier: JovianMultiplier_vue_1.default,
    },
    computed: {
        isThisPlayer: function () {
            var _a;
            return this.player.color === ((_a = this.playerView.thisPlayer) === null || _a === void 0 ? void 0 : _a.color);
        },
    },
    methods: {
        showColonyCount: function () {
            return this.playerView.game.gameOptions.coloniesExtension;
        },
        showInfluence: function () {
            return this.playerView.game.turmoil !== undefined;
        },
        showVenus: function () {
            return this.playerView.game.gameOptions.venusNextExtension;
        },
        showMoon: function () {
            return this.playerView.game.gameOptions.moonExpansion;
        },
        getTagsPlaceholders: function () {
            var _this = this;
            var tags = exports.PLAYER_INTERFACE_TAGS_ORDER;
            return tags.filter(function (tag) {
                return isTagInGame(tag, _this.playerView.game);
            });
        },
        getCardCount: function () {
            if (this.player.cardsInHandNbr) {
                return this.player.cardsInHandNbr;
            }
            return 0;
        },
        getTR: function () {
            return this.player.terraformRating;
        },
        getVpCount: function () {
            return this.player.victoryPointsBreakdown.total;
        },
        hideVpCount: function () {
            return !this.playerView.game.gameOptions.showOtherPlayersVP && !this.isThisPlayer;
        },
        showShortTags: function () {
            if (this.hideZeroTags === true)
                return true;
            return Shared_1.Shared.isTagsViewConcise(this.$root);
        },
        hasTagDiscount: function (tag) {
            var _a, _b, _c;
            for (var _i = 0, _d = __spreadArray(__spreadArray([], this.player.playedCards), [this.player.corporationCard]); _i < _d.length; _i++) {
                var card = _d[_i];
                if (card !== undefined) {
                    if (hasDiscount(tag, card)) {
                        return true;
                    }
                }
            }
            var turmoil = this.playerView.game.turmoil;
            if (tag === Tags_1.Tags.SPACE &&
                turmoil && turmoil.ruling === PartyName_1.PartyName.UNITY &&
                ((_a = turmoil.politicalAgendas) === null || _a === void 0 ? void 0 : _a.unity.policyId) === TurmoilPolicy_1.TurmoilPolicy.UNITY_POLICY_4) {
                return true;
            }
            if (tag === 'all' && ((_c = (_b = this.playerView.thisPlayer) === null || _b === void 0 ? void 0 : _b.cardDiscount) !== null && _c !== void 0 ? _c : 0) > 0) {
                return true;
            }
            return false;
        },
        getTagDiscountAmount: function (tag) {
            var _a, _b, _c, _d;
            var discount = 0;
            for (var _i = 0, _e = __spreadArray(__spreadArray([], this.player.playedCards), [this.player.corporationCard]); _i < _e.length; _i++) {
                var card = _e[_i];
                if (card !== undefined) {
                    discount += getDiscountAmount(tag, card);
                }
            }
            if (tag === Tags_1.Tags.SPACE && ((_a = this.playerView.game.turmoil) === null || _a === void 0 ? void 0 : _a.ruling) === PartyName_1.PartyName.UNITY) {
                if (((_b = this.playerView.game.turmoil.politicalAgendas) === null || _b === void 0 ? void 0 : _b.unity.policyId) === TurmoilPolicy_1.TurmoilPolicy.UNITY_POLICY_4)
                    discount += 2;
            }
            if (tag === 'all') {
                discount += (_d = (_c = this.playerView.thisPlayer) === null || _c === void 0 ? void 0 : _c.cardDiscount) !== null && _d !== void 0 ? _d : 0;
            }
            return discount;
        },
        getTagCount: function (tagName) {
            if (tagName === SpecialTags_1.SpecialTags.COLONY_COUNT && this.showColonyCount()) {
                return this.player.coloniesCount || 0;
            }
            if (tagName === SpecialTags_1.SpecialTags.INFLUENCE && this.showInfluence()) {
                return this.player.influence || 0;
            }
            if (tagName === SpecialTags_1.SpecialTags.CITY_COUNT) {
                return this.player.citiesCount || 0;
            }
            if (tagName === SpecialTags_1.SpecialTags.NONE) {
                return this.player.noTagsCount || 0;
            }
            var basicTagFound = this.player.tags.find(function (tag) { return tag.tag === tagName; });
            if (basicTagFound !== undefined) {
                return basicTagFound.count;
            }
            return 0;
        },
        showJovianMultipliers: function (tag) {
            return tag === Tags_1.Tags.JOVIAN && this.playerJovianMultipliersCount() > 0;
        },
        playerJovianMultipliersCount: function () {
            var multipliers = 0;
            for (var _i = 0, _a = this.player.playedCards; _i < _a.length; _i++) {
                var card = _a[_i];
                if (card !== undefined && JOVIAN_MULTIPLIERS.includes(card.name)) {
                    multipliers += 1;
                }
            }
            return multipliers;
        },
    },
});
