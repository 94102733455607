"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectPlayer = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectPlayer = (function () {
    function SelectPlayer(players, title, buttonLabel, cb) {
        if (buttonLabel === void 0) { buttonLabel = 'Save'; }
        this.players = players;
        this.title = title;
        this.buttonLabel = buttonLabel;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_PLAYER;
        this.buttonLabel = buttonLabel;
    }
    return SelectPlayer;
}());
exports.SelectPlayer = SelectPlayer;
