"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Titania = void 0;
var Colony_1 = require("../../colonies/Colony");
var Resources_1 = require("../../Resources");
var ColonyName_1 = require("../../colonies/ColonyName");
var ColonyBenefit_1 = require("../../colonies/ColonyBenefit");
var Titania = (function (_super) {
    __extends(Titania, _super);
    function Titania() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = ColonyName_1.ColonyName.TITANIA;
        _this.description = 'VP';
        _this.buildType = ColonyBenefit_1.ColonyBenefit.GAIN_VP;
        _this.buildQuantity = [5, 3, 2];
        _this.tradeType = ColonyBenefit_1.ColonyBenefit.GAIN_VP;
        _this.tradeQuantity = [2, 2, 2, 1, 1, 0, 0];
        _this.colonyBonusType = ColonyBenefit_1.ColonyBenefit.LOSE_RESOURCES;
        _this.colonyBonusQuantity = 3;
        _this.colonyBonusResource = Resources_1.Resources.MEGACREDITS;
        _this.shouldIncreaseTrack = Colony_1.ShouldIncreaseTrack.NO;
        return _this;
    }
    return Titania;
}(Colony_1.Colony));
exports.Titania = Titania;
