"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmallOpenPitMine = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var SmallOpenPitMine = (function (_super) {
    __extends(SmallOpenPitMine, _super);
    function SmallOpenPitMine() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.SMALL_OPEN_PIT_MINE,
            cost: 10,
            tags: [Tags_1.Tags.BUILDING],
            metadata: {
                cardNumber: 'Pf31',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.steel(2); }).or().production(function (pb) { return pb.titanium(1); });
                }),
                description: 'Increase your steel production 2 steps OR increase your titanium production 1 step.',
            },
        }) || this;
    }
    SmallOpenPitMine.prototype.produce = function (player) {
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Increase your steel production 2 steps', 'Increase', function () {
                player.addProduction(Resources_1.Resources.STEEL, 2, { log: true });
                return undefined;
            }), new SelectOption_1.SelectOption('Increase your titanium production 1 step', 'Increase', function () {
                player.addProduction(Resources_1.Resources.TITANIUM, 1, { log: true });
                return undefined;
            }));
        }));
    };
    SmallOpenPitMine.prototype.play = function (player) {
        this.produce(player);
        return undefined;
    };
    return SmallOpenPitMine;
}(Card_1.Card));
exports.SmallOpenPitMine = SmallOpenPitMine;
