"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CORP_ERA_CARD_MANIFEST = exports.BASE_CARD_MANIFEST = void 0;
var CardName_1 = require("../CardName");
var GameModule_1 = require("../GameModule");
var AcquiredCompany_1 = require("./base/AcquiredCompany");
var AdaptationTechnology_1 = require("./base/AdaptationTechnology");
var AdaptedLichen_1 = require("./base/AdaptedLichen");
var AdvancedAlloys_1 = require("./base/AdvancedAlloys");
var AdvancedEcosystems_1 = require("./base/AdvancedEcosystems");
var AerobrakedAmmoniaAsteroid_1 = require("./base/AerobrakedAmmoniaAsteroid");
var AICentral_1 = require("./base/AICentral");
var Algae_1 = require("./base/Algae");
var AntiGravityTechnology_1 = require("./base/AntiGravityTechnology");
var Ants_1 = require("./base/Ants");
var AquiferPumping_1 = require("./base/AquiferPumping");
var ArchaeBacteria_1 = require("./base/ArchaeBacteria");
var ArcticAlgae_1 = require("./base/ArcticAlgae");
var ArtificialLake_1 = require("./base/ArtificialLake");
var ArtificialPhotosynthesis_1 = require("./base/ArtificialPhotosynthesis");
var Asteroid_1 = require("./base/Asteroid");
var AsteroidMining_1 = require("./base/AsteroidMining");
var AsteroidMiningConsortium_1 = require("./base/AsteroidMiningConsortium");
var BeamFromAThoriumAsteroid_1 = require("./base/BeamFromAThoriumAsteroid");
var BeginnerCorporation_1 = require("./corporation/BeginnerCorporation");
var BigAsteroid_1 = require("./base/BigAsteroid");
var BiomassCombustors_1 = require("./base/BiomassCombustors");
var Birds_1 = require("./base/Birds");
var BlackPolarDust_1 = require("./base/BlackPolarDust");
var BreathingFilters_1 = require("./base/BreathingFilters");
var BribedCommittee_1 = require("./base/BribedCommittee");
var BuildingIndustries_1 = require("./base/BuildingIndustries");
var Bushes_1 = require("./base/Bushes");
var BusinessContacts_1 = require("./base/BusinessContacts");
var BusinessNetwork_1 = require("./base/BusinessNetwork");
var CallistoPenalMines_1 = require("./base/CallistoPenalMines");
var Capital_1 = require("./base/Capital");
var CarbonateProcessing_1 = require("./base/CarbonateProcessing");
var CardManifest_1 = require("./CardManifest");
var CaretakerContract_1 = require("./base/CaretakerContract");
var Cartel_1 = require("./base/Cartel");
var CEOsFavoriteProject_1 = require("./base/CEOsFavoriteProject");
var CloudSeeding_1 = require("./base/CloudSeeding");
var ColonizerTrainingCamp_1 = require("./base/ColonizerTrainingCamp");
var Comet_1 = require("./base/Comet");
var CommercialDistrict_1 = require("./base/CommercialDistrict");
var ConvoyFromEuropa_1 = require("./base/ConvoyFromEuropa");
var CorporateStronghold_1 = require("./base/CorporateStronghold");
var CrediCor_1 = require("./corporation/CrediCor");
var EcoLine_1 = require("./corporation/EcoLine");
var Helion_1 = require("./corporation/Helion");
var InterplanetaryCinematics_1 = require("./corporation/InterplanetaryCinematics");
var Inventrix_1 = require("./corporation/Inventrix");
var MiningGuild_1 = require("./corporation/MiningGuild");
var PhoboLog_1 = require("./corporation/PhoboLog");
var SaturnSystems_1 = require("./corporation/SaturnSystems");
var Teractor_1 = require("./corporation/Teractor");
var TharsisRepublic_1 = require("./corporation/TharsisRepublic");
var Thorgate_1 = require("./corporation/Thorgate");
var UnitedNationsMarsInitiative_1 = require("./corporation/UnitedNationsMarsInitiative");
var CupolaCity_1 = require("./base/CupolaCity");
var Decomposers_1 = require("./base/Decomposers");
var DeepWellHeating_1 = require("./base/DeepWellHeating");
var DeimosDown_1 = require("./base/DeimosDown");
var DesignedMicroOrganisms_1 = require("./base/DesignedMicroOrganisms");
var DevelopmentCenter_1 = require("./base/DevelopmentCenter");
var DomedCrater_1 = require("./base/DomedCrater");
var DustSeals_1 = require("./base/DustSeals");
var EarthCatapult_1 = require("./base/EarthCatapult");
var EarthOffice_1 = require("./base/EarthOffice");
var EcologicalZone_1 = require("./base/EcologicalZone");
var ElectroCatapult_1 = require("./base/ElectroCatapult");
var EnergySaving_1 = require("./base/EnergySaving");
var EnergyTapping_1 = require("./base/EnergyTapping");
var EOSChasmaNationalPark_1 = require("./base/EOSChasmaNationalPark");
var EquatorialMagnetizer_1 = require("./base/EquatorialMagnetizer");
var ExtremeColdFungus_1 = require("./base/ExtremeColdFungus");
var Farming_1 = require("./base/Farming");
var Fish_1 = require("./base/Fish");
var Flooding_1 = require("./base/Flooding");
var FoodFactory_1 = require("./base/FoodFactory");
var FueledGenerators_1 = require("./base/FueledGenerators");
var FuelFactory_1 = require("./base/FuelFactory");
var FusionPower_1 = require("./base/FusionPower");
var GanymedeColony_1 = require("./base/GanymedeColony");
var GeneRepair_1 = require("./base/GeneRepair");
var GeothermalPower_1 = require("./base/GeothermalPower");
var GHGFactories_1 = require("./base/GHGFactories");
var GHGProducingBacteria_1 = require("./base/GHGProducingBacteria");
var GiantIceAsteroid_1 = require("./base/GiantIceAsteroid");
var GiantSpaceMirror_1 = require("./base/GiantSpaceMirror");
var Grass_1 = require("./base/Grass");
var GreatDam_1 = require("./base/GreatDam");
var GreatEscarpmentConsortium_1 = require("./base/GreatEscarpmentConsortium");
var Greenhouses_1 = require("./base/Greenhouses");
var Hackers_1 = require("./base/Hackers");
var Heather_1 = require("./base/Heather");
var HeatTrappers_1 = require("./base/HeatTrappers");
var Herbivores_1 = require("./base/Herbivores");
var HiredRaiders_1 = require("./base/HiredRaiders");
var IceAsteroid_1 = require("./base/IceAsteroid");
var IceCapMelting_1 = require("./base/IceCapMelting");
var ImmigrantCity_1 = require("./base/ImmigrantCity");
var ImmigrationShuttles_1 = require("./base/ImmigrationShuttles");
var ImportedGHG_1 = require("./base/ImportedGHG");
var ImportedHydrogen_1 = require("./base/ImportedHydrogen");
var ImportedNitrogen_1 = require("./base/ImportedNitrogen");
var ImportOfAdvancedGHG_1 = require("./base/ImportOfAdvancedGHG");
var IndenturedWorkers_1 = require("./base/IndenturedWorkers");
var IndustrialCenter_1 = require("./base/IndustrialCenter");
var IndustrialMicrobes_1 = require("./base/IndustrialMicrobes");
var Insects_1 = require("./base/Insects");
var Insulation_1 = require("./base/Insulation");
var InterstellarColonyShip_1 = require("./base/InterstellarColonyShip");
var InventionContest_1 = require("./base/InventionContest");
var InventorsGuild_1 = require("./base/InventorsGuild");
var InvestmentLoan_1 = require("./base/InvestmentLoan");
var IoMiningIndustries_1 = require("./base/IoMiningIndustries");
var Ironworks_1 = require("./base/Ironworks");
var KelpFarming_1 = require("./base/KelpFarming");
var LagrangeObservatory_1 = require("./base/LagrangeObservatory");
var LakeMarineris_1 = require("./base/LakeMarineris");
var LandClaim_1 = require("./base/LandClaim");
var LargeConvoy_1 = require("./base/LargeConvoy");
var LavaFlows_1 = require("./base/LavaFlows");
var Lichen_1 = require("./base/Lichen");
var LightningHarvest_1 = require("./base/LightningHarvest");
var Livestock_1 = require("./base/Livestock");
var LocalHeatTrapping_1 = require("./base/LocalHeatTrapping");
var LunarBeam_1 = require("./base/LunarBeam");
var MagneticFieldDome_1 = require("./base/MagneticFieldDome");
var MagneticFieldGenerators_1 = require("./base/MagneticFieldGenerators");
var Mangrove_1 = require("./base/Mangrove");
var MarsUniversity_1 = require("./base/MarsUniversity");
var MartianRails_1 = require("./base/MartianRails");
var MassConverter_1 = require("./base/MassConverter");
var MediaArchives_1 = require("./base/MediaArchives");
var MediaGroup_1 = require("./base/MediaGroup");
var MedicalLab_1 = require("./base/MedicalLab");
var MethaneFromTitan_1 = require("./base/MethaneFromTitan");
var MicroMills_1 = require("./base/MicroMills");
var Mine_1 = require("./base/Mine");
var MineralDeposit_1 = require("./base/MineralDeposit");
var MiningArea_1 = require("./base/MiningArea");
var MiningExpedition_1 = require("./base/MiningExpedition");
var MiningRights_1 = require("./base/MiningRights");
var MirandaResort_1 = require("./base/MirandaResort");
var MoholeArea_1 = require("./base/MoholeArea");
var Moss_1 = require("./base/Moss");
var NaturalPreserve_1 = require("./base/NaturalPreserve");
var NitriteReducingBacteria_1 = require("./base/NitriteReducingBacteria");
var NitrogenRichAsteroid_1 = require("./base/NitrogenRichAsteroid");
var NitrophilicMoss_1 = require("./base/NitrophilicMoss");
var NoctisCity_1 = require("./base/NoctisCity");
var NoctisFarming_1 = require("./base/NoctisFarming");
var NuclearPower_1 = require("./base/NuclearPower");
var NuclearZone_1 = require("./base/NuclearZone");
var OlympusConference_1 = require("./base/OlympusConference");
var OpenCity_1 = require("./base/OpenCity");
var OptimalAerobraking_1 = require("./base/OptimalAerobraking");
var OreProcessor_1 = require("./base/OreProcessor");
var PermafrostExtraction_1 = require("./base/PermafrostExtraction");
var PeroxidePower_1 = require("./base/PeroxidePower");
var Pets_1 = require("./base/Pets");
var PhobosSpaceHaven_1 = require("./base/PhobosSpaceHaven");
var PhysicsComplex_1 = require("./base/PhysicsComplex");
var Plantation_1 = require("./base/Plantation");
var PowerGrid_1 = require("./base/PowerGrid");
var PowerInfrastructure_1 = require("./base/PowerInfrastructure");
var PowerPlant_1 = require("./base/PowerPlant");
var PowerSupplyConsortium_1 = require("./base/PowerSupplyConsortium");
var Predators_1 = require("./base/Predators");
var ProtectedHabitats_1 = require("./base/ProtectedHabitats");
var ProtectedValley_1 = require("./base/ProtectedValley");
var QuantumExtractor_1 = require("./base/QuantumExtractor");
var RadChemFactory_1 = require("./base/RadChemFactory");
var RadSuits_1 = require("./base/RadSuits");
var RegolithEaters_1 = require("./base/RegolithEaters");
var ReleaseOfInertGases_1 = require("./base/ReleaseOfInertGases");
var Research_1 = require("./base/Research");
var ResearchOutpost_1 = require("./base/ResearchOutpost");
var RestrictedArea_1 = require("./base/RestrictedArea");
var RoboticWorkforce_1 = require("./base/RoboticWorkforce");
var RoverConstruction_1 = require("./base/RoverConstruction");
var Sabotage_1 = require("./base/Sabotage");
var Satellites_1 = require("./base/Satellites");
var SearchForLife_1 = require("./base/SearchForLife");
var SecurityFleet_1 = require("./base/SecurityFleet");
var Shuttles_1 = require("./base/Shuttles");
var SmallAnimals_1 = require("./base/SmallAnimals");
var SoilFactory_1 = require("./base/SoilFactory");
var SolarPower_1 = require("./base/SolarPower");
var SolarWindPower_1 = require("./base/SolarWindPower");
var Soletta_1 = require("./base/Soletta");
var SpaceElevator_1 = require("./base/SpaceElevator");
var SpaceMirrors_1 = require("./base/SpaceMirrors");
var SpaceStation_1 = require("./base/SpaceStation");
var SpecialDesign_1 = require("./base/SpecialDesign");
var Sponsors_1 = require("./base/Sponsors");
var StandardTechnology_1 = require("./base/StandardTechnology");
var Steelworks_1 = require("./base/Steelworks");
var StripMine_1 = require("./base/StripMine");
var SubterraneanReservoir_1 = require("./base/SubterraneanReservoir");
var SymbioticFungus_1 = require("./base/SymbioticFungus");
var Tardigrades_1 = require("./base/Tardigrades");
var TechnologyDemonstration_1 = require("./base/TechnologyDemonstration");
var TectonicStressPower_1 = require("./base/TectonicStressPower");
var TerraformingGanymede_1 = require("./base/TerraformingGanymede");
var TitaniumMine_1 = require("./base/TitaniumMine");
var TollStation_1 = require("./base/TollStation");
var TowingAComet_1 = require("./base/TowingAComet");
var TransNeptuneProbe_1 = require("./base/TransNeptuneProbe");
var Trees_1 = require("./base/Trees");
var TropicalResort_1 = require("./base/TropicalResort");
var TundraFarming_1 = require("./base/TundraFarming");
var UndergroundCity_1 = require("./base/UndergroundCity");
var UndergroundDetonations_1 = require("./base/UndergroundDetonations");
var UrbanizedArea_1 = require("./base/UrbanizedArea");
var VestaShipyard_1 = require("./base/VestaShipyard");
var ViralEnhancers_1 = require("./base/ViralEnhancers");
var Virus_1 = require("./base/Virus");
var WaterImportFromEuropa_1 = require("./base/WaterImportFromEuropa");
var WaterSplittingPlant_1 = require("./base/WaterSplittingPlant");
var WavePower_1 = require("./base/WavePower");
var Windmills_1 = require("./base/Windmills");
var Worms_1 = require("./base/Worms");
var Zeppelins_1 = require("./base/Zeppelins");
var AquiferStandardProject_1 = require("./base/standardProjects/AquiferStandardProject");
var CityStandardProject_1 = require("./base/standardProjects/CityStandardProject");
var PowerPlantStandardProject_1 = require("./base/standardProjects/PowerPlantStandardProject");
var GreeneryStandardProject_1 = require("./base/standardProjects/GreeneryStandardProject");
var AsteroidStandardProject_1 = require("./base/standardProjects/AsteroidStandardProject");
var SellPatentsStandardProject_1 = require("./base/standardProjects/SellPatentsStandardProject");
var ConvertPlants_1 = require("./base/standardActions/ConvertPlants");
var ConvertHeat_1 = require("./base/standardActions/ConvertHeat");
var BufferGasStandardProject_1 = require("./prelude/BufferGasStandardProject");
exports.BASE_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Base,
    projectCards: [
        { cardName: CardName_1.CardName.ADAPTATION_TECHNOLOGY, Factory: AdaptationTechnology_1.AdaptationTechnology },
        { cardName: CardName_1.CardName.ADAPTED_LICHEN, Factory: AdaptedLichen_1.AdaptedLichen },
        { cardName: CardName_1.CardName.ADVANCED_ECOSYSTEMS, Factory: AdvancedEcosystems_1.AdvancedEcosystems },
        { cardName: CardName_1.CardName.AEROBRAKED_AMMONIA_ASTEROID, Factory: AerobrakedAmmoniaAsteroid_1.AerobrakedAmmoniaAsteroid },
        { cardName: CardName_1.CardName.ANTS, Factory: Ants_1.Ants },
        { cardName: CardName_1.CardName.AQUIFER_PUMPING, Factory: AquiferPumping_1.AquiferPumping },
        { cardName: CardName_1.CardName.ALGAE, Factory: Algae_1.Algae },
        { cardName: CardName_1.CardName.ARCHAEBACTERIA, Factory: ArchaeBacteria_1.ArchaeBacteria },
        { cardName: CardName_1.CardName.ARCTIC_ALGAE, Factory: ArcticAlgae_1.ArcticAlgae },
        { cardName: CardName_1.CardName.ARTIFICIAL_LAKE, Factory: ArtificialLake_1.ArtificialLake },
        { cardName: CardName_1.CardName.ARTIFICIAL_PHOTOSYNTHESIS, Factory: ArtificialPhotosynthesis_1.ArtificialPhotosynthesis },
        { cardName: CardName_1.CardName.ASTEROID, Factory: Asteroid_1.Asteroid },
        { cardName: CardName_1.CardName.ASTEROID_MINING, Factory: AsteroidMining_1.AsteroidMining },
        { cardName: CardName_1.CardName.BEAM_FROM_A_THORIUM_ASTEROID, Factory: BeamFromAThoriumAsteroid_1.BeamFromAThoriumAsteroid },
        { cardName: CardName_1.CardName.BIG_ASTEROID, Factory: BigAsteroid_1.BigAsteroid },
        { cardName: CardName_1.CardName.BIOMASS_COMBUSTORS, Factory: BiomassCombustors_1.BiomassCombustors },
        { cardName: CardName_1.CardName.BIRDS, Factory: Birds_1.Birds },
        { cardName: CardName_1.CardName.BLACK_POLAR_DUST, Factory: BlackPolarDust_1.BlackPolarDust },
        { cardName: CardName_1.CardName.BREATHING_FILTERS, Factory: BreathingFilters_1.BreathingFilters },
        { cardName: CardName_1.CardName.BUSHES, Factory: Bushes_1.Bushes },
        { cardName: CardName_1.CardName.CAPITAL, Factory: Capital_1.Capital },
        { cardName: CardName_1.CardName.CARBONATE_PROCESSING, Factory: CarbonateProcessing_1.CarbonateProcessing },
        { cardName: CardName_1.CardName.CLOUD_SEEDING, Factory: CloudSeeding_1.CloudSeeding },
        { cardName: CardName_1.CardName.COLONIZER_TRAINING_CAMP, Factory: ColonizerTrainingCamp_1.ColonizerTrainingCamp },
        { cardName: CardName_1.CardName.COMET, Factory: Comet_1.Comet },
        { cardName: CardName_1.CardName.CONVOY_FROM_EUROPA, Factory: ConvoyFromEuropa_1.ConvoyFromEuropa },
        { cardName: CardName_1.CardName.CUPOLA_CITY, Factory: CupolaCity_1.CupolaCity },
        { cardName: CardName_1.CardName.DECOMPOSERS, Factory: Decomposers_1.Decomposers },
        { cardName: CardName_1.CardName.DEEP_WELL_HEATING, Factory: DeepWellHeating_1.DeepWellHeating },
        { cardName: CardName_1.CardName.DEIMOS_DOWN, Factory: DeimosDown_1.DeimosDown },
        { cardName: CardName_1.CardName.DESIGNED_MICRO_ORGANISMS, Factory: DesignedMicroOrganisms_1.DesignedMicroOrganisms },
        { cardName: CardName_1.CardName.DOMED_CRATER, Factory: DomedCrater_1.DomedCrater },
        { cardName: CardName_1.CardName.DUST_SEALS, Factory: DustSeals_1.DustSeals },
        { cardName: CardName_1.CardName.ECOLOGICAL_ZONE, Factory: EcologicalZone_1.EcologicalZone },
        { cardName: CardName_1.CardName.ENERGY_SAVING, Factory: EnergySaving_1.EnergySaving },
        { cardName: CardName_1.CardName.EOS_CHASMA_NATIONAL_PARK, Factory: EOSChasmaNationalPark_1.EosChasmaNationalPark },
        { cardName: CardName_1.CardName.EQUATORIAL_MAGNETIZER, Factory: EquatorialMagnetizer_1.EquatorialMagnetizer },
        { cardName: CardName_1.CardName.EXTREME_COLD_FUNGUS, Factory: ExtremeColdFungus_1.ExtremeColdFungus },
        { cardName: CardName_1.CardName.FARMING, Factory: Farming_1.Farming },
        { cardName: CardName_1.CardName.FISH, Factory: Fish_1.Fish },
        { cardName: CardName_1.CardName.FLOODING, Factory: Flooding_1.Flooding },
        { cardName: CardName_1.CardName.FOOD_FACTORY, Factory: FoodFactory_1.FoodFactory },
        { cardName: CardName_1.CardName.FUSION_POWER, Factory: FusionPower_1.FusionPower },
        { cardName: CardName_1.CardName.FUELED_GENERATORS, Factory: FueledGenerators_1.FueledGenerators },
        { cardName: CardName_1.CardName.GANYMEDE_COLONY, Factory: GanymedeColony_1.GanymedeColony },
        { cardName: CardName_1.CardName.GEOTHERMAL_POWER, Factory: GeothermalPower_1.GeothermalPower },
        { cardName: CardName_1.CardName.GHG_FACTORIES, Factory: GHGFactories_1.GHGFactories },
        { cardName: CardName_1.CardName.GHG_PRODUCING_BACTERIA, Factory: GHGProducingBacteria_1.GHGProducingBacteria },
        { cardName: CardName_1.CardName.GIANT_ICE_ASTEROID, Factory: GiantIceAsteroid_1.GiantIceAsteroid },
        { cardName: CardName_1.CardName.GIANT_SPACE_MIRROR, Factory: GiantSpaceMirror_1.GiantSpaceMirror },
        { cardName: CardName_1.CardName.GRASS, Factory: Grass_1.Grass },
        { cardName: CardName_1.CardName.GREAT_DAM, Factory: GreatDam_1.GreatDam },
        { cardName: CardName_1.CardName.GREENHOUSES, Factory: Greenhouses_1.Greenhouses },
        { cardName: CardName_1.CardName.HEATHER, Factory: Heather_1.Heather },
        { cardName: CardName_1.CardName.HEAT_TRAPPERS, Factory: HeatTrappers_1.HeatTrappers },
        { cardName: CardName_1.CardName.HERBIVORES, Factory: Herbivores_1.Herbivores },
        { cardName: CardName_1.CardName.ICE_ASTEROID, Factory: IceAsteroid_1.IceAsteroid },
        { cardName: CardName_1.CardName.ICE_CAP_MELTING, Factory: IceCapMelting_1.IceCapMelting },
        { cardName: CardName_1.CardName.IMMIGRANT_CITY, Factory: ImmigrantCity_1.ImmigrantCity },
        { cardName: CardName_1.CardName.IMMIGRATION_SHUTTLES, Factory: ImmigrationShuttles_1.ImmigrationShuttles },
        { cardName: CardName_1.CardName.IMPORTED_GHG, Factory: ImportedGHG_1.ImportedGHG },
        { cardName: CardName_1.CardName.IMPORTED_HYDROGEN, Factory: ImportedHydrogen_1.ImportedHydrogen },
        { cardName: CardName_1.CardName.IMPORTED_NITROGEN, Factory: ImportedNitrogen_1.ImportedNitrogen },
        { cardName: CardName_1.CardName.IMPORT_OF_ADVANCED_GHG, Factory: ImportOfAdvancedGHG_1.ImportOfAdvancedGHG },
        { cardName: CardName_1.CardName.INDUSTRIAL_MICROBES, Factory: IndustrialMicrobes_1.IndustrialMicrobes },
        { cardName: CardName_1.CardName.INSECTS, Factory: Insects_1.Insects },
        { cardName: CardName_1.CardName.INSULATION, Factory: Insulation_1.Insulation },
        { cardName: CardName_1.CardName.IRONWORKS, Factory: Ironworks_1.Ironworks },
        { cardName: CardName_1.CardName.KELP_FARMING, Factory: KelpFarming_1.KelpFarming },
        { cardName: CardName_1.CardName.LAKE_MARINERIS, Factory: LakeMarineris_1.LakeMarineris },
        { cardName: CardName_1.CardName.LARGE_CONVOY, Factory: LargeConvoy_1.LargeConvoy },
        { cardName: CardName_1.CardName.LAVA_FLOWS, Factory: LavaFlows_1.LavaFlows },
        { cardName: CardName_1.CardName.LICHEN, Factory: Lichen_1.Lichen },
        { cardName: CardName_1.CardName.LIVESTOCK, Factory: Livestock_1.Livestock },
        { cardName: CardName_1.CardName.LOCAL_HEAT_TRAPPING, Factory: LocalHeatTrapping_1.LocalHeatTrapping },
        { cardName: CardName_1.CardName.LUNAR_BEAM, Factory: LunarBeam_1.LunarBeam },
        { cardName: CardName_1.CardName.MAGNETIC_FIELD_DOME, Factory: MagneticFieldDome_1.MagneticFieldDome },
        { cardName: CardName_1.CardName.MAGNETIC_FIELD_GENERATORS, Factory: MagneticFieldGenerators_1.MagneticFieldGenerators },
        { cardName: CardName_1.CardName.MANGROVE, Factory: Mangrove_1.Mangrove },
        { cardName: CardName_1.CardName.MARTIAN_RAILS, Factory: MartianRails_1.MartianRails },
        { cardName: CardName_1.CardName.METHANE_FROM_TITAN, Factory: MethaneFromTitan_1.MethaneFromTitan },
        { cardName: CardName_1.CardName.MICRO_MILLS, Factory: MicroMills_1.MicroMills },
        { cardName: CardName_1.CardName.MINING_EXPEDITION, Factory: MiningExpedition_1.MiningExpedition },
        { cardName: CardName_1.CardName.MINING_RIGHTS, Factory: MiningRights_1.MiningRights },
        { cardName: CardName_1.CardName.MOHOLE_AREA, Factory: MoholeArea_1.MoholeArea },
        { cardName: CardName_1.CardName.MOSS, Factory: Moss_1.Moss },
        { cardName: CardName_1.CardName.NATURAL_PRESERVE, Factory: NaturalPreserve_1.NaturalPreserve },
        { cardName: CardName_1.CardName.NITRITE_REDUCING_BACTERIA, Factory: NitriteReducingBacteria_1.NitriteReducingBacteria },
        { cardName: CardName_1.CardName.NITROGEN_RICH_ASTEROID, Factory: NitrogenRichAsteroid_1.NitrogenRichAsteroid },
        { cardName: CardName_1.CardName.NITROPHILIC_MOSS, Factory: NitrophilicMoss_1.NitrophilicMoss },
        { cardName: CardName_1.CardName.NOCTIS_CITY, Factory: NoctisCity_1.NoctisCity },
        { cardName: CardName_1.CardName.NOCTIS_FARMING, Factory: NoctisFarming_1.NoctisFarming },
        { cardName: CardName_1.CardName.NUCLEAR_POWER, Factory: NuclearPower_1.NuclearPower },
        { cardName: CardName_1.CardName.NUCLEAR_ZONE, Factory: NuclearZone_1.NuclearZone },
        { cardName: CardName_1.CardName.OPEN_CITY, Factory: OpenCity_1.OpenCity },
        { cardName: CardName_1.CardName.OPTIMAL_AEROBRAKING, Factory: OptimalAerobraking_1.OptimalAerobraking },
        { cardName: CardName_1.CardName.ORE_PROCESSOR, Factory: OreProcessor_1.OreProcessor },
        { cardName: CardName_1.CardName.PERMAFROST_EXTRACTION, Factory: PermafrostExtraction_1.PermafrostExtraction },
        { cardName: CardName_1.CardName.PEROXIDE_POWER, Factory: PeroxidePower_1.PeroxidePower },
        { cardName: CardName_1.CardName.PETS, Factory: Pets_1.Pets },
        { cardName: CardName_1.CardName.PHOBOS_SPACE_HAVEN, Factory: PhobosSpaceHaven_1.PhobosSpaceHaven },
        { cardName: CardName_1.CardName.PLANTATION, Factory: Plantation_1.Plantation },
        { cardName: CardName_1.CardName.POWER_GRID, Factory: PowerGrid_1.PowerGrid },
        { cardName: CardName_1.CardName.POWER_PLANT, Factory: PowerPlant_1.PowerPlant },
        { cardName: CardName_1.CardName.PREDATORS, Factory: Predators_1.Predators },
        { cardName: CardName_1.CardName.PROTECTED_VALLEY, Factory: ProtectedValley_1.ProtectedValley },
        { cardName: CardName_1.CardName.RAD_CHEM_FACTORY, Factory: RadChemFactory_1.RadChemFactory },
        { cardName: CardName_1.CardName.REGOLITH_EATERS, Factory: RegolithEaters_1.RegolithEaters },
        { cardName: CardName_1.CardName.RELEASE_OF_INERT_GASES, Factory: ReleaseOfInertGases_1.ReleaseOfInertGases },
        { cardName: CardName_1.CardName.RESEARCH_OUTPOST, Factory: ResearchOutpost_1.ResearchOutpost },
        { cardName: CardName_1.CardName.ROVER_CONSTRUCTION, Factory: RoverConstruction_1.RoverConstruction },
        { cardName: CardName_1.CardName.SEARCH_FOR_LIFE, Factory: SearchForLife_1.SearchForLife },
        { cardName: CardName_1.CardName.SHUTTLES, Factory: Shuttles_1.Shuttles },
        { cardName: CardName_1.CardName.SMALL_ANIMALS, Factory: SmallAnimals_1.SmallAnimals },
        { cardName: CardName_1.CardName.SOIL_FACTORY, Factory: SoilFactory_1.SoilFactory },
        { cardName: CardName_1.CardName.SOLAR_POWER, Factory: SolarPower_1.SolarPower },
        { cardName: CardName_1.CardName.SOLAR_WIND_POWER, Factory: SolarWindPower_1.SolarWindPower },
        { cardName: CardName_1.CardName.SOLETTA, Factory: Soletta_1.Soletta },
        { cardName: CardName_1.CardName.SPACE_MIRRORS, Factory: SpaceMirrors_1.SpaceMirrors },
        { cardName: CardName_1.CardName.SPECIAL_DESIGN, Factory: SpecialDesign_1.SpecialDesign },
        { cardName: CardName_1.CardName.STEELWORKS, Factory: Steelworks_1.Steelworks },
        { cardName: CardName_1.CardName.STRIP_MINE, Factory: StripMine_1.StripMine },
        { cardName: CardName_1.CardName.SUBTERRANEAN_RESERVOIR, Factory: SubterraneanReservoir_1.SubterraneanReservoir },
        { cardName: CardName_1.CardName.SYMBIOTIC_FUNGUS, Factory: SymbioticFungus_1.SymbioticFungus },
        { cardName: CardName_1.CardName.TECTONIC_STRESS_POWER, Factory: TectonicStressPower_1.TectonicStressPower },
        { cardName: CardName_1.CardName.TOWING_A_COMET, Factory: TowingAComet_1.TowingAComet },
        { cardName: CardName_1.CardName.TREES, Factory: Trees_1.Trees },
        { cardName: CardName_1.CardName.TUNDRA_FARMING, Factory: TundraFarming_1.TundraFarming },
        { cardName: CardName_1.CardName.UNDERGROUND_CITY, Factory: UndergroundCity_1.UndergroundCity },
        { cardName: CardName_1.CardName.UNDERGROUND_DETONATIONS, Factory: UndergroundDetonations_1.UndergroundDetonations },
        { cardName: CardName_1.CardName.URBANIZED_AREA, Factory: UrbanizedArea_1.UrbanizedArea },
        { cardName: CardName_1.CardName.WATER_IMPORT_FROM_EUROPA, Factory: WaterImportFromEuropa_1.WaterImportFromEuropa },
        { cardName: CardName_1.CardName.WATER_SPLITTING_PLANT, Factory: WaterSplittingPlant_1.WaterSplittingPlant },
        { cardName: CardName_1.CardName.WAVE_POWER, Factory: WavePower_1.WavePower },
        { cardName: CardName_1.CardName.WINDMILLS, Factory: Windmills_1.Windmills },
        { cardName: CardName_1.CardName.WORMS, Factory: Worms_1.Worms },
        { cardName: CardName_1.CardName.ZEPPELINS, Factory: Zeppelins_1.Zeppelins },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.BEGINNER_CORPORATION, Factory: BeginnerCorporation_1.BeginnerCorporation },
        { cardName: CardName_1.CardName.CREDICOR, Factory: CrediCor_1.CrediCor },
        { cardName: CardName_1.CardName.ECOLINE, Factory: EcoLine_1.EcoLine },
        { cardName: CardName_1.CardName.HELION, Factory: Helion_1.Helion },
        { cardName: CardName_1.CardName.INTERPLANETARY_CINEMATICS, Factory: InterplanetaryCinematics_1.InterplanetaryCinematics },
        { cardName: CardName_1.CardName.INVENTRIX, Factory: Inventrix_1.Inventrix },
        { cardName: CardName_1.CardName.MINING_GUILD, Factory: MiningGuild_1.MiningGuild },
        { cardName: CardName_1.CardName.PHOBOLOG, Factory: PhoboLog_1.PhoboLog },
        { cardName: CardName_1.CardName.THARSIS_REPUBLIC, Factory: TharsisRepublic_1.TharsisRepublic },
        { cardName: CardName_1.CardName.THORGATE, Factory: Thorgate_1.Thorgate },
        { cardName: CardName_1.CardName.UNITED_NATIONS_MARS_INITIATIVE, Factory: UnitedNationsMarsInitiative_1.UnitedNationsMarsInitiative },
    ],
    standardProjects: [
        { cardName: CardName_1.CardName.AQUIFER_STANDARD_PROJECT, Factory: AquiferStandardProject_1.AquiferStandardProject },
        { cardName: CardName_1.CardName.CITY_STANDARD_PROJECT, Factory: CityStandardProject_1.CityStandardProject },
        { cardName: CardName_1.CardName.POWER_PLANT_STANDARD_PROJECT, Factory: PowerPlantStandardProject_1.PowerPlantStandardProject },
        { cardName: CardName_1.CardName.GREENERY_STANDARD_PROJECT, Factory: GreeneryStandardProject_1.GreeneryStandardProject },
        { cardName: CardName_1.CardName.ASTEROID_STANDARD_PROJECT, Factory: AsteroidStandardProject_1.AsteroidStandardProject },
        { cardName: CardName_1.CardName.SELL_PATENTS_STANDARD_PROJECT, Factory: SellPatentsStandardProject_1.SellPatentsStandardProject },
        { cardName: CardName_1.CardName.BUFFER_GAS_STANDARD_PROJECT, Factory: BufferGasStandardProject_1.BufferGasStandardProject },
    ],
    standardActions: [
        { cardName: CardName_1.CardName.CONVERT_PLANTS, Factory: ConvertPlants_1.ConvertPlants },
        { cardName: CardName_1.CardName.CONVERT_HEAT, Factory: ConvertHeat_1.ConvertHeat },
    ],
});
exports.CORP_ERA_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.CorpEra,
    projectCards: [
        { cardName: CardName_1.CardName.ACQUIRED_COMPANY, Factory: AcquiredCompany_1.AcquiredCompany },
        { cardName: CardName_1.CardName.ADVANCED_ALLOYS, Factory: AdvancedAlloys_1.AdvancedAlloys },
        { cardName: CardName_1.CardName.AI_CENTRAL, Factory: AICentral_1.AICentral },
        { cardName: CardName_1.CardName.ANTI_GRAVITY_TECHNOLOGY, Factory: AntiGravityTechnology_1.AntiGravityTechnology },
        { cardName: CardName_1.CardName.ASTEROID_MINING_CONSORTIUM, Factory: AsteroidMiningConsortium_1.AsteroidMiningConsortium },
        { cardName: CardName_1.CardName.BRIBED_COMMITTEE, Factory: BribedCommittee_1.BribedCommittee },
        { cardName: CardName_1.CardName.BUILDING_INDUSTRIES, Factory: BuildingIndustries_1.BuildingIndustries },
        { cardName: CardName_1.CardName.BUSINESS_CONTACTS, Factory: BusinessContacts_1.BusinessContacts },
        { cardName: CardName_1.CardName.BUSINESS_NETWORK, Factory: BusinessNetwork_1.BusinessNetwork },
        { cardName: CardName_1.CardName.CALLISTO_PENAL_MINES, Factory: CallistoPenalMines_1.CallistoPenalMines },
        { cardName: CardName_1.CardName.CARETAKER_CONTRACT, Factory: CaretakerContract_1.CaretakerContract },
        { cardName: CardName_1.CardName.CARTEL, Factory: Cartel_1.Cartel },
        { cardName: CardName_1.CardName.CEOS_FAVORITE_PROJECT, Factory: CEOsFavoriteProject_1.CEOsFavoriteProject },
        { cardName: CardName_1.CardName.COMMERCIAL_DISTRICT, Factory: CommercialDistrict_1.CommercialDistrict },
        { cardName: CardName_1.CardName.CORPORATE_STRONGHOLD, Factory: CorporateStronghold_1.CorporateStronghold },
        { cardName: CardName_1.CardName.DEVELOPMENT_CENTER, Factory: DevelopmentCenter_1.DevelopmentCenter },
        { cardName: CardName_1.CardName.EARTH_CATAPULT, Factory: EarthCatapult_1.EarthCatapult },
        { cardName: CardName_1.CardName.EARTH_OFFICE, Factory: EarthOffice_1.EarthOffice },
        { cardName: CardName_1.CardName.ELECTRO_CATAPULT, Factory: ElectroCatapult_1.ElectroCatapult },
        { cardName: CardName_1.CardName.ENERGY_TAPPING, Factory: EnergyTapping_1.EnergyTapping },
        { cardName: CardName_1.CardName.FUEL_FACTORY, Factory: FuelFactory_1.FuelFactory },
        { cardName: CardName_1.CardName.GENE_REPAIR, Factory: GeneRepair_1.GeneRepair },
        { cardName: CardName_1.CardName.GREAT_ESCARPMENT_CONSORTIUM, Factory: GreatEscarpmentConsortium_1.GreatEscarpmentConsortium },
        { cardName: CardName_1.CardName.HACKERS, Factory: Hackers_1.Hackers },
        { cardName: CardName_1.CardName.HIRED_RAIDERS, Factory: HiredRaiders_1.HiredRaiders },
        { cardName: CardName_1.CardName.INDENTURED_WORKERS, Factory: IndenturedWorkers_1.IndenturedWorkers },
        { cardName: CardName_1.CardName.INDUSTRIAL_CENTER, Factory: IndustrialCenter_1.IndustrialCenter },
        { cardName: CardName_1.CardName.INTERSTELLAR_COLONY_SHIP, Factory: InterstellarColonyShip_1.InterstellarColonyShip },
        { cardName: CardName_1.CardName.INVENTION_CONTEST, Factory: InventionContest_1.InventionContest },
        { cardName: CardName_1.CardName.INVENTORS_GUILD, Factory: InventorsGuild_1.InventorsGuild },
        { cardName: CardName_1.CardName.INVESTMENT_LOAN, Factory: InvestmentLoan_1.InvestmentLoan },
        { cardName: CardName_1.CardName.IO_MINING_INDUSTRIES, Factory: IoMiningIndustries_1.IoMiningIndustries },
        { cardName: CardName_1.CardName.LAGRANGE_OBSERVATORY, Factory: LagrangeObservatory_1.LagrangeObservatory },
        { cardName: CardName_1.CardName.LAND_CLAIM, Factory: LandClaim_1.LandClaim },
        { cardName: CardName_1.CardName.LIGHTNING_HARVEST, Factory: LightningHarvest_1.LightningHarvest },
        { cardName: CardName_1.CardName.MARS_UNIVERSITY, Factory: MarsUniversity_1.MarsUniversity },
        { cardName: CardName_1.CardName.MASS_CONVERTER, Factory: MassConverter_1.MassConverter },
        { cardName: CardName_1.CardName.MEDIA_ARCHIVES, Factory: MediaArchives_1.MediaArchives },
        { cardName: CardName_1.CardName.MEDIA_GROUP, Factory: MediaGroup_1.MediaGroup },
        { cardName: CardName_1.CardName.MEDICAL_LAB, Factory: MedicalLab_1.MedicalLab },
        { cardName: CardName_1.CardName.MINE, Factory: Mine_1.Mine },
        { cardName: CardName_1.CardName.MINERAL_DEPOSIT, Factory: MineralDeposit_1.MineralDeposit },
        { cardName: CardName_1.CardName.MINING_AREA, Factory: MiningArea_1.MiningArea },
        { cardName: CardName_1.CardName.MIRANDA_RESORT, Factory: MirandaResort_1.MirandaResort },
        { cardName: CardName_1.CardName.OLYMPUS_CONFERENCE, Factory: OlympusConference_1.OlympusConference },
        { cardName: CardName_1.CardName.PHYSICS_COMPLEX, Factory: PhysicsComplex_1.PhysicsComplex },
        { cardName: CardName_1.CardName.POWER_INFRASTRUCTURE, Factory: PowerInfrastructure_1.PowerInfrastructure },
        { cardName: CardName_1.CardName.POWER_SUPPLY_CONSORTIUM, Factory: PowerSupplyConsortium_1.PowerSupplyConsortium },
        { cardName: CardName_1.CardName.PROTECTED_HABITATS, Factory: ProtectedHabitats_1.ProtectedHabitats },
        { cardName: CardName_1.CardName.QUANTUM_EXTRACTOR, Factory: QuantumExtractor_1.QuantumExtractor },
        { cardName: CardName_1.CardName.RAD_SUITS, Factory: RadSuits_1.RadSuits },
        { cardName: CardName_1.CardName.RESEARCH, Factory: Research_1.Research },
        { cardName: CardName_1.CardName.RESTRICTED_AREA, Factory: RestrictedArea_1.RestrictedArea },
        { cardName: CardName_1.CardName.ROBOTIC_WORKFORCE, Factory: RoboticWorkforce_1.RoboticWorkforce },
        { cardName: CardName_1.CardName.SABOTAGE, Factory: Sabotage_1.Sabotage },
        { cardName: CardName_1.CardName.SATELLITES, Factory: Satellites_1.Satellites },
        { cardName: CardName_1.CardName.SECURITY_FLEET, Factory: SecurityFleet_1.SecurityFleet },
        { cardName: CardName_1.CardName.SPACE_ELEVATOR, Factory: SpaceElevator_1.SpaceElevator },
        { cardName: CardName_1.CardName.SPACE_STATION, Factory: SpaceStation_1.SpaceStation },
        { cardName: CardName_1.CardName.SPONSORS, Factory: Sponsors_1.Sponsors },
        { cardName: CardName_1.CardName.STANDARD_TECHNOLOGY, Factory: StandardTechnology_1.StandardTechnology },
        { cardName: CardName_1.CardName.TARDIGRADES, Factory: Tardigrades_1.Tardigrades },
        { cardName: CardName_1.CardName.TECHNOLOGY_DEMONSTRATION, Factory: TechnologyDemonstration_1.TechnologyDemonstration },
        { cardName: CardName_1.CardName.TERRAFORMING_GANYMEDE, Factory: TerraformingGanymede_1.TerraformingGanymede },
        { cardName: CardName_1.CardName.TITANIUM_MINE, Factory: TitaniumMine_1.TitaniumMine },
        { cardName: CardName_1.CardName.TOLL_STATION, Factory: TollStation_1.TollStation },
        { cardName: CardName_1.CardName.TRANS_NEPTUNE_PROBE, Factory: TransNeptuneProbe_1.TransNeptuneProbe },
        { cardName: CardName_1.CardName.TROPICAL_RESORT, Factory: TropicalResort_1.TropicalResort },
        { cardName: CardName_1.CardName.VESTA_SHIPYARD, Factory: VestaShipyard_1.VestaShipyard },
        { cardName: CardName_1.CardName.VIRAL_ENHANCERS, Factory: ViralEnhancers_1.ViralEnhancers },
        { cardName: CardName_1.CardName.VIRUS, Factory: Virus_1.Virus },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.SATURN_SYSTEMS, Factory: SaturnSystems_1.SaturnSystems },
        { cardName: CardName_1.CardName.TERACTOR, Factory: Teractor_1.Teractor },
    ]
});
