"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackBuilder = void 0;
var TrackBuilder = (function () {
    function TrackBuilder(size) {
        this.spaces = [];
        for (var idx = 0; idx <= size; idx++) {
            this.spaces.push({
                risingPlayer: [],
                everyone: [],
                mostTags: [],
            });
        }
        this.currentSpace = 0;
    }
    TrackBuilder.prototype.at = function (space) {
        this.currentSpace = space;
        return this;
    };
    TrackBuilder.prototype.everyone = function () {
        var rewards = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            rewards[_i] = arguments[_i];
        }
        this.spaces[this.currentSpace].everyone = rewards;
        return this;
    };
    TrackBuilder.prototype.risingPlayer = function () {
        var rewards = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            rewards[_i] = arguments[_i];
        }
        this.spaces[this.currentSpace].risingPlayer = rewards;
        return this;
    };
    TrackBuilder.prototype.mostTags = function () {
        var rewards = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            rewards[_i] = arguments[_i];
        }
        this.spaces[this.currentSpace].mostTags = rewards;
        return this;
    };
    TrackBuilder.prototype.build = function () {
        return {
            spaces: this.spaces,
        };
    };
    return TrackBuilder;
}());
exports.TrackBuilder = TrackBuilder;
