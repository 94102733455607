"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenusInfrastructure = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.megacredits(2).slash().venus(1, { played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var VenusInfrastructure = (function () {
    function VenusInfrastructure() {
        this.name = GlobalEventName_1.GlobalEventName.VENUS_INFRASTRUCTURE;
        this.description = 'Gain 2 M€ per Venus tag (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.MARS;
        this.currentDelegate = PartyName_1.PartyName.UNITY;
        this.renderData = RENDER_DATA;
    }
    VenusInfrastructure.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.min(5, player.getTagCount(Tags_1.Tags.VENUS, false, false)) + turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.addResource(Resources_1.Resources.MEGACREDITS, amount * 2, { log: true, from: _this.name });
            }
        });
    };
    return VenusInfrastructure;
}());
exports.VenusInfrastructure = VenusInfrastructure;
