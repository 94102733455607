"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrescentResearchAssociation = void 0;
var CardName_1 = require("../../CardName");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var CrescentResearchAssociation = (function (_super) {
    __extends(CrescentResearchAssociation, _super);
    function CrescentResearchAssociation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.CRESCENT_RESEARCH_ASSOCIATION,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.MOON],
            startingMegaCredits: 50,
            metadata: {
                description: 'You start with 50 M€. 1 VP for every 3 Moon tags you have.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(50).br;
                    b.effect('When you play a moon tag, you pay 1 M€ less for each Moon tag you have.', function (eb) {
                        eb.moon().startEffect.megacredits(1).slash().moon();
                    });
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.moon(1, 3),
            },
        }) || this;
    }
    CrescentResearchAssociation.prototype.play = function () {
        return undefined;
    };
    CrescentResearchAssociation.prototype.getCardDiscount = function (player, card) {
        if (card.tags.indexOf(Tags_1.Tags.MOON) === -1) {
            return 0;
        }
        return player.getTagCount(Tags_1.Tags.MOON, false, true);
    };
    CrescentResearchAssociation.prototype.getVictoryPoints = function (player) {
        return Math.floor(player.getTagCount(Tags_1.Tags.MOON, true, false) / 3);
    };
    return CrescentResearchAssociation;
}(Card_1.Card));
exports.CrescentResearchAssociation = CrescentResearchAssociation;
