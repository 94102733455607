"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WarOnEarth = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().text('4').tr(1).influence({ size: Size_1.Size.SMALL });
});
var WarOnEarth = (function () {
    function WarOnEarth() {
        this.name = GlobalEventName_1.GlobalEventName.WAR_ON_EARTH;
        this.description = 'Reduce TR 4 steps. Each influence prevents 1 step.';
        this.revealedDelegate = PartyName_1.PartyName.MARS;
        this.currentDelegate = PartyName_1.PartyName.KELVINISTS;
        this.renderData = RENDER_DATA;
    }
    WarOnEarth.prototype.resolve = function (game, turmoil) {
        game.getPlayers().forEach(function (player) {
            player.decreaseTerraformRatingSteps(4 - turmoil.getPlayerInfluence(player));
        });
    };
    return WarOnEarth;
}());
exports.WarOnEarth = WarOnEarth;
