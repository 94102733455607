"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TempestConsultancy = void 0;
var CardName_1 = require("../../CardName");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Tags_1 = require("../Tags");
var Turmoil_1 = require("../../turmoil/Turmoil");
var TempestConsultancy = (function (_super) {
    __extends(TempestConsultancy, _super);
    function TempestConsultancy() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.TEMPEST_CONSULTANCY,
            tags: [Tags_1.Tags.MOON],
            startingMegaCredits: 37,
            initialActionText: 'Place 2 delegates in one party',
            metadata: {
                description: 'You start with 37 M€. As your first action, place 2 delegates in one party.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(37).delegates(1).delegates(1).br;
                    b.action('Place 1 delegate in any party for every 5 moon tags you have [max 3.]', function (eb) {
                        eb.empty().startAction.delegates(1).text('(max 3)', Size_1.Size.SMALL).slash().text('5 ').moon();
                    }).br;
                    b.effect('When your delegate becomes the chairman, increase your TR 1 step.', function (eb) {
                        eb.chairman().startEffect.tr(1);
                    });
                }),
            },
        }) || this;
    }
    TempestConsultancy.prototype.play = function () {
        return undefined;
    };
    TempestConsultancy.prototype.initialAction = function (player) {
        var title = 'Tempest Consultancy first action - Select where to send two delegates';
        player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, title, { count: 2, source: 'reserve' }));
        return undefined;
    };
    TempestConsultancy.prototype.canAct = function (player) {
        return player.getTagCount(Tags_1.Tags.MOON) >= 5;
    };
    TempestConsultancy.prototype.action = function (player) {
        var count = Math.floor(player.getTagCount(Tags_1.Tags.MOON) / 5);
        count = Math.min(count, 3);
        count = Math.min(count, Turmoil_1.Turmoil.getTurmoil(player.game).getDelegatesInReserve(player.id));
        if (count > 0) {
            player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, "Select a party to send " + count + " delegate(s) to", { count: count, source: 'reserve' }));
        }
        return undefined;
    };
    ;
    return TempestConsultancy;
}(Card_1.Card));
exports.TempestConsultancy = TempestConsultancy;
