"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianCulture = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Tags_1 = require("../Tags");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var ResourceType_1 = require("../../ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var Options_1 = require("../Options");
var MartianCulture = (function (_super) {
    __extends(MartianCulture, _super);
    function MartianCulture() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MARTIAN_CULTURE,
            cost: 11,
            tags: [Tags_1.Tags.MARS, Tags_1.Tags.MARS],
            resourceType: ResourceType_1.ResourceType.DATA,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.MARS, 2, { all: Options_1.all }); }),
            metadata: {
                cardNumber: 'Pf35',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Data resource to any card.', function (eb) { return eb.empty().startAction.data({ amount: 1 }); });
                }),
                description: 'Requires any 2 Mars tags in play.  1 VP for every 2 data here.',
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.data(1, 2),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    MartianCulture.prototype.canAct = function () {
        return true;
    };
    MartianCulture.prototype.action = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.DATA));
        return undefined;
    };
    MartianCulture.prototype.play = function () {
        return undefined;
    };
    MartianCulture.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 2);
    };
    return MartianCulture;
}(Card_1.Card));
exports.MartianCulture = MartianCulture;
