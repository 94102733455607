"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CosmicRadiation = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var TileType_1 = require("../../TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var CosmicRadiation = (function (_super) {
    __extends(CosmicRadiation, _super);
    function CosmicRadiation() {
        return _super.call(this, {
            name: CardName_1.CardName.COSMIC_RADIATION,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.MOON],
            cost: 3,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.miningRate(4); }),
            metadata: {
                description: 'Requires 4 Mining Rate. All players pay 4M€ for each mining tile they own.',
                cardNumber: 'M52',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().megacredits(4, { all: Options_1.all }).slash().moonMine({ size: Size_1.Size.SMALL, all: Options_1.all });
                }),
            },
        }) || this;
    }
    ;
    CosmicRadiation.prototype.play = function (player) {
        var mines = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE);
        player.game.getPlayers().forEach(function (mineTileOwner) {
            var owned = mines.filter(function (mine) { var _a; return ((_a = mine.player) === null || _a === void 0 ? void 0 : _a.id) === mineTileOwner.id; }).length;
            if (owned > 0) {
                var owes = owned * 4;
                var spent_1 = Math.min(owes, mineTileOwner.megaCredits);
                mineTileOwner.megaCredits -= spent_1;
                player.game.log('${0} spends ${1} M€ for the ${2} mines they own.', function (b) { return b.player(mineTileOwner).number(spent_1).number(owned); });
            }
        });
        return undefined;
    };
    return CosmicRadiation;
}(Card_1.Card));
exports.CosmicRadiation = CosmicRadiation;
