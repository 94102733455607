"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectWorkshop = void 0;
var Tags_1 = require("../Tags");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var CardRenderItem_1 = require("../render/CardRenderItem");
var Resources_1 = require("../../Resources");
var Options_1 = require("../Options");
var ProjectWorkshop = (function (_super) {
    __extends(ProjectWorkshop, _super);
    function ProjectWorkshop() {
        return _super.call(this, {
            name: CardName_1.CardName.PROJECT_WORKSHOP,
            tags: [Tags_1.Tags.EARTH],
            startingMegaCredits: 39,
            cardType: CardType_1.CardType.CORPORATION,
            initialActionText: 'Draw a blue card',
            metadata: {
                cardNumber: 'R45',
                description: 'You start with 39 M€, 1 steel and 1 titanium. As your first action, draw a blue card.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(39).steel(1).titanium(1).cards(1, { secondaryTag: CardRenderItem_1.AltSecondaryTag.BLUE });
                    b.corpBox('action', function (cb) {
                        cb.vSpace(Size_1.Size.LARGE);
                        cb.action(undefined, function (eb) {
                            eb.text('flip', Size_1.Size.SMALL, true).cards(1, { secondaryTag: CardRenderItem_1.AltSecondaryTag.BLUE });
                            eb.startAction.text('?', Size_1.Size.MEDIUM, true).tr(1, { size: Size_1.Size.SMALL });
                            eb.cards(2, { digit: Options_1.digit });
                        });
                        cb.vSpace(Size_1.Size.SMALL);
                        cb.action('Flip and discard a played blue card to convert any VP on it into TR and draw 2 cards, or spend 3 M€ to draw a blue card.', function (eb) {
                            eb.or().megacredits(3).startAction.cards(1, { secondaryTag: CardRenderItem_1.AltSecondaryTag.BLUE });
                        });
                    });
                }),
            },
        }) || this;
    }
    ProjectWorkshop.prototype.play = function (player) {
        player.steel = 1;
        player.titanium = 1;
        return undefined;
    };
    ProjectWorkshop.prototype.initialAction = function (player) {
        player.drawCard(1, { cardType: CardType_1.CardType.ACTIVE });
        return undefined;
    };
    ProjectWorkshop.prototype.canAct = function (player) {
        var activeCards = player.getCardsByCardType(CardType_1.CardType.ACTIVE);
        return activeCards.length > 0 || player.megaCredits >= 3;
    };
    ProjectWorkshop.prototype.action = function (player) {
        var _this = this;
        var activeCards = player.getCardsByCardType(CardType_1.CardType.ACTIVE);
        var flipBlueCard = new SelectOption_1.SelectOption('Flip and discard a played blue card', 'Select', function () {
            if (activeCards.length === 1) {
                _this.convertCardPointsToTR(player, activeCards[0]);
                _this.discardPlayedCard(player, activeCards[0]);
                player.drawCard(2);
                return undefined;
            }
            return new SelectCard_1.SelectCard('Select active card to discard', 'Discard', activeCards, function (foundCards) {
                _this.convertCardPointsToTR(player, foundCards[0]);
                _this.discardPlayedCard(player, foundCards[0]);
                player.drawCard(2);
                return undefined;
            });
        });
        var drawBlueCard = new SelectOption_1.SelectOption('Spend 3 M€ to draw a blue card', 'Draw card', function () {
            player.deductResource(Resources_1.Resources.MEGACREDITS, 3);
            player.drawCard(1, { cardType: CardType_1.CardType.ACTIVE });
            return undefined;
        });
        if (activeCards.length === 0)
            return drawBlueCard;
        if (!player.canAfford(3))
            return flipBlueCard;
        return new OrOptions_1.OrOptions(drawBlueCard, flipBlueCard);
    };
    ProjectWorkshop.prototype.convertCardPointsToTR = function (player, card) {
        if (card.getVictoryPoints !== undefined) {
            var steps = card.getVictoryPoints(player);
            player.increaseTerraformRatingSteps(steps, { log: true });
        }
    };
    ProjectWorkshop.prototype.discardPlayedCard = function (player, card) {
        var cardIndex = player.playedCards.findIndex(function (c) { return c.name === card.name; });
        player.playedCards.splice(cardIndex, 1);
        player.game.dealer.discard(card);
        if (card.onDiscard) {
            card.onDiscard(player);
        }
        player.game.log('${0} flipped and discarded ${1}', function (b) { return b.player(player).card(card); });
    };
    return ProjectWorkshop;
}(Card_1.Card));
exports.ProjectWorkshop = ProjectWorkshop;
