"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AndOptions = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var AndOptions = (function () {
    function AndOptions(cb) {
        var options = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            options[_i - 1] = arguments[_i];
        }
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.AND_OPTIONS;
        this.title = '';
        this.buttonLabel = 'Save';
        this.options = options;
    }
    return AndOptions;
}());
exports.AndOptions = AndOptions;
