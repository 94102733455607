"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EarthOffice = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var EarthOffice = (function (_super) {
    __extends(EarthOffice, _super);
    function EarthOffice() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.EARTH_OFFICE,
            tags: [Tags_1.Tags.EARTH],
            cost: 1,
            cardDiscount: { tag: Tags_1.Tags.EARTH, amount: 3 },
            metadata: {
                cardNumber: '105',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play an Earth card, you pay 3 M€ less for it.', function (eb) {
                        eb.earth(1, { played: Options_1.played }).startEffect.megacredits(-3);
                    });
                }),
            },
        }) || this;
    }
    EarthOffice.prototype.getCardDiscount = function (_player, card) {
        return card.tags.filter(function (tag) { return tag === Tags_1.Tags.EARTH; }).length * 3;
    };
    EarthOffice.prototype.play = function () {
        return undefined;
    };
    return EarthOffice;
}(Card_1.Card));
exports.EarthOffice = EarthOffice;
