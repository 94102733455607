"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpinoffProducts = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.megacredits(2).slash().science(1, { played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var SpinoffProducts = (function () {
    function SpinoffProducts() {
        this.name = GlobalEventName_1.GlobalEventName.SPINOFF_PRODUCTS;
        this.description = 'Gain 2 M€ for each Science tag (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.GREENS;
        this.currentDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.renderData = RENDER_DATA;
    }
    SpinoffProducts.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, 2 * (Math.min(5, player.getTagCount(Tags_1.Tags.SCIENCE, false, false)) + turmoil.getPlayerInfluence(player)), { log: true, from: _this.name });
        });
    };
    return SpinoffProducts;
}());
exports.SpinoffProducts = SpinoffProducts;
