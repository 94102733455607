"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectedImpactors = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var Tags_1 = require("../Tags");
var SelectCard_1 = require("../../inputs/SelectCard");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var constants_1 = require("../../constants");
var LogHelper_1 = require("../../LogHelper");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var DirectedImpactors = (function (_super) {
    __extends(DirectedImpactors, _super);
    function DirectedImpactors() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.DIRECTED_IMPACTORS,
            tags: [Tags_1.Tags.SPACE],
            cost: 8,
            resourceType: ResourceType_1.ResourceType.ASTEROID,
            metadata: {
                cardNumber: 'X19',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 6 M€ to add 1 asteroid to ANY CARD (titanium may be used to pay for this).', function (eb) {
                        eb.megacredits(6).openBrackets.titanium(1).closeBrackets.startAction.asteroids(1).asterix();
                    }).br;
                    b.or().br;
                    b.action('Remove 1 asteroid here to raise temperature 1 step.', function (eb) {
                        eb.asteroids(1).startAction.temperature(1);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    DirectedImpactors.prototype.play = function () {
        return undefined;
    };
    DirectedImpactors.prototype.canAct = function (player) {
        var cardHasResources = this.resourceCount > 0;
        var canPayForAsteroid = player.canAfford(6, { titanium: true });
        if (player.game.getTemperature() === constants_1.MAX_TEMPERATURE && cardHasResources)
            return true;
        if (canPayForAsteroid)
            return true;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(constants_1.REDS_RULING_POLICY_COST) && cardHasResources;
        }
        return cardHasResources;
    };
    DirectedImpactors.prototype.action = function (player) {
        var _this = this;
        var asteroidCards = player.getResourceCards(ResourceType_1.ResourceType.ASTEROID);
        var opts = [];
        var addResource = new SelectOption_1.SelectOption('Pay 6 to add 1 asteroid to a card', 'Pay', function () { return _this.addResource(player, asteroidCards); });
        var spendResource = new SelectOption_1.SelectOption('Remove 1 asteroid to raise temperature 1 step', 'Remove asteroid', function () { return _this.spendResource(player); });
        var redsAreRuling = PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS);
        var temperatureIsMaxed = player.game.getTemperature() === constants_1.MAX_TEMPERATURE;
        if (this.resourceCount > 0) {
            if (!redsAreRuling || temperatureIsMaxed || (redsAreRuling && player.canAfford(constants_1.REDS_RULING_POLICY_COST))) {
                opts.push(spendResource);
            }
        }
        else {
            return this.addResource(player, asteroidCards);
        }
        if (player.canAfford(6, { titanium: true })) {
            opts.push(addResource);
        }
        else {
            return this.spendResource(player);
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], opts)))();
    };
    DirectedImpactors.prototype.addResource = function (player, asteroidCards) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 6, { canUseTitanium: true, title: 'Select how to pay for Directed Impactors action' }));
        if (asteroidCards.length === 1) {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 asteroid', 'Add asteroid', asteroidCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    DirectedImpactors.prototype.spendResource = function (player) {
        this.resourceCount--;
        LogHelper_1.LogHelper.logRemoveResource(player, this, 1, 'raise temperature 1 step');
        player.game.increaseTemperature(player, 1);
        return undefined;
    };
    return DirectedImpactors;
}(Card_1.Card));
exports.DirectedImpactors = DirectedImpactors;
