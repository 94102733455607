"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var constants_1 = require("@/constants");
var PartyName_1 = require("@/turmoil/parties/PartyName");
var GameSetupDetail_vue_1 = require("@/client/components/GameSetupDetail.vue");
var GlobalParameterValue_vue_1 = require("@/client/components/GlobalParameterValue.vue");
var MoonGlobalParameterValue_vue_1 = require("@/client/components/MoonGlobalParameterValue.vue");
var GlobalParameter_1 = require("@/GlobalParameter");
var PreferencesDialog_vue_1 = require("@/client/components/PreferencesDialog.vue");
exports.default = vue_1.default.extend({
    name: 'sidebar',
    props: {
        playerNumber: {
            type: Number,
        },
        gameOptions: {
            type: Object,
        },
        acting_player: {
            type: Boolean,
        },
        player_color: {
            type: String,
        },
        generation: {
            type: Number,
        },
        coloniesCount: {
            type: Number,
        },
        temperature: {
            type: Number,
        },
        oxygen: {
            type: Number,
        },
        oceans: {
            type: Number,
        },
        venus: {
            type: Number,
        },
        moonData: {
            type: Object,
        },
        turmoil: {
            type: Object || undefined,
        },
        lastSoloGeneration: {
            type: Number,
        },
    },
    components: {
        'game-setup-detail': GameSetupDetail_vue_1.default,
        'global-parameter-value': GlobalParameterValue_vue_1.default,
        MoonGlobalParameterValue: MoonGlobalParameterValue_vue_1.default,
        'preferences-dialog': PreferencesDialog_vue_1.default,
    },
    data: function () {
        return {
            'ui': {
                'preferences_panel_open': false,
                'gamesetup_detail_open': false,
            },
            'hide_hand': false,
            'hide_awards_and_milestones': false,
            'hide_top_bar': false,
            'small_cards': false,
            'remove_background': false,
            'magnify_cards': true,
            'show_alerts': true,
            'lang': 'en',
            'langs': constants_1.LANGUAGES,
            'enable_sounds': false,
            'hide_tile_confirmation': false,
            'show_card_number': false,
            'hide_discount_on_cards': false,
            'learner_mode': true,
            'hide_animated_sidebar': false,
            'globalParameter': GlobalParameter_1.GlobalParameter,
        };
    },
    methods: {
        getPlayerColorCubeClass: function () {
            return this.acting_player && (PreferencesManager_1.PreferencesManager.loadBoolean('hide_animated_sidebar') === false) ? 'preferences_player_inner active' : 'preferences_player_inner';
        },
        getSideBarClass: function () {
            return this.acting_player && (PreferencesManager_1.PreferencesManager.loadBoolean('hide_animated_sidebar') === false) ? 'preferences_acting_player' : 'preferences_nonacting_player';
        },
        getGenMarker: function () {
            return "" + this.generation;
        },
        rulingPartyToCss: function () {
            if (this.turmoil.ruling === undefined) {
                console.warn('no party provided');
                return '';
            }
            return this.turmoil.ruling.toLowerCase().split(' ').join('_');
        },
        getRulingParty: function () {
            var rulingPartyName = this.turmoil.ruling;
            if (rulingPartyName === PartyName_1.PartyName.MARS) {
                return 'Mars';
            }
            else if (rulingPartyName === PartyName_1.PartyName.SCIENTISTS) {
                return 'Science';
            }
            else if (rulingPartyName === PartyName_1.PartyName.KELVINISTS) {
                return 'Kelvin';
            }
            else {
                return rulingPartyName;
            }
        },
    },
});
