"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertPlants = void 0;
var StandardActionCard_1 = require("../../StandardActionCard");
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var PartyHooks_1 = require("../../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../../turmoil/parties/PartyName");
var constants_1 = require("../../../constants");
var SelectSpace_1 = require("../../../inputs/SelectSpace");
var ConvertPlants = (function (_super) {
    __extends(ConvertPlants, _super);
    function ConvertPlants() {
        return _super.call(this, {
            name: CardName_1.CardName.CONVERT_PLANTS,
            metadata: {
                cardNumber: 'SA2',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 8 Plants to place a greenery tile and raise oxygen 1 step.', function (eb) {
                        eb.plants(8).startAction.greenery();
                    });
                }),
            },
        }) || this;
    }
    ConvertPlants.prototype.canAct = function (player) {
        if (player.plants < player.plantsNeededForGreenery) {
            return false;
        }
        if (player.game.board.getAvailableSpacesForGreenery(player).length === 0) {
            return false;
        }
        if (player.game.getOxygenLevel() === constants_1.MAX_OXYGEN_LEVEL) {
            return true;
        }
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(constants_1.REDS_RULING_POLICY_COST);
        }
        return true;
    };
    ConvertPlants.prototype.action = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace("Convert " + player.plantsNeededForGreenery + " plants into greenery", player.game.board.getAvailableSpacesForGreenery(player), function (space) {
            _this.actionUsed(player);
            player.game.addGreenery(player, space.id);
            player.plants -= player.plantsNeededForGreenery;
            return undefined;
        });
    };
    return ConvertPlants;
}(StandardActionCard_1.StandardActionCard));
exports.ConvertPlants = ConvertPlants;
