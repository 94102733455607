"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UndergroundDetonators = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var UndergroundDetonators = (function (_super) {
    __extends(UndergroundDetonators, _super);
    function UndergroundDetonators() {
        return _super.call(this, {
            name: CardName_1.CardName.UNDERGROUND_DETONATORS,
            cardType: CardType_1.CardType.EVENT,
            cost: 9,
            tr: { moonMining: 1 },
            metadata: {
                description: 'Gain 1 steel and 1 titanium. Raise the Mining Rate 1 step.',
                cardNumber: 'M34',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.steel(1).titanium(1);
                    b.br;
                    b.moonMiningRate();
                }),
            },
        }) || this;
    }
    ;
    UndergroundDetonators.prototype.play = function (player) {
        player.steel += 1;
        player.titanium += 1;
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return undefined;
    };
    return UndergroundDetonators;
}(Card_1.Card));
exports.UndergroundDetonators = UndergroundDetonators;
