"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shuttles = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Options_1 = require("../Options");
var Shuttles = (function (_super) {
    __extends(Shuttles, _super);
    function Shuttles() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.SHUTTLES,
            tags: [Tags_1.Tags.SPACE],
            cost: 10,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(5); }),
            cardDiscount: { tag: Tags_1.Tags.SPACE, amount: 2 },
            metadata: {
                cardNumber: '166',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play a Space card, you pay 2 M€ less for it.', function (eb) {
                        eb.space({ played: Options_1.played }).startEffect.megacredits(-2);
                    }).br;
                    b.production(function (pb) {
                        pb.minus().energy(1).nbsp;
                        pb.plus().megacredits(2);
                    });
                }),
                description: {
                    text: 'Requires 5% oxygen. Decrease your Energy production 1 step and increase your M€ production 2 steps.',
                    align: 'left',
                },
                victoryPoints: 1,
            },
        }) || this;
    }
    Shuttles.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    Shuttles.prototype.getCardDiscount = function (_player, card) {
        if (card.tags.includes(Tags_1.Tags.SPACE)) {
            return 2;
        }
        return 0;
    };
    Shuttles.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        return undefined;
    };
    Shuttles.prototype.getVictoryPoints = function () {
        return 1;
    };
    return Shuttles;
}(Card_1.Card));
exports.Shuttles = Shuttles;
