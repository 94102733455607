"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CEOsFavoriteProject = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var LogHelper_1 = require("../../LogHelper");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var CEOsFavoriteProject = (function (_super) {
    __extends(CEOsFavoriteProject, _super);
    function CEOsFavoriteProject() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.CEOS_FAVORITE_PROJECT,
            cost: 1,
            metadata: {
                cardNumber: '149',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.text('Add 1 resource to a card with at least 1 resource on it', Size_1.Size.SMALL, true); }),
            },
        }) || this;
    }
    CEOsFavoriteProject.prototype.canPlay = function (player) {
        return player.getCardsWithResources().length > 0 ||
            player.getSelfReplicatingRobotsTargetCards().length > 0;
    };
    CEOsFavoriteProject.prototype.play = function (player) {
        var robotCards = player.getSelfReplicatingRobotsTargetCards();
        return new SelectCard_1.SelectCard('Select card to add resource', 'Add resource', player.getCardsWithResources().concat(robotCards.map(function (c) { return c.card; })), function (foundCards) {
            var robotCard = robotCards.find(function (c) { return c.card.name === foundCards[0].name; });
            if (robotCard) {
                robotCard.resourceCount++;
                LogHelper_1.LogHelper.logAddResource(player, robotCard.card);
            }
            else {
                player.addResourceTo(foundCards[0], { log: true });
            }
            return undefined;
        });
    };
    return CEOsFavoriteProject;
}(Card_1.Card));
exports.CEOsFavoriteProject = CEOsFavoriteProject;
