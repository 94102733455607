"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRenderDynamicVictoryPoints_1 = require("@/cards/render/CardRenderDynamicVictoryPoints");
var CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
exports.default = vue_1.default.extend({
    name: 'CardVictoryPoints',
    props: {
        victoryPoints: {
            type: [Number, Object],
            required: true,
        },
    },
    components: {
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
    },
    methods: {
        getClasses: function () {
            var classes = ['card-points'];
            if (this.isObject(this.victoryPoints)) {
                var item = this.victoryPoints;
                if (item.anyPlayer) {
                    classes.push('card-points-big');
                    classes.push('red-outline');
                }
                else {
                    classes.push('card-points-normal');
                }
            }
            else {
                classes.push('card-points-big');
            }
            return classes.join(' ');
        },
        isObject: function (item) {
            return item instanceof CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints;
        },
    },
});
