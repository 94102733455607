"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxwellBase = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var SpaceName_1 = require("../../SpaceName");
var SpaceType_1 = require("../../SpaceType");
var Resources_1 = require("../../Resources");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var MaxwellBase = (function (_super) {
    __extends(MaxwellBase, _super);
    function MaxwellBase() {
        return _super.call(this, {
            name: CardName_1.CardName.MAXWELL_BASE,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.VENUS],
            cost: 18,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(12); }),
            metadata: {
                cardNumber: '238',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 resource to ANOTHER VENUS CARD.', function (eb) {
                        eb.empty().startAction.wild(1, { secondaryTag: Tags_1.Tags.VENUS });
                    }).br;
                    b.production(function (pb) { return pb.minus().energy(1); }).nbsp.city().asterix();
                }),
                description: {
                    text: 'Requires Venus 12%. Decrease your energy production 1 step. Place a City tile ON THE RESERVED AREA.',
                    align: 'left',
                },
                victoryPoints: 3,
            },
        }) || this;
    }
    ;
    MaxwellBase.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    MaxwellBase.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.game.addCityTile(player, SpaceName_1.SpaceName.MAXWELL_BASE, SpaceType_1.SpaceType.COLONY);
        return undefined;
    };
    MaxwellBase.prototype.getVictoryPoints = function () {
        return 3;
    };
    MaxwellBase.prototype.getResCards = function (player) {
        return player.getResourceCards().filter(function (card) { return card.tags.includes(Tags_1.Tags.VENUS); });
    };
    MaxwellBase.prototype.canAct = function (player) {
        return this.getResCards(player).length > 0;
    };
    MaxwellBase.prototype.action = function (player) {
        var cards = this.getResCards(player);
        if (cards.length === 1) {
            player.addResourceTo(cards[0], { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 resource', 'Add resource', cards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    return MaxwellBase;
}(Card_1.Card));
exports.MaxwellBase = MaxwellBase;
