"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Midas = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Midas = (function (_super) {
    __extends(Midas, _super);
    function Midas() {
        return _super.call(this, {
            name: CardName_1.CardName.MIDAS,
            startingMegaCredits: 120,
            cardType: CardType_1.CardType.CORPORATION,
            metadata: {
                cardNumber: 'R41',
                description: 'You start with 120 M€. Lower your TR 7 steps.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.vSpace(Size_1.Size.LARGE).br;
                    b.megacredits(120, { size: Size_1.Size.LARGE }).nbsp.nbsp.nbsp;
                    b.minus().tr(7);
                }),
            },
        }) || this;
    }
    Midas.prototype.play = function (player) {
        player.decreaseTerraformRatingSteps(7);
        return undefined;
    };
    return Midas;
}(Card_1.Card));
exports.Midas = Midas;
