"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectAmount = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectAmount = (function () {
    function SelectAmount(title, buttonLabel, cb, min, max, maxByDefault) {
        if (buttonLabel === void 0) { buttonLabel = 'Save'; }
        this.title = title;
        this.buttonLabel = buttonLabel;
        this.cb = cb;
        this.min = min;
        this.max = max;
        this.maxByDefault = maxByDefault;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_AMOUNT;
        this.buttonLabel = buttonLabel;
    }
    return SelectAmount;
}());
exports.SelectAmount = SelectAmount;
