"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoliticalAgendas = exports.AgendaStyle = void 0;
var ChoosePoliticalAgenda_1 = require("../deferredActions/ChoosePoliticalAgenda");
var AgendaStyle;
(function (AgendaStyle) {
    AgendaStyle["STANDARD"] = "Standard";
    AgendaStyle["RANDOM"] = "Random";
    AgendaStyle["CHAIRMAN"] = "Chairman";
})(AgendaStyle = exports.AgendaStyle || (exports.AgendaStyle = {}));
var PoliticalAgendas = (function () {
    function PoliticalAgendas() {
    }
    PoliticalAgendas.newInstance = function (agendaStyle, parties) {
        var agendas = new Map();
        parties.forEach(function (p) {
            if (agendaStyle === AgendaStyle.STANDARD) {
                agendas.set(p.name, { bonusId: p.bonuses[0].id, policyId: p.policies[0].id });
            }
            else {
                agendas.set(p.name, PoliticalAgendas.getRandomAgenda(p));
            }
        });
        return {
            agendas: agendas,
            agendaStyle: agendaStyle,
        };
    };
    PoliticalAgendas.getRandomAgenda = function (party) {
        var bonus = PoliticalAgendas.randomElement(party.bonuses);
        var policy = PoliticalAgendas.randomElement(party.policies);
        return { bonusId: bonus.id, policyId: policy.id };
    };
    PoliticalAgendas.currentAgenda = function (turmoil) {
        return this.getAgenda(turmoil, turmoil.rulingParty.name);
    };
    PoliticalAgendas.getAgenda = function (turmoil, partyName) {
        var agenda = turmoil.politicalAgendasData.agendas.get(partyName);
        if (agenda === undefined) {
            throw new Error('Invalid party: ' + partyName);
        }
        return agenda;
    };
    ;
    PoliticalAgendas.setNextAgenda = function (turmoil, game) {
        var rulingParty = turmoil.rulingParty;
        var politicalAgendasData = turmoil.politicalAgendasData;
        var chairman = turmoil.chairman;
        if (chairman === undefined) {
            throw new Error('Chairman not defined');
        }
        if (politicalAgendasData.agendaStyle === AgendaStyle.CHAIRMAN && chairman !== 'NEUTRAL') {
            var agenda_1 = this.getAgenda(turmoil, rulingParty.name);
            game.defer(new ChoosePoliticalAgenda_1.ChoosePoliticalAgenda(game.getPlayerById(chairman), rulingParty, function (bonusId) {
                agenda_1.bonusId = bonusId;
                turmoil.onAgendaSelected(game);
            }, function (policyId) {
                agenda_1.policyId = policyId;
                turmoil.onAgendaSelected(game);
            }));
        }
        else {
            turmoil.onAgendaSelected(game);
        }
    };
    PoliticalAgendas.serialize = function (agenda) {
        return {
            agendas: Array.from(agenda.agendas.entries()),
            agendaStyle: agenda.agendaStyle,
        };
    };
    PoliticalAgendas.deserialize = function (d) {
        return {
            agendas: new Map(d.agendas),
            agendaStyle: d.agendaStyle,
        };
    };
    PoliticalAgendas.defaultRandomElement = function (list) {
        var rng = Math.floor(Math.random() * list.length);
        return list[rng];
    };
    PoliticalAgendas.randomElement = PoliticalAgendas.defaultRandomElement;
    return PoliticalAgendas;
}());
exports.PoliticalAgendas = PoliticalAgendas;
