"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveAnyPlants = void 0;
var Resources_1 = require("../Resources");
var OrOptions_1 = require("../inputs/OrOptions");
var SelectOption_1 = require("../inputs/SelectOption");
var DeferredAction_1 = require("./DeferredAction");
var RemoveAnyPlants = (function () {
    function RemoveAnyPlants(player, count, title) {
        if (count === void 0) { count = 1; }
        if (title === void 0) { title = 'Select player to remove up to ' + count + ' plants'; }
        this.player = player;
        this.count = count;
        this.title = title;
        this.priority = DeferredAction_1.Priority.ATTACK_OPPONENT;
    }
    RemoveAnyPlants.prototype.execute = function () {
        var _this = this;
        if (this.player.game.isSoloMode()) {
            this.player.game.someoneHasRemovedOtherPlayersPlants = true;
            return undefined;
        }
        var candidates = this.player.game.getPlayers().filter(function (p) { return p.id !== _this.player.id && !p.plantsAreProtected() && p.plants > 0; });
        if (candidates.length === 0) {
            return undefined;
        }
        var removalOptions = candidates.map(function (candidate) {
            var qtyToRemove = Math.min(candidate.plants, _this.count);
            return new SelectOption_1.SelectOption('Remove ' + qtyToRemove + ' plants from ' + candidate.name, 'Remove plants', function () {
                candidate.deductResource(Resources_1.Resources.PLANTS, qtyToRemove, { log: true, from: _this.player });
                return undefined;
            });
        });
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray(__spreadArray([void 0], removalOptions), [new SelectOption_1.SelectOption('Skip removing plants', 'Confirm', function () {
                return undefined;
            })])))();
    };
    return RemoveAnyPlants;
}());
exports.RemoveAnyPlants = RemoveAnyPlants;
