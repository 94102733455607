"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTFHeadquarters = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var Card_1 = require("../Card");
var LTFHeadquarters = (function (_super) {
    __extends(LTFHeadquarters, _super);
    function LTFHeadquarters() {
        return _super.call(this, {
            name: CardName_1.CardName.LTF_HEADQUARTERS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.SPACE],
            cost: 31,
            tr: { moonColony: 1 },
            metadata: {
                description: 'Raise the Colony Rate 1 step. Place a colony. Gain 1 trade fleet.',
                cardNumber: 'M79',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.moonColonyRate().colonies(1).tradeFleet();
                }),
            },
        }) || this;
    }
    ;
    LTFHeadquarters.prototype.play = function (player) {
        MoonExpansion_1.MoonExpansion.raiseColonyRate(player);
        player.game.defer(new BuildColony_1.BuildColony(player, false));
        player.increaseFleetSize();
        return undefined;
    };
    LTFHeadquarters.prototype.onDiscard = function (player) {
        player.decreaseFleetSize();
    };
    return LTFHeadquarters;
}(Card_1.Card));
exports.LTFHeadquarters = LTFHeadquarters;
