"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchForLife = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Options_1 = require("../Options");
var SearchForLife = (function (_super) {
    __extends(SearchForLife, _super);
    function SearchForLife() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.SEARCH_FOR_LIFE,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 3,
            resourceType: ResourceType_1.ResourceType.SCIENCE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(6, { max: Options_1.max }); }),
            metadata: {
                cardNumber: '005',
                description: 'Oxygen must be 6% or less.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 M€ to reveal the top card of the draw deck. If that card has a Microbe tag, add a Science resource here.', function (eb) {
                        eb.megacredits(1).startAction.microbes(1, { played: Options_1.played }).asterix().nbsp.colon().nbsp.science();
                    }).br;
                    b.vpText('3 VPs if you have one or more Science resources here.');
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.searchForLife(),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    SearchForLife.prototype.getVictoryPoints = function () {
        if (this.resourceCount > 0) {
            return 3;
        }
        return 0;
    };
    SearchForLife.prototype.play = function () {
        return undefined;
    };
    SearchForLife.prototype.canAct = function (player) {
        return player.canAfford(1);
    };
    SearchForLife.prototype.action = function (player) {
        var topCard = player.game.dealer.dealCard(player.game);
        player.game.log('${0} revealed and discarded ${1}', function (b) { return b.player(player).card(topCard); });
        if (topCard.tags.includes(Tags_1.Tags.MICROBE)) {
            player.addResourceTo(this, 1);
            player.game.log('${0} found life!', function (b) { return b.player(player); });
        }
        player.game.dealer.discard(topCard);
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 1, { title: 'Select how to pay for action' }));
        return undefined;
    };
    return SearchForLife;
}(Card_1.Card));
exports.SearchForLife = SearchForLife;
