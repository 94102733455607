"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Card_vue_1 = require("@/client/components/card/Card.vue");
var CardOrderStorage_1 = require("@/client/utils/CardOrderStorage");
exports.default = vue_1.default.extend({
    name: 'SortableCards',
    components: {
        Card: Card_vue_1.default,
    },
    props: {
        cards: {
            type: Array,
        },
        playerId: {
            type: String,
        },
    },
    data: function () {
        var cache = CardOrderStorage_1.CardOrderStorage.getCardOrder(this.playerId);
        var cardOrder = {};
        var keys = Object.keys(cache);
        var max = 0;
        var _loop_1 = function (key) {
            if (this_1.cards.find(function (card) { return card.name === key; }) !== undefined) {
                cardOrder[key] = cache[key];
                max = Math.max(max, cache[key]);
            }
        };
        var this_1 = this;
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            _loop_1(key);
        }
        max++;
        for (var _a = 0, _b = this.cards; _a < _b.length; _a++) {
            var card = _b[_a];
            if (cardOrder[card.name] === undefined) {
                cardOrder[card.name] = max++;
            }
        }
        return {
            cardOrder: cardOrder,
            dragCard: undefined,
        };
    },
    methods: {
        getSortedCards: function () {
            return CardOrderStorage_1.CardOrderStorage.getOrdered(this.cardOrder, this.cards);
        },
        onDragStart: function (source) {
            this.dragCard = source;
        },
        onDragEnd: function () {
            this.dragCard = undefined;
        },
        onDragOver: function (source) {
            if (this.dragCard !== undefined && source !== this.dragCard) {
                var temp = this.cardOrder[source];
                this.cardOrder[source] = this.cardOrder[this.dragCard];
                this.cardOrder[this.dragCard] = temp;
                CardOrderStorage_1.CardOrderStorage.updateCardOrder(this.playerId, this.cardOrder);
            }
        },
    },
});
