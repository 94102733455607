"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.JovianLanterns = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var CardRenderer_1 = require("../render/CardRenderer");
var JovianLanterns = (function (_super) {
    __extends(JovianLanterns, _super);
    function JovianLanterns() {
        var _this = _super.call(this, {
            cost: 20,
            tags: [Tags_1.Tags.JOVIAN],
            name: CardName_1.CardName.JOVIAN_LANTERNS,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            tr: { tr: 1 },
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.JOVIAN); }),
            metadata: {
                cardNumber: 'C18',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 titanium to add 2 floaters here.', function (eb) {
                        eb.titanium(1).startAction.floaters(2);
                    }).br;
                    b.tr(1).floaters(2).asterix().br;
                    b.vpText('1 VP per 2 floaters here.');
                }),
                description: {
                    text: 'Requires 1 Jovian tag. Increase your TR 1 step. Add 2 floaters to ANY card.',
                    align: 'left',
                },
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.floaters(1, 2),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    JovianLanterns.prototype.canAct = function (player) {
        return player.titanium > 0;
    };
    JovianLanterns.prototype.action = function (player) {
        player.titanium--;
        player.addResourceTo(this, 2);
        return undefined;
    };
    JovianLanterns.prototype.play = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2 }));
        player.increaseTerraformRating();
        return undefined;
    };
    JovianLanterns.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 2);
    };
    return JovianLanterns;
}(Card_1.Card));
exports.JovianLanterns = JovianLanterns;
