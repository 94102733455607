"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectResourcesDeferred = void 0;
var SelectResources_1 = require("../inputs/SelectResources");
var DeferredAction_1 = require("./DeferredAction");
var SelectResourcesDeferred = (function () {
    function SelectResourcesDeferred(player, count, title) {
        this.player = player;
        this.count = count;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    SelectResourcesDeferred.prototype.execute = function () {
        return new SelectResources_1.SelectResources(this.player, this.count, this.title);
    };
    return SelectResourcesDeferred;
}());
exports.SelectResourcesDeferred = SelectResourcesDeferred;
