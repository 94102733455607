"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CuriosityII = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var DrawCards_1 = require("../../deferredActions/DrawCards");
var SpaceType_1 = require("../../SpaceType");
var SpaceBonus_1 = require("../../SpaceBonus");
var Phase_1 = require("../../Phase");
var Units_1 = require("../../Units");
var CuriosityII = (function (_super) {
    __extends(CuriosityII, _super);
    function CuriosityII() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.CURIOSITY_II,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.BUILDING],
            startingMegaCredits: 40,
            productionBox: Units_1.Units.of({ steel: 2 }),
            metadata: {
                cardNumber: '',
                description: 'You start with 40 M€ and 2 steel production.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(40).nbsp.production(function (pb) { return pb.steel(2); });
                    b.corpBox('effect', function (ce) {
                        ce.vSpace();
                        ce.effect('When you place a tile on an area that has a RESOURCE placement bonus, ' +
                            'or on top of another tile, you may pay 2 M€ to draw a card.', function (eb) {
                            eb.emptyTile('normal', { size: Size_1.Size.SMALL }).nbsp.asterix().startEffect.megacredits(-2).cards(1);
                        });
                    });
                }),
            },
        }) || this;
    }
    CuriosityII.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        var _this = this;
        var _a;
        var eligibleBonuses = [SpaceBonus_1.SpaceBonus.STEEL, SpaceBonus_1.SpaceBonus.TITANIUM, SpaceBonus_1.SpaceBonus.HEAT, SpaceBonus_1.SpaceBonus.PLANT, SpaceBonus_1.SpaceBonus.MEGACREDITS, SpaceBonus_1.SpaceBonus.ANIMAL, SpaceBonus_1.SpaceBonus.MICROBE];
        if (cardOwner.id !== activePlayer.id)
            return;
        if (cardOwner.game.phase === Phase_1.Phase.SOLAR)
            return;
        if (space.spaceType === SpaceType_1.SpaceType.COLONY)
            return;
        if (space.bonus.some(function (bonus) { return eligibleBonuses.includes(bonus); }) || ((_a = space.tile) === null || _a === void 0 ? void 0 : _a.covers) !== undefined) {
            cardOwner.game.defer(new DeferredAction_1.DeferredAction(cardOwner, function () { return _this.corpAction(cardOwner); }));
        }
    };
    CuriosityII.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.STEEL, 2);
        return undefined;
    };
    CuriosityII.prototype.corpAction = function (player) {
        if (!player.canAfford(2))
            return undefined;
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Pay 2 M€ to draw a card', 'Confirm', function () {
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 2, { title: 'Select how to pay for action' }));
            player.game.defer(DrawCards_1.DrawCards.keepAll(player));
            return undefined;
        }), new SelectOption_1.SelectOption('Do nothing', 'Confirm', function () {
            return undefined;
        }));
    };
    return CuriosityII;
}(Card_1.Card));
exports.CuriosityII = CuriosityII;
