"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchimedesHydroponicsStation = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var ArchimedesHydroponicsStation = (function (_super) {
    __extends(ArchimedesHydroponicsStation, _super);
    function ArchimedesHydroponicsStation() {
        return _super.call(this, {
            name: CardName_1.CardName.ARCHIMEDES_HYDROPONICS_STATION,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.PLANT],
            cost: 12,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: -1, plants: 2 }),
            metadata: {
                description: 'Decrease your energy production 1 step and your M€ production 1 step. Increase your plant production 2 steps.',
                cardNumber: 'M27',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.minus().energy(1).megacredits(1); });
                    b.br;
                    b.production(function (pb) { return pb.plants(2); });
                }),
            },
        }, {}) || this;
    }
    ;
    ArchimedesHydroponicsStation.prototype.play = function (player) {
        player.adjustProduction(this.productionBox, { log: true });
        return undefined;
    };
    return ArchimedesHydroponicsStation;
}(MoonCard_1.MoonCard));
exports.ArchimedesHydroponicsStation = ArchimedesHydroponicsStation;
