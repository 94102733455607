"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Board_vue_1 = require("@/client/components/Board.vue");
var Card_vue_1 = require("@/client/components/card/Card.vue");
var Milestone_vue_1 = require("@/client/components/Milestone.vue");
var Awards_vue_1 = require("@/client/components/Awards.vue");
var PlayersOverview_vue_1 = require("@/client/components/overview/PlayersOverview.vue");
var WaitingFor_vue_1 = require("@/client/components/WaitingFor.vue");
var Sidebar_vue_1 = require("@/client/components/Sidebar.vue");
var Colony_vue_1 = require("@/client/components/Colony.vue");
var LogPanel_vue_1 = require("@/client/components/LogPanel.vue");
var PlayerMixin_1 = require("@/client/mixins/PlayerMixin");
var Turmoil_vue_1 = require("@/client/components/Turmoil.vue");
var utils_1 = require("@/utils/utils");
var DynamicTitle_vue_1 = require("@/client/components/common/DynamicTitle.vue");
var SortableCards_vue_1 = require("@/client/components/SortableCards.vue");
var TopBar_vue_1 = require("@/client/components/TopBar.vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var KeyboardNavigation_1 = require("@/KeyboardNavigation");
var MoonBoard_vue_1 = require("@/client/components/moon/MoonBoard.vue");
var Phase_1 = require("@/Phase");
var StackedCards_vue_1 = require("@/client/components/StackedCards.vue");
var TerraformedAlertDialog = (function () {
    function TerraformedAlertDialog() {
    }
    TerraformedAlertDialog.shouldAlert = true;
    return TerraformedAlertDialog;
}());
exports.default = vue_1.default.extend({
    name: 'player-home',
    data: function () {
        return {
            showActiveCards: !PreferencesManager_1.PreferencesManager.loadBoolean('hide_active_cards'),
            showAutomatedCards: !PreferencesManager_1.PreferencesManager.loadBoolean('hide_automated_cards'),
            showEventCards: !PreferencesManager_1.PreferencesManager.loadBoolean('hide_event_cards'),
            hideTiles: false,
        };
    },
    watch: {
        hide_active_cards: function () {
            PreferencesManager_1.PreferencesManager.save('hide_active_cards', !this.showActiveCards);
        },
        hide_automated_cards: function () {
            PreferencesManager_1.PreferencesManager.save('hide_automated_cards', !this.showAutomatedCards);
        },
        hide_event_cards: function () {
            PreferencesManager_1.PreferencesManager.save('hide_event_cards', !this.showEventCards);
        },
    },
    props: {
        playerView: {
            type: Object,
        },
        settings: {
            type: Object,
        },
    },
    computed: {
        thisPlayer: function () {
            return this.playerView.thisPlayer;
        },
        game: function () {
            return this.playerView.game;
        },
    },
    components: {
        'board': Board_vue_1.default,
        DynamicTitle: DynamicTitle_vue_1.default,
        Card: Card_vue_1.default,
        'players-overview': PlayersOverview_vue_1.default,
        'waiting-for': WaitingFor_vue_1.default,
        Milestone: Milestone_vue_1.default,
        Awards: Awards_vue_1.default,
        'sidebar': Sidebar_vue_1.default,
        'colony': Colony_vue_1.default,
        'log-panel': LogPanel_vue_1.default,
        'turmoil': Turmoil_vue_1.default,
        'sortable-cards': SortableCards_vue_1.default,
        'top-bar': TopBar_vue_1.default,
        MoonBoard: MoonBoard_vue_1.default,
        'stacked-cards': StackedCards_vue_1.default,
    },
    mixins: [PlayerMixin_1.PlayerMixin],
    methods: __assign(__assign({}, PlayerMixin_1.PlayerMixin.methods), { navigatePage: function (event) {
            var inputSource = event.target;
            if (inputSource.nodeName.toLowerCase() !== 'input') {
                var idSuffix = undefined;
                switch (event.code) {
                    case KeyboardNavigation_1.KeyboardNavigation.GAMEBOARD:
                        idSuffix = 'board';
                        break;
                    case KeyboardNavigation_1.KeyboardNavigation.PLAYERSOVERVIEW:
                        idSuffix = 'playersoverview';
                        break;
                    case KeyboardNavigation_1.KeyboardNavigation.HAND:
                        idSuffix = 'hand';
                        break;
                    case KeyboardNavigation_1.KeyboardNavigation.COLONIES:
                        idSuffix = 'colonies';
                        break;
                    default:
                        return;
                }
                var el = document.getElementById('shortkey-' + idSuffix);
                if (el) {
                    event.preventDefault();
                    el.scrollIntoView({ block: 'center', inline: 'center', behavior: 'auto' });
                }
            }
        },
        isPlayerActing: function (playerView) {
            return playerView.players.length > 1 && playerView.waitingFor !== undefined;
        }, getPlayerCssForTurnOrder: function (player, highlightActive) {
            var classes = ['highlighter_box'];
            if (highlightActive) {
                if (player.needsToDraft || (player.needsToDraft === undefined && player.isActive)) {
                    classes.push('player_is_active');
                }
                classes.push(utils_1.playerColorClass(player.color, 'bg'));
            }
            return classes.join(' ');
        }, getFleetsCountRange: function (player) {
            var fleetsRange = [];
            for (var i = 0; i < player.fleetSize - player.tradesThisGeneration; i++) {
                fleetsRange.push(i);
            }
            return fleetsRange;
        },
        toggle: function (type) {
            switch (type) {
                case 'ACTIVE':
                    this.showActiveCards = !this.showActiveCards;
                    break;
                case 'AUTOMATED':
                    this.showAutomatedCards = !this.showAutomatedCards;
                    break;
                case 'EVENT':
                    this.showEventCards = !this.showEventCards;
                    break;
            }
        },
        isVisible: function (type) {
            switch (type) {
                case 'ACTIVE':
                    return this.showActiveCards;
                case 'AUTOMATED':
                    return this.showAutomatedCards;
                case 'EVENT':
                    return this.showEventCards;
            }
            return false;
        },
        isInitialDraftingPhase: function () {
            return (this.game.phase === Phase_1.Phase.INITIALDRAFTING) && this.game.gameOptions.initialDraftVariant;
        },
        getToggleLabel: function (hideType) {
            if (hideType === 'ACTIVE') {
                return (this.showActiveCards ? '✔' : '');
            }
            else if (hideType === 'AUTOMATED') {
                return (this.showAutomatedCards ? '✔' : '');
            }
            else if (hideType === 'EVENT') {
                return (this.showEventCards ? '✔' : '');
            }
            else {
                return '';
            }
        },
        getHideButtonClass: function (hideType) {
            var prefix = 'hiding-card-button ';
            if (hideType === 'ACTIVE') {
                return prefix + (this.showActiveCards ? 'active' : 'active-transparent');
            }
            else if (hideType === 'AUTOMATED') {
                return prefix + (this.showAutomatedCards ? 'automated' : 'automated-transparent');
            }
            else if (hideType === 'EVENT') {
                return prefix + (this.showEventCards ? 'event' : 'event-transparent');
            }
            else {
                return '';
            }
        } }),
    destroyed: function () {
        window.removeEventListener('keydown', this.navigatePage);
    },
    mounted: function () {
        window.addEventListener('keydown', this.navigatePage);
        if (this.game.isTerraformed && TerraformedAlertDialog.shouldAlert && PreferencesManager_1.PreferencesManager.load('show_alerts') === '1') {
            alert('Mars is Terraformed!');
            TerraformedAlertDialog.shouldAlert = false;
        }
        ;
    },
});
