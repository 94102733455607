"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var TileType_1 = require("@/TileType");
var Bonus_vue_1 = require("@/client/components/Bonus.vue");
var tileTypeToCssClass = new Map([
    [TileType_1.TileType.MOON_ROAD, 'road'],
    [TileType_1.TileType.MOON_COLONY, 'colony'],
    [TileType_1.TileType.MOON_MINE, 'mine'],
    [TileType_1.TileType.LUNA_TRADE_STATION, 'luna-trade-station'],
    [TileType_1.TileType.LUNA_MINING_HUB, 'luna-mining-hub'],
    [TileType_1.TileType.LUNA_TRAIN_STATION, 'luna-train-station'],
    [TileType_1.TileType.LUNAR_MINE_URBANIZATION, 'lunar-mine-urbanization'],
]);
exports.default = vue_1.default.extend({
    name: 'MoonSpace',
    props: {
        space: {
            type: Object,
        },
        text: {
            type: String,
        },
        is_selectable: {
            type: Boolean,
        },
        hideTiles: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Bonus: Bonus_vue_1.default,
    },
    computed: {
        verboseTitle: function () {
            var ret = '';
            if (this.space.tileType === TileType_1.TileType.LUNA_TRADE_STATION) {
                ret = 'Luna Trade Station';
            }
            else if (this.space.tileType === TileType_1.TileType.LUNA_MINING_HUB) {
                ret = 'Luna Mining Hub';
            }
            else if (this.space.tileType === TileType_1.TileType.LUNA_TRAIN_STATION) {
                ret = 'Luna Train Station';
            }
            return this.$t(ret);
        },
        mainClass: function () {
            var css = 'board-space moon-space-' + this.space.id.toString();
            if (this.is_selectable) {
                css += ' board-space-selectable';
            }
            if (this.space.spaceType === 'lunar_mine') {
                css += ' moon-space-type-mine';
            }
            else {
                css += ' moon-space-type-other';
            }
            return css;
        },
        tileClass: function () {
            var css = 'board-space';
            var tileType = this.space.tileType;
            if (tileType !== undefined) {
                switch (this.space.tileType) {
                    case TileType_1.TileType.MOON_COLONY:
                        css += ' board-space-tile--colony';
                        break;
                    case TileType_1.TileType.MOON_ROAD:
                        css += ' board-space-tile--road';
                        break;
                    case TileType_1.TileType.MOON_MINE:
                        css += ' board-space-tile--mine';
                        break;
                    default:
                        var cssClass = tileTypeToCssClass.get(tileType);
                        css += ' board-space-tile--' + cssClass;
                }
            }
            if (this.hideTiles) {
                css += ' board-hidden-tile';
            }
            return css;
        },
    },
});
