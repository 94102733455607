"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarsUniversity = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectCard_1 = require("../../inputs/SelectCard");
var SelectOption_1 = require("../../inputs/SelectOption");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var MarsUniversity = (function (_super) {
    __extends(MarsUniversity, _super);
    function MarsUniversity() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MARS_UNIVERSITY,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.BUILDING],
            cost: 8,
            metadata: {
                cardNumber: '073',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play a Science tag, including this, you may discard a card from hand to draw a card.', function (eb) {
                        eb.science(1, { played: Options_1.played }).startEffect.minus().cards(1).nbsp.plus().cards(1);
                    });
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    MarsUniversity.prototype.onCardPlayed = function (player, card) {
        var _this = this;
        var scienceTags = card.tags.filter(function (tag) { return tag === Tags_1.Tags.SCIENCE; }).length;
        for (var i = 0; i < scienceTags; i++) {
            player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
                if (player.cardsInHand.length === 0) {
                    return undefined;
                }
                return new OrOptions_1.OrOptions(new SelectCard_1.SelectCard('Select a card to discard', 'Discard', player.cardsInHand, function (foundCards) {
                    player.cardsInHand.splice(player.cardsInHand.indexOf(foundCards[0]), 1);
                    player.game.dealer.discard(foundCards[0]);
                    player.game.log('${0} is using their ${1} effect to draw a card by discarding a card.', function (b) { return b.player(player).card(_this); });
                    player.game.log('You discarded ${0}', function (b) { return b.card(foundCards[0]); }, { reservedFor: player });
                    player.drawCard();
                    return undefined;
                }), new SelectOption_1.SelectOption('Do nothing', 'Confirm', function () {
                    return undefined;
                }));
            }));
        }
        return undefined;
    };
    MarsUniversity.prototype.play = function () {
        return undefined;
    };
    MarsUniversity.prototype.getVictoryPoints = function () {
        return 1;
    };
    return MarsUniversity;
}(Card_1.Card));
exports.MarsUniversity = MarsUniversity;
