"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MareImbriumMine = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonSpaces_1 = require("../../moon/MoonSpaces");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Units_1 = require("../../Units");
var TileType_1 = require("../../TileType");
var MoonCard_1 = require("./MoonCard");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MareImbriumMine = (function (_super) {
    __extends(MareImbriumMine, _super);
    function MareImbriumMine() {
        return _super.call(this, {
            name: CardName_1.CardName.MARE_IMBRIUM_MINE,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.BUILDING],
            cost: 19,
            productionBox: Units_1.Units.of({ steel: 1, titanium: 1 }),
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            tr: { moonMining: 1 },
            metadata: {
                description: 'Spend 1 titanium. Increase your steel production 1 step and your titanium production 1 step. Place a mine ON THE RESERVED AREA and raise the Mining Rate 1 step.',
                cardNumber: 'M03',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(1);
                    b.production(function (pb) { return pb.steel(1).titanium(1); }).br;
                    b.moonMine({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE }).asterix();
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_MINE],
        }) || this;
    }
    MareImbriumMine.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.addMineTile(player, MoonSpaces_1.MoonSpaces.MARE_IMBRIUM, this.name);
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return undefined;
    };
    return MareImbriumMine;
}(MoonCard_1.MoonCard));
exports.MareImbriumMine = MareImbriumMine;
