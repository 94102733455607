"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardOrderStorage = void 0;
var STORAGE_PREFIX = 'cardOrder';
var CardOrderStorage = (function () {
    function CardOrderStorage() {
    }
    CardOrderStorage.getCardOrder = function (playerId) {
        try {
            var order = typeof localStorage === 'undefined' ? null : localStorage.getItem("" + STORAGE_PREFIX + playerId);
            if (order === null) {
                return {};
            }
            return JSON.parse(order);
        }
        catch (err) {
            console.warn('unable to pull card order from local storage', err);
            return {};
        }
    };
    CardOrderStorage.getOrdered = function (order, cards) {
        var misses = cards.filter(function (card) { return order[card.name] === undefined; });
        var hits = cards.filter(function (card) { return order[card.name] !== undefined; });
        hits.sort(function (a, b) {
            return order[a.name] - order[b.name];
        });
        return hits.concat(misses);
    };
    CardOrderStorage.updateCardOrder = function (playerId, order) {
        try {
            localStorage.setItem("" + STORAGE_PREFIX + playerId, JSON.stringify(order));
        }
        catch (err) {
            console.warn('unable to update card order with local storage', err);
        }
    };
    return CardOrderStorage;
}());
exports.CardOrderStorage = CardOrderStorage;
