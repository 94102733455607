"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shared = void 0;
var Shared = (function () {
    function Shared() {
    }
    Shared.isTagsViewConcise = function (root) {
        return root.getVisibilityState('tags_concise');
    };
    return Shared;
}());
exports.Shared = Shared;
;
