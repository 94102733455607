"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROMO_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var Advertising_1 = require("./Advertising");
var ArcadianCommunities_1 = require("./ArcadianCommunities");
var AsteroidDeflectionSystem_1 = require("./AsteroidDeflectionSystem");
var AsteroidHollowing_1 = require("./AsteroidHollowing");
var AsteroidRights_1 = require("./AsteroidRights");
var Astrodrill_1 = require("./Astrodrill");
var CometAiming_1 = require("./CometAiming");
var CrashSiteCleanup_1 = require("./CrashSiteCleanup");
var CuttingEdgeTechnology_1 = require("./CuttingEdgeTechnology");
var DeimosDownPromo_1 = require("./DeimosDownPromo");
var DirectedImpactors_1 = require("./DirectedImpactors");
var DiversitySupport_1 = require("./DiversitySupport");
var DuskLaserMining_1 = require("./DuskLaserMining");
var EnergyMarket_1 = require("./EnergyMarket");
var Factorum_1 = require("./Factorum");
var FieldCappedCity_1 = require("./FieldCappedCity");
var GreatDamPromo_1 = require("./GreatDamPromo");
var HiTechLab_1 = require("./HiTechLab");
var ImportedNutrients_1 = require("./ImportedNutrients");
var InterplanetaryTrade_1 = require("./InterplanetaryTrade");
var JovianEmbassy_1 = require("./JovianEmbassy");
var LawSuit_1 = require("./LawSuit");
var MagneticFieldGeneratorsPromo_1 = require("./MagneticFieldGeneratorsPromo");
var MagneticShield_1 = require("./MagneticShield");
var MeatIndustry_1 = require("./MeatIndustry");
var Meltworks_1 = require("./Meltworks");
var MercurianAlloys_1 = require("./MercurianAlloys");
var MoholeLake_1 = require("./MoholeLake");
var MonsInsurance_1 = require("./MonsInsurance");
var OrbitalCleanup_1 = require("./OrbitalCleanup");
var Penguins_1 = require("./Penguins");
var PharmacyUnion_1 = require("./PharmacyUnion");
var Philares_1 = require("./Philares");
var Potatoes_1 = require("./Potatoes");
var ProjectInspection_1 = require("./ProjectInspection");
var Recyclon_1 = require("./Recyclon");
var RegoPlastics_1 = require("./RegoPlastics");
var SaturnSurfing_1 = require("./SaturnSurfing");
var SelfReplicatingRobots_1 = require("./SelfReplicatingRobots");
var SmallAsteroid_1 = require("./SmallAsteroid");
var SnowAlgae_1 = require("./SnowAlgae");
var Splice_1 = require("./Splice");
var StanfordTorus_1 = require("./StanfordTorus");
var SubCrustMeasurements_1 = require("./SubCrustMeasurements");
var TopsoilContract_1 = require("./TopsoilContract");
var BioPrintingFacility_1 = require("./BioPrintingFacility");
var BactoviralResearch_1 = require("./BactoviralResearch");
var Harvest_1 = require("./Harvest");
exports.PROMO_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Promo,
    projectCards: [
        { cardName: CardName_1.CardName.PENGUINS, Factory: Penguins_1.Penguins },
        { cardName: CardName_1.CardName.SELF_REPLICATING_ROBOTS, Factory: SelfReplicatingRobots_1.SelfReplicatingRobots },
        { cardName: CardName_1.CardName.SMALL_ASTEROID, Factory: SmallAsteroid_1.SmallAsteroid },
        { cardName: CardName_1.CardName.SNOW_ALGAE, Factory: SnowAlgae_1.SnowAlgae },
        { cardName: CardName_1.CardName.DUSK_LASER_MINING, Factory: DuskLaserMining_1.DuskLaserMining },
        { cardName: CardName_1.CardName.MERCURIAN_ALLOYS, Factory: MercurianAlloys_1.MercurianAlloys },
        { cardName: CardName_1.CardName.REGO_PLASTICS, Factory: RegoPlastics_1.RegoPlastics },
        { cardName: CardName_1.CardName.INTERPLANETARY_TRADE, Factory: InterplanetaryTrade_1.InterplanetaryTrade },
        { cardName: CardName_1.CardName.ORBITAL_CLEANUP, Factory: OrbitalCleanup_1.OrbitalCleanup },
        { cardName: CardName_1.CardName.PROJECT_INSPECTION, Factory: ProjectInspection_1.ProjectInspection },
        { cardName: CardName_1.CardName.HI_TECH_LAB, Factory: HiTechLab_1.HiTechLab },
        { cardName: CardName_1.CardName.ENERGY_MARKET, Factory: EnergyMarket_1.EnergyMarket },
        { cardName: CardName_1.CardName.LAW_SUIT, Factory: LawSuit_1.LawSuit },
        { cardName: CardName_1.CardName.STANFORD_TORUS, Factory: StanfordTorus_1.StanfordTorus },
        { cardName: CardName_1.CardName.ASTEROID_HOLLOWING, Factory: AsteroidHollowing_1.AsteroidHollowing },
        { cardName: CardName_1.CardName.ASTEROID_RIGHTS, Factory: AsteroidRights_1.AsteroidRights },
        { cardName: CardName_1.CardName.COMET_AIMING, Factory: CometAiming_1.CometAiming },
        { cardName: CardName_1.CardName.CUTTING_EDGE_TECHNOLOGY, Factory: CuttingEdgeTechnology_1.CuttingEdgeTechnology },
        { cardName: CardName_1.CardName.CRASH_SITE_CLEANUP, Factory: CrashSiteCleanup_1.CrashSiteCleanup },
        { cardName: CardName_1.CardName.DIRECTED_IMPACTORS, Factory: DirectedImpactors_1.DirectedImpactors },
        { cardName: CardName_1.CardName.FIELD_CAPPED_CITY, Factory: FieldCappedCity_1.FieldCappedCity },
        { cardName: CardName_1.CardName.MAGNETIC_SHIELD, Factory: MagneticShield_1.MagneticShield },
        { cardName: CardName_1.CardName.MELTWORKS, Factory: Meltworks_1.Meltworks },
        { cardName: CardName_1.CardName.MOHOLE_LAKE, Factory: MoholeLake_1.MoholeLake },
        { cardName: CardName_1.CardName.DIVERSITY_SUPPORT, Factory: DiversitySupport_1.DiversitySupport },
        { cardName: CardName_1.CardName.JOVIAN_EMBASSY, Factory: JovianEmbassy_1.JovianEmbassy },
        { cardName: CardName_1.CardName.TOPSOIL_CONTRACT, Factory: TopsoilContract_1.TopsoilContract },
        { cardName: CardName_1.CardName.IMPORTED_NUTRIENTS, Factory: ImportedNutrients_1.ImportedNutrients },
        { cardName: CardName_1.CardName.ASTEROID_DEFLECTION_SYSTEM, Factory: AsteroidDeflectionSystem_1.AsteroidDeflectionSystem },
        { cardName: CardName_1.CardName.SUB_CRUST_MEASUREMENTS, Factory: SubCrustMeasurements_1.SubCrustMeasurements },
        { cardName: CardName_1.CardName.POTATOES, Factory: Potatoes_1.Potatoes },
        { cardName: CardName_1.CardName.MEAT_INDUSTRY, Factory: MeatIndustry_1.MeatIndustry },
        { cardName: CardName_1.CardName.ADVERTISING, Factory: Advertising_1.Advertising },
        { cardName: CardName_1.CardName.DEIMOS_DOWN_PROMO, Factory: DeimosDownPromo_1.DeimosDownPromo },
        { cardName: CardName_1.CardName.GREAT_DAM_PROMO, Factory: GreatDamPromo_1.GreatDamPromo },
        { cardName: CardName_1.CardName.MAGNETIC_FIELD_GENERATORS_PROMO, Factory: MagneticFieldGeneratorsPromo_1.MagneticFieldGeneratorsPromo },
        { cardName: CardName_1.CardName.SATURN_SURFING, Factory: SaturnSurfing_1.SaturnSurfing },
        { cardName: CardName_1.CardName.BIO_PRINTING_FACILITY, Factory: BioPrintingFacility_1.BioPrintingFacility },
        { cardName: CardName_1.CardName.BACTOVIRAL_RESEARCH, Factory: BactoviralResearch_1.BactoviralResearch },
        { cardName: CardName_1.CardName.HARVEST, Factory: Harvest_1.Harvest },
    ],
    cardsToRemove: [
        CardName_1.CardName.DEIMOS_DOWN,
        CardName_1.CardName.GREAT_DAM,
        CardName_1.CardName.MAGNETIC_FIELD_GENERATORS
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.ARCADIAN_COMMUNITIES, Factory: ArcadianCommunities_1.ArcadianCommunities },
        { cardName: CardName_1.CardName.ASTRODRILL, Factory: Astrodrill_1.Astrodrill },
        { cardName: CardName_1.CardName.FACTORUM, Factory: Factorum_1.Factorum },
        { cardName: CardName_1.CardName.PHARMACY_UNION, Factory: PharmacyUnion_1.PharmacyUnion },
        { cardName: CardName_1.CardName.PHILARES, Factory: Philares_1.Philares },
        { cardName: CardName_1.CardName.MONS_INSURANCE, Factory: MonsInsurance_1.MonsInsurance },
        { cardName: CardName_1.CardName.RECYCLON, Factory: Recyclon_1.Recyclon },
        { cardName: CardName_1.CardName.SPLICE, Factory: Splice_1.Splice },
    ]
});
