"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GreeneryStandardProject = void 0;
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var constants_1 = require("../../../constants");
var StandardProjectCard_1 = require("../../StandardProjectCard");
var PartyHooks_1 = require("../../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../../turmoil/parties/PartyName");
var constants = require("../../../constants");
var PlaceGreeneryTile_1 = require("../../../deferredActions/PlaceGreeneryTile");
var GreeneryStandardProject = (function (_super) {
    __extends(GreeneryStandardProject, _super);
    function GreeneryStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.GREENERY_STANDARD_PROJECT,
            cost: 23,
            metadata: {
                cardNumber: 'SP6',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 23 M€ to place a greenery tile and raise oxygen 1 step.', function (eb) {
                        eb.megacredits(23).startAction.greenery();
                    });
                }),
            },
        }) || this;
    }
    GreeneryStandardProject.prototype.canAct = function (player) {
        var greeneryCost = this.cost;
        var oxygenNotMaxed = player.game.getOxygenLevel() < constants.MAX_OXYGEN_LEVEL;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && oxygenNotMaxed)
            greeneryCost += constants_1.REDS_RULING_POLICY_COST;
        return player.canAfford(greeneryCost) && player.game.board.getAvailableSpacesForGreenery(player).length > 0;
    };
    GreeneryStandardProject.prototype.actionEssence = function (player) {
        player.game.defer(new PlaceGreeneryTile_1.PlaceGreeneryTile(player, 'Select space for greenery'));
    };
    return GreeneryStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.GreeneryStandardProject = GreeneryStandardProject;
