"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cryptocurrency = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var Cryptocurrency = (function (_super) {
    __extends(Cryptocurrency, _super);
    function Cryptocurrency() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.CRYPTOCURRENCY,
            cost: 6,
            tags: [Tags_1.Tags.ENERGY],
            resourceType: ResourceType_1.ResourceType.DATA,
            metadata: {
                cardNumber: 'Pf51',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 1 energy to add 1 data to this card.', function (eb) {
                        eb.energy(1).startAction.data({ amount: 1 }).or();
                    }).br;
                    b.action('Remove all data from this card to gain 3MC per data removed.', function (eb) {
                        eb.text('x').data({ amount: 1 }).startAction.text('x').megacredits(3);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Cryptocurrency.prototype.canAct = function (player) {
        return player.energy > 0 || this.resourceCount > 0;
    };
    Cryptocurrency.prototype.action = function (player) {
        var _this = this;
        var firstOption = new SelectOption_1.SelectOption('Spend 1 energy to add 1 data to this card.', 'Spend energy', function () {
            player.addResource(Resources_1.Resources.ENERGY, -1);
            player.addResourceTo(_this, { qty: 1, log: true });
            return undefined;
        });
        var secondOption = new SelectOption_1.SelectOption('Remove all data from this card to gain 3MC per data removed.', 'Spend data', function () {
            player.addResource(Resources_1.Resources.MEGACREDITS, 3 * _this.resourceCount, { log: true });
            _this.resourceCount = 0;
            return undefined;
        });
        if (this.resourceCount === 0) {
            firstOption.cb();
            return undefined;
        }
        if (player.energy === 0) {
            secondOption.cb();
            return undefined;
        }
        return new OrOptions_1.OrOptions(firstOption, secondOption);
    };
    Cryptocurrency.prototype.play = function () {
        return undefined;
    };
    return Cryptocurrency;
}(Card_1.Card));
exports.Cryptocurrency = Cryptocurrency;
