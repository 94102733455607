"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiredRaiders = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Options_1 = require("../Options");
var HiredRaiders = (function (_super) {
    __extends(HiredRaiders, _super);
    function HiredRaiders() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.HIRED_RAIDERS,
            cost: 1,
            metadata: {
                cardNumber: '124',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('steal', Size_1.Size.MEDIUM, true).steel(2, { all: Options_1.all }).br;
                    b.or().br;
                    b.text('steal', Size_1.Size.MEDIUM, true).megacredits(3, { all: Options_1.all });
                }),
                description: 'Steal up to 2 steel, or 3 M€ from any player.',
            },
        }) || this;
    }
    HiredRaiders.prototype.play = function (player) {
        if (player.game.isSoloMode()) {
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Steal 2 steel', 'Steal steel', function () {
                player.steel += 2;
                return undefined;
            }), new SelectOption_1.SelectOption('Steal 3 M€', 'Steal M€', function () {
                player.megaCredits += 3;
                return undefined;
            }));
        }
        var availablePlayerTargets = player.game.getPlayers().filter(function (p) { return p.id !== player.id; });
        var availableActions = new OrOptions_1.OrOptions();
        availablePlayerTargets.forEach(function (target) {
            if (target.steel > 0 && !target.alloysAreProtected()) {
                var amountStolen_1 = Math.min(2, target.steel);
                var optionTitle = 'Steal ' + amountStolen_1 + ' steel from ' + target.name;
                availableActions.options.push(new SelectOption_1.SelectOption(optionTitle, 'Confirm', function () {
                    player.steel += amountStolen_1;
                    target.deductResource(Resources_1.Resources.STEEL, 2, { log: true, from: player });
                    return undefined;
                }));
            }
            if (target.megaCredits > 0 && !target.megaCreditsAreProtected()) {
                var amountStolen_2 = Math.min(3, target.megaCredits);
                var optionTitle = 'Steal ' + amountStolen_2 + ' M€ from ' + target.name;
                availableActions.options.push(new SelectOption_1.SelectOption(optionTitle, 'Confirm', function () {
                    player.megaCredits += amountStolen_2;
                    target.deductResource(Resources_1.Resources.MEGACREDITS, 3, { log: true, from: player });
                    return undefined;
                }));
            }
        });
        if (availableActions.options.length > 0) {
            availableActions.options.push(new SelectOption_1.SelectOption('Do not steal', 'Confirm', function () {
                return undefined;
            }));
            return availableActions;
        }
        return undefined;
    };
    return HiredRaiders;
}(Card_1.Card));
exports.HiredRaiders = HiredRaiders;
