"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StratosphericBirds = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var RemoveResourcesFromCard_1 = require("../../deferredActions/RemoveResourcesFromCard");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Card_1 = require("../Card");
var StratosphericBirds = (function (_super) {
    __extends(StratosphericBirds, _super);
    function StratosphericBirds() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.STRATOSPHERIC_BIRDS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.ANIMAL],
            cost: 12,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(12); }),
            metadata: {
                cardNumber: '249',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 animal to this card.', function (eb) {
                        eb.empty().startAction.animals(1);
                    }).br;
                    b.minus().floaters(1).br;
                    b.vpText('1 VP for each Animal on this card.');
                }),
                description: {
                    text: 'Requires Venus 12% and that you spend 1 Floater from any card.',
                    align: 'left',
                },
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.animals(1, 1),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    StratosphericBirds.prototype.canPlay = function (player) {
        var cardsWithFloater = player.getCardsWithResources().filter(function (card) { return card.resourceType === ResourceType_1.ResourceType.FLOATER; });
        if (cardsWithFloater.length === 0)
            return false;
        if (cardsWithFloater.length > 1) {
            return true;
        }
        else {
            var floaterCard = cardsWithFloater[0];
            if (floaterCard.name !== CardName_1.CardName.DIRIGIBLES)
                return true;
            var canPayForFloater = ((floaterCard.resourceCount - 1) * 3 + player.megaCredits) >= player.getCardCost(this);
            return canPayForFloater;
        }
    };
    StratosphericBirds.prototype.play = function (player) {
        player.game.defer(new RemoveResourcesFromCard_1.RemoveResourcesFromCard(player, ResourceType_1.ResourceType.FLOATER, 1, true));
        return undefined;
    };
    StratosphericBirds.prototype.canAct = function () {
        return true;
    };
    StratosphericBirds.prototype.getVictoryPoints = function () {
        return this.resourceCount;
    };
    StratosphericBirds.prototype.action = function (player) {
        player.addResourceTo(this);
        return undefined;
    };
    return StratosphericBirds;
}(Card_1.Card));
exports.StratosphericBirds = StratosphericBirds;
