"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRenderSymbolType_1 = require("@/cards/render/CardRenderSymbolType");
var Size_1 = require("@/cards/render/Size");
exports.default = vue_1.default.extend({
    name: 'CardRenderSymbolComponent',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getClasses: function () {
            var type = this.item.type;
            var size = this.item.size;
            var classes = ['card-special'];
            if (type === CardRenderSymbolType_1.CardRenderSymbolType.ASTERIX) {
                classes.push('card-asterix');
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.MINUS) {
                classes.push('card-minus');
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.PLUS) {
                classes.push('card-plus');
                if (size === Size_1.Size.SMALL) {
                    classes.push('card-plus--small');
                }
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.OR) {
                classes.push('card-or');
                if (size === Size_1.Size.SMALL) {
                    classes.push('card-or--small');
                }
                if (size === Size_1.Size.TINY) {
                    classes.push('card-or--tiny');
                }
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.COLON) {
                classes.push('card-colon');
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.ARROW) {
                if (size === Size_1.Size.SMALL) {
                    classes.push('card-red-arrow--small');
                }
                else {
                    classes.push('card-red-arrow');
                }
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.NBSP) {
                classes.push('card-nbsp');
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.VSPACE) {
                classes.push('card-vspace');
                if (size === Size_1.Size.SMALL) {
                    classes.push('card-vspace--small');
                }
                else if (size === Size_1.Size.MEDIUM) {
                    classes.push('card-vspace--medium');
                }
                else if (size === Size_1.Size.LARGE) {
                    classes.push('card-vspace--large');
                }
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.SLASH) {
                classes.push('card-slash');
            }
            else if (type === CardRenderSymbolType_1.CardRenderSymbolType.EQUALS) {
                classes.push('card-equals');
            }
            return classes.join(' ');
        },
        getContent: function () {
            return this.item.isIcon ? '' : this.item.type;
        },
    },
});
