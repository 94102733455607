"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterstellarColonyShip = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var InterstellarColonyShip = (function (_super) {
    __extends(InterstellarColonyShip, _super);
    function InterstellarColonyShip() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.INTERSTELLAR_COLONY_SHIP,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 24,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 5); }),
            metadata: {
                description: 'Requires that you have 5 Science tags.',
                cardNumber: '027',
                victoryPoints: 4,
            },
        }) || this;
    }
    InterstellarColonyShip.prototype.play = function (player) {
        if (player.getTagCount(Tags_1.Tags.SCIENCE) < 5) {
            throw 'Requires 5 science tags.';
        }
        return undefined;
    };
    InterstellarColonyShip.prototype.getVictoryPoints = function () {
        return 4;
    };
    return InterstellarColonyShip;
}(Card_1.Card));
exports.InterstellarColonyShip = InterstellarColonyShip;
