"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNITY_POLICY_4 = exports.UNITY_POLICY_3 = exports.UNITY_POLICY_2 = exports.UNITY_POLICY_1 = exports.UNITY_BONUS_2 = exports.UNITY_BONUS_1 = exports.Unity = void 0;
var Party_1 = require("./Party");
var PartyName_1 = require("./PartyName");
var Tags_1 = require("../../cards/Tags");
var Resources_1 = require("../../Resources");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var constants_1 = require("../../constants");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectCard_1 = require("../../inputs/SelectCard");
var SelectOption_1 = require("../../inputs/SelectOption");
var ResourceType_1 = require("../../ResourceType");
var TurmoilPolicy_1 = require("../TurmoilPolicy");
var Unity = (function (_super) {
    __extends(Unity, _super);
    function Unity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = PartyName_1.PartyName.UNITY;
        _this.description = 'Wants to see humanity prosper in the whole solar system.';
        _this.bonuses = [exports.UNITY_BONUS_1, exports.UNITY_BONUS_2];
        _this.policies = [exports.UNITY_POLICY_1, exports.UNITY_POLICY_2, exports.UNITY_POLICY_3, exports.UNITY_POLICY_4];
        return _this;
    }
    return Unity;
}(Party_1.Party));
exports.Unity = Unity;
var UnityBonus01 = (function () {
    function UnityBonus01() {
        this.id = 'ub01';
        this.description = 'Gain 1 M€ for each Venus, Earth and Jovian tag you have';
        this.isDefault = true;
    }
    UnityBonus01.prototype.getScore = function (player) {
        var tags = [Tags_1.Tags.VENUS, Tags_1.Tags.EARTH, Tags_1.Tags.JOVIAN];
        return tags.map(function (tag) { return player.getTagCount(tag, false, false); }).reduce(function (acc, count) { return acc + count; }, 0);
    };
    UnityBonus01.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return UnityBonus01;
}());
var UnityBonus02 = (function () {
    function UnityBonus02() {
        this.id = 'ub02';
        this.description = 'Gain 1 M€ for each Space tag you have';
        this.isDefault = false;
    }
    UnityBonus02.prototype.getScore = function (player) {
        return player.getTagCount(Tags_1.Tags.SPACE, false, false);
    };
    UnityBonus02.prototype.grant = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.MEGACREDITS, _this.getScore(player));
        });
    };
    return UnityBonus02;
}());
var UnityPolicy01 = (function () {
    function UnityPolicy01() {
        this.isDefault = true;
        this.id = TurmoilPolicy_1.TurmoilPolicy.UNITY_DEFAULT_POLICY;
        this.description = 'Your titanium resources are worth 1 M€ extra';
    }
    return UnityPolicy01;
}());
var UnityPolicy02 = (function () {
    function UnityPolicy02() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.UNITY_POLICY_2;
        this.description = 'Spend 4 M€ to gain 2 titanium or add 2 floaters to any card (Turmoil Unity)';
        this.isDefault = false;
    }
    UnityPolicy02.prototype.canAct = function (player) {
        return player.canAfford(4) && player.politicalAgendasActionUsedCount < constants_1.POLITICAL_AGENDAS_MAX_ACTION_USES;
    };
    UnityPolicy02.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Unity action', function (b) { return b.player(player); });
        player.politicalAgendasActionUsedCount += 1;
        game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 4, {
            title: 'Select how to pay for Turmoil Unity action',
            afterPay: function () {
                var availableFloaterCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
                var orOptions = new OrOptions_1.OrOptions();
                if (availableFloaterCards.length === 1) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Add 2 floaters to ' + availableFloaterCards[0].name, 'Confirm', function () {
                        player.addResourceTo(availableFloaterCards[0], { qty: 2, log: true });
                        return undefined;
                    }));
                }
                else if (availableFloaterCards.length > 1) {
                    orOptions.options.push(new SelectOption_1.SelectOption('Add 2 floaters to a card', 'Confirm', function () {
                        return new SelectCard_1.SelectCard('Select card to add 2 floaters', 'Add floaters', availableFloaterCards, function (foundCards) {
                            player.addResourceTo(foundCards[0], { qty: 2, log: true });
                            return undefined;
                        });
                    }));
                }
                orOptions.options.push(new SelectOption_1.SelectOption('Gain 2 titanium', 'Confirm', function () {
                    player.addResource(Resources_1.Resources.TITANIUM, 2);
                    game.log('${0} gained 2 titanium', function (b) { return b.player(player); });
                    return undefined;
                }));
                if (orOptions.options.length === 1)
                    return orOptions.options[0].cb();
                game.defer(new DeferredAction_1.DeferredAction(player, function () { return orOptions; }));
                return undefined;
            },
        }));
        return undefined;
    };
    return UnityPolicy02;
}());
var UnityPolicy03 = (function () {
    function UnityPolicy03() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.UNITY_POLICY_3;
        this.description = 'Spend 4 M€ to draw a Space card (Turmoil Unity)';
        this.isDefault = false;
    }
    UnityPolicy03.prototype.canAct = function (player) {
        return player.canAfford(4) && player.politicalAgendasActionUsedCount < constants_1.POLITICAL_AGENDAS_MAX_ACTION_USES;
    };
    UnityPolicy03.prototype.action = function (player) {
        var game = player.game;
        game.log('${0} used Turmoil Unity action', function (b) { return b.player(player); });
        player.politicalAgendasActionUsedCount += 1;
        game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 4, {
            title: 'Select how to pay for Turmoil Unity action',
            afterPay: function () {
                player.drawCard(1, { tag: Tags_1.Tags.SPACE });
            },
        }));
        return undefined;
    };
    return UnityPolicy03;
}());
var UnityPolicy04 = (function () {
    function UnityPolicy04() {
        this.id = TurmoilPolicy_1.TurmoilPolicy.UNITY_POLICY_4;
        this.description = 'Cards with Space tags cost 2 M€ less to play';
        this.isDefault = false;
    }
    return UnityPolicy04;
}());
exports.UNITY_BONUS_1 = new UnityBonus01();
exports.UNITY_BONUS_2 = new UnityBonus02();
exports.UNITY_POLICY_1 = new UnityPolicy01();
exports.UNITY_POLICY_2 = new UnityPolicy02();
exports.UNITY_POLICY_3 = new UnityPolicy03();
exports.UNITY_POLICY_4 = new UnityPolicy04();
