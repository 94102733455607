"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TURMOIL_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var AerialLenses_1 = require("./AerialLenses");
var BannedDelegate_1 = require("./BannedDelegate");
var CulturalMetropolis_1 = require("./CulturalMetropolis");
var DiasporaMovement_1 = require("./DiasporaMovement");
var EventAnalysts_1 = require("./EventAnalysts");
var GMOContract_1 = require("./GMOContract");
var LakefrontResorts_1 = require("./LakefrontResorts");
var MartianMediaCenter_1 = require("./MartianMediaCenter");
var ParliamentHall_1 = require("./ParliamentHall");
var PoliticalAlliance_1 = require("./PoliticalAlliance");
var Pristar_1 = require("./Pristar");
var PROffice_1 = require("./PROffice");
var PublicCelebrations_1 = require("./PublicCelebrations");
var Recruitment_1 = require("./Recruitment");
var RedTourismWave_1 = require("./RedTourismWave");
var SeptumTribus_1 = require("./SeptumTribus");
var SponsoredMohole_1 = require("./SponsoredMohole");
var SupportedResearch_1 = require("./SupportedResearch");
var TerralabsResearch_1 = require("./TerralabsResearch");
var UtopiaInvest_1 = require("./UtopiaInvest");
var VoteOfNoConfidence_1 = require("./VoteOfNoConfidence");
var WildlifeDome_1 = require("./WildlifeDome");
exports.TURMOIL_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Turmoil,
    projectCards: [
        { cardName: CardName_1.CardName.AERIAL_LENSES, Factory: AerialLenses_1.AerialLenses },
        { cardName: CardName_1.CardName.BANNED_DELEGATE, Factory: BannedDelegate_1.BannedDelegate },
        { cardName: CardName_1.CardName.CULTURAL_METROPOLIS, Factory: CulturalMetropolis_1.CulturalMetropolis },
        { cardName: CardName_1.CardName.DIASPORA_MOVEMENT, Factory: DiasporaMovement_1.DiasporaMovement },
        { cardName: CardName_1.CardName.EVENT_ANALYSTS, Factory: EventAnalysts_1.EventAnalysts },
        { cardName: CardName_1.CardName.GMO_CONTRACT, Factory: GMOContract_1.GMOContract },
        { cardName: CardName_1.CardName.MARTIAN_MEDIA_CENTER, Factory: MartianMediaCenter_1.MartianMediaCenter },
        { cardName: CardName_1.CardName.PARLIAMENT_HALL, Factory: ParliamentHall_1.ParliamentHall },
        { cardName: CardName_1.CardName.PR_OFFICE, Factory: PROffice_1.PROffice },
        { cardName: CardName_1.CardName.POLITICAL_ALLIANCE, Factory: PoliticalAlliance_1.PoliticalAlliance },
        { cardName: CardName_1.CardName.PUBLIC_CELEBRATIONS, Factory: PublicCelebrations_1.PublicCelebrations },
        { cardName: CardName_1.CardName.RECRUITMENT, Factory: Recruitment_1.Recruitment },
        { cardName: CardName_1.CardName.RED_TOURISM_WAVE, Factory: RedTourismWave_1.RedTourismWave },
        { cardName: CardName_1.CardName.SPONSORED_MOHOLE, Factory: SponsoredMohole_1.SponsoredMohole },
        { cardName: CardName_1.CardName.SUPPORTED_RESEARCH, Factory: SupportedResearch_1.SupportedResearch },
        { cardName: CardName_1.CardName.WILDLIFE_DOME, Factory: WildlifeDome_1.WildlifeDome },
        { cardName: CardName_1.CardName.VOTE_OF_NO_CONFIDENCE, Factory: VoteOfNoConfidence_1.VoteOfNoConfidence },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.LAKEFRONT_RESORTS, Factory: LakefrontResorts_1.LakefrontResorts },
        { cardName: CardName_1.CardName.PRISTAR, Factory: Pristar_1.Pristar },
        { cardName: CardName_1.CardName.TERRALABS_RESEARCH, Factory: TerralabsResearch_1.TerralabsResearch },
        { cardName: CardName_1.CardName.UTOPIA_INVEST, Factory: UtopiaInvest_1.UtopiaInvest },
        { cardName: CardName_1.CardName.SEPTUM_TRIBUS, Factory: SeptumTribus_1.SeptumTribus },
    ],
});
