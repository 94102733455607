"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'SelectPlayerRow',
    props: {
        player: {
            type: Object,
        },
    },
    methods: {},
    data: function () {
        return {};
    },
});
