"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Timer = void 0;
var Timer = (function () {
    function Timer() {
        this.sumElapsed = 0;
        this.startedAt = 0;
        this.running = false;
        this.afterFirstAction = false;
    }
    Timer.newInstance = function () {
        return new Timer();
    };
    Timer.prototype.serialize = function () {
        return {
            sumElapsed: this.sumElapsed,
            startedAt: this.startedAt,
            running: this.running,
            afterFirstAction: this.afterFirstAction,
            lastStoppedAt: Timer.lastStoppedAt,
        };
    };
    Timer.deserialize = function (d) {
        var timer = new Timer();
        timer.sumElapsed = d.sumElapsed;
        timer.startedAt = d.startedAt;
        timer.running = d.running;
        timer.afterFirstAction = d.afterFirstAction;
        Timer.lastStoppedAt = d.lastStoppedAt;
        return timer;
    };
    Timer.prototype.start = function () {
        this.running = true;
        this.startedAt = Timer.lastStoppedAt === 0 ? Date.now() : Timer.lastStoppedAt;
    };
    Timer.prototype.stop = function () {
        this.running = false;
        Timer.lastStoppedAt = Date.now();
        if (!this.afterFirstAction) {
            this.afterFirstAction = true;
            return;
        }
        this.sumElapsed += Timer.lastStoppedAt - this.startedAt;
    };
    Timer.toString = function (d) {
        var elapsed = d.sumElapsed + (d.running ? Date.now() - d.startedAt : 0);
        var elapsedDate = new Date(elapsed);
        var hours = elapsedDate.getUTCHours() + (elapsedDate.getUTCDate() - 1) * 24;
        if (hours > 0) {
            return String(hours) + elapsedDate.toISOString().substr(13, 6);
        }
        return elapsedDate.toISOString().substr(14, 5);
    };
    Timer.lastStoppedAt = 0;
    return Timer;
}());
exports.Timer = Timer;
