"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceOceanTile = void 0;
var constants = require("../constants");
var SelectSpace_1 = require("../inputs/SelectSpace");
var SpaceType_1 = require("../SpaceType");
var DeferredAction_1 = require("./DeferredAction");
var PlaceOceanTile = (function () {
    function PlaceOceanTile(player, title) {
        if (title === void 0) { title = 'Select space for ocean tile'; }
        this.player = player;
        this.title = title;
        this.priority = DeferredAction_1.Priority.PLACE_OCEAN_TILE;
    }
    PlaceOceanTile.prototype.execute = function () {
        var _this = this;
        if (this.player.game.board.getOceansOnBoard() >= constants.MAX_OCEAN_TILES) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, this.player.game.board.getAvailableSpacesForOcean(this.player), function (space) {
            _this.player.game.addOceanTile(_this.player, space.id, SpaceType_1.SpaceType.OCEAN);
            return undefined;
        });
    };
    return PlaceOceanTile;
}());
exports.PlaceOceanTile = PlaceOceanTile;
