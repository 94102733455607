"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadershipSummit = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.cards(1).slash().partyLeaders(1).plus().influence();
});
var LeadershipSummit = (function () {
    function LeadershipSummit() {
        this.name = GlobalEventName_1.GlobalEventName.LEADERSHIP_SUMMIT;
        this.description = 'Draw 1 card for each party leader (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.GREENS;
        this.currentDelegate = PartyName_1.PartyName.REDS;
        this.renderData = RENDER_DATA;
    }
    LeadershipSummit.prototype.resolve = function (game, turmoil) {
        game.getPlayers().forEach(function (player) {
            var partyLeaderCount = turmoil.parties.filter(function (party) { return party.partyLeader === player.id; }).length;
            player.drawCard(Math.min(5, partyLeaderCount) + turmoil.getPlayerInfluence(player));
        });
    };
    return LeadershipSummit;
}());
exports.LeadershipSummit = LeadershipSummit;
