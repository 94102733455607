"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Units = void 0;
var Units;
(function (Units) {
    Units.EMPTY = {
        get megacredits() {
            return 0;
        },
        get steel() {
            return 0;
        },
        get titanium() {
            return 0;
        },
        get plants() {
            return 0;
        },
        get energy() {
            return 0;
        },
        get heat() {
            return 0;
        },
    };
    function of(partialUnits) {
        return {
            megacredits: partialUnits.megacredits === undefined ? 0 : partialUnits.megacredits,
            steel: partialUnits.steel === undefined ? 0 : partialUnits.steel,
            titanium: partialUnits.titanium === undefined ? 0 : partialUnits.titanium,
            plants: partialUnits.plants === undefined ? 0 : partialUnits.plants,
            energy: partialUnits.energy === undefined ? 0 : partialUnits.energy,
            heat: partialUnits.heat === undefined ? 0 : partialUnits.heat,
        };
    }
    Units.of = of;
    function negative(units) {
        return {
            megacredits: -units.megacredits,
            steel: -units.steel,
            titanium: -units.titanium,
            plants: -units.plants,
            energy: -units.energy,
            heat: -units.heat,
        };
    }
    Units.negative = negative;
})(Units = exports.Units || (exports.Units = {}));
