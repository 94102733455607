"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderDynamicVictoryPoints = void 0;
var CardRenderItemType_1 = require("./CardRenderItemType");
var CardRenderItem_1 = require("./CardRenderItem");
var Size_1 = require("./Size");
var CardRenderDynamicVictoryPoints = (function () {
    function CardRenderDynamicVictoryPoints(item, points, target) {
        this.item = item;
        this.points = points;
        this.target = target;
        this.targetOneOrMore = false;
        this.anyPlayer = false;
    }
    CardRenderDynamicVictoryPoints.prototype.getPointsHtml = function () {
        if (this.item === undefined && this.points === 0 && this.target === 0)
            return '?';
        if (this.item === undefined)
            return "" + this.points;
        if (this.target === this.points)
            return this.target + "/";
        return this.points + "/" + this.target;
    };
    CardRenderDynamicVictoryPoints.asteroids = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ASTEROIDS), points, target);
    };
    CardRenderDynamicVictoryPoints.microbes = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MICROBES), points, target);
    };
    CardRenderDynamicVictoryPoints.animals = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ANIMALS), points, target);
    };
    CardRenderDynamicVictoryPoints.oceans = function (points, target) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.OCEANS);
        item.size = Size_1.Size.SMALL;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.cities = function (points, target, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CITY);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.jovians = function (points, target) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.JOVIAN);
        item.isPlayed = true;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.floaters = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.FLOATERS), points, target);
    };
    CardRenderDynamicVictoryPoints.searchForLife = function () {
        var item = new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SCIENCE), 3, 3);
        item.targetOneOrMore = true;
        return item;
    };
    CardRenderDynamicVictoryPoints.fighter = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.FIGHTER), points, target);
    };
    CardRenderDynamicVictoryPoints.camps = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CAMPS), points, target);
    };
    CardRenderDynamicVictoryPoints.venusianHabitats = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.VENUSIAN_HABITAT), points, target);
    };
    CardRenderDynamicVictoryPoints.specializedRobots = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SPECIALIZED_ROBOT), points, target);
    };
    CardRenderDynamicVictoryPoints.colonies = function (points, target, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.COLONIES);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.science = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SCIENCE), points, target);
    };
    CardRenderDynamicVictoryPoints.preservation = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PRESERVATION), points, target);
    };
    CardRenderDynamicVictoryPoints.resourceCube = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE), points, target);
    };
    CardRenderDynamicVictoryPoints.data = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DATA_RESOURCE), points, target);
    };
    CardRenderDynamicVictoryPoints.moon = function (points, target) {
        return new CardRenderDynamicVictoryPoints(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON), points, target);
    };
    CardRenderDynamicVictoryPoints.moonMiningTile = function (points, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_MINE);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, points);
    };
    CardRenderDynamicVictoryPoints.moonColonyTile = function (points) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_COLONY);
        item.size = Size_1.Size.SMALL;
        return new CardRenderDynamicVictoryPoints(item, points, 1);
    };
    CardRenderDynamicVictoryPoints.moonRoadTile = function (points, any) {
        if (any === void 0) { any = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_ROAD);
        item.size = Size_1.Size.SMALL;
        item.anyPlayer = any;
        return new CardRenderDynamicVictoryPoints(item, points, 1);
    };
    CardRenderDynamicVictoryPoints.venus = function (points, target) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.VENUS);
        item.isPlayed = true;
        item.size = Size_1.Size.SMALL;
        return new CardRenderDynamicVictoryPoints(item, points, target);
    };
    CardRenderDynamicVictoryPoints.questionmark = function () {
        return new CardRenderDynamicVictoryPoints(undefined, 0, 0);
    };
    CardRenderDynamicVictoryPoints.any = function (points) {
        var item = new CardRenderDynamicVictoryPoints(undefined, points, points);
        item.anyPlayer = true;
        return item;
    };
    return CardRenderDynamicVictoryPoints;
}());
exports.CardRenderDynamicVictoryPoints = CardRenderDynamicVictoryPoints;
