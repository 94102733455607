"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PlayerInfo_vue_1 = require("@/client/components/overview/PlayerInfo.vue");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'top-bar',
    props: {
        playerView: {
            type: Object,
        },
    },
    components: {
        PlayerInfo: PlayerInfo_vue_1.default,
    },
    data: function () {
        return {
            componentKey: 0,
        };
    },
    methods: {
        forceRerender: function () {
            this.componentKey += 1;
        },
        toggleBar: function () {
            PreferencesManager_1.PreferencesManager.save('hide_top_bar', this.isExpanded(), true);
            this.forceRerender();
        },
        isExpanded: function () {
            return !PreferencesManager_1.PreferencesManager.loadBoolean('hide_top_bar');
        },
        formatCssClass: function () {
            var cssClasses = ['top-bar'];
            if (!this.isExpanded()) {
                cssClasses.push('top-bar-collapsed');
            }
            return cssClasses.join(' ');
        },
    },
});
