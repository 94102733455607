"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaEcumenopolis = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MoonCard_1 = require("./MoonCard");
var TileType_1 = require("../../TileType");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var PlaceMoonColonyTile_1 = require("../../moon/PlaceMoonColonyTile");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var LunaEcumenopolis = (function (_super) {
    __extends(LunaEcumenopolis, _super);
    function LunaEcumenopolis() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_ECUMENOPOLIS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.CITY, Tags_1.Tags.MOON],
            cost: 35,
            reserveUnits: Units_1.Units.of({ titanium: 2 }),
            metadata: {
                description: 'Spend 2 Titanium. ' +
                    'Place 2 colony tiles adjacent to at least 2 other colony tiles and raise Colony rate 2 steps. ' +
                    'Increase your TR 1 step for each 2 steps of the colony rate.',
                cardNumber: 'M84',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(2).nbsp;
                    b.text('2').moonColony({ secondaryTag: CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE }).asterix().br;
                    b.tr(1).slash().moonColonyRate().moonColonyRate();
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_COLONY],
        }) || this;
    }
    ;
    LunaEcumenopolis.prototype.canAffordTRBump = function (player) {
        var moonData = MoonExpansion_1.MoonExpansion.moonData(player.game);
        var expectedColonyRate = Math.min(moonData.colonyRate + 2, 8);
        var expectedTRBump = Math.floor(expectedColonyRate / 2);
        return player.canAfford(0, { tr: { moonColony: 2, tr: expectedTRBump } });
    };
    LunaEcumenopolis.prototype.canPlay = function (player) {
        if (!_super.prototype.canPlay.call(this, player)) {
            return false;
        }
        if (!this.canAffordTRBump(player)) {
            return false;
        }
        var moonData = MoonExpansion_1.MoonExpansion.moonData(player.game);
        var spaces = moonData.moon.getAvailableSpacesOnLand(player);
        var len = spaces.length;
        var firstSpaceId = '';
        var nextToTwoColonies = function (space) {
            var adjacentSpaces = moonData.moon.getAdjacentSpaces(space).filter(function (adjacentSpace) {
                var _a;
                return ((_a = adjacentSpace.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.MOON_COLONY || adjacentSpace.id === firstSpaceId;
            });
            return adjacentSpaces.length >= 2;
        };
        for (var x = 0; x < len; x++) {
            var first = spaces[x];
            if (nextToTwoColonies(first) === true) {
                firstSpaceId = first.id;
                for (var y = x + 1; y < len; y++) {
                    var second = spaces[y];
                    if (nextToTwoColonies(second) === true) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    LunaEcumenopolis.prototype.play = function (player) {
        player.game.defer(new CustomPlaceMoonTile(player));
        player.game.defer(new CustomPlaceMoonTile(player));
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
            var colonyRate = MoonExpansion_1.MoonExpansion.moonData(player.game).colonyRate;
            player.increaseTerraformRatingSteps(Math.floor(colonyRate / 2));
            return undefined;
        }));
        return undefined;
    };
    return LunaEcumenopolis;
}(MoonCard_1.MoonCard));
exports.LunaEcumenopolis = LunaEcumenopolis;
var CustomPlaceMoonTile = (function (_super) {
    __extends(CustomPlaceMoonTile, _super);
    function CustomPlaceMoonTile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomPlaceMoonTile.prototype.getSpaces = function () {
        var moonData = MoonExpansion_1.MoonExpansion.moonData(this.player.game);
        var spaces = moonData.moon.getAvailableSpacesOnLand(this.player);
        var filtered = spaces.filter(function (space) {
            var adjacentSpaces = moonData.moon.getAdjacentSpaces(space).filter(function (adjacentSpace) {
                var _a;
                return ((_a = adjacentSpace.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.MOON_COLONY;
            });
            return adjacentSpaces.length >= 2;
        });
        return filtered;
    };
    return CustomPlaceMoonTile;
}(PlaceMoonColonyTile_1.PlaceMoonColonyTile));
