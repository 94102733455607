"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainAppSettings = void 0;
var GameEnd_vue_1 = require("@/client/components/GameEnd.vue");
var CreateGameForm_vue_1 = require("@/client/components/create/CreateGameForm.vue");
var GameHome_vue_1 = require("@/client/components/GameHome.vue");
var GamesOverview_vue_1 = require("@/client/components/GamesOverview.vue");
var PlayerHome_vue_1 = require("@/client/components/PlayerHome.vue");
var PlayerInputFactory_vue_1 = require("@/client/components/PlayerInputFactory.vue");
var SpectatorHome_vue_1 = require("@/client/components/SpectatorHome.vue");
var StartScreen_vue_1 = require("@/client/components/StartScreen.vue");
var LoadGameForm_vue_1 = require("@/client/components/LoadGameForm.vue");
var DebugUI_vue_1 = require("@/client/components/DebugUI.vue");
var Help_vue_1 = require("@/client/components/help/Help.vue");
var i18n_1 = require("@/client/directives/i18n");
var constants = require("@/constants");
var raw_settings = require("@/genfiles/settings.json");
var utils_1 = require("@/utils/utils");
var dialogPolyfill = require('dialog-polyfill');
exports.mainAppSettings = {
    'el': '#app',
    'data': {
        screen: 'empty',
        playerkey: 0,
        settings: raw_settings,
        isServerSideRequestInProgress: false,
        componentsVisibility: {
            'milestones_list': true,
            'awards_list': true,
            'tags_concise': false,
            'pinned_player_0': false,
            'pinned_player_1': false,
            'pinned_player_2': false,
            'pinned_player_3': false,
            'pinned_player_4': false,
            'turmoil_parties': false,
        },
        game: undefined,
        playerView: undefined,
        spectator: undefined,
        logPaused: false,
    },
    'components': {
        'player-input-factory': PlayerInputFactory_vue_1.default,
        'start-screen': StartScreen_vue_1.default,
        'create-game-form': CreateGameForm_vue_1.default,
        'load-game-form': LoadGameForm_vue_1.default,
        'game-home': GameHome_vue_1.default,
        'player-home': PlayerHome_vue_1.default,
        'spectator-home': SpectatorHome_vue_1.default,
        'game-end': GameEnd_vue_1.default,
        'games-overview': GamesOverview_vue_1.default,
        'debug-ui': DebugUI_vue_1.default,
        'help': Help_vue_1.default,
    },
    'methods': {
        showAlert: function (message, cb) {
            if (cb === void 0) { cb = function () { }; }
            var dialogElement = document.getElementById('alert-dialog');
            var buttonElement = document.getElementById('alert-dialog-button');
            var messageElement = document.getElementById('alert-dialog-message');
            if (buttonElement !== null && messageElement !== null && dialogElement !== null && dialogElement.showModal !== undefined) {
                messageElement.innerHTML = i18n_1.$t(message);
                var handler_1 = function () {
                    buttonElement.removeEventListener('click', handler_1);
                    cb();
                };
                buttonElement.addEventListener('click', handler_1);
                dialogElement.showModal();
            }
            else {
                alert(message);
                cb();
            }
        },
        setVisibilityState: function (targetVar, isVisible) {
            if (isVisible === this.getVisibilityState(targetVar))
                return;
            this.componentsVisibility[targetVar] = isVisible;
        },
        getVisibilityState: function (targetVar) {
            return this.componentsVisibility[targetVar] ? true : false;
        },
        update: function (path) {
            var currentPathname = window.location.pathname;
            var xhr = new XMLHttpRequest();
            var app = this;
            var url = '/api' + path + window.location.search.replace('&noredirect', '');
            xhr.open('GET', url);
            xhr.onerror = function () {
                alert('Error getting game data');
            };
            xhr.onload = function () {
                try {
                    if (xhr.status === 200) {
                        var model = xhr.response;
                        if (path === '/player') {
                            app.playerView = model;
                        }
                        else if (path === '/spectator') {
                            app.spectator = model;
                        }
                        app.playerkey++;
                        if (model.game.phase === 'end' &&
                            window.location.search.includes('&noredirect') === false) {
                            app.screen = 'the-end';
                            if (currentPathname !== '/the-end') {
                                window.history.replaceState(xhr.response, constants.APP_NAME + " - Player", '/the-end?id=' + model.id);
                            }
                        }
                        else {
                            if (path === '/player') {
                                app.screen = 'player-home';
                            }
                            else if (path === '/spectator') {
                                app.screen = 'spectator-home';
                            }
                            if (currentPathname !== path) {
                                window.history.replaceState(xhr.response, constants.APP_NAME + " - Game", path + '?id=' + model.id);
                            }
                        }
                    }
                    else {
                        alert('Unexpected server response: ' + xhr.statusText);
                    }
                }
                catch (e) {
                    console.log('Error processing XHR response: ' + e);
                }
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        updatePlayer: function () {
            this.update('/player');
        },
        updateSpectator: function () {
            this.update('/spectator');
        },
    },
    mounted: function () {
        document.title = constants.APP_NAME;
        if (!window.HTMLDialogElement)
            dialogPolyfill.default.registerDialog(document.getElementById('alert-dialog'));
        var currentPathname = window.location.pathname;
        var app = this;
        if (currentPathname === '/player') {
            app.updatePlayer();
        }
        else if (currentPathname === '/the-end') {
            var urlParams = new URLSearchParams(window.location.search);
            var id = urlParams.get('id') || '';
            if (utils_1.isPlayerId(id)) {
                app.updatePlayer();
            }
            else if (utils_1.isSpectatorId(id)) {
                app.updateSpectator();
            }
            else {
                alert('Bad id URL parameter.');
            }
        }
        else if (currentPathname === '/game') {
            app.screen = 'game-home';
            var xhr_1 = new XMLHttpRequest();
            xhr_1.open('GET', '/api/game' + window.location.search);
            xhr_1.onerror = function () {
                alert('Error getting game data');
            };
            xhr_1.onload = function () {
                if (xhr_1.status === 200) {
                    window.history.replaceState(xhr_1.response, constants.APP_NAME + " - Game", '/game?id=' + xhr_1.response.id);
                    app.game = xhr_1.response;
                }
                else {
                    alert('Unexpected server response');
                }
            };
            xhr_1.responseType = 'json';
            xhr_1.send();
        }
        else if (currentPathname === '/games-overview') {
            app.screen = 'games-overview';
        }
        else if (currentPathname === '/new-game' || currentPathname === '/solo') {
            app.screen = 'create-game-form';
        }
        else if (currentPathname === '/load') {
            app.screen = 'load';
        }
        else if (currentPathname === '/cards') {
            app.screen = 'cards';
        }
        else if (currentPathname === '/help') {
            app.screen = 'help';
        }
        else if (currentPathname === '/spectator') {
            app.updateSpectator();
        }
        else {
            app.screen = 'start-screen';
        }
    },
};
