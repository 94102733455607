"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
var CardRenderEffectBoxComponent_vue_1 = require("@/client/components/card/CardRenderEffectBoxComponent.vue");
var CardRenderSymbolComponent_vue_1 = require("@/client/components/card/CardRenderSymbolComponent.vue");
var CardRenderer_1 = require("@/cards/render/CardRenderer");
var CardRenderItem_1 = require("@/cards/render/CardRenderItem");
var CardRenderSymbol_1 = require("@/cards/render/CardRenderSymbol");
exports.default = vue_1.default.extend({
    name: 'CardRenderCorpBoxComponent',
    props: {
        rows: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    components: {
        CardRenderSymbolComponent: CardRenderSymbolComponent_vue_1.default,
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
        CardRenderEffectBoxComponent: CardRenderEffectBoxComponent_vue_1.default,
    },
    methods: {
        getClasses: function () {
            var classes = ['card-corporation-box'];
            return classes.join(' ');
        },
        getComponentType: function (rowItem) {
            if (rowItem instanceof CardRenderSymbol_1.CardRenderSymbol) {
                return 'symbol';
            }
            else if (rowItem instanceof CardRenderer_1.CardRenderEffect) {
                return 'effect';
            }
            else if (rowItem instanceof CardRenderItem_1.CardRenderItem) {
                return 'item';
            }
            return '';
        },
    },
});
