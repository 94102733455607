"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var AllCards_1 = require("@/cards/AllCards");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var allItems = AllCards_1.ALL_PROJECT_CARD_NAMES.concat(AllCards_1.ALL_PRELUDE_CARD_NAMES).sort();
exports.default = vue_1.default.extend({
    name: 'CardsFilter',
    props: {},
    data: function () {
        return {
            selectedCardNames: [],
            foundCardNames: [],
            searchTerm: '',
        };
    },
    components: { Button: Button_vue_1.default },
    methods: {
        isPrelude: function (cardName) {
            return AllCards_1.ALL_PRELUDE_CARD_NAMES.includes(cardName);
        },
        removeCard: function (cardNameToRemove) {
            this.selectedCardNames = this.selectedCardNames.filter(function (curCardName) { return curCardName !== cardNameToRemove; }).sort();
        },
        addCard: function (cardNameToAdd) {
            if (this.selectedCardNames.includes(cardNameToAdd))
                return;
            this.selectedCardNames.push(cardNameToAdd);
            this.selectedCardNames.sort();
            this.searchTerm = '';
        },
    },
    watch: {
        selectedCardNames: function (value) {
            this.$emit('cards-list-changed', value);
        },
        searchTerm: function (value) {
            var _this = this;
            if (value === '') {
                this.foundCardNames = [];
                return;
            }
            if (value.indexOf(',') !== -1) {
                var cardNames = new Set(value.split(',').map(function (c) { return c.trim(); }));
                for (var _i = 0, allItems_1 = allItems; _i < allItems_1.length; _i++) {
                    var item = allItems_1[_i];
                    if (cardNames.has(item)) {
                        this.addCard(item);
                    }
                }
                return;
            }
            var newCardNames = allItems.filter(function (candidate) { return !_this.selectedCardNames.includes(candidate) && candidate.toLowerCase().indexOf(value.toLowerCase()) !== -1; }).sort();
            this.foundCardNames = newCardNames.slice(0, 5);
        },
    },
});
