"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceType = void 0;
var ResourceType;
(function (ResourceType) {
    ResourceType["ANIMAL"] = "Animal";
    ResourceType["MICROBE"] = "Microbe";
    ResourceType["FIGHTER"] = "Fighter";
    ResourceType["SCIENCE"] = "Science";
    ResourceType["FLOATER"] = "Floater";
    ResourceType["ASTEROID"] = "Asteroid";
    ResourceType["PRESERVATION"] = "Preservation";
    ResourceType["CAMP"] = "Camp";
    ResourceType["DISEASE"] = "Disease";
    ResourceType["RESOURCE_CUBE"] = "Resource cube";
    ResourceType["DATA"] = "Data";
    ResourceType["SYNDICATE_FLEET"] = "Syndicate Fleet";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));
