"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Board_vue_1 = require("@/client/components/Board.vue");
var MoonBoard_vue_1 = require("@/client/components/moon/MoonBoard.vue");
var LogPanel_vue_1 = require("@/client/components/LogPanel.vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var utils_1 = require("@/utils/utils");
var Timer_1 = require("@/Timer");
var constants = require("@/constants");
function getViewModel(playerView, spectator) {
    if (playerView !== undefined)
        return playerView;
    if (spectator !== undefined)
        return spectator;
    throw new Error('Neither playerView nor spectator are defined');
}
exports.default = vue_1.default.extend({
    name: 'game-end',
    props: {
        playerView: {
            type: Object,
        },
        spectator: {
            type: Object,
        },
    },
    computed: {
        viewModel: function () {
            return getViewModel(this.playerView, this.spectator);
        },
        game: function () {
            return getViewModel(this.playerView, this.spectator).game;
        },
        players: function () {
            return getViewModel(this.playerView, this.spectator).players;
        },
        color: function () {
            if (this.playerView !== undefined)
                return this.playerView.thisPlayer.color;
            if (this.spectator !== undefined)
                return this.spectator.color;
            throw new Error('Neither playerView nor spectator are defined');
        },
    },
    data: function () {
        return {
            constants: constants,
        };
    },
    components: {
        'board': Board_vue_1.default,
        'log-panel': LogPanel_vue_1.default,
        Button: Button_vue_1.default,
        MoonBoard: MoonBoard_vue_1.default,
    },
    methods: {
        getEndGamePlayerRowColorClass: function (color) {
            return utils_1.playerColorClass(color.toLowerCase(), 'bg_transparent');
        },
        getTimer: function (p) {
            return Timer_1.Timer.toString(p.timer);
        },
        getSortedPlayers: function () {
            var copy = __spreadArray([], this.viewModel.players);
            copy.sort(function (a, b) {
                if (a.victoryPointsBreakdown.total < b.victoryPointsBreakdown.total)
                    return -1;
                if (a.victoryPointsBreakdown.total > b.victoryPointsBreakdown.total)
                    return 1;
                if (a.megaCredits < b.megaCredits)
                    return -1;
                if (a.megaCredits > b.megaCredits)
                    return 1;
                return 0;
            });
            return copy.reverse();
        },
        getWinners: function () {
            var sortedPlayers = this.getSortedPlayers();
            var firstWinner = sortedPlayers[0];
            var winners = [firstWinner];
            for (var i = 1; i < sortedPlayers.length; i++) {
                if (sortedPlayers[i].victoryPointsBreakdown.total === firstWinner.victoryPointsBreakdown.total &&
                    sortedPlayers[i].megaCredits === firstWinner.megaCredits) {
                    winners.push(sortedPlayers[i]);
                }
            }
            return winners;
        },
        isSoloGame: function () {
            return this.players.length === 1;
        },
    },
});
