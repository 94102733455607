"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OffWorldCityLiving = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var OffWorldCityLiving = (function (_super) {
    __extends(OffWorldCityLiving, _super);
    function OffWorldCityLiving() {
        return _super.call(this, {
            name: CardName_1.CardName.OFF_WORLD_CITY_LIVING,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.SPACE],
            cost: 35,
            tr: { moonColony: 1 },
            metadata: {
                description: 'Increase your M€ production 1 step per city tile NOT ON MARS. Increase Colony Rate 1 step.',
                cardNumber: 'M53',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).slash().city({ all: Options_1.all, secondaryTag: Tags_1.Tags.SPACE }).br;
                    b.moonColonyRate().br;
                    b.vpText('1 VP for each 3 city tiles in play.');
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.cities(1, 3, true),
            },
        }) || this;
    }
    ;
    OffWorldCityLiving.prototype.play = function (player) {
        var amount = player.game.getCitiesInPlay() - player.game.getCitiesInPlayOnMars();
        player.addProduction(Resources_1.Resources.MEGACREDITS, amount, { log: true });
        MoonExpansion_1.MoonExpansion.raiseColonyRate(player);
        return undefined;
    };
    OffWorldCityLiving.prototype.getVictoryPoints = function (player) {
        var amount = player.game.getCitiesInPlay();
        return Math.floor(amount / 3);
    };
    return OffWorldCityLiving;
}(Card_1.Card));
exports.OffWorldCityLiving = OffWorldCityLiving;
