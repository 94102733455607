"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnergyMarket = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var Options_1 = require("../Options");
var EnergyMarket = (function (_super) {
    __extends(EnergyMarket, _super);
    function EnergyMarket() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ENERGY_MARKET,
            tags: [Tags_1.Tags.ENERGY],
            cost: 3,
            metadata: {
                cardNumber: 'X03',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 2X M€ to gain X energy.', function (eb) {
                        eb.megacredits(2, { multiplier: Options_1.multiplier }).startAction.text('x').energy(1);
                    }).br;
                    b.or().br;
                    b.action('Decrease energy production 1 step to gain 8 M€.', function (eb) {
                        eb.production(function (pb) { return pb.energy(1); }).startAction.megacredits(8);
                    });
                }),
            },
        }) || this;
    }
    EnergyMarket.prototype.play = function () {
        return undefined;
    };
    EnergyMarket.prototype.canAct = function (player) {
        return player.canAfford(2) || player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    EnergyMarket.prototype.getEnergyOption = function (player, availableMC) {
        return new SelectAmount_1.SelectAmount('Select amount of energy to gain', 'Gain energy', function (amount) {
            if (player.canUseHeatAsMegaCredits) {
                player.addResource(Resources_1.Resources.ENERGY, amount);
                player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, (amount * 2)));
            }
            else {
                player.addResource(Resources_1.Resources.ENERGY, amount);
                player.deductResource(Resources_1.Resources.MEGACREDITS, (amount * 2));
            }
            player.game.log('${0} gained ${1} energy', function (b) { return b.player(player).number(amount); });
            return undefined;
        }, 1, Math.floor(availableMC / 2));
    };
    EnergyMarket.prototype.getMegacreditsOption = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.addResource(Resources_1.Resources.MEGACREDITS, 8);
        player.game.log('${0} decreased energy production 1 step to gain 8 M€', function (b) { return b.player(player); });
        return undefined;
    };
    EnergyMarket.prototype.action = function (player) {
        var _this = this;
        var availableMC = player.spendableMegacredits();
        if (availableMC >= 2 && player.getProduction(Resources_1.Resources.ENERGY) >= 1) {
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Spend 2X M€ to gain X energy', 'Spend M€', function () {
                return _this.getEnergyOption(player, availableMC);
            }), new SelectOption_1.SelectOption('Decrease energy production 1 step to gain 8 M€', 'Decrease energy', function () {
                return _this.getMegacreditsOption(player);
            }));
        }
        else if (availableMC >= 2) {
            return this.getEnergyOption(player, availableMC);
        }
        else if (player.getProduction(Resources_1.Resources.ENERGY) >= 1) {
            return this.getMegacreditsOption(player);
        }
        return undefined;
    };
    return EnergyMarket;
}(Card_1.Card));
exports.EnergyMarket = EnergyMarket;
