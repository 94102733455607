"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmeltingPlant = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var SmeltingPlant = (function (_super) {
    __extends(SmeltingPlant, _super);
    function SmeltingPlant() {
        return _super.call(this, {
            name: CardName_1.CardName.SMELTING_PLANT,
            tags: [Tags_1.Tags.BUILDING],
            metadata: {
                cardNumber: 'P30',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.oxygen(2).br;
                    b.steel(5);
                }),
                description: 'Raise oxygen 2 steps. Gain 5 steel.',
            },
        }) || this;
    }
    SmeltingPlant.prototype.play = function (player) {
        player.steel += 5;
        return player.game.increaseOxygenLevel(player, 2);
    };
    return SmeltingPlant;
}(PreludeCard_1.PreludeCard));
exports.SmeltingPlant = SmeltingPlant;
