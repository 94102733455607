"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resources = void 0;
var Resources;
(function (Resources) {
    Resources["MEGACREDITS"] = "megacredits";
    Resources["STEEL"] = "steel";
    Resources["TITANIUM"] = "titanium";
    Resources["PLANTS"] = "plants";
    Resources["ENERGY"] = "energy";
    Resources["HEAT"] = "heat";
})(Resources = exports.Resources || (exports.Resources = {}));
