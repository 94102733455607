"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopernicusTower = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../ResourceType");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Card_1 = require("../Card");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Size_1 = require("../render/Size");
var CopernicusTower = (function (_super) {
    __extends(CopernicusTower, _super);
    function CopernicusTower() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.COPERNICUS_TOWER,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.MOON],
            cost: 36,
            resourceType: ResourceType_1.ResourceType.SCIENCE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.production(Resources_1.Resources.TITANIUM, 2); }),
            metadata: {
                cardNumber: 'M72',
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.moon(1, 1),
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Requires you have 2 titanium production.', Size_1.Size.TINY, false, false).br;
                    b.action('Add 1 Science resource here, or spend 1 Science resource here to raise your TR 1 step.', function (eb) {
                        eb.empty().startAction.science(1).nbsp.slash().nbsp.science(1).arrow().tr(1);
                    });
                    b.br;
                    b.vpText('1 VP PER MOON TAG YOU HAVE.');
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    CopernicusTower.prototype.play = function () {
        return undefined;
    };
    CopernicusTower.prototype.canRaiseTR = function (player) {
        return this.resourceCount > 0 && player.canAfford(0, { tr: { tr: 1 } });
    };
    CopernicusTower.prototype.canAct = function (player) {
        return player.getProduction(Resources_1.Resources.TITANIUM) >= 2 || this.canRaiseTR(player);
    };
    CopernicusTower.prototype.action = function (player) {
        var _this = this;
        if (!this.canRaiseTR(player)) {
            this.addResource(player);
            return undefined;
        }
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Add 1 science resource to this card', 'Add resource', function () { return _this.addResource(player); }), new SelectOption_1.SelectOption('Remove 1 science resource to increase TR 1 step', 'Remove resource', function () { return _this.spendResource(player); }));
    };
    CopernicusTower.prototype.getVictoryPoints = function (player) {
        return player.getTagCount(Tags_1.Tags.MOON, true, false);
    };
    CopernicusTower.prototype.addResource = function (player) {
        player.addResourceTo(this, 1);
        return undefined;
    };
    CopernicusTower.prototype.spendResource = function (player) {
        player.removeResourceFrom(this);
        player.increaseTerraformRating();
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        return undefined;
    };
    return CopernicusTower;
}(Card_1.Card));
exports.CopernicusTower = CopernicusTower;
