"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RandomBoardOption = exports.BoardName = void 0;
var BoardName;
(function (BoardName) {
    BoardName["ORIGINAL"] = "tharsis";
    BoardName["HELLAS"] = "hellas";
    BoardName["ELYSIUM"] = "elysium";
    BoardName["ARABIA_TERRA"] = "arabia terra";
})(BoardName = exports.BoardName || (exports.BoardName = {}));
var RandomBoardOption;
(function (RandomBoardOption) {
    RandomBoardOption["OFFICIAL"] = "random official";
    RandomBoardOption["ALL"] = "random all";
})(RandomBoardOption = exports.RandomBoardOption || (exports.RandomBoardOption = {}));
