"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GainResources = void 0;
var DeferredAction_1 = require("./DeferredAction");
var GainResources = (function () {
    function GainResources(player, resource, options) {
        if (options === void 0) { options = {}; }
        var _a;
        this.player = player;
        this.resource = resource;
        this.options = options;
        this.priority = DeferredAction_1.Priority.GAIN_RESOURCE_OR_PRODUCTION;
        if (((_a = options.count) !== null && _a !== void 0 ? _a : 0) < 0) {
            throw new Error('GainResources count option must be >= 0');
        }
    }
    GainResources.prototype.execute = function () {
        var _a;
        if (this.options.count === 0) {
            return undefined;
        }
        this.player.addResource(this.resource, (_a = this.options.count) !== null && _a !== void 0 ? _a : 1, { log: this.options.log });
        if (this.options.cb) {
            this.options.cb();
        }
        return undefined;
    };
    return GainResources;
}());
exports.GainResources = GainResources;
