"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AerosportTournament = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var AerosportTournament = (function (_super) {
    __extends(AerosportTournament, _super);
    function AerosportTournament() {
        return _super.call(this, {
            name: CardName_1.CardName.AEROSPORT_TOURNAMENT,
            cardType: CardType_1.CardType.EVENT,
            cost: 7,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.floaters(5); }),
            metadata: {
                cardNumber: '214',
                description: 'Requires that you have 5 Floaters. Gain 1 M€ per each City tile in play.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(1).slash().city({ size: Size_1.Size.SMALL, all: Options_1.all });
                }),
                victoryPoints: 1,
            },
        }) || this;
    }
    ;
    AerosportTournament.prototype.play = function (player) {
        player.addResource(Resources_1.Resources.MEGACREDITS, player.game.getCitiesInPlay(), { log: true });
        return undefined;
    };
    AerosportTournament.prototype.getVictoryPoints = function () {
        return 1;
    };
    return AerosportTournament;
}(Card_1.Card));
exports.AerosportTournament = AerosportTournament;
