"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiveColonyBonus = void 0;
var DeferredAction_1 = require("./DeferredAction");
var Multiset_1 = require("../utils/Multiset");
var GiveColonyBonus = (function () {
    function GiveColonyBonus(player, colony) {
        this.player = player;
        this.colony = colony;
        this.priority = DeferredAction_1.Priority.DEFAULT;
        this.cb = function () { };
        this.waitingFor = new Multiset_1.Multiset();
    }
    GiveColonyBonus.prototype.execute = function () {
        if (this.colony.colonies.length === 0) {
            this.cb();
            return undefined;
        }
        for (var _i = 0, _a = this.colony.colonies; _i < _a.length; _i++) {
            var playerId = _a[_i];
            this.waitingFor.add(playerId);
        }
        for (var _b = 0, _c = this.waitingFor.entries(); _b < _c.length; _b++) {
            var entry = _c[_b];
            var playerId = entry[0];
            var bonusPlayer = this.player.game.getPlayerById(playerId);
            this.giveColonyBonus(bonusPlayer);
        }
        return undefined;
    };
    GiveColonyBonus.prototype.giveColonyBonus = function (player) {
        var _this = this;
        if (this.waitingFor.get(player.id) !== undefined && this.waitingFor.get(player.id) > 0) {
            this.waitingFor.subtract(player.id);
            var input = this.colony.giveColonyBonus(player, true);
            if (input !== undefined) {
                player.setWaitingFor(input, function () { return _this.giveColonyBonus(player); });
            }
            else {
                this.giveColonyBonus(player);
            }
        }
        else {
            this.waitingFor.remove(player.id);
            this.doneGettingBonus();
        }
    };
    GiveColonyBonus.prototype.doneGettingBonus = function () {
        if (this.waitingFor.entries().length === 0) {
            this.cb();
        }
    };
    return GiveColonyBonus;
}());
exports.GiveColonyBonus = GiveColonyBonus;
