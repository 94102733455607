"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Factorum = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var Units_1 = require("../../Units");
var Factorum = (function (_super) {
    __extends(Factorum, _super);
    function Factorum() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.FACTORUM,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.BUILDING],
            startingMegaCredits: 37,
            productionBox: Units_1.Units.of({ steel: 1 }),
            metadata: {
                cardNumber: 'R22',
                description: 'You start with 37 M€. Increase your steel production 1 step.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(37).nbsp.production(function (pb) { return pb.steel(1); });
                    b.corpBox('action', function (ce) {
                        ce.vSpace(Size_1.Size.LARGE);
                        ce.action('Increase your energy production 1 step IF YOU HAVE NO ENERGY RESOURCES, or spend 3M€ to draw a building card.', function (eb) {
                            eb.empty().arrow().production(function (pb) { return pb.energy(1); });
                            eb.or().megacredits(3).startAction.cards(1, { secondaryTag: Tags_1.Tags.BUILDING });
                        });
                    });
                }),
            },
        }) || this;
    }
    Factorum.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.STEEL, 1);
        return undefined;
    };
    Factorum.prototype.canAct = function (player) {
        return player.energy === 0 || player.canAfford(3);
    };
    Factorum.prototype.action = function (player) {
        var increaseEnergy = new SelectOption_1.SelectOption('Increase your energy production 1 step', 'Increase production', function () {
            player.addProduction(Resources_1.Resources.ENERGY, 1, { log: true });
            return undefined;
        });
        var drawBuildingCard = new SelectOption_1.SelectOption('Spend 3 M€ to draw a building card', 'Draw card', function () {
            player.deductResource(Resources_1.Resources.MEGACREDITS, 3);
            player.drawCard(1, { tag: Tags_1.Tags.BUILDING });
            return undefined;
        });
        if (player.energy > 0)
            return drawBuildingCard;
        if (!player.canAfford(3))
            return increaseEnergy;
        return new OrOptions_1.OrOptions(increaseEnergy, drawBuildingCard);
    };
    return Factorum;
}(Card_1.Card));
exports.Factorum = Factorum;
