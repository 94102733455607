"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Button_vue_1 = require("@/client/components/common/Button.vue");
var Party_vue_1 = require("@/client/components/Party.vue");
exports.default = vue_1.default.extend({
    name: 'SelectPartyToSendDelegate',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data: function () {
        return {
            selectedParty: undefined,
        };
    },
    components: { Button: Button_vue_1.default, Party: Party_vue_1.default },
    methods: {
        saveData: function () {
            var result = [];
            result.push([]);
            if (this.selectedParty !== undefined) {
                result[0].push(this.selectedParty);
            }
            this.onsave(result);
        },
        isDominant: function (partyName) {
            var _a;
            return partyName === ((_a = this.playerinput.turmoil) === null || _a === void 0 ? void 0 : _a.dominant);
        },
        partyAvailableToSelect: function (partyName) {
            if (this.playerinput.availableParties === undefined) {
                return false;
            }
            else {
                return this.playerinput.availableParties.includes(partyName);
            }
        },
    },
});
