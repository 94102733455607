"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceMoonRoadTile = void 0;
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var SelectSpace_1 = require("../inputs/SelectSpace");
var MoonExpansion_1 = require("./MoonExpansion");
var PlaceMoonRoadTile = (function () {
    function PlaceMoonRoadTile(player, title, spaces) {
        if (title === void 0) { title = 'Select a space on the Moon for a road tile.'; }
        this.player = player;
        this.title = title;
        this.spaces = spaces;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    PlaceMoonRoadTile.prototype.execute = function () {
        var _this = this;
        var moonData = MoonExpansion_1.MoonExpansion.moonData(this.player.game);
        var spaces = this.spaces !== undefined ?
            this.spaces :
            moonData.moon.getAvailableSpacesOnLand(this.player);
        if (spaces.length === 0) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, spaces, function (space) {
            MoonExpansion_1.MoonExpansion.addRoadTile(_this.player, space.id);
            MoonExpansion_1.MoonExpansion.raiseLogisticRate(_this.player);
            return undefined;
        });
    };
    return PlaceMoonRoadTile;
}());
exports.PlaceMoonRoadTile = PlaceMoonRoadTile;
