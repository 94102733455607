"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CelebrityLeaders = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardType_1 = require("../../cards/CardType");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.megacredits(2).slash().event({ played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var CelebrityLeaders = (function () {
    function CelebrityLeaders() {
        this.name = GlobalEventName_1.GlobalEventName.CELEBRITY_LEADERS;
        this.description = 'Gain 2 M€ for each event played (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.UNITY;
        this.currentDelegate = PartyName_1.PartyName.GREENS;
        this.renderData = RENDER_DATA;
    }
    CelebrityLeaders.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var eventsCards = player.playedCards.filter(function (card) { return card.cardType === CardType_1.CardType.EVENT; }).length;
            player.addResource(Resources_1.Resources.MEGACREDITS, 2 * (Math.min(5, eventsCards) + turmoil.getPlayerInfluence(player)), { log: true, from: _this.name });
        });
    };
    return CelebrityLeaders;
}());
exports.CelebrityLeaders = CelebrityLeaders;
