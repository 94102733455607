"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLONIES_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var Airliners_1 = require("./Airliners");
var AirRaid_1 = require("./AirRaid");
var Aridor_1 = require("./Aridor");
var Arklight_1 = require("./Arklight");
var AtmoCollectors_1 = require("./AtmoCollectors");
var CommunityServices_1 = require("./CommunityServices");
var Conscription_1 = require("./Conscription");
var CoronaExtractor_1 = require("./CoronaExtractor");
var CryoSleep_1 = require("./CryoSleep");
var EarthElevator_1 = require("./EarthElevator");
var EcologyResearch_1 = require("./EcologyResearch");
var FloaterLeasing_1 = require("./FloaterLeasing");
var FloaterPrototypes_1 = require("./FloaterPrototypes");
var FloaterTechnology_1 = require("./FloaterTechnology");
var GalileanWaystation_1 = require("./GalileanWaystation");
var HeavyTaxation_1 = require("./HeavyTaxation");
var IceMoonColony_1 = require("./IceMoonColony");
var ImpactorSwarm_1 = require("./ImpactorSwarm");
var InterplanetaryColonyShip_1 = require("./InterplanetaryColonyShip");
var JovianLanterns_1 = require("./JovianLanterns");
var JupiterFloatingStation_1 = require("./JupiterFloatingStation");
var LunaGovernor_1 = require("./LunaGovernor");
var LunarExports_1 = require("./LunarExports");
var LunarMining_1 = require("./LunarMining");
var MarketManipulation_1 = require("./MarketManipulation");
var MartianZoo_1 = require("./MartianZoo");
var MiningColony_1 = require("./MiningColony");
var MinorityRefuge_1 = require("./MinorityRefuge");
var MolecularPrinting_1 = require("./MolecularPrinting");
var NitrogenFromTitan_1 = require("./NitrogenFromTitan");
var PioneerSettlement_1 = require("./PioneerSettlement");
var Polyphemos_1 = require("./Polyphemos");
var Poseidon_1 = require("./Poseidon");
var ProductiveOutpost_1 = require("./ProductiveOutpost");
var QuantumCommunications_1 = require("./QuantumCommunications");
var RedSpotObservatory_1 = require("./RedSpotObservatory");
var RefugeeCamps_1 = require("./RefugeeCamps");
var ResearchColony_1 = require("./ResearchColony");
var RimFreighters_1 = require("./RimFreighters");
var SkyDocks_1 = require("./SkyDocks");
var SolarProbe_1 = require("./SolarProbe");
var SolarReflectors_1 = require("./SolarReflectors");
var SpacePort_1 = require("./SpacePort");
var SpacePortColony_1 = require("./SpacePortColony");
var SpinoffDepartment_1 = require("./SpinoffDepartment");
var StormCraftIncorporated_1 = require("./StormCraftIncorporated");
var SubZeroSaltFish_1 = require("./SubZeroSaltFish");
var TitanAirScrapping_1 = require("./TitanAirScrapping");
var TitanFloatingLaunchPad_1 = require("./TitanFloatingLaunchPad");
var TitanShuttles_1 = require("./TitanShuttles");
var TradeEnvoys_1 = require("./TradeEnvoys");
var TradingColony_1 = require("./TradingColony");
var UrbanDecomposers_1 = require("./UrbanDecomposers");
var WarpDrive_1 = require("./WarpDrive");
var BuildColonyStandardProject_1 = require("./BuildColonyStandardProject");
exports.COLONIES_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Colonies,
    projectCards: [
        { cardName: CardName_1.CardName.AIRLINERS, Factory: Airliners_1.Airliners },
        { cardName: CardName_1.CardName.AIR_RAID, Factory: AirRaid_1.AirRaid },
        { cardName: CardName_1.CardName.ATMO_COLLECTORS, Factory: AtmoCollectors_1.AtmoCollectors },
        { cardName: CardName_1.CardName.COMMUNITY_SERVICES, Factory: CommunityServices_1.CommunityServices },
        { cardName: CardName_1.CardName.CONSCRIPTION, Factory: Conscription_1.Conscription },
        { cardName: CardName_1.CardName.CORONA_EXTRACTOR, Factory: CoronaExtractor_1.CoronaExtractor },
        { cardName: CardName_1.CardName.CRYO_SLEEP, Factory: CryoSleep_1.CryoSleep },
        { cardName: CardName_1.CardName.EARTH_ELEVATOR, Factory: EarthElevator_1.EarthElevator },
        { cardName: CardName_1.CardName.ECOLOGY_RESEARCH, Factory: EcologyResearch_1.EcologyResearch },
        { cardName: CardName_1.CardName.FLOATER_LEASING, Factory: FloaterLeasing_1.FloaterLeasing },
        { cardName: CardName_1.CardName.FLOATER_PROTOTYPES, Factory: FloaterPrototypes_1.FloaterPrototypes },
        { cardName: CardName_1.CardName.FLOATER_TECHNOLOGY, Factory: FloaterTechnology_1.FloaterTechnology },
        { cardName: CardName_1.CardName.GALILEAN_WAYSTATION, Factory: GalileanWaystation_1.GalileanWaystation },
        { cardName: CardName_1.CardName.HEAVY_TAXATION, Factory: HeavyTaxation_1.HeavyTaxation },
        { cardName: CardName_1.CardName.ICE_MOON_COLONY, Factory: IceMoonColony_1.IceMoonColony },
        { cardName: CardName_1.CardName.IMPACTOR_SWARM, Factory: ImpactorSwarm_1.ImpactorSwarm },
        { cardName: CardName_1.CardName.INTERPLANETARY_COLONY_SHIP, Factory: InterplanetaryColonyShip_1.InterplanetaryColonyShip },
        { cardName: CardName_1.CardName.JOVIAN_LANTERNS, Factory: JovianLanterns_1.JovianLanterns },
        { cardName: CardName_1.CardName.JUPITER_FLOATING_STATION, Factory: JupiterFloatingStation_1.JupiterFloatingStation },
        { cardName: CardName_1.CardName.LUNA_GOVERNOR, Factory: LunaGovernor_1.LunaGovernor },
        { cardName: CardName_1.CardName.LUNAR_EXPORTS, Factory: LunarExports_1.LunarExports },
        { cardName: CardName_1.CardName.LUNAR_MINING, Factory: LunarMining_1.LunarMining },
        { cardName: CardName_1.CardName.MARTIAN_ZOO, Factory: MartianZoo_1.MartianZoo },
        { cardName: CardName_1.CardName.MARKET_MANIPULATION, Factory: MarketManipulation_1.MarketManipulation },
        { cardName: CardName_1.CardName.MINING_COLONY, Factory: MiningColony_1.MiningColony },
        { cardName: CardName_1.CardName.MINORITY_REFUGE, Factory: MinorityRefuge_1.MinorityRefuge },
        { cardName: CardName_1.CardName.MOLECULAR_PRINTING, Factory: MolecularPrinting_1.MolecularPrinting },
        { cardName: CardName_1.CardName.NITROGEN_FROM_TITAN, Factory: NitrogenFromTitan_1.NitrogenFromTitan },
        { cardName: CardName_1.CardName.PIONEER_SETTLEMENT, Factory: PioneerSettlement_1.PioneerSettlement },
        { cardName: CardName_1.CardName.PRODUCTIVE_OUTPOST, Factory: ProductiveOutpost_1.ProductiveOutpost },
        { cardName: CardName_1.CardName.QUANTUM_COMMUNICATIONS, Factory: QuantumCommunications_1.QuantumCommunications },
        { cardName: CardName_1.CardName.RED_SPOT_OBSERVATORY, Factory: RedSpotObservatory_1.RedSpotObservatory },
        { cardName: CardName_1.CardName.RESEARCH_COLONY, Factory: ResearchColony_1.ResearchColony },
        { cardName: CardName_1.CardName.RIM_FREIGHTERS, Factory: RimFreighters_1.RimFreighters },
        { cardName: CardName_1.CardName.REFUGEE_CAMP, Factory: RefugeeCamps_1.RefugeeCamps },
        { cardName: CardName_1.CardName.SOLAR_PROBE, Factory: SolarProbe_1.SolarProbe },
        { cardName: CardName_1.CardName.SOLAR_REFLECTORS, Factory: SolarReflectors_1.SolarReflectors },
        { cardName: CardName_1.CardName.SKY_DOCKS, Factory: SkyDocks_1.SkyDocks },
        { cardName: CardName_1.CardName.SPACE_PORT, Factory: SpacePort_1.SpacePort },
        { cardName: CardName_1.CardName.SPACE_PORT_COLONY, Factory: SpacePortColony_1.SpacePortColony },
        { cardName: CardName_1.CardName.SPINOFF_DEPARTMENT, Factory: SpinoffDepartment_1.SpinoffDepartment },
        { cardName: CardName_1.CardName.SUBZERO_SALT_FISH, Factory: SubZeroSaltFish_1.SubZeroSaltFish },
        { cardName: CardName_1.CardName.TITAN_AIRSCRAPPING, Factory: TitanAirScrapping_1.TitanAirScrapping },
        { cardName: CardName_1.CardName.TITAN_FLOATING_LAUNCHPAD, Factory: TitanFloatingLaunchPad_1.TitanFloatingLaunchPad },
        { cardName: CardName_1.CardName.TITAN_SHUTTLES, Factory: TitanShuttles_1.TitanShuttles },
        { cardName: CardName_1.CardName.TRADING_COLONY, Factory: TradingColony_1.TradingColony },
        { cardName: CardName_1.CardName.TRADE_ENVOYS, Factory: TradeEnvoys_1.TradeEnvoys },
        { cardName: CardName_1.CardName.URBAN_DECOMPOSERS, Factory: UrbanDecomposers_1.UrbanDecomposers },
        { cardName: CardName_1.CardName.WARP_DRIVE, Factory: WarpDrive_1.WarpDrive },
    ],
    standardProjects: [
        { cardName: CardName_1.CardName.BUILD_COLONY_STANDARD_PROJECT, Factory: BuildColonyStandardProject_1.BuildColonyStandardProject },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.ARIDOR, Factory: Aridor_1.Aridor },
        { cardName: CardName_1.CardName.ARKLIGHT, Factory: Arklight_1.Arklight },
        { cardName: CardName_1.CardName.POLYPHEMOS, Factory: Polyphemos_1.Polyphemos },
        { cardName: CardName_1.CardName.POSEIDON, Factory: Poseidon_1.Poseidon },
        { cardName: CardName_1.CardName.STORMCRAFT_INCORPORATED, Factory: StormCraftIncorporated_1.StormCraftIncorporated },
    ]
});
