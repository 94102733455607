"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Bonus_vue_1 = require("@/client/components/Bonus.vue");
var SpaceType_1 = require("@/SpaceType");
var TileType_1 = require("@/TileType");
var tileTypeToCssClass = new Map([
    [TileType_1.TileType.COMMERCIAL_DISTRICT, 'commercial_district'],
    [TileType_1.TileType.ECOLOGICAL_ZONE, 'ecological_zone'],
    [TileType_1.TileType.INDUSTRIAL_CENTER, 'industrial_center'],
    [TileType_1.TileType.LAVA_FLOWS, 'lava_flows'],
    [TileType_1.TileType.MINING_AREA, 'mining_area'],
    [TileType_1.TileType.MINING_RIGHTS, 'mining_rights'],
    [TileType_1.TileType.CAPITAL, 'capital'],
    [TileType_1.TileType.MOHOLE_AREA, 'mohole_area'],
    [TileType_1.TileType.NATURAL_PRESERVE, 'natural_preserve'],
    [TileType_1.TileType.NUCLEAR_ZONE, 'nuclear_zone'],
    [TileType_1.TileType.RESTRICTED_AREA, 'restricted_area'],
    [TileType_1.TileType.DEIMOS_DOWN, 'deimos_down'],
    [TileType_1.TileType.GREAT_DAM, 'great_dam'],
    [TileType_1.TileType.MAGNETIC_FIELD_GENERATORS, 'magnetic_field_generators'],
    [TileType_1.TileType.BIOFERTILIZER_FACILITY, 'biofertilizer-facility'],
    [TileType_1.TileType.METALLIC_ASTEROID, 'metallic-asteroid'],
    [TileType_1.TileType.SOLAR_FARM, 'solar-farm'],
    [TileType_1.TileType.OCEAN_CITY, 'ocean-city'],
    [TileType_1.TileType.OCEAN_FARM, 'ocean-farm'],
    [TileType_1.TileType.OCEAN_SANCTUARY, 'ocean-sanctuary'],
    [TileType_1.TileType.DUST_STORM_MILD, 'dust-storm-mild'],
    [TileType_1.TileType.DUST_STORM_SEVERE, 'dust-storm-severe'],
    [TileType_1.TileType.EROSION_MILD, 'erosion-mild'],
    [TileType_1.TileType.EROSION_SEVERE, 'erosion-severe'],
    [TileType_1.TileType.MINING_STEEL_BONUS, 'mining-steel'],
    [TileType_1.TileType.MINING_TITANIUM_BONUS, 'mining-titanium'],
]);
var tileTypeToCssClassAresOverride = new Map([
    [TileType_1.TileType.COMMERCIAL_DISTRICT, 'commercial-district-ares'],
    [TileType_1.TileType.ECOLOGICAL_ZONE, 'ecological-zone-ares'],
    [TileType_1.TileType.INDUSTRIAL_CENTER, 'industrial-center-ares'],
    [TileType_1.TileType.LAVA_FLOWS, 'lava-flows-ares'],
    [TileType_1.TileType.CAPITAL, 'capital-ares'],
    [TileType_1.TileType.MOHOLE_AREA, 'mohole-area-ares'],
    [TileType_1.TileType.NATURAL_PRESERVE, 'natural-preserve-ares'],
    [TileType_1.TileType.NUCLEAR_ZONE, 'nuclear-zone-ares'],
    [TileType_1.TileType.RESTRICTED_AREA, 'restricted-area-ares'],
]);
exports.default = vue_1.default.extend({
    name: 'board-space',
    props: {
        space: {
            type: Object,
        },
        text: {
            type: String,
        },
        is_selectable: {
            type: Boolean,
        },
        aresExtension: {
            type: Boolean,
        },
        hideTiles: {
            type: Boolean,
        },
    },
    data: function () {
        return {};
    },
    components: {
        'bonus': Bonus_vue_1.default,
    },
    methods: {
        getVerboseTitle: function (tileType) {
            var ret = '';
            if (tileType === TileType_1.TileType.MOHOLE_AREA) {
                ret = 'Mohole Area';
            }
            else if (tileType === TileType_1.TileType.COMMERCIAL_DISTRICT) {
                ret = 'Commercial District: 1 VP per adjacent city tile';
            }
            else if (tileType === TileType_1.TileType.ECOLOGICAL_ZONE) {
                ret = 'Ecological Zone';
            }
            else if (tileType === TileType_1.TileType.INDUSTRIAL_CENTER) {
                ret = 'Industrial Center';
            }
            else if (tileType === TileType_1.TileType.LAVA_FLOWS) {
                ret = 'Lava Flows';
            }
            else if (tileType === TileType_1.TileType.CAPITAL) {
                ret = 'Capital';
            }
            else if (tileType === TileType_1.TileType.MINING_AREA) {
                ret = 'Mining Area';
            }
            else if (tileType === TileType_1.TileType.MINING_RIGHTS) {
                ret = 'Mining Rights';
            }
            else if (tileType === TileType_1.TileType.NATURAL_PRESERVE) {
                ret = 'Natural Preserve';
            }
            else if (tileType === TileType_1.TileType.NUCLEAR_ZONE) {
                ret = 'Nuclear Zone';
            }
            else if (tileType === TileType_1.TileType.RESTRICTED_AREA) {
                ret = 'Restricted Area';
            }
            else if (tileType === TileType_1.TileType.GREAT_DAM) {
                ret = 'Great Dam';
            }
            else if (tileType === TileType_1.TileType.MAGNETIC_FIELD_GENERATORS) {
                ret = 'Magnetic field generators';
            }
            else if (tileType === TileType_1.TileType.DEIMOS_DOWN) {
                ret = 'Deimos Down';
            }
            else if (tileType === TileType_1.TileType.CITY) {
                ret = 'City: 1 VP per adjacent greenery';
            }
            else if (tileType === TileType_1.TileType.GREENERY) {
                ret = 'Greenery: 1 VP';
            }
            else if (tileType === TileType_1.TileType.BIOFERTILIZER_FACILITY) {
                ret = 'Biofertilizer Facility';
            }
            else if (tileType === TileType_1.TileType.METALLIC_ASTEROID) {
                ret = 'Metallic Asteroid';
            }
            else if (tileType === TileType_1.TileType.SOLAR_FARM) {
                ret = 'Solar Farm';
            }
            else if (tileType === TileType_1.TileType.OCEAN_CITY) {
                ret = 'Ocean City';
            }
            else if (tileType === TileType_1.TileType.OCEAN_FARM) {
                ret = 'Ocean Farm';
            }
            else if (tileType === TileType_1.TileType.OCEAN_SANCTUARY) {
                ret = 'Ocean Sanctuary';
            }
            else if (tileType === TileType_1.TileType.DUST_STORM_MILD) {
                ret = 'Mild Dust Storm';
            }
            else if (tileType === TileType_1.TileType.DUST_STORM_SEVERE) {
                ret = 'Severe Dust Storm';
            }
            else if (tileType === TileType_1.TileType.EROSION_MILD) {
                ret = 'Mild Erosion';
            }
            else if (tileType === TileType_1.TileType.EROSION_SEVERE) {
                ret = 'Severe Erosion';
            }
            else if (tileType === TileType_1.TileType.MINING_STEEL_BONUS) {
                ret = 'Mining: steel bonus';
            }
            else if (tileType === TileType_1.TileType.MINING_TITANIUM_BONUS) {
                ret = 'Mining: titanium bonus';
            }
            return this.$t(ret);
        },
        getMainClass: function () {
            var css = 'board-space board-space-' + this.space.id.toString();
            if (this.is_selectable) {
                css += ' board-space-selectable';
            }
            return css;
        },
        getTileClass: function () {
            var css = 'board-space';
            var tileType = this.space.tileType;
            if (tileType !== undefined) {
                switch (this.space.tileType) {
                    case TileType_1.TileType.OCEAN:
                        css += ' board-space-tile--ocean';
                        break;
                    case TileType_1.TileType.CITY:
                        css += ' board-space-tile--city';
                        break;
                    case TileType_1.TileType.GREENERY:
                        css += ' board-space-tile--greenery';
                        break;
                    default:
                        var cssClass = tileTypeToCssClass.get(tileType);
                        if (this.aresExtension && tileTypeToCssClassAresOverride.has(tileType)) {
                            cssClass = tileTypeToCssClassAresOverride.get(tileType);
                        }
                        css += ' board-space-tile--' + cssClass;
                }
            }
            else {
                if (this.space.spaceType === SpaceType_1.SpaceType.OCEAN) {
                    css += ' board-space-type-ocean';
                }
                else if (this.space.spaceType === SpaceType_1.SpaceType.COVE) {
                    css += ' board-space-type-cove';
                }
                else {
                    css += " board-space-type-land";
                    var highlight = this.space.highlight;
                    if (highlight) {
                        css += " board-space-type-land-" + highlight;
                    }
                }
            }
            if (this.hideTiles) {
                css += ' board-hidden-tile';
            }
            return css;
        },
    },
});
