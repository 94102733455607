"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcologicalSurvey = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var SpaceBonus_1 = require("../../SpaceBonus");
var Resources_1 = require("../../Resources");
var ResourceType_1 = require("../../ResourceType");
var Tags_1 = require("../Tags");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var SurveyCard_1 = require("./SurveyCard");
var Options_1 = require("../Options");
var EcologicalSurvey = (function (_super) {
    __extends(EcologicalSurvey, _super);
    function EcologicalSurvey() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.ECOLOGICAL_SURVEY,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 9,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.greeneries(3, { all: Options_1.all }); }),
            metadata: {
                description: 'Requires 3 greeneries on Mars.',
                cardNumber: 'A07',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When placing a tile grants you any plants, animals or microbes, you gain one additional of each of those resources that you gain.', function (eb) {
                        eb.emptyTile().startEffect;
                        eb.plus().plants(1).animals(1).microbes(1);
                    });
                }),
            },
        }) || this;
    }
    EcologicalSurvey.prototype.checkForBonuses = function (cardOwner, space) {
        _super.prototype.testForStandardResource.call(this, cardOwner, space, Resources_1.Resources.PLANTS, SpaceBonus_1.SpaceBonus.PLANT);
        _super.prototype.testForCardResource.call(this, cardOwner, space, ResourceType_1.ResourceType.MICROBE, SpaceBonus_1.SpaceBonus.MICROBE);
        _super.prototype.testForCardResource.call(this, cardOwner, space, ResourceType_1.ResourceType.ANIMAL, SpaceBonus_1.SpaceBonus.ANIMAL);
    };
    return EcologicalSurvey;
}(SurveyCard_1.SurveyCard));
exports.EcologicalSurvey = EcologicalSurvey;
