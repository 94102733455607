"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolarFarm = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var Resources_1 = require("../../Resources");
var SpaceBonus_1 = require("../../SpaceBonus");
var SpaceType_1 = require("../../SpaceType");
var TileType_1 = require("../../TileType");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var SolarFarm = (function (_super) {
    __extends(SolarFarm, _super);
    function SolarFarm() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.SOLAR_FARM,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.BUILDING],
            cost: 12,
            metadata: {
                cardNumber: 'A17',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.energy(1).slash().plants(1);
                    }).asterix().nbsp.tile(TileType_1.TileType.SOLAR_FARM, false, true).br;
                }),
                description: 'Place this tile which grants an ADJACENCY BONUS of 2 energy. Increase your power production 1 step for each plant resource on the area where you place the tile.',
            },
        }) || this;
    }
    SolarFarm.prototype.canPlay = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player).length > 0;
    };
    SolarFarm.prototype.produce = function (player) {
        var space = player.game.board.getSpaceByTileCard(this.name);
        if (space === undefined) {
            throw new Error('solar Farm space not found');
        }
        var plantsOnSpace = space.bonus.filter(function (b) { return b === SpaceBonus_1.SpaceBonus.PLANT; }).length;
        player.addProduction(Resources_1.Resources.ENERGY, plantsOnSpace, { log: true });
    };
    SolarFarm.prototype.play = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace('Select space for Solar Farm tile', player.game.board.getAvailableSpacesOnLand(player), function (space) {
            player.game.addTile(player, SpaceType_1.SpaceType.LAND, space, {
                tileType: TileType_1.TileType.SOLAR_FARM,
                card: _this.name,
            });
            _this.produce(player);
            space.adjacency = { bonus: [SpaceBonus_1.SpaceBonus.POWER, SpaceBonus_1.SpaceBonus.POWER] };
            return undefined;
        });
    };
    return SolarFarm;
}(Card_1.Card));
exports.SolarFarm = SolarFarm;
