"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceGreeneryTile = void 0;
var SelectSpace_1 = require("../inputs/SelectSpace");
var DeferredAction_1 = require("./DeferredAction");
var PlaceGreeneryTile = (function () {
    function PlaceGreeneryTile(player, title) {
        if (title === void 0) { title = 'Select space for greenery tile'; }
        this.player = player;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    PlaceGreeneryTile.prototype.execute = function () {
        var _this = this;
        var availableSpaces = this.player.game.board.getAvailableSpacesForGreenery(this.player);
        if (availableSpaces.length === 0) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, availableSpaces, function (space) {
            _this.player.game.addGreenery(_this.player, space.id);
            return undefined;
        });
    };
    return PlaceGreeneryTile;
}());
exports.PlaceGreeneryTile = PlaceGreeneryTile;
