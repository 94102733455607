"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colony = exports.ShouldIncreaseTrack = void 0;
var AddResourcesToCard_1 = require("../deferredActions/AddResourcesToCard");
var CardName_1 = require("../CardName");
var ColonyBenefit_1 = require("./ColonyBenefit");
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var DiscardCards_1 = require("../deferredActions/DiscardCards");
var DrawCards_1 = require("../deferredActions/DrawCards");
var GiveColonyBonus_1 = require("../deferredActions/GiveColonyBonus");
var IncreaseColonyTrack_1 = require("../deferredActions/IncreaseColonyTrack");
var LogHelper_1 = require("../LogHelper");
var constants_1 = require("../constants");
var PlaceOceanTile_1 = require("../deferredActions/PlaceOceanTile");
var Resources_1 = require("../Resources");
var ScienceTagCard_1 = require("../cards/community/ScienceTagCard");
var SelectColony_1 = require("../inputs/SelectColony");
var SelectPlayer_1 = require("../inputs/SelectPlayer");
var StealResources_1 = require("../deferredActions/StealResources");
var Tags_1 = require("../cards/Tags");
var SendDelegateToArea_1 = require("../deferredActions/SendDelegateToArea");
var Turmoil_1 = require("../turmoil/Turmoil");
var ShouldIncreaseTrack;
(function (ShouldIncreaseTrack) {
    ShouldIncreaseTrack[ShouldIncreaseTrack["YES"] = 0] = "YES";
    ShouldIncreaseTrack[ShouldIncreaseTrack["NO"] = 1] = "NO";
    ShouldIncreaseTrack[ShouldIncreaseTrack["ASK"] = 2] = "ASK";
})(ShouldIncreaseTrack = exports.ShouldIncreaseTrack || (exports.ShouldIncreaseTrack = {}));
var Colony = (function () {
    function Colony() {
        this.isActive = true;
        this.visitor = undefined;
        this.colonies = [];
        this.trackPosition = 1;
        this.buildQuantity = [1, 1, 1];
        this.tradeQuantity = [1, 1, 1, 1, 1, 1, 1];
        this.colonyBonusQuantity = 1;
        this.shouldIncreaseTrack = ShouldIncreaseTrack.YES;
    }
    Colony.prototype.endGeneration = function (game) {
        if (this.isActive) {
            this.increaseTrack();
        }
        if (game.syndicatePirateRaider) {
            if (game.syndicatePirateRaider === this.visitor) {
                this.visitor = undefined;
            }
        }
        else {
            this.visitor = undefined;
        }
    };
    Colony.prototype.increaseTrack = function (value) {
        if (value === void 0) { value = 1; }
        this.trackPosition = Math.min(this.trackPosition + value, constants_1.MAX_COLONY_TRACK_POSITION);
    };
    Colony.prototype.decreaseTrack = function (value) {
        if (value === void 0) { value = 1; }
        this.trackPosition = Math.max(this.trackPosition - value, this.colonies.length);
    };
    Colony.prototype.isColonyFull = function () {
        return this.colonies.length >= 3;
    };
    Colony.prototype.addColony = function (player) {
        var _this = this;
        player.game.log('${0} built a colony on ${1}', function (b) { return b.player(player).colony(_this); });
        this.giveBonus(player, this.buildType, this.buildQuantity[this.colonies.length], this.buildResource);
        this.colonies.push(player.id);
        if (this.trackPosition < this.colonies.length) {
            this.trackPosition = this.colonies.length;
        }
        var poseidon = player.game.getPlayers().find(function (player) { return player.isCorporation(CardName_1.CardName.POSEIDON); });
        if (poseidon !== undefined) {
            poseidon.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        }
    };
    Colony.prototype.trade = function (player, bonusTradeOffset, usesTradeFleet, decreaseTrackAfterTrade) {
        var _this = this;
        if (bonusTradeOffset === void 0) { bonusTradeOffset = 0; }
        if (usesTradeFleet === void 0) { usesTradeFleet = true; }
        if (decreaseTrackAfterTrade === void 0) { decreaseTrackAfterTrade = true; }
        var tradeOffset = player.colonyTradeOffset + bonusTradeOffset;
        var maxTrackPosition = Math.min(this.trackPosition + tradeOffset, constants_1.MAX_COLONY_TRACK_POSITION);
        var steps = maxTrackPosition - this.trackPosition;
        if (steps === 0 || this.shouldIncreaseTrack === ShouldIncreaseTrack.NO) {
            this.handleTrade(player, usesTradeFleet, decreaseTrackAfterTrade);
            return;
        }
        if (this.shouldIncreaseTrack === ShouldIncreaseTrack.YES || (this.tradeResource !== undefined && this.tradeResource[this.trackPosition] === this.tradeResource[maxTrackPosition])) {
            this.increaseTrack(steps);
            LogHelper_1.LogHelper.logColonyTrackIncrease(player, this, steps);
            this.handleTrade(player, usesTradeFleet, decreaseTrackAfterTrade);
            return;
        }
        player.game.defer(new IncreaseColonyTrack_1.IncreaseColonyTrack(player, this, steps, function () { return _this.handleTrade(player, usesTradeFleet, decreaseTrackAfterTrade); }));
    };
    Colony.prototype.handleTrade = function (player, usesTradeFleet, decreaseTrackAfterTrade, giveColonyBonuses) {
        var _this = this;
        if (giveColonyBonuses === void 0) { giveColonyBonuses = true; }
        var resource = Array.isArray(this.tradeResource) ? this.tradeResource[this.trackPosition] : this.tradeResource;
        this.giveBonus(player, this.tradeType, this.tradeQuantity[this.trackPosition], resource);
        if (giveColonyBonuses) {
            player.game.defer(new GiveColonyBonus_1.GiveColonyBonus(player, this));
        }
        if (usesTradeFleet) {
            this.visitor = player.id;
            player.tradesThisGeneration++;
        }
        if (decreaseTrackAfterTrade) {
            player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
                _this.trackPosition = _this.colonies.length;
                return undefined;
            }), DeferredAction_1.Priority.DECREASE_COLONY_TRACK_AFTER_TRADE);
        }
    };
    Colony.prototype.giveColonyBonus = function (player, isGiveColonyBonus) {
        if (isGiveColonyBonus === void 0) { isGiveColonyBonus = false; }
        return this.giveBonus(player, this.colonyBonusType, this.colonyBonusQuantity, this.colonyBonusResource, isGiveColonyBonus);
    };
    Colony.prototype.giveBonus = function (player, bonusType, quantity, resource, isGiveColonyBonus) {
        var _this = this;
        if (isGiveColonyBonus === void 0) { isGiveColonyBonus = false; }
        var game = player.game;
        var action = undefined;
        switch (bonusType) {
            case ColonyBenefit_1.ColonyBenefit.ADD_RESOURCES_TO_CARD:
                var resourceType = this.resourceType;
                action = new AddResourcesToCard_1.AddResourcesToCard(player, resourceType, { count: quantity });
                break;
            case ColonyBenefit_1.ColonyBenefit.ADD_RESOURCES_TO_VENUS_CARD:
                action = new AddResourcesToCard_1.AddResourcesToCard(player, undefined, { count: quantity, restrictedTag: Tags_1.Tags.VENUS, title: 'Select Venus card to add ' + quantity + ' resource(s)' });
                break;
            case ColonyBenefit_1.ColonyBenefit.COPY_TRADE:
                var openColonies_1 = game.colonies.filter(function (colony) { return colony.isActive; });
                var coloniesModel_1 = game.getColoniesModel(openColonies_1);
                action = new DeferredAction_1.DeferredAction(player, function () { return new SelectColony_1.SelectColony('Select colony to gain trade income from', 'Select', coloniesModel_1, function (colonyName) {
                    openColonies_1.forEach(function (colony) {
                        if (colony.name === colonyName) {
                            game.log('${0} gained ${1} trade bonus', function (b) { return b.player(player).colony(colony); });
                            colony.handleTrade(player, false, false, false);
                        }
                        return undefined;
                    });
                    return undefined;
                }); });
                break;
            case ColonyBenefit_1.ColonyBenefit.DRAW_CARDS:
                action = DrawCards_1.DrawCards.keepAll(player, quantity);
                break;
            case ColonyBenefit_1.ColonyBenefit.DRAW_CARDS_AND_BUY_ONE:
                action = DrawCards_1.DrawCards.keepSome(player, 1, { paying: true, logDrawnCard: true });
                break;
            case ColonyBenefit_1.ColonyBenefit.DRAW_CARDS_AND_DISCARD_ONE:
                player.drawCard();
                action = new DiscardCards_1.DiscardCards(player, 1, this.name + ' colony bonus. Select a card to discard');
                break;
            case ColonyBenefit_1.ColonyBenefit.DRAW_CARDS_AND_KEEP_ONE:
                action = DrawCards_1.DrawCards.keepSome(player, quantity, { keepMax: 1 });
                break;
            case ColonyBenefit_1.ColonyBenefit.GAIN_CARD_DISCOUNT:
                player.cardDiscount += 1;
                game.log('Cards played by ${0} cost 1 M€ less this generation', function (b) { return b.player(player); });
                break;
            case ColonyBenefit_1.ColonyBenefit.GAIN_PRODUCTION:
                if (resource === undefined)
                    throw new Error('Resource cannot be undefined');
                player.addProduction(resource, quantity, { log: true });
                break;
            case ColonyBenefit_1.ColonyBenefit.GAIN_RESOURCES:
                if (resource === undefined)
                    throw new Error('Resource cannot be undefined');
                player.addResource(resource, quantity, { log: true });
                break;
            case ColonyBenefit_1.ColonyBenefit.GAIN_SCIENCE_TAG:
                player.scienceTagCount += 1;
                player.playCard(new ScienceTagCard_1.ScienceTagCard(), undefined, false);
                game.log('${0} gained 1 Science tag', function (b) { return b.player(player); });
                break;
            case ColonyBenefit_1.ColonyBenefit.GAIN_INFLUENCE:
                Turmoil_1.Turmoil.ifTurmoil(game, function (turmoil) {
                    turmoil.addInfluenceBonus(player);
                    game.log('${0} gained 1 influence', function (b) { return b.player(player); });
                });
                break;
            case ColonyBenefit_1.ColonyBenefit.PLACE_DELEGATES:
                Turmoil_1.Turmoil.ifTurmoil(game, function (turmoil) {
                    var playerHasLobbyDelegate = turmoil.lobby.has(player.id);
                    var availablePlayerDelegates = turmoil.getDelegatesInReserve(player.id);
                    if (playerHasLobbyDelegate)
                        availablePlayerDelegates += 1;
                    var qty = Math.min(quantity, availablePlayerDelegates);
                    for (var i = 0; i < qty; i++) {
                        var fromLobby = (i === qty - 1 && qty === availablePlayerDelegates && playerHasLobbyDelegate);
                        game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, 'Select where to send delegate', { source: fromLobby ? 'lobby' : 'reserve' }));
                    }
                });
                break;
            case ColonyBenefit_1.ColonyBenefit.GIVE_MC_PER_DELEGATE:
                Turmoil_1.Turmoil.ifTurmoil(game, function (turmoil) {
                    var partyDelegateCount = constants_1.PLAYER_DELEGATES_COUNT - turmoil.getDelegatesInReserve(player.id);
                    if (turmoil.lobby.has(player.id))
                        partyDelegateCount--;
                    if (turmoil.chairman === player.id)
                        partyDelegateCount--;
                    player.addResource(Resources_1.Resources.MEGACREDITS, partyDelegateCount, { log: true });
                });
                break;
            case ColonyBenefit_1.ColonyBenefit.GAIN_TR:
                if (quantity > 0) {
                    player.increaseTerraformRatingSteps(quantity, { log: true });
                }
                ;
                break;
            case ColonyBenefit_1.ColonyBenefit.GAIN_VP:
                if (quantity > 0) {
                    player.colonyVictoryPoints += quantity;
                    game.log('${0} gained ${1} VP', function (b) { return b.player(player).number(quantity); });
                }
                break;
            case ColonyBenefit_1.ColonyBenefit.INCREASE_VENUS_SCALE:
                game.increaseVenusScaleLevel(player, quantity);
                game.log('${0} increased Venus scale ${1} step(s)', function (b) { return b.player(player).number(quantity); });
                break;
            case ColonyBenefit_1.ColonyBenefit.LOSE_RESOURCES:
                if (resource === undefined)
                    throw new Error('Resource cannot be undefined');
                player.deductResource(resource, quantity);
                break;
            case ColonyBenefit_1.ColonyBenefit.OPPONENT_DISCARD:
                if (game.isSoloMode())
                    break;
                action = new DeferredAction_1.DeferredAction(player, function () {
                    var playersWithCards = game.getPlayers().filter(function (p) { return p.cardsInHand.length > 0; });
                    if (playersWithCards.length === 0)
                        return undefined;
                    return new SelectPlayer_1.SelectPlayer(playersWithCards, 'Select player to discard a card', 'Select', function (selectedPlayer) {
                        game.defer(new DiscardCards_1.DiscardCards(selectedPlayer, 1, _this.name + ' colony effect. Select a card to discard'));
                        return undefined;
                    });
                });
                break;
            case ColonyBenefit_1.ColonyBenefit.PLACE_OCEAN_TILE:
                action = new PlaceOceanTile_1.PlaceOceanTile(player, 'Select ocean space for ' + this.name + ' colony');
                break;
            case ColonyBenefit_1.ColonyBenefit.STEAL_RESOURCES:
                if (resource === undefined)
                    throw new Error('Resource cannot be undefined');
                action = new StealResources_1.StealResources(player, resource, quantity);
                break;
            default:
                throw new Error('Unsupported benefit type');
        }
        if (action !== undefined) {
            if (isGiveColonyBonus) {
                return action.execute();
            }
            else {
                game.defer(action);
                return undefined;
            }
        }
        else {
            return undefined;
        }
    };
    return Colony;
}());
exports.Colony = Colony;
