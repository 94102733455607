"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinersOnStrike = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var CardRenderer_1 = require("../../cards/render/CardRenderer");
var Size_1 = require("../../cards/render/Size");
var Options_1 = require("../../cards/Options");
var RENDER_DATA = CardRenderer_1.CardRenderer.builder(function (b) {
    b.minus().titanium(1).slash().jovian({ played: Options_1.played }).influence({ size: Size_1.Size.SMALL });
});
var MinersOnStrike = (function () {
    function MinersOnStrike() {
        this.name = GlobalEventName_1.GlobalEventName.MINERS_ON_STRIKE;
        this.description = 'Lose 1 titanium for each Jovian tag (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.MARS;
        this.currentDelegate = PartyName_1.PartyName.GREENS;
        this.renderData = RENDER_DATA;
    }
    MinersOnStrike.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.min(5, player.getTagCount(Tags_1.Tags.JOVIAN, false, false)) - turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.deductResource(Resources_1.Resources.TITANIUM, amount, { log: true, from: _this.name });
            }
        });
    };
    return MinersOnStrike;
}());
exports.MinersOnStrike = MinersOnStrike;
