"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductiveOutpost = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var ProductiveOutpost = (function (_super) {
    __extends(ProductiveOutpost, _super);
    function ProductiveOutpost() {
        return _super.call(this, {
            cost: 0,
            name: CardName_1.CardName.PRODUCTIVE_OUTPOST,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                cardNumber: 'C30',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('Gain all your colony bonuses.', Size_1.Size.SMALL, true);
                }),
            },
        }) || this;
    }
    ProductiveOutpost.prototype.play = function (player) {
        player.game.colonies.forEach(function (colony) {
            colony.colonies.filter(function (owner) { return owner === player.id; }).forEach(function (owner) {
                player.game.defer(new DeferredAction_1.DeferredAction(player, function () { return colony.giveColonyBonus(player.game.getPlayerById(owner)); }));
            });
        });
        return undefined;
    };
    return ProductiveOutpost;
}(Card_1.Card));
exports.ProductiveOutpost = ProductiveOutpost;
