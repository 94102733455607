"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AerobrakedAmmoniaAsteroid = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var Resources_1 = require("../../Resources");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var AerobrakedAmmoniaAsteroid = (function (_super) {
    __extends(AerobrakedAmmoniaAsteroid, _super);
    function AerobrakedAmmoniaAsteroid() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.AEROBRAKED_AMMONIA_ASTEROID,
            tags: [Tags_1.Tags.SPACE],
            cost: 26,
            metadata: {
                description: 'Increase your heat production 3 steps and your plant production 1 step. Add 2 Microbes to ANOTHER card.',
                cardNumber: '170',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.heat(3).br;
                        pb.plants(1);
                    }).br;
                    b.microbes(2).asterix();
                }),
            },
        }) || this;
    }
    AerobrakedAmmoniaAsteroid.prototype.play = function (player) {
        var cardsToPick = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        player.addProduction(Resources_1.Resources.HEAT, 3);
        player.addProduction(Resources_1.Resources.PLANTS, 1);
        if (cardsToPick.length < 1)
            return undefined;
        if (cardsToPick.length === 1) {
            player.addResourceTo(cardsToPick[0], { qty: 2, log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 2 microbes', 'Add microbes', cardsToPick, function (foundCards) {
            player.addResourceTo(foundCards[0], { qty: 2, log: true });
            return undefined;
        });
    };
    return AerobrakedAmmoniaAsteroid;
}(Card_1.Card));
exports.AerobrakedAmmoniaAsteroid = AerobrakedAmmoniaAsteroid;
