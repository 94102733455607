"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoctisCity = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var NoctisCity = (function (_super) {
    __extends(NoctisCity, _super);
    function NoctisCity() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.NOCTIS_CITY,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 18,
            productionBox: Units_1.Units.of({ energy: -1, megacredits: 3 }),
            metadata: {
                cardNumber: '017',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).br;
                        pb.plus().megacredits(3);
                    }).nbsp.city().asterix();
                }),
                description: 'Decrease your Energy production 1 step and increase your M€ production 3 steps. Place a City tile ON THE RESERVED AREA, disregarding normal placement restrictions.',
            },
        }) || this;
    }
    NoctisCity.prototype.canPlay = function (player) {
        if (player.getProduction(Resources_1.Resources.ENERGY) < 1) {
            return false;
        }
        if (player.game.board.getNoctisCitySpaceIds().length > 0) {
            return true;
        }
        else {
            return player.game.board.getAvailableSpacesForCity(player).length > 0;
            ;
        }
    };
    NoctisCity.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 3);
        var noctisCitySpaceIds = player.game.board.getNoctisCitySpaceIds();
        if (noctisCitySpaceIds.length !== 0) {
            player.game.addCityTile(player, noctisCitySpaceIds[0]);
            return undefined;
        }
        return new SelectSpace_1.SelectSpace('Select space for Noctis city', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addCityTile(player, space.id);
            return undefined;
        });
    };
    return NoctisCity;
}(Card_1.Card));
exports.NoctisCity = NoctisCity;
