"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HE3Lobbyists = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var HE3Lobbyists = (function (_super) {
    __extends(HE3Lobbyists, _super);
    function HE3Lobbyists() {
        return _super.call(this, {
            name: CardName_1.CardName.HE3_LOBBYISTS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON],
            cost: 7,
            metadata: {
                description: 'Increase your M€ production 1 step for each moon tag you have (including this).',
                cardNumber: 'M50',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).slash().moon();
                }),
            },
        }) || this;
    }
    ;
    HE3Lobbyists.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, player.getTagCount(Tags_1.Tags.MOON) + 1, { log: true });
        return undefined;
    };
    return HE3Lobbyists;
}(Card_1.Card));
exports.HE3Lobbyists = HE3Lobbyists;
