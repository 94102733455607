"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayProjectCard = void 0;
var DeferredAction_1 = require("./DeferredAction");
var PlayProjectCard = (function () {
    function PlayProjectCard(player) {
        this.player = player;
        this.priority = DeferredAction_1.Priority.DEFAULT;
    }
    PlayProjectCard.prototype.execute = function () {
        if (this.player.getPlayableCards().length === 0) {
            return undefined;
        }
        return this.player.playProjectCard();
    };
    return PlayProjectCard;
}());
exports.PlayProjectCard = PlayProjectCard;
