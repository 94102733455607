"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaSenate = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Resources_1 = require("../../Resources");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Card_1 = require("../Card");
var Options_1 = require("../Options");
var LunaSenate = (function (_super) {
    __extends(LunaSenate, _super);
    function LunaSenate() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNA_SENATE,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.MOON],
            cost: 32,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.MOON, 3); }),
            metadata: {
                description: 'Requires that you have 3 Moon tags. Increase your M€ production 1 step per Moon tag in the game (including these.)',
                cardNumber: 'M70',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).slash().moon(1, { all: Options_1.all });
                    b.vpText('1 VP per Moon tag you have.');
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.moon(1, 1),
            },
        }) || this;
    }
    ;
    LunaSenate.prototype.play = function (player) {
        var count = player.game.getPlayers().map(function (p) { return p.getTagCount(Tags_1.Tags.MOON); }).reduce(function (p, v) { return p + v; }, 0);
        player.addProduction(Resources_1.Resources.MEGACREDITS, count + 2, { log: true });
        return undefined;
    };
    LunaSenate.prototype.getVictoryPoints = function (player) {
        return player.getTagCount(Tags_1.Tags.MOON, true, false);
    };
    return LunaSenate;
}(Card_1.Card));
exports.LunaSenate = LunaSenate;
