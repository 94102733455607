"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NaturalPreserveAres = void 0;
var SpaceBonus_1 = require("../../SpaceBonus");
var TileType_1 = require("../../TileType");
var CardName_1 = require("../../CardName");
var NaturalPreserve_1 = require("../base/NaturalPreserve");
var CardRenderer_1 = require("../render/CardRenderer");
var NaturalPreserveAres = (function (_super) {
    __extends(NaturalPreserveAres, _super);
    function NaturalPreserveAres() {
        return _super.call(this, CardName_1.CardName.NATURAL_PRESERVE_ARES, { bonus: [SpaceBonus_1.SpaceBonus.MEGACREDITS] }, {
            cardNumber: 'A18',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) { return pb.megacredits(1); }).nbsp.tile(TileType_1.TileType.NATURAL_PRESERVE, false, true).asterix();
            }),
            description: 'Oxygen must be 4% or less. Place this tile NEXT TO NO OTHER TILE. The tile grants an ADJACENCY BONUS of of 1 M€. Increase your M€ production 1 step.',
            victoryPoints: 1,
        }) || this;
    }
    return NaturalPreserveAres;
}(NaturalPreserve_1.NaturalPreserve));
exports.NaturalPreserveAres = NaturalPreserveAres;
