"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceMoonMineTile = void 0;
var DeferredAction_1 = require("../deferredActions/DeferredAction");
var SelectSpace_1 = require("../inputs/SelectSpace");
var MoonExpansion_1 = require("./MoonExpansion");
var PlaceMoonMineTile = (function () {
    function PlaceMoonMineTile(player, title) {
        if (title === void 0) { title = 'Select a space on the Moon for a mining tile.'; }
        this.player = player;
        this.title = title;
        this.priority = DeferredAction_1.Priority.DEFAULT;
        this.cb = function () { };
    }
    PlaceMoonMineTile.prototype.andThen = function (cb) {
        this.cb = cb;
        return this;
    };
    ;
    PlaceMoonMineTile.prototype.execute = function () {
        var _this = this;
        var moonData = MoonExpansion_1.MoonExpansion.moonData(this.player.game);
        var spaces = moonData.moon.getAvailableSpacesForMine(this.player);
        if (spaces.length === 0) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace(this.title, spaces, function (space) {
            MoonExpansion_1.MoonExpansion.addMineTile(_this.player, space.id);
            MoonExpansion_1.MoonExpansion.raiseMiningRate(_this.player);
            _this.cb(space);
            return undefined;
        });
    };
    return PlaceMoonMineTile;
}());
exports.PlaceMoonMineTile = PlaceMoonMineTile;
