"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonExpansion = void 0;
var MoonBoard_1 = require("./MoonBoard");
var TileType_1 = require("../TileType");
var SpaceType_1 = require("../SpaceType");
var CardName_1 = require("../CardName");
var Units_1 = require("../Units");
var Tags_1 = require("../cards/Tags");
var constants_1 = require("../constants");
var Resources_1 = require("../Resources");
var Phase_1 = require("../Phase");
var BoardType_1 = require("../boards/BoardType");
var MoonExpansion = (function () {
    function MoonExpansion() {
    }
    MoonExpansion.ifMoon = function (game, cb) {
        if (game.gameOptions.moonExpansion) {
            if (game.moonData === undefined) {
                console.log("Assertion failure: game.moonData is undefined for " + game.id);
            }
            else {
                return cb(game.moonData);
            }
        }
        return undefined;
    };
    MoonExpansion.ifElseMoon = function (game, cb, elseCb) {
        if (game.gameOptions.moonExpansion) {
            if (game.moonData === undefined) {
                console.log("Assertion failure: game.moonData is undefined for " + game.id);
            }
            else {
                return cb(game.moonData);
            }
        }
        return elseCb();
    };
    MoonExpansion.moonData = function (game) {
        if (game.gameOptions.moonExpansion === true && game.moonData !== undefined) {
            return game.moonData;
        }
        throw new Error('Assertion error: Using a Moon feature when the Moon expansion is undefined.');
    };
    MoonExpansion.initialize = function () {
        return {
            moon: MoonBoard_1.MoonBoard.newInstance(),
            colonyRate: 0,
            miningRate: 0,
            logisticRate: 0,
            lunaFirstPlayer: undefined,
            lunaProjectOfficeLastGeneration: undefined,
        };
    };
    MoonExpansion.addMineTile = function (player, spaceId, cardName) {
        if (cardName === void 0) { cardName = undefined; }
        MoonExpansion.addTile(player, spaceId, { tileType: TileType_1.TileType.MOON_MINE, card: cardName });
    };
    MoonExpansion.addColonyTile = function (player, spaceId, cardName) {
        if (cardName === void 0) { cardName = undefined; }
        MoonExpansion.addTile(player, spaceId, { tileType: TileType_1.TileType.MOON_COLONY, card: cardName });
    };
    MoonExpansion.addRoadTile = function (player, spaceId, cardName) {
        if (cardName === void 0) { cardName = undefined; }
        MoonExpansion.addTile(player, spaceId, { tileType: TileType_1.TileType.MOON_ROAD, card: cardName });
    };
    MoonExpansion.addTile = function (player, spaceId, tile) {
        var _this = this;
        var game = player.game;
        MoonExpansion.ifMoon(game, function (moonData) {
            var space = moonData.moon.getSpace(spaceId);
            if (!_this.MOON_TILES.has(tile.tileType)) {
                throw new Error("Bad tile type for the moon: " + tile.tileType);
            }
            if (space.tile !== undefined) {
                throw new Error('Selected space is occupied');
            }
            if (space.player !== undefined && space.player !== player) {
                throw new Error('This space is land claimed by ' + space.player.name);
            }
            space.tile = tile;
            if (player.game.phase !== Phase_1.Phase.SOLAR) {
                space.player = player;
            }
            if (game.phase !== Phase_1.Phase.SOLAR) {
                space.bonus.forEach(function (spaceBonus) {
                    game.grantSpaceBonus(player, spaceBonus);
                });
            }
            MoonExpansion.logTilePlacement(player, space, tile.tileType);
            if (player.corporationCard !== undefined && player.corporationCard.onTilePlaced !== undefined) {
                player.corporationCard.onTilePlaced(player, player, space, BoardType_1.BoardType.MOON);
            }
        });
    };
    MoonExpansion.logTilePlacement = function (player, space, tileType) {
        if (space.x !== -1 && space.y !== -1) {
            var offsets = [-1, 0, 1, 1, 1, 0, -1];
            var row_1 = space.y + 1;
            var position_1 = space.x + offsets[space.y];
            player.game.log('${0} placed a ${1} tile on the Moon at (${2}, ${3})', function (b) {
                return b.player(player).string(TileType_1.TileType.toString(tileType)).number(row_1).number(position_1);
            });
        }
    };
    MoonExpansion.bonus = function (originalRate, increment, value, cb) {
        if (originalRate < value && originalRate + increment >= value) {
            cb();
        }
    };
    MoonExpansion.raiseMiningRate = function (player, count) {
        var _this = this;
        if (count === void 0) { count = 1; }
        MoonExpansion.ifMoon(player.game, function (moonData) {
            var available = constants_1.MAXIMUM_MINING_RATE - moonData.miningRate;
            var increment = Math.min(count, available);
            if (increment > 0) {
                if (player.game.phase === Phase_1.Phase.SOLAR) {
                    player.game.log('${0} acted as World Government and raised the mining rate ${1} step(s)', function (b) { return b.player(player).number(increment); });
                    _this.activateLunaFirst(undefined, player.game, increment);
                }
                else {
                    player.game.log('${0} raised the mining rate ${1} step(s)', function (b) { return b.player(player).number(increment); });
                    player.increaseTerraformRatingSteps(increment);
                    _this.bonus(moonData.miningRate, increment, 3, function () {
                        player.drawCard();
                    });
                    _this.bonus(moonData.miningRate, increment, 6, function () {
                        player.addProduction(Resources_1.Resources.TITANIUM, 1, { log: true });
                    });
                    _this.activateLunaFirst(player, player.game, increment);
                }
                moonData.miningRate += increment;
            }
        });
    };
    MoonExpansion.raiseColonyRate = function (player, count) {
        var _this = this;
        if (count === void 0) { count = 1; }
        MoonExpansion.ifMoon(player.game, function (moonData) {
            var available = constants_1.MAXIMUM_COLONY_RATE - moonData.colonyRate;
            var increment = Math.min(count, available);
            if (increment > 0) {
                if (player.game.phase === Phase_1.Phase.SOLAR) {
                    player.game.log('${0} acted as World Government and raised the colony rate ${1} step(s)', function (b) { return b.player(player).number(increment); });
                    _this.activateLunaFirst(undefined, player.game, count);
                }
                else {
                    player.game.log('${0} raised the moon colony rate ${1} step(s)', function (b) { return b.player(player).number(increment); });
                    player.increaseTerraformRatingSteps(count);
                    _this.bonus(moonData.colonyRate, increment, 3, function () {
                        player.drawCard();
                    });
                    _this.bonus(moonData.colonyRate, increment, 6, function () {
                        player.addProduction(Resources_1.Resources.ENERGY, 1, { log: true });
                    });
                    _this.activateLunaFirst(player, player.game, count);
                }
                moonData.colonyRate += increment;
            }
        });
    };
    MoonExpansion.raiseLogisticRate = function (player, count) {
        var _this = this;
        if (count === void 0) { count = 1; }
        MoonExpansion.ifMoon(player.game, function (moonData) {
            var available = constants_1.MAXIMUM_LOGISTICS_RATE - moonData.logisticRate;
            var increment = Math.min(count, available);
            if (increment > 0) {
                if (player.game.phase === Phase_1.Phase.SOLAR) {
                    player.game.log('${0} acted as World Government and raised the logistic rate ${1} step(s)', function (b) { return b.player(player).number(increment); });
                    _this.activateLunaFirst(undefined, player.game, increment);
                }
                else {
                    player.game.log('${0} raised the logistic rate ${1} step(s)', function (b) { return b.player(player).number(increment); });
                    player.increaseTerraformRatingSteps(count);
                    _this.bonus(moonData.logisticRate, increment, 3, function () {
                        player.drawCard();
                    });
                    _this.bonus(moonData.logisticRate, increment, 6, function () {
                        player.addProduction(Resources_1.Resources.STEEL, 1, { log: true });
                    });
                    _this.activateLunaFirst(player, player.game, increment);
                }
                moonData.logisticRate += increment;
            }
        });
    };
    MoonExpansion.activateLunaFirst = function (sourcePlayer, game, count) {
        var lunaFirstPlayer = MoonExpansion.moonData(game).lunaFirstPlayer;
        if (lunaFirstPlayer !== undefined) {
            lunaFirstPlayer.addResource(Resources_1.Resources.MEGACREDITS, count, { log: true });
            if (lunaFirstPlayer.id === (sourcePlayer === null || sourcePlayer === void 0 ? void 0 : sourcePlayer.id)) {
                lunaFirstPlayer.addProduction(Resources_1.Resources.MEGACREDITS, count, { log: true });
            }
        }
    };
    MoonExpansion.spaceHasType = function (space, type) {
        if (space.tile === undefined) {
            return false;
        }
        if (space.tile.tileType === type) {
            return true;
        }
        if (space.tile.tileType === TileType_1.TileType.LUNAR_MINE_URBANIZATION) {
            return type === TileType_1.TileType.MOON_COLONY || type === TileType_1.TileType.MOON_MINE;
        }
        return false;
    };
    MoonExpansion.tiles = function (game, tileType, options) {
        return MoonExpansion.ifElseMoon(game, function (moonData) {
            return moonData.moon.spaces.filter(function (space) {
                if (space.tile === undefined) {
                    return false;
                }
                var include = true;
                if (tileType) {
                    include = MoonExpansion.spaceHasType(space, tileType);
                }
                if (include && (options === null || options === void 0 ? void 0 : options.surfaceOnly)) {
                    include = space.spaceType !== SpaceType_1.SpaceType.COLONY;
                }
                if (include && (options === null || options === void 0 ? void 0 : options.ownedBy) !== undefined) {
                    include = space.player === (options === null || options === void 0 ? void 0 : options.ownedBy);
                }
                return include;
            });
        }, function () { return []; });
    };
    MoonExpansion.adjustedReserveCosts = function (player, card) {
        if (player.cardIsInEffect(CardName_1.CardName.LTF_PRIVILEGES) && card.tags.includes(Tags_1.Tags.MOON)) {
            return Units_1.Units.EMPTY;
        }
        var reserveUnits = card.reserveUnits || Units_1.Units.EMPTY;
        var heat = reserveUnits.heat || 0;
        var steel = reserveUnits.steel || 0;
        var titanium = reserveUnits.titanium || 0;
        var tilesBuilt = card.tilesBuilt || [];
        if (tilesBuilt.includes(TileType_1.TileType.MOON_COLONY) && player.cardIsInEffect(CardName_1.CardName.SUBTERRANEAN_HABITATS)) {
            titanium -= 1;
        }
        if (tilesBuilt.includes(TileType_1.TileType.MOON_MINE) && player.cardIsInEffect(CardName_1.CardName.IMPROVED_MOON_CONCRETE)) {
            titanium -= 1;
        }
        if (tilesBuilt.includes(TileType_1.TileType.MOON_ROAD) && player.cardIsInEffect(CardName_1.CardName.LUNAR_DUST_PROCESSING_PLANT)) {
            steel = 0;
        }
        steel = Math.max(steel, 0);
        titanium = Math.max(titanium, 0);
        return Units_1.Units.of({ steel: steel, titanium: titanium, heat: heat });
    };
    MoonExpansion.calculateVictoryPoints = function (player, vpb) {
        MoonExpansion.ifMoon(player.game, function (moonData) {
            var moon = moonData.moon;
            var mySpaces = moon.spaces.filter(function (space) { var _a; return ((_a = space.player) === null || _a === void 0 ? void 0 : _a.id) === player.id; });
            mySpaces.forEach(function (space) {
                if (space.tile !== undefined) {
                    var type = space.tile.tileType;
                    switch (type) {
                        case TileType_1.TileType.MOON_ROAD:
                            vpb.setVictoryPoints('moon road', 1);
                            break;
                        case TileType_1.TileType.MOON_MINE:
                        case TileType_1.TileType.MOON_COLONY:
                        case TileType_1.TileType.LUNAR_MINE_URBANIZATION:
                            var points = moon.getAdjacentSpaces(space).filter(function (adj) { return MoonExpansion.spaceHasType(adj, TileType_1.TileType.MOON_ROAD); }).length;
                            if (type === TileType_1.TileType.MOON_MINE || type === TileType_1.TileType.LUNAR_MINE_URBANIZATION) {
                                vpb.setVictoryPoints('moon mine', points);
                            }
                            if (type === TileType_1.TileType.MOON_COLONY || type === TileType_1.TileType.LUNAR_MINE_URBANIZATION) {
                                vpb.setVictoryPoints('moon colony', points);
                            }
                            break;
                    }
                }
            });
        });
    };
    MoonExpansion.MOON_TILES = new Set([
        TileType_1.TileType.MOON_MINE,
        TileType_1.TileType.MOON_COLONY,
        TileType_1.TileType.MOON_ROAD,
        TileType_1.TileType.LUNA_TRADE_STATION,
        TileType_1.TileType.LUNA_MINING_HUB,
        TileType_1.TileType.LUNA_TRAIN_STATION,
        TileType_1.TileType.LUNAR_MINE_URBANIZATION,
    ]);
    return MoonExpansion;
}());
exports.MoonExpansion = MoonExpansion;
