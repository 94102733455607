"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Supplier = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("./PreludeCard");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Supplier = (function (_super) {
    __extends(Supplier, _super);
    function Supplier() {
        return _super.call(this, {
            name: CardName_1.CardName.SUPPLIER,
            tags: [Tags_1.Tags.ENERGY],
            metadata: {
                cardNumber: 'P32',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.energy(2); }).br;
                    b.steel(4);
                }),
                description: 'Increase your energy production 2 steps. Gain 4 steel.',
            },
        }) || this;
    }
    Supplier.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 2);
        player.steel += 4;
        return undefined;
    };
    return Supplier;
}(PreludeCard_1.PreludeCard));
exports.Supplier = Supplier;
