"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRELUDE_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var AcquiredSpaceAgency_1 = require("./AcquiredSpaceAgency");
var AlliedBanks_1 = require("./AlliedBanks");
var AquiferTurbines_1 = require("./AquiferTurbines");
var Biofuels_1 = require("./Biofuels");
var Biolab_1 = require("./Biolab");
var BiosphereSupport_1 = require("./BiosphereSupport");
var BusinessEmpire_1 = require("./BusinessEmpire");
var CheungShingMARS_1 = require("./CheungShingMARS");
var DomeFarming_1 = require("./DomeFarming");
var Donation_1 = require("./Donation");
var EarlySettlement_1 = require("./EarlySettlement");
var EccentricSponsor_1 = require("./EccentricSponsor");
var EcologyExperts_1 = require("./EcologyExperts");
var ExperimentalForest_1 = require("./ExperimentalForest");
var GalileanMining_1 = require("./GalileanMining");
var GreatAquifer_1 = require("./GreatAquifer");
var HousePrinting_1 = require("./HousePrinting");
var HugeAsteroid_1 = require("./HugeAsteroid");
var IoResearchOutpost_1 = require("./IoResearchOutpost");
var LavaTubeSettlement_1 = require("./LavaTubeSettlement");
var Loan_1 = require("./Loan");
var MartianIndustries_1 = require("./MartianIndustries");
var MartianSurvey_1 = require("./MartianSurvey");
var MetalRichAsteroid_1 = require("./MetalRichAsteroid");
var MetalsCompany_1 = require("./MetalsCompany");
var MiningOperations_1 = require("./MiningOperations");
var Mohole_1 = require("./Mohole");
var MoholeExcavation_1 = require("./MoholeExcavation");
var NitrogenDelivery_1 = require("./NitrogenDelivery");
var OrbitalConstructionYard_1 = require("./OrbitalConstructionYard");
var PointLuna_1 = require("./PointLuna");
var PolarIndustries_1 = require("./PolarIndustries");
var PowerGeneration_1 = require("./PowerGeneration");
var Psychrophiles_1 = require("./Psychrophiles");
var ResearchCoordination_1 = require("./ResearchCoordination");
var ResearchNetwork_1 = require("./ResearchNetwork");
var RobinsonIndustries_1 = require("./RobinsonIndustries");
var SelfSufficientSettlement_1 = require("./SelfSufficientSettlement");
var SFMemorial_1 = require("./SFMemorial");
var SmeltingPlant_1 = require("./SmeltingPlant");
var SocietySupport_1 = require("./SocietySupport");
var SpaceHotels_1 = require("./SpaceHotels");
var Supplier_1 = require("./Supplier");
var SupplyDrop_1 = require("./SupplyDrop");
var UNMIContractor_1 = require("./UNMIContractor");
var ValleyTrust_1 = require("./ValleyTrust");
var Vitor_1 = require("./Vitor");
exports.PRELUDE_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Prelude,
    projectCards: [
        { cardName: CardName_1.CardName.SF_MEMORIAL, Factory: SFMemorial_1.SFMemorial },
        { cardName: CardName_1.CardName.HOUSE_PRINTING, Factory: HousePrinting_1.HousePrinting },
        { cardName: CardName_1.CardName.SPACE_HOTELS, Factory: SpaceHotels_1.SpaceHotels },
        { cardName: CardName_1.CardName.MARTIAN_SURVEY, Factory: MartianSurvey_1.MartianSurvey },
        { cardName: CardName_1.CardName.RESEARCH_COORDINATION, Factory: ResearchCoordination_1.ResearchCoordination },
        { cardName: CardName_1.CardName.LAVA_TUBE_SETTLEMENT, Factory: LavaTubeSettlement_1.LavaTubeSettlement },
        { cardName: CardName_1.CardName.PSYCHROPHILES, Factory: Psychrophiles_1.Psychrophiles },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.CHEUNG_SHING_MARS, Factory: CheungShingMARS_1.CheungShingMARS },
        { cardName: CardName_1.CardName.POINT_LUNA, Factory: PointLuna_1.PointLuna },
        { cardName: CardName_1.CardName.ROBINSON_INDUSTRIES, Factory: RobinsonIndustries_1.RobinsonIndustries },
        { cardName: CardName_1.CardName.VALLEY_TRUST, Factory: ValleyTrust_1.ValleyTrust },
        { cardName: CardName_1.CardName.VITOR, Factory: Vitor_1.Vitor },
    ],
    preludeCards: [
        { cardName: CardName_1.CardName.ALLIED_BANKS, Factory: AlliedBanks_1.AlliedBanks },
        { cardName: CardName_1.CardName.BIOSPHERE_SUPPORT, Factory: BiosphereSupport_1.BiosphereSupport },
        { cardName: CardName_1.CardName.AQUIFER_TURBINES, Factory: AquiferTurbines_1.AquiferTurbines },
        { cardName: CardName_1.CardName.MOHOLE_EXCAVATION, Factory: MoholeExcavation_1.MoholeExcavation },
        { cardName: CardName_1.CardName.EARLY_SETTLEMENT, Factory: EarlySettlement_1.EarlySettlement },
        { cardName: CardName_1.CardName.BIOFUELS, Factory: Biofuels_1.Biofuels },
        { cardName: CardName_1.CardName.POWER_GENERATION, Factory: PowerGeneration_1.PowerGeneration },
        { cardName: CardName_1.CardName.SELF_SUFFICIENT_SETTLEMENT, Factory: SelfSufficientSettlement_1.SelfSufficientSettlement },
        { cardName: CardName_1.CardName.MINING_OPERATIONS, Factory: MiningOperations_1.MiningOperations },
        { cardName: CardName_1.CardName.UNMI_CONTRACTOR, Factory: UNMIContractor_1.UNMIContractor },
        { cardName: CardName_1.CardName.DOME_FARMING, Factory: DomeFarming_1.DomeFarming },
        { cardName: CardName_1.CardName.BUSINESS_EMPIRE, Factory: BusinessEmpire_1.BusinessEmpire },
        { cardName: CardName_1.CardName.DONATION, Factory: Donation_1.Donation },
        { cardName: CardName_1.CardName.NITROGEN_SHIPMENT, Factory: NitrogenDelivery_1.NitrogenDelivery },
        { cardName: CardName_1.CardName.SMELTING_PLANT, Factory: SmeltingPlant_1.SmeltingPlant },
        { cardName: CardName_1.CardName.SUPPLIER, Factory: Supplier_1.Supplier },
        { cardName: CardName_1.CardName.SUPPLY_DROP, Factory: SupplyDrop_1.SupplyDrop },
        { cardName: CardName_1.CardName.GREAT_AQUIFER, Factory: GreatAquifer_1.GreatAquifer },
        { cardName: CardName_1.CardName.BIOLAB, Factory: Biolab_1.Biolab },
        { cardName: CardName_1.CardName.MARTIAN_INDUSTRIES, Factory: MartianIndustries_1.MartianIndustries },
        { cardName: CardName_1.CardName.IO_RESEARCH_OUTPOST, Factory: IoResearchOutpost_1.IoResearchOutpost },
        { cardName: CardName_1.CardName.POLAR_INDUSTRIES, Factory: PolarIndustries_1.PolarIndustries },
        { cardName: CardName_1.CardName.SOCIETY_SUPPORT, Factory: SocietySupport_1.SocietySupport },
        { cardName: CardName_1.CardName.GALILEAN_MINING, Factory: GalileanMining_1.GalileanMining },
        { cardName: CardName_1.CardName.HUGE_ASTEROID, Factory: HugeAsteroid_1.HugeAsteroid },
        { cardName: CardName_1.CardName.METALS_COMPANY, Factory: MetalsCompany_1.MetalsCompany },
        { cardName: CardName_1.CardName.LOAN, Factory: Loan_1.Loan },
        { cardName: CardName_1.CardName.MOHOLE, Factory: Mohole_1.Mohole },
        { cardName: CardName_1.CardName.METAL_RICH_ASTEROID, Factory: MetalRichAsteroid_1.MetalRichAsteroid },
        { cardName: CardName_1.CardName.ORBITAL_CONSTRUCTION_YARD, Factory: OrbitalConstructionYard_1.OrbitalConstructionYard },
        { cardName: CardName_1.CardName.ACQUIRED_SPACE_AGENCY, Factory: AcquiredSpaceAgency_1.AcquiredSpaceAgency },
        { cardName: CardName_1.CardName.RESEARCH_NETWORK, Factory: ResearchNetwork_1.ResearchNetwork },
        { cardName: CardName_1.CardName.ECCENTRIC_SPONSOR, Factory: EccentricSponsor_1.EccentricSponsor },
        { cardName: CardName_1.CardName.ECOLOGY_EXPERTS, Factory: EcologyExperts_1.EcologyExperts },
        { cardName: CardName_1.CardName.EXPERIMENTAL_FOREST, Factory: ExperimentalForest_1.ExperimentalForest },
    ],
});
