"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardProjectCard = void 0;
var CardType_1 = require("./CardType");
var SelectHowToPayDeferred_1 = require("../deferredActions/SelectHowToPayDeferred");
var Card_1 = require("./Card");
var MoonExpansion_1 = require("../moon/MoonExpansion");
var StandardProjectCard = (function (_super) {
    __extends(StandardProjectCard, _super);
    function StandardProjectCard(properties) {
        return _super.call(this, __assign({ cardType: CardType_1.CardType.STANDARD_PROJECT }, properties)) || this;
    }
    StandardProjectCard.prototype.discount = function (_player) {
        return 0;
    };
    StandardProjectCard.prototype.play = function () {
        return undefined;
    };
    StandardProjectCard.prototype.onStandardProject = function (player) {
        var _a;
        if (((_a = player.corporationCard) === null || _a === void 0 ? void 0 : _a.onStandardProject) !== undefined) {
            player.corporationCard.onStandardProject(player, this);
        }
        for (var _i = 0, _b = player.playedCards; _i < _b.length; _i++) {
            var playedCard = _b[_i];
            if (playedCard.onStandardProject !== undefined) {
                playedCard.onStandardProject(player, this);
            }
        }
    };
    StandardProjectCard.prototype.canAct = function (player) {
        var canPayWith = this.canPayWith(player);
        return player.canAfford(this.cost - this.discount(player), __assign(__assign({}, canPayWith), { tr: this.tr, reserveUnits: MoonExpansion_1.MoonExpansion.adjustedReserveCosts(player, this) }));
    };
    StandardProjectCard.prototype.canPayWith = function (_player) {
        return {};
    };
    StandardProjectCard.prototype.projectPlayed = function (player) {
        var _this = this;
        player.game.log('${0} used ${1} standard project', function (b) { return b.player(player).card(_this); });
        this.onStandardProject(player);
    };
    StandardProjectCard.prototype.suffixFreeCardName = function (cardName) {
        return cardName.split(':')[0];
    };
    ;
    StandardProjectCard.prototype.action = function (player) {
        var _this = this;
        var canPayWith = this.canPayWith(player);
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, this.cost - this.discount(player), {
            canUseSteel: canPayWith.steel,
            canUseTitanium: canPayWith.titanium,
            title: "Select how to pay for " + this.suffixFreeCardName(this.name) + " standard project",
            afterPay: function () {
                _this.projectPlayed(player);
                _this.actionEssence(player);
            },
        }));
        return undefined;
    };
    return StandardProjectCard;
}(Card_1.Card));
exports.StandardProjectCard = StandardProjectCard;
