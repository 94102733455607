"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenusianPlants = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var VenusianPlants = (function (_super) {
    __extends(VenusianPlants, _super);
    function VenusianPlants() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.VENUSIAN_PLANTS,
            cost: 13,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.PLANT],
            tr: { venus: 1 },
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(16); }),
            metadata: {
                cardNumber: '261',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(1).br.br;
                    b.microbes(1, { secondaryTag: Tags_1.Tags.VENUS }).nbsp;
                    b.or().nbsp.animals(1, { secondaryTag: Tags_1.Tags.VENUS });
                }),
                description: {
                    text: 'Requires Venus 16%. Raise Venus 1 step. Add 1 Microbe or 1 Animal to ANOTHER VENUS CARD',
                    align: 'left',
                },
                victoryPoints: 1,
            },
        }) || this;
    }
    VenusianPlants.prototype.play = function (player) {
        player.game.increaseVenusScaleLevel(player, 1);
        var cards = this.getResCards(player);
        if (cards.length === 0)
            return undefined;
        if (cards.length === 1) {
            player.addResourceTo(cards[0], { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 resource', 'Add resource', cards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    VenusianPlants.prototype.getVictoryPoints = function () {
        return 1;
    };
    VenusianPlants.prototype.getResCards = function (player) {
        var resourceCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        resourceCards = resourceCards.concat(player.getResourceCards(ResourceType_1.ResourceType.ANIMAL));
        return resourceCards.filter(function (card) { return card.tags.includes(Tags_1.Tags.VENUS); });
    };
    return VenusianPlants;
}(Card_1.Card));
exports.VenusianPlants = VenusianPlants;
