"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectOption = void 0;
var PlayerInputTypes_1 = require("../PlayerInputTypes");
var SelectOption = (function () {
    function SelectOption(title, buttonLabel, cb) {
        if (buttonLabel === void 0) { buttonLabel = 'Select'; }
        this.title = title;
        this.buttonLabel = buttonLabel;
        this.cb = cb;
        this.inputType = PlayerInputTypes_1.PlayerInputTypes.SELECT_OPTION;
        this.buttonLabel = buttonLabel;
    }
    return SelectOption;
}());
exports.SelectOption = SelectOption;
